<template>
  <div class="container pt-60 pb-60">
    <div class="row align-items-center">
      <div style="text-align: center; margin-bottom: 20px;">
        <div class="main-title pb-30">
          NIỀNG RĂNG TRẺ EM
        </div>
        <div class="decs-intro-doctor">
          Niềng Răng Trẻ Em là giải pháp giúp điều chỉnh răng mọc lệch, khớp cắn sai và hỗ trợ phát triển hàm đúng cách. 
          Phương pháp này được khuyến nghị cho trẻ từ 6-12 tuổi để ngăn ngừa các vấn đề răng miệng về sau. Việc can thiệp 
          sớm không chỉ cải thiện thẩm mỹ mà còn đảm bảo chức năng ăn nhai và sức khỏe toàn diện cho trẻ.
        </div>
      </div>
    </div>
  </div>



</template>

<script>
import { ref } from "vue";
const currentDate = ref(new Date());
import AOS from "aos";
import "aos/dist/aos.css";
import indexfivespecialities from "@/assets/json/indexfivespecialities.json";
import 'vue3-carousel/dist/carousel.css'
export default {
  components: {
  },
  data() {
    return {
      startdate: currentDate,
      indexfivespecialities: indexfivespecialities,
      list: [],
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  props: {
    header: { type : Object}
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  watch:{
    '$props':{
      handler: function (val) {
        this.list = val.header;
        //  console.log("LOG WATCH LIST ", this.list )
      },
      deep: true
    }
  },
  methods: {
    submitForm() {
      this.$router.push("/search-2");
    },
  },
};
</script>
<style>
</style>
