<template>
    <div class="container pt-60 pb-60">
      <div class="row">
        <div class="col-md-12 who-trongrang">
          <div>
            <div class="main-title pb-30">Nha khoa trẻ em - Giai đoạn răng sữa</div>
            <div class="name-sub ">Tại sao chăm sóc răng sữa lại quan trọng?</div>
            <div class="card-who-trongrang pt-30">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Răng sữa là nền tảng cho răng vĩnh viễn: Răng sữa giữ chỗ cho răng vĩnh viễn mọc lên. Nếu răng sữa bị sâu, rụng sớm có thể dẫn đến tình trạng răng vĩnh viễn mọc lệch lạc, hô, móm.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Ảnh hưởng đến quá trình ăn nhai, phát âm: Răng sữa giúp trẻ nghiền nát thức ăn và phát âm rõ ràng.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Tự tin giao tiếp: Một hàm răng đều đẹp, trắng sáng giúp trẻ tự tin hơn trong giao tiếp.
              </div>
            </div>

            <div class="name-sub pt-30">Tại sao chăm sóc răng sữa lại quan trọng?</div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Thông thường, trẻ bắt đầu mọc răng sữa từ khoảng 6-12 tháng tuổi. Quá trình mọc răng sữa diễn ra theo một trình tự nhất định.
              </div>
            </div>

            <div class="name-sub pt-30">Các vấn đề thường gặp ở răng sữa?</div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Sâu răng: Là vấn đề phổ biến nhất ở trẻ em. Sâu răng nếu không được điều trị kịp thời có thể gây đau nhức, viêm tủy và ảnh hưởng đến răng vĩnh viễn.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Viêm nướu: Viêm nướu thường xảy ra khi trẻ không vệ sinh răng miệng đúng cách.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Răng mọc lệch: Răng mọc lệch có thể do nhiều nguyên nhân như: thói quen ngậm mút tay, mút môi, đẩy lưỡi...
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Chấn thương răng: Trẻ nhỏ thường hiếu động nên dễ bị va đập gây chấn thương răng.
              </div>
            </div>
            

            <div class="name-sub pt-30">Cách chăm sóc răng sữa cho trẻ?</div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Vệ sinh răng miệng hàng ngày: Cha mẹ nên giúp trẻ đánh răng ít nhất 2 lần/ngày bằng bàn chải lông mềm và kem đánh răng có hàm lượng fluor phù hợp với trẻ.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Khám răng định kỳ: Nên đưa trẻ đi khám răng định kỳ 6 tháng/lần để phát hiện và điều trị sớm các vấn đề về răng miệng.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Hạn chế cho trẻ ăn đồ ngọt: Đường là nguyên nhân chính gây sâu răng.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Xây dựng thói quen ăn uống lành mạnh: Khuyến khích trẻ ăn nhiều rau xanh, trái cây và các loại thực phẩm giàu canxi.
              </div>
            </div>
          </div>


        </div>
<!--        <div class="col-md-6">-->
<!--            <div class="img-trongrang">-->
<!--                <img src="@/assets/img/phuongthanh/dichvu/who.png" alt="" style="max-height: 500px;">-->
<!--            </div>-->
<!--        </div>-->
      </div>
    </div>



</template>

<script>
  import { ref } from "vue";
  const currentDate = ref(new Date());
  import AOS from "aos";
  import "aos/dist/aos.css";
  import 'vue3-carousel/dist/carousel.css'
  export default {
    components: {
    },
    data() {
      return {
        startdate: currentDate,
        list: [],
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    props: {
      header: { type : Object}
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
    watch:{
      '$props':{
        handler: function (val) {
          this.list = val.header;
        //  console.log("LOG WATCH LIST ", this.list )
        },
        deep: true
      }
    },
    methods: {
      submitForm() {
        this.$router.push("/search-2");
      },
    },
  };
</script>
<style>
</style>
