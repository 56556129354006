<template>
  <div class="container pt-60 pb-60">
    <div class="row align-items-center">
      <div style="text-align: center; margin-bottom: 20px;">
        <div class="main-title pb-30">
          NIỀNG RĂNG THẨM MỸ
        </div>
        <div class="decs-intro-doctor">Chỉnh nha (hay niềng răng) là phương pháp điều trị nha khoa chuyên sâu, sử dụng các khay niềng trong suốt, mắc cài, dây cung hoặc khí cụ chuyên dụng để điều chỉnh răng, xương về lại đúng hướng và đúng vị trí. Qua đó giúp khắc phục các khuyết điểm về răng, xương (như răng thưa, chen chúc, hô, móm,... ), giúp răng đều đẹp, khớp cắn chuẩn, cải thiện chức năng ăn nhai.
        </div>
      </div>
    </div>
  </div>



</template>

<script>
import { ref } from "vue";
const currentDate = ref(new Date());
import AOS from "aos";
import "aos/dist/aos.css";
import indexfivespecialities from "@/assets/json/indexfivespecialities.json";
import 'vue3-carousel/dist/carousel.css'
export default {
  components: {
  },
  data() {
    return {
      startdate: currentDate,
      indexfivespecialities: indexfivespecialities,
      list: [],
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  props: {
    header: { type : Object}
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  watch:{
    '$props':{
      handler: function (val) {
        this.list = val.header;
        //  console.log("LOG WATCH LIST ", this.list )
      },
      deep: true
    }
  },
  methods: {
    submitForm() {
      this.$router.push("/search-2");
    },
  },
};
</script>
<style>
</style>
