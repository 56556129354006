<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <!-- <breadcrumb :title="title" :text="text" :text1="text1" /> -->

    <div class="page-wrapper" style="padding-top: 120px;">
      <!-- Coming Soon -->
      <section class="error-section">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12 text-center">
              <div class="error-info">
                <div class="error-img">
                  <img src="@/assets/img/phuongthanh/logo/logo.png"
                       class="img-fluid"
                       alt="coming-soon-image"
                       style="width: 60%;">
                </div>
                <div class="error-content">
                  <p style="font-family: 'Poppins Black'; font-size: 22px; color: #000; margin-bottom: 10px;">
                    TRANG ĐANG TRONG QUÁ TRÌNH PHÁT TRIỂN !
                  </p>
                  <p>Chúng tôi hiện đang làm việc chăm chỉ để phát triển trang này.</p>
                </div>
                <!-- <div class="coming-soon-info">
                    <div class="coming-soon-count">
                        <h4 id="day-box">5</h4>
                        <p>Days</p>
                    </div>
                    <div class="coming-soon-count">
                        <h4 id="hr-box">8</h4>
                        <p>Hours</p>
                    </div>
                    <div class="coming-soon-count">
                        <h4 id="min-box">48</h4>
                        <p>Minutes</p>
                    </div>
                    <div class="coming-soon-count">
                        <h4 id="sec-box">20</h4>
                        <p>Seconds</p>
                    </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- /Coming Soon -->
    </div>
  </div>
  <footerHome></footerHome>
</template>

<script>
export default {
  data() {
    return {
      title: 'Đang trong quá trình phát triển',
      text: 'Home',
      text1: 'Đang trong quá trình phát triển',
    }
  },
}
</script>
