<template>
  <!-- Service Section -->
  <section class="service-section section-list-service pt-60 pb-60">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-header-one section-header-slider">
            <div class="main-title pb-30"><span>Dịch vụ nha khoa chuyên sâu</span></div>
          </div>
        </div>
      </div>
      <div class="listing-service">
        <div class="row">
          <div class="col-xl-4 col-md-6 col-12">
            <router-link
                :to="{
                  path: `/trong-rang-implant`,
                }"
            >
              <div class="service-item">
                <div class="service-thumb">
                  <a href="">
                    <img width="300" height="208" src="@/assets/img/phuongthanh/trangchu/implant.png" class="attachment-medium img-dichvu size-medium wp-post-image" alt="Ảnh" decoding="async" fetchpriority="high" srcset="" sizes="100vw">
                  </a>
                </div>
                <div class="service-content">
                  <span>Giải pháp tiên tiến cho người mất răng</span>
                  <div class="service-title">
                    <h3>
                      <a href="" data-wpel-link="internal">
                        Trồng răng Implant
                      </a>
                    </h3>
                  </div>
                  <div class="service-excerpt">
                    <!-- <p>Giải pháp tiên tiến cho người mất răng</p> -->
                    <p>Đội ngũ bác sĩ cấy ghép Implant hơn 10 năm kinh nghiệm, tu học chuyên sâu tại nước ngoài. Kết hợp với ứng dụng kỹ thuật số hiện đại đem đến hành trình trồng răng nhẹ nhàng, nhanh chóng và kết quả chính xác, bền vững.</p>
                  </div>
                  <div class="service-button">
                    <a href="" data-wpel-link="internal">
                      <div>
                        Xem thêm
                      </div>
                      <i class="fa fa-solid fa-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-xl-4 col-md-6 col-12">
            <router-link
                :to="{
                  path: `/nieng-rang`,
                }"
            >
              <div class="service-item">
                <div class="service-thumb">
                  <a href="">
                    <img width="300" height="208" src="@/assets/img/phuongthanh/trangchu/nieng_rang.jpg" class="attachment-medium img-dichvu size-medium wp-post-image" alt="Ảnh" decoding="async" fetchpriority="high" srcset="" sizes="100vw">
                  </a>
                </div>
                <div class="service-content">
                  <span>Lấy lại nụ cười thẳng đều, chuẩn khớp cắn</span>
                  <div class="service-title">
                    <h3>
                      <a href="" data-wpel-link="internal">
                        Niềng răng
                      </a>
                    </h3>
                  </div>
                  <div class="service-excerpt">
                    <!-- <p>Giải pháp tiên tiến cho người mất răng</p> -->
                    <p>Thực hiện bởi bác sĩ giàu kinh nghiệm thực chiến niềng răng mắc cài và niềng răng trong suốt, theo dõi sát sao suốt quá trình điều trị để đảm bảo răng dịch chuyển đúng kế hoạch và có kết quả khỏe đẹp, bền vững.</p>
                  </div>
                  <div class="service-button">
                    <a href="" data-wpel-link="internal">
                      <div>
                        Xem thêm
                      </div>
                      <i class="fa fa-solid fa-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-xl-4 col-md-6 col-12">
            <router-link
                :to="{
                  path: `/nha-khoa-tham-my`,
                }"
            >
              <div class="service-item">
                <div class="service-thumb">
                  <a href="">
                    <img width="300" height="208" src="@/assets/img/phuongthanh/trangchu/tham_my.png" class="attachment-medium img-dichvu size-medium wp-post-image" alt="Ảnh" decoding="async" fetchpriority="high" srcset="" sizes="100vw">
                  </a>
                </div>
                <div class="service-content">
                  <span>Khắc phục khiếm khuyết của răng và nướu</span>
                  <div class="service-title">
                    <h3>
                      <a href="" data-wpel-link="internal">
                        Nha khoa thẩm mỹ
                      </a>
                    </h3>
                  </div>
                  <div class="service-excerpt">
                    <!-- <p>Giải pháp tiên tiến cho người mất răng</p> -->
                    <p>Bao gồm các dịch vụ dán sứ veneer, bọc răng sứ, chữa cười hở lợi, tẩy trắng răng,… mang lại kết quả sau cùng không chỉ hướng đến thẩm mỹ nụ cười, mà còn đảm bảo sức khỏe răng miệng lâu dài cho khách hàng.</p>
                  </div>
                  <div class="service-button">
                    <a href="" data-wpel-link="internal">
                      <div>
                        Xem thêm
                      </div>
                      <i class="fa fa-solid fa-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-xl-4 col-md-6 col-12">
            <router-link
                :to="{
                  path: `/nha-khoa-tong-quat`,
                }"
            >
              <div class="service-item">
                <div class="service-thumb">
                  <a href="">
                    <img width="300" height="208" src="@/assets/img/phuongthanh/trangchu/tong_quat.png" class="attachment-medium img-dichvu size-medium wp-post-image" alt="Ảnh" decoding="async" fetchpriority="high" srcset="" sizes="100vw">
                  </a>
                </div>
                <div class="service-content">
                  <span>Chẩn đoán và điều trị kịp thời bệnh lý răng miệng</span>
                  <div class="service-title">
                    <h3>
                      <a href="" data-wpel-link="internal">
                        Nha khoa tổng quát
                      </a>
                    </h3>
                  </div>
                  <div class="service-excerpt">
                    <!-- <p>Giải pháp tiên tiến cho người mất răng</p> -->
                    <p>Giải quyết triệt để các bệnh về răng miệng với phương châm “xâm lấn tối thiểu – bảo tồn răng thật tối đa”. Sau mỗi điều trị, bác sĩ còn đưa ra lời khuyên để khách hàng chăm sóc sức khỏe răng miệng đúng cách.</p>
                  </div>
                  <div class="service-button">
                    <a href="" data-wpel-link="internal">
                      <div>
                        Xem thêm
                      </div>
                      <i class="fa fa-solid fa-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-xl-4 col-md-6 col-12">
            <router-link
                :to="{
                  path: `/nha-khoa-tre-em`,
                }"
            >
              <div class="service-item">
                <div class="service-thumb">
                  <a href="">
                    <img width="300" height="208" src="@/assets/img/phuongthanh/trangchu/tre_em.png" class="attachment-medium img-dichvu size-medium wp-post-image" alt="Ảnh" decoding="async" fetchpriority="high" srcset="" sizes="100vw">
                  </a>
                </div>
                <div class="service-content">
                  <span>Chăm sóc và điều trị vấn đề răng miệng ở trẻ</span>
                  <div class="service-title">
                    <h3>
                      <a href="" data-wpel-link="internal">
                        Nha khoa trẻ em
                      </a>
                    </h3>
                  </div>
                  <div class="service-excerpt">
                    <!-- <p>Giải pháp tiên tiến cho người mất răng</p> -->
                    <p>Đa dạng dịch vụ điều trị từ trám răng sâu, nhổ răng đến chỉnh nha cho trẻ từ 6 tuổi. Bác sĩ thấu hiểu tâm lý trẻ em, giao tiếp nhẹ nhàng và đầu tư khu vui chơi chuyên biệt để trẻ thoải mái mỗi khi thăm khám.</p>
                  </div>
                  <div class="service-button">
                    <a href="" data-wpel-link="internal">
                      <div>
                        Xem thêm
                      </div>
                      <i class="fa fa-solid fa-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-xl-4 col-md-6 col-12">
            <router-link
                :to="{
                  path: `/nha-khoa-tong-quat/nho-rang-khon`,
                }"
            >
              <div class="service-item">
                <div class="service-thumb">
                  <a href="">
                    <img width="300" height="208" src="@/assets/img/phuongthanh/trangchu/rang_khon.png" class="attachment-medium img-dichvu size-medium wp-post-image" alt="Ảnh" decoding="async" fetchpriority="high" srcset="" sizes="100vw">
                  </a>
                </div>
                <div class="service-content">
                  <span>Giải pháp khắc phục các vấn đề do răng khôn</span>
                  <div class="service-title">
                    <h3>
                      <a href="" data-wpel-link="internal">
                        Nhổ răng khôn
                      </a>
                    </h3>
                  </div>
                  <div class="service-excerpt">
                    <!-- <p>Giải pháp tiên tiến cho người mất răng</p> -->
                    <p>Chuyên khoa tiểu phẫu riêng biệt với bác sĩ giỏi và công nghệ hiện đại, mang đến trải nghiệm nhổ răng khôn không đau, chấm dứt tình trạng răng khôn mọc lệch, kiểm soát tốt chấn thương dây thần kinh nếu có.</p>
                  </div>
                  <div class="service-button">
                    <a href="" data-wpel-link="internal">
                      <div>
                        Xem thêm
                      </div>
                      <i class="fa fa-solid fa-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Service Section -->

</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
import indexFiveService from "@/assets/json/indexfiveservice.json";
export default {
  data() {
    return {
      indexFiveService: indexFiveService,
      list: [],
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  created() {
    // this.getData();
  },
  methods: {
    // async getData() {
    //     let params = {
    //         start: this.currentPage,
    //         limit: this.perPage,
    //         sortBy: this.sortBy,
    //     }
    //     await this.$store.dispatch("dichVuStore/getPagingParams", params ).then(res => {
    //         this.list = res.data.data
    //     });
    // },
    toggleFavorite(item) {
      item.favorite = !item.favorite;
    },
  },
};
</script>

<style>
.img-service{
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 12px;
}

@media (max-width: 576px) {
  .img-service{
    height: 300px;
  }
}
</style>
