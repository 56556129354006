<template>
  <div class="main-wrapper home-ten">
    <layoutheader :class="{ 'header-space': showHeaderSpace }" ref="header" />
    <section class="banner-section">
      <div class="banner-slider">
        <img class="image-slider" src="@/assets/img/phuongthanh/banner.png" alt="">
        <div class="name-slider">{{menu}}</div>
      </div>
    </section>

    <section class="need-to-know-section section-list-service pt-60 pb-60">
      <div class="container">
        <div class="listing-service">
          <div class="row">
            <div class="col-xl-4 col-md-6 col-12">
              <router-link
                  :to="{
                  path: `/trong-rang-implant`,
                }"
              >
                <div class="service-item">
                  <div class="service-thumb">
                    <a href="">
                      <a href="">
                        <img width="300" height="208" src="@/assets/img/phuongthanh/trangchu/implant.png" class="attachment-medium img-dichvu size-medium wp-post-image" alt="Ảnh" decoding="async" fetchpriority="high" srcset="" sizes="100vw">
                      </a>
                    </a>
                  </div>
                  <div class="service-content">
                    <span>Giải pháp tiên tiến cho người mất răng</span>
                    <div class="service-title">
                      <h3>
                        <a href="" data-wpel-link="internal">
                          Trồng răng Implant
                        </a>
                      </h3>
                    </div>
                    <div class="service-excerpt">
                      <!-- <p>Giải pháp tiên tiến cho người mất răng</p> -->
                      <p>Đội ngũ bác sĩ cấy ghép Implant hơn 10 năm kinh nghiệm, tu học chuyên sâu tại nước ngoài. Kết hợp với ứng dụng kỹ thuật số hiện đại đem đến hành trình trồng răng nhẹ nhàng, nhanh chóng và kết quả chính xác, bền vững.</p>
                    </div>
                    <div class="service-button">
                      <a href="" data-wpel-link="internal">
                        <div>
                          Xem thêm
                        </div>
                        <i class="fa fa-solid fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>

            <div class="col-xl-4 col-md-6 col-12">
              <router-link
                  :to="{
                     path: `/nieng-rang`,
                  }"
              >
                <div class="service-item">
                  <div class="service-thumb">
                    <a href="">
                      <a href="">
                        <img width="300" height="208" src="@/assets/img/phuongthanh/trangchu/nieng_rang.jpg" class="attachment-medium img-dichvu size-medium wp-post-image" alt="Ảnh" decoding="async" fetchpriority="high" srcset="" sizes="100vw">
                      </a>
                    </a>
                  </div>
                  <div class="service-content">
                    <span>Lấy lại nụ cười thẳng đều, chuẩn khớp cắn</span>
                    <div class="service-title">
                      <h3>
                        <a href="" data-wpel-link="internal">
                          Niềng răng
                        </a>
                      </h3>
                    </div>
                    <div class="service-excerpt">
                      <!-- <p>Giải pháp tiên tiến cho người mất răng</p> -->
                      <p>Thực hiện bởi bác sĩ giàu kinh nghiệm thực chiến niềng răng mắc cài và niềng răng trong suốt, theo dõi sát sao suốt quá trình điều trị để đảm bảo răng dịch chuyển đúng kế hoạch và có kết quả khỏe đẹp, bền vững.</p>
                    </div>
                    <div class="service-button">
                      <a href="" data-wpel-link="internal">
                        <div>
                          Xem thêm
                        </div>
                        <i class="fa fa-solid fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-xl-4 col-md-6 col-12">
              <router-link
                  :to="{
                   path: `/nha-khoa-tham-my`,
                  }"
              >
                <div class="service-item">
                  <div class="service-thumb">
                    <a href="">
                      <a href="">
                        <img width="300" height="208" src="@/assets/img/phuongthanh/trangchu/tham_my.png" class="attachment-medium img-dichvu size-medium wp-post-image" alt="Ảnh" decoding="async" fetchpriority="high" srcset="" sizes="100vw">
                      </a>
                    </a>
                  </div>
                  <div class="service-content">
                    <span>Khắc phục khiếm khuyết của răng và nướu</span>
                    <div class="service-title">
                      <h3>
                        <a href="" data-wpel-link="internal">
                          Nha khoa thẩm mỹ
                        </a>
                      </h3>
                    </div>
                    <div class="service-excerpt">
                      <!-- <p>Giải pháp tiên tiến cho người mất răng</p> -->
                      <p>Bao gồm các dịch vụ dán sứ veneer, bọc răng sứ, chữa cười hở lợi, tẩy trắng răng,… mang lại kết quả sau cùng không chỉ hướng đến thẩm mỹ nụ cười, mà còn đảm bảo sức khỏe răng miệng lâu dài cho khách hàng.</p>
                    </div>
                    <div class="service-button">
                      <a href="" data-wpel-link="internal">
                        <div>
                          Xem thêm
                        </div>
                        <i class="fa fa-solid fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <dangky></dangky>

    <div class="page-wrapper pt-60 pb-60">
      <!-- Page Content -->
      <div class="container">
        <div class="row pb-3" v-if="isArray">
          <div class="col-lg-8 col-md-12">
            <div v-for="(item, index) in model" :key="index">
              <router-link :to="{
                              path: `chi-tiet/${item.id}`
                            }">
                <div class="row card-array">
                  <div class="col-md-4 col-sm-4" style="padding: 0; height: 100%">
                    <img
                        v-if="item.fileImage"
                        class="img-fluid card-array-img"
                        :src="item.fileImage.urlFile"
                        alt="Post Image"
                    />
                  </div>
                  <div class="col-md-8 col-sm-8" style="padding: 25px; display: flex; align-items: center;">
                    <div>
                      <div class="card-array-sub">
                        <p>Kiến thức nha khoa</p>
                      </div>
                      <div class="card-array-title">
                        <p>{{ item.name }}</p>
                      </div>
                      <div class="card-array-desc">
                        <p>{{ item.describe }}</p>
                      </div>
                      <b-button
                          class="btn-card-array"
                          style="font-size: 14px;"
                      >
                        <div>
                          Xem thêm
                        </div>
                        <i class="fa fa-solid fa-arrow-right"></i>
                      </b-button>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="mt-2" style="display: flex; justify-content: flex-end; padding: 10px">
              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  size="sm"
                  class="my-0"
              />
            </div>
          </div>
          <blog-sidebar></blog-sidebar>
        </div>

        <div class="row"  v-else>
          <div class="col-lg-8 col-md-12">
            <div class="blog-view">
              <div class="terms-content"  v-if="model && model.content">
                <div class="noidung" v-html="model.content">
                </div>
              </div>
            </div>
          </div>

          <blog-sidebar></blog-sidebar>
        </div>
      </div>
      <!-- /Page Content -->
    </div>

    <lichlamviec></lichlamviec>
  </div>
  <footerHome></footerHome>
</template>
<script>
import BlogGrid from "@/assets/json/blog-grid.json";
import BlogSidebar from "@/views/News/baiviet/newSidebar.vue";
export default {
  components: {BlogSidebar},
  data() {
    return {
      title: 'Giới thiệu',
      text: 'Trang chủ',
      text1: 'Giới thiệu',
      numberOfElement: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      model: [],
      menu: null,
      list: [],
      BlogGrid: BlogGrid,
      url: `${process.env.VUE_APP_API_URL}files/view/`,
      isArray : true,
    }
  },

  watch: {
    $route(to, from) {
      //  console.log("LOG WATCH DATA : ")
      this.getData();
      this.getMenuName();
    },
    perPage: {
      deep: true,
      handler(val){
        this.getData();
      }
    },
    currentPage:{
      deep: true,
      handler(val){
        //   console.log("this.perpage", this.currentPage);
        this.getData();
      }
    }
  },


  mounted() {


    this.getData();
    this.getList();
  },
  methods: {
    async getList() {
      let params = {
        start: this.currentPage,
        limit: this.perPage,
        sortBy: this.sortBy,
      }
      await this.$store.dispatch("dichVuStore/getPagingParams", params ).then(res => {
        this.list = res.data.data

      });
    },
    async getData() {
      const params = {
        start: this.currentPage,
        limit: this.perPage,
        sortBy: "sort",
        sortDesc: true,
        menuId : this.$route.params.id

      };
      await this.$store.dispatch("newsStore/getByMenuId", params).then((res) => {
        // console.log("LOG GIOI THIEU  ABC  : ", res)
        if (res.data != null && res.data.data != null && Array.isArray(res.data.data))
        {
          //    console.log("LA ARRAY : ", res.data)
          this.isArray = true;
          this.model = res.data.data;
          // this.title = (res.data != null && res.data.data.length > 0) ? res.data.data[0].menu.name : "";
          // this.text1= (res.data != null && res.data.data.length > 0) ? res.data.data[0].menu.name : "";
          // this.menu = (res.data != null && res.data.data.length > 0) ? res.data.data[0].menu.name : "";
          this.totalRows = res.data.totalRows;
          this.numberOfElement = res.data.data.length;
        }else if (res.data != null){
          //    console.log("KHÔNG LA ARRAY 123: ", res)
          this.isArray = false;
          this.model = res.data;
          // this.title = this.model != null ? this.model.name : "";
          // this.text1= (this.model != null && this.model.menu) ? this.model.menu.name : "";
          // this.menu = (this.model != null && this.model.menu) ? this.model.menu.name : "";
          // if(this.model.content && this.model.content != null){
          //   this.model.content = this.model.content.replaceAll("http://phuongthanh.goldenkeysoft.com/api/v1/files/view/", this.url)
          // }
        }

      })
    },

    async getMenuName()
    {
      var data = localStorage.getItem('menu')
      if (data != null) {
        console.log("LOG DATA :", data)
        var dataMenu = JSON.parse(data);
        if (dataMenu != null) {
          console.log("LOG DATA MENU :", dataMenu.label)
          this.menu = dataMenu.label;
        }
      }
    }
  },
  created() {
  //  console.log("LOG CREATED INDEX :")
    this.getMenuName();

  }

}
</script>

<style>

.card-news{
  border-radius: 20px !important;
}

.card-news .blog-image img{
  border-radius: 10px;
  height: 100%;
  object-fit: cover;
}

.card-news .blog-image{
  height: 250px !important;
}

.card-news .blog-image a{
  height: 100%;
}

.noidung {
  text-align: justify;
  font-family: 'Poppins Light' !important;
  font-weight: 500;
}

.noidung .image {
  text-align: center;
  width: 100% !important;
}

.content-title {
  text-align: center;
}

.blog-title-details{
  font-size: 16px !important;
  justify-content: center !important;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

</style>
