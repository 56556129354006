import {createRouter, createWebHistory} from 'vue-router';

import IndexTwo from '@/views/frontend/pages/home2/index-2.vue'
import IndexThree from '@/views/frontend/pages/home3/index-3.vue'
import IndexFour from '@/views/frontend/pages/home4/index-4.vue'
import IndexFive from '@/views/frontend/pages/home5/index-5.vue'
import IndexSix from '@/views/frontend/pages/home6/index-6.vue'
import IndexEight from '@/views/frontend/pages/home8/index-8.vue'
import IndexNine from '@/views/frontend/pages/home9/index-9.vue'
import IndexEleven from '@/views/frontend/pages/home11/index-11.vue'
import IndexSeven from '@/views/frontend/pages/home7/index-7.vue'
import IndexTen from '@/views/frontend/pages/home10/index-10.vue'
import IndexTwelve from '@/views/frontend/pages/home12/index-12.vue'
import IndexThirteen from '@/views/frontend/pages/home13/index-13.vue'
import AboutUs from '@/views/frontend/pages/pages/about-us.vue'

import ContactUs from '@/views/frontend/pages/pages/contact-us.vue'
import VoiceCall from '@/views/frontend/pages/pages/call/voice-call.vue'
import VideoCall from '@/views/frontend/pages/pages/call/video-call.vue'
import Invoices from '@/views/frontend/pages/pages/invoices/invoices.vue'
import InvoiceView from '@/views/frontend/pages/pages/invoices/invoice-view.vue'
import LoginEmail from '@/views/frontend/pages/pages/authentication/login-email.vue'
import Mobileotp from '@/views/frontend/pages/pages/authentication/mobile-otp.vue'
import LoginPhone from '@/views/frontend/pages/pages/authentication/login-phone.vue'
import DoctorSignup from '@/views/frontend/pages/pages/authentication/doctor-signup.vue'
import PatientSignup from '@/views/frontend/pages/pages/authentication/patient-signup.vue'
import ForgotPassword from '@/views/frontend/pages/pages/authentication/forgot-password.vue'
import ForgotPasswordTwo from '@/views/frontend/pages/pages/authentication/forgot-password2.vue'
import LoginEmailOtp from '@/views/frontend/pages/pages/authentication/login-email-otp.vue'
import EmailOtp from '@/views/frontend/pages/pages/authentication/email-otp.vue'
import LoginPhoneOtp from '@/views/frontend/pages/pages/authentication/login-phone-otp.vue'
import Error404 from '@/views/frontend/pages/pages/errorpages/error-404.vue'
import Error500 from '@/views/frontend/pages/pages/errorpages/error-500.vue'
import BlankPage from '@/views/frontend/pages/pages/blank-page.vue'
import Pricing from '@/views/frontend/pages/pages/pricing.vue'
import Maintenance from '@/views/frontend/pages/pages/maintenance.vue'
// import ComingSoon from '@/views/frontend/pages/pages/coming-soon.vue'
import TermsCondition from '@/views/frontend/pages/pages/terms-condition.vue'
import BlogList from '@/views/frontend/pages/blog/blog-list.vue'
import FAQ from '@/views/frontend/pages/pages/faq.vue'
import BlogGrid from '@/views/frontend/pages/blog/blog-grid.vue'
import BlogDetails from '@/views/frontend/pages/blog/blog-details.vue'
import PharmacyIndex from '@/views/frontend/pages/pharmacymenu/pharmacy-index/pharmacy-index.vue'
import PharmacyDetails from '@/views/frontend/pages/pharmacymenu/details/pharmacy-details.vue'
import PharmacySearch from '@/views/frontend/pages/pharmacymenu/pharmacy-search.vue'
import Product_Description from '@/views/frontend/pages/pharmacymenu/product-description/product-description.vue'
import Cart from '@/views/frontend/pages/pharmacymenu/cart.vue'
import PaymentSuccess from '@/views/frontend/pages/pharmacymenu/payment-success.vue'
import Payment from '@/views/frontend/pages/pharmacymenu/payment.vue'
import PharmacyRegister from '@/views/frontend/pages/pharmacymenu/pharmacy-register.vue'
import ProductCheckout from '@/views/frontend/pages/pharmacymenu/product-checkout.vue'

import Change_Password from '@/views/frontend/pages/patients/change-password.vue'
import Profile_Settings from '@/views/frontend/pages/patients/profile-settings.vue'
import Chat from '@/views/frontend/pages/patients/chat/chat.vue'
import Booking_Success from '@/views/frontend/pages/patients/booking-success.vue'
import Booking_Success_one from '@/views/frontend/pages/patients/booking-success-one.vue'
import Paitentdetails from '@/views/frontend/pages/patients/patient-details.vue'
import Patient_Dashboard from '@/views/frontend/pages/patients/dashboard/patient-dashboard.vue'
import map_grid from '@/views/frontend/pages/patients/doctors/map-grid.vue'
import map_list from '@/views/frontend/pages/patients/doctors/maplist/map-list.vue'
import Search from '@/views/frontend/pages/patients/searchdoctor/search.vue'
import Search_2 from '@/views/frontend/pages/patients/searchdoctor/search2/search-2.vue'

import Booking_2 from '@/views/News/dat_Lich/booking-2.vue'
import Doctor_Search_Grid from '@/views/frontend/pages/patients/searchdoctor/search2/doctor-search-grid.vue'
import Pharmacy_Register_Step1 from '@/views/frontend/pages/pharmacymenu/pharmacy-register-step1.vue'
import Pharmacy_Register_Step2 from '@/views/frontend/pages/pharmacymenu/pharmacy-register-step2.vue'
import Pharmacy_Register_Step3 from '@/views/frontend/pages/pharmacymenu/pharmacy-register-step3.vue'
import Medical_Details from '@/views/frontend/pages/patients/medical-details.vue'
import Medical_Records from '@/views/frontend/pages/patients/medical-records/medical-records.vue'
import Orders_List from '@/views/frontend/pages/patients/orders-list.vue'
import Dependent from '@/views/frontend/pages/patients/dependent/dependent.vue'
import Patient_Accounts from '@/views/frontend/pages/patients/accounts/accounts.vue'

//Doctor Pages
import Reviews from '@/views/frontend/pages/doctors/reviews.vue'
import DoctorDashBoard from '@/views/frontend/pages/doctors/doctor-dashboard.vue'
import DoctorRegister from '@/views/frontend/pages/doctors/doctor-register.vue'
import DoctorRegisterStep1 from '@/views/frontend/pages/doctors/doctor-register-step1.vue'
import DoctorRegisterStep2 from '@/views/frontend/pages/doctors/doctor-register-step2.vue'
import DoctorRegisterStep3 from '@/views/frontend/pages/doctors/doctor-register-step3.vue'
import Appointments from '@/views/frontend/pages/doctors/doctor-appointment.vue'
import PatientsList from '@/views/frontend/pages/doctors/patients-list.vue'
import DoctorPatientProfile from '@/views/frontend/pages/doctors/patient-profile/doctor-patientprofile.vue'
import AddPrescription from '@/views/frontend/pages/doctors/add-prescription.vue'
import AddBilling from '@/views/frontend/pages/doctors/add-billing.vue'
import Editbilling from '@/views/frontend/pages/doctors/edit-billing.vue'
import Frontcomponents from '@/views/frontend/pages/pages/components.vue'
import Signupsuccess from '@/views/frontend/pages/pages/signup-success.vue'
import FrontendSignup from '@/views/frontend/pages/pages/signup.vue'
import Consultation from '@/views/frontend/pages/pages/consultation.vue'
import ChatDoctor from '@/views/frontend/pages/doctors/chat/chat-doctor.vue'
import DoctorProfileSettings from '@/views/frontend/pages/doctors/profile-settings/doctorprofile-settings.vue'
import DoctorBlog from '@/views/frontend/pages/doctors/doctorblog/doctor-blog.vue'
import DoctorPendingBlog from '@/views/frontend/pages/doctors/doctorblog/doctor-pendingblog.vue'
import DoctorAddBlog from '@/views/frontend/pages/doctors/doctorblog/doctor-addblog.vue'
import DoctorEditlog from '@/views/frontend/pages/doctors/doctorblog/doctor-editblog.vue'
import EditPrescription from '@/views/frontend/pages/doctors/edit-prescription.vue'
import Accounts from '@/views/frontend/pages/doctors/accounts.vue'
import SocialMedia from '@/views/frontend/pages/doctors/social-media.vue'
import DoctorChangePassword from '@/views/frontend/pages/doctors/doctor-changepassword.vue'
import Register from '@/views/frontend/pages/doctors/register.vue'
import PatientRegisterstep1 from '@/views/frontend/pages/doctors/patient-register-step1.vue'
import PatientRegisterstep2 from '@/views/frontend/pages/doctors/patient-register-step2.vue'
import PatientRegisterstep3 from '@/views/frontend/pages/doctors/patient-register-step3.vue'
import PatientRegisterstep4 from '@/views/frontend/pages/doctors/patient-register-step4.vue'
import PatientRegisterstep5 from '@/views/frontend/pages/doctors/patient-register-step5.vue'
import ScheduleTimings from '@/views/frontend/pages/doctors/scheduletiming/schedule-timings.vue'
import AvailableTiming from '@/views/frontend/pages/doctors/scheduletiming/availabletiming.vue'
import OnboardingEmaill from '@/views/frontend/pages/onboard/onboarding-email.vue'
import OnboardingEmaillOtp from '@/views/frontend/pages/onboard/onboarding-email-otp.vue'
import OnboardingPhone from '@/views/frontend/pages/onboard/onboarding-phone.vue'
import OnboardingPhoneOtp from '@/views/frontend/pages/onboard/onboarding-phone-otp.vue'
import OnBoardingPassword from '@/views/frontend/pages/onboard/onboarding-password.vue'
import OnBoardingIdentity from '@/views/frontend/pages/onboard/onboarding-identity.vue'
import OnBoardingPersonalize from '@/views/frontend/pages/onboard/onboarding-personalize.vue'
import OnBoardingVerification from '@/views/frontend/pages/onboard/onboarding-verification.vue'
import OnBoardingPayments from '@/views/frontend/pages/onboard/onboarding-payments.vue'
import OnBoardingPreferences from '@/views/frontend/pages/onboard/onboarding-preferences.vue'
import OnBoardingAvailability from '@/views/frontend/pages/onboard/onboarding-availability.vue'
import OnBoardingConsultation from '@/views/frontend/pages/onboard/onboarding-consultation.vue'
import OnBoardingCost from '@/views/frontend/pages/onboard/onboarding-cost.vue'
import OnBoardingStepVerify from '@/views/frontend/pages/onboard/onboarding-email-step-2-verify.vue'
import OnBoardingLock from '@/views/frontend/pages/onboard/onboarding-lock.vue'
import OnBoardingVerifyAccount from '@/views/frontend/pages/onboard/onboarding-verify-account.vue'
import ComingSoon from "@/views/frontend/pages/home5/comingsoon.vue";

/********* WEB ***********/


/****************** THUOC ******************/

import Thuoc from '@/views/News/thuoc/index.vue'
import ThuocChiTiet from '@/views/News/thuoc/chi-tiet.vue'

//pharmacy components
import Pharmacyindex from '@/views/admin/pages/dashboard/pharmacyindex.vue'
import DoiNguBacSi from '@/views/News/baiviet/doingubacsi.vue'


/**************** ADMIN  *************/

import TaoBaiViet from '@/views/admin/pages/taoBaiViet/index.vue'
import User from '@/views/admin/pages/user/index.vue'
import UserCitizen from '@/views/admin/pages/userCitizen/index.vue'
import MenuCongDan from '@/views/admin/pages/menuCongDan/index.vue'
import Menu from '@/views/admin/pages/menu/index.vue'
import DanhSach from "@/views/admin/pages/danhSach";
import DanhSachCapNhat from "@/views/admin/pages/danhSach/capnhat.vue";


import LoginPage from '@/views/admin/pages/login'
import LoginAdmin from '@/views/admin/pages/login/login2.vue'
import ProFile from '@/views/admin/pages/profile'


import Header from '@/views/admin/pages/header/index.vue'
import Footer from '@/views/admin/pages/footer/index.vue'
import KhongGianNhaKhoa from '@/views/admin/pages/TrangChu/khongGianNhaKhoa/index.vue'
import BenhNhanSay from '@/views/admin/pages/TrangChu/benhNhanSay/index.vue'
import CauChuyenThuongHieu from '@/views/admin/pages/TrangChu/cauChuyenThuongHieu/index.vue'
import DichVu from '@/views/admin/pages/dichVu/index.vue'
import DichVuCapNhap from '@/views/admin/pages/dichVu/capNhap.vue'
import MenuApp from '@/views/admin/pages/menuApp/index.vue'
import Slider from '@/views/admin/pages/slider/index.vue'
import NewsSectionMobi from '@/views/admin/pages/chuyenMucTinMobi/index.vue'
import DonVi from "@/views/admin/pages/donVi";
import PhongBan from "@/views/admin/pages/phongBan";
import Khoa from "@/views/admin/pages/khoa";

import Doctor from "@/views/admin/pages/doctor";
import DoctorDetail from "@/views/admin/pages/doctor/chitiet";
import DanhMuc from "@/views/admin/pages/danhmuc";
import SanPham from "@/views/News/san_Pham/index";
import DatLichBacSi from '@/views/News/dat_Lich_Bac_Si'
import HoSoBacSi from '@/views/News/ho_so_bac_si'
import DatLich from '@/views/News/dat_Lich/index.vue'
import DatLichTest from '@/views/News/dat_Lich/test.vue'
import GioiThieu from '@/views/News/baiviet/gioithieu.vue'
import GioiThieuMobi from '@/views/mobi/gioiThieuMobi'
import BaiViet from "@/views/News/baiviet/index.vue";
import BacSiChiTiet from "@/views/News/baiviet/bacsichitiet.vue";
import BaiVietMobi from "@/views/mobi/baivietmobi/index.vue";
import Chitiet from "@/views/News/baiviet/chitiet.vue";
import ThuTuc from '@/views/News/thu_Tuc_Dat_Lich'
import MenuFooter from "@/views/admin/pages/menuFooter";
import ThongTinCaNhan from '@/views/frontend/layouts/thongtincanhan.vue'


import TuVan from "@/views/admin/pages/tuVan";
import TheThuoc from "@/views/admin/pages/thuoc";
import TheThuocAll from "@/views/News/thuoc/thuoc-all.vue";

import TrongRangImplant from "@/views/News/dichVu/trong_rang_implant";
import TrongRangImplantToanHam from "@/views/News/dichVu/trong_rang_implant/trong_rang_implant_toan_ham/index";
import TrongRangImplant1Rang from "@/views/News/dichVu/trong_rang_implant/trong_rang_implant_1_rang/index";
import TrongRangImplant1VaiRang from "@/views/News/dichVu/trong_rang_implant/trong_rang_implant_1_vai_rang/index";

import NiengRang from "@/views/News/dichVu/nieng_rang";
import NiengRangTrongSuot from "@/views/News/dichVu/nieng_rang/nieng_rang_trong_suot/index";
import NiengRangMacCai from "@/views/News/dichVu/nieng_rang/nieng_rang_mac_cai/index";

import NhaKhoaTreEm from "@/views/News/dichVu/nha_khoa_tre_em";
import NiengRangTreEm from "@/views/News/dichVu/nha_khoa_tre_em/nieng_rang_tre_em/index";
import PhucHoiSuTreEm from "@/views/News/dichVu/nha_khoa_tre_em/phuc_hoi_su_tre_em/index";
import TramNguaSauRang from "@/views/News/dichVu/nha_khoa_tre_em/tram_ngua_sau_rang/index";

import NhaKhoaThamMy from "@/views/News/dichVu/nha_khoa_tham_my";
import DanSu from "@/views/News/dichVu/nha_khoa_tham_my/dan_su/index";
import BocRangSu from "@/views/News/dichVu/nha_khoa_tham_my/boc_rang_su/index";
import PhucHinhRangSu from "@/views/News/dichVu/nha_khoa_tham_my/phuc_hinh_rang_su/index";
import CuoiHoLoi from "@/views/News/dichVu/nha_khoa_tham_my/cuoi_ho_loi/index";
import TayTrangRang from "@/views/News/dichVu/nha_khoa_tham_my/tay_trang_rang/index";

import NhaKhoaTongQuat from "@/views/News/dichVu/nha_khoa_tong_quat";
import NhoRangKhon from "@/views/News/dichVu/nha_khoa_tong_quat/nho_rang_khon/index";
import ChuaTuyRang from "@/views/News/dichVu/nha_khoa_tong_quat/dieu_tri_tuy_rang/index";
import TramRangThamMy from "@/views/News/dichVu/nha_khoa_tong_quat/tram_rang/index";
import CaoVoi from "@/views/News/dichVu/nha_khoa_tong_quat/cao_voi/index";
import ThaiDuongHam from "@/views/News/dichVu/nha_khoa_tong_quat/roi_loan_thai_duong_ham/index";

import DichVuMobi from "@/views/mobi/dichvu/index.vue";
import LienHe from "@/views/News/lienhe/index.vue";
import ThuocChiTietMobi from "@/views/mobi/thuocChiTiet/index.vue";
const routes = [

/**************** NEW  *************/



/****************************************/

/**************** Frontend  *************/

    {
        path: '/',
        name: '/',
        component: IndexFive,
        meta: {
            headerClass: 'header-ten',
            title: 'Trung tâm nha khoa Phương Thành - Đẳng cấp quốc tế!',
            description: 'Trung tâm nha khoa Phương Thành - Đẳng cấp quốc tế! có đầy đủ các chuyên khoa, với mỗi khoa là đội ngũ bác sĩ chuyên sâu trong từng lĩnh vực để đem đến kết quả tối ưu cho khách hàng'
       }
    },
    {
        path: '/index-2',
        name: 'index-2',
        component: IndexTwo,
        meta: {
            headerClass: 'header-two',
       }
    },
    {
        path: '/index-3',
        name: 'index-3',
        component: IndexThree,
        meta: {
            headerClass: 'header-eight',
       }
    },
    {
        path: '/index-4',
        name: 'index-4',
        component: IndexFour,
        meta: {
            headerClass: 'custom',
            headerNavClass: 'no-border',
            headerMainNavClass: 'white-font',
       }
    },
    {
        path: '/index-5',
        name: 'index-5',
        component: IndexFive,
        meta: {
            headerClass: 'header-ten',
       }
    },
    {
        path: '/index-6',
        name: 'index-6',
        component: IndexSix,
        meta: {
            headerClass: 'header-eleven',
       }
    },
    {
        path: '/index-8',
        name: 'index-8',
        component: IndexEight,
        meta: {
            headerClass: 'header-fourteen header-twelve',
       }
    },
    {
        path: '/index-9',
        name: 'index-9',
        component: IndexNine
    },
    {
        path: '/index-11',
        name: 'index-11',
        component: IndexEleven,
        meta: {
            headerClass: 'header-fourteen header-sixteen',
       }
    },
    {
        path: '/index-12',
        name: 'index-12',
        component: IndexTwelve,
        meta: {
            headerClass: 'header-fourteen header-twelve header-thirteen',
       }
    },
    {
        path: '/index-7',
        name: 'index-7',
        component: IndexSeven,
        meta: {
            headerClass: 'header-fourteen header-twelve',
       }
    },
    {
        path: '/index-10',
        name: 'index-10',
        component: IndexTen,
        meta: {
            headerClass: 'header-fourteen',
       }
    },
    {
        path: '/index-12',
        name: 'index-12',
        component: IndexTwelve,
        meta: {
            headerClass: 'header-fourteen header-twelve header-thirteen',
       }
    },
{
        path: '/index-13',
        name: 'index-13',
        component: IndexThirteen,
        meta: {
            headerClass: 'header-ten home-care-header',
       }
    },
    {
        path: '/about-us',
        name: 'about-us',
        component: AboutUs
    },
    {
        path: '/contact-us',
        name: 'contact-us',
        component: ContactUs
    },
    {
        path: '/voice-call',
        name: 'voice-call',
        component: VoiceCall
    },
    {
        path: '/video-call',
        name: 'video-call',
        component: VideoCall
    },
    {
        path: '/invoices',
        name: 'invoices',
        component: Invoices
    },
    {
        path: '/invoice-view',
        name: 'invoice-view',
        component: InvoiceView
    },
    {
        path: '/login',
        name: 'login',
        component: LoginPage
    },
    {
        path: '/quan-tri/login',
        name: 'quan-tri/login',
        component: LoginAdmin
    },
    {
        path: '/mobile-otp',
        name: 'mobile-otp',
        component: Mobileotp
    },
    {
        path: '/login-email',
        name: 'login-email',
        component: LoginEmail
    },
    {
        path: '/login-phone',
        name: 'login-phone',
        component: LoginPhone
    },
    {
        path: '/doctor-signup',
        name: 'doctor-signup',
        component: DoctorSignup
    },
    {
        path: '/patient-signup',
        name: 'patient-signup',
        component: PatientSignup
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: ForgotPassword
    },
    {
        path: '/forgot-password2',
        name: 'forgot-password2',
        component: ForgotPasswordTwo
    },
    {
        path: '/login-email-otp',
        name: 'login-email-otp',
        component: LoginEmailOtp
    },
    {
        path: '/email-otp',
        name: 'email-otp',
        component: EmailOtp
    },
    {
        path: '/login-phone-otp',
        name: 'login-phone-otp',
        component: LoginPhoneOtp
    },
    {
        path: '/error-404',
        name: 'error-404',
        component: Error404
    },
    {
        path: '/error-500',
        name: 'error-500',
        component: Error500
    },
    {
        path: '/blank-page',
        name: 'blank-page',
        component: BlankPage
    },
    {
        path: '/pricing',
        name: 'pricing',
        component: Pricing
    },
    {
        path: '/maintenance',
        name: 'maintenance',
        component: Maintenance
    },
    // {
    //     path: '/coming-soon',
    //     name: 'coming-soon',
    //     component: ComingSoon
    // },
    {
        path: '/terms-condition',
        name: 'terms-condition',
        component: TermsCondition
    },
    {
        path: '/faq',
        name: 'faq',
        component: FAQ
    },
    {
        path: '/blog-list',
        name: 'blog-list',
        component: BlogList
    },
    {
        path: '/blog-grid',
        name: 'blog-grid',
        component: BlogGrid
    },
    {
        path: '/blog-details',
        name: 'blog-details',
        component: BlogDetails
    },
    {
        path: '/pharmacy-index',
        name: 'pharmacy-index',
        component: PharmacyIndex
    },
    {
        path: '/pharmacy-details',
        name: 'pharmacy-details',
        component: PharmacyDetails
    },
    {
        path: '/pharmacy-search',
        name: 'pharmacy-search',
        component: PharmacySearch
    },
    {
        path: '/product-description',
        name: 'product-description',
        component: Product_Description
    },
    {
        path: '/cart',
        name: 'cart',
        component: Cart
    },
    {
        path: '/payment-success',
        name: 'payment-success',
        component: PaymentSuccess
    },
    {
        path: '/payment',
        name: 'payment',
        component: Payment
    },
    {
        path: '/pharmacy-register',
        name: 'pharmacy-register',
        component: PharmacyRegister
    },
    {
        path: '/product-checkout',
        name: 'product-checkout',
        component: ProductCheckout
    },

    {
        path: '/change-password',
        name: 'change-password',
        component: Change_Password
    },

    {
        path: '/profile-settings',
        name: 'profile-settings',
        component: Profile_Settings
    },

    {
        path: '/chat',
        name: 'chat',
        component: Chat
    },
    {
        path: '/ban-tin/dat-lich-theo-bac-si',
        name: 'ban-tin/dat-lich-theo-bac-si',
        component: DatLichBacSi
    },
    {
        path: '/booking-success',
        name: 'booking-success',
        component: Booking_Success
    },
    {
        path: '/booking-success-one',
        name: 'booking-success-one',
        component: Booking_Success_one
    },
    {
        path: '/patient-details',
        name: 'patient-details',
        component: Paitentdetails
    },
    {
        path: '/patient-dashboard',
        name: 'patient-dashboard',
        component: Patient_Dashboard
    },
    {
        path: '/map-grid',
        name: 'map-grid',
        component: map_grid
    },
    {
        path: '/map-list',
        name: 'map-list',
        component: map_list
    },
    {
        path: '/search',
        name: 'search',
        component: Search
    },
    {
        path: '/search-2',
        name: 'search-2',
        component: Search_2
    },

    {
        path: '/booking-2',
        name: 'booking-2',
        component: Booking_2
    },
    {
        path: '/reviews',
        name: 'reviews',
        component: Reviews
    },
    {
        path: '/doctor-search-grid',
        name: 'doctor-search-grid',
        component: Doctor_Search_Grid
    },
    {
        path: '/pharmacy-register-step1',
        name: 'pharmacy-register-step1',
        component: Pharmacy_Register_Step1
    },
    {
        path: '/pharmacy-register-step2',
        name: 'pharmacy-register-step2',
        component: Pharmacy_Register_Step2
    },
    {
        path: '/pharmacy-register-step3',
        name: 'pharmacy-register-step3',
        component: Pharmacy_Register_Step3
    },
    {
        path: '/medical-details',
        name: 'medical-details',
        component: Medical_Details
    },
    {
        path: '/medical-records',
        name: 'medical-records',
        component: Medical_Records
    },
    {
        path: '/orders-list',
        name: 'orders-list',
        component: Orders_List
    },
    {
        path: '/dependent',
        name: 'dependent',
        component: Dependent
    },
    {
        path: '/patient-accounts',
        name: 'patient-accounts',
        component: Patient_Accounts
    },


    {
        path: '/doctor-dashboard',
        name: 'doctor-dashboard',
        component: DoctorDashBoard
    },
    {
        path: '/doctor-register',
        name: 'doctor-register',
        component: DoctorRegister
    },
    {
        path: '/doctor-register-step1',
        name: 'doctor-register-step1',
        component: DoctorRegisterStep1
    },
    {
        path: '/doctor-register-step2',
        name: 'doctor-register-step2',
        component: DoctorRegisterStep2
    },
    {
        path: '/doctor-register-step3',
        name: 'doctor-register-step3',
        component: DoctorRegisterStep3
    },
    {
        path: '/appointments',
        name: 'appointments',
        component: Appointments
    },
    {
        path: '/schedule-timings',
        name: 'schedule-timings',
        component: ScheduleTimings
    },
    {
        path: '/my-patients',
        name: 'my-patients',
        component: PatientsList
    },
    {
        path: '/patient-profile',
        name: 'patient-profile',
        component: DoctorPatientProfile
    },
    {
        path: '/add-prescription',
        name: 'add-prescription',
        component: AddPrescription
    },
    {
        path: '/add-billing',
        name: 'add-billing',
        component: AddBilling
    },
    {
        path: '/edit-billing',
        name: 'edit-billing',
        component: Editbilling
    },
    {
        path: '/components',
        name: 'components',
        component: Frontcomponents
    },
    {
        path: '/signup-success',
        name: 'signup-success',
        component: Signupsuccess
    },
    {
        path: '/signup',
        name: 'signup',
        component: FrontendSignup
    },
    {
        path: '/consultation',
        name: 'consultation',
        component: Consultation
    },
    {
        path: '/chat-doctor',
        name: 'chat-doctor',
        component: ChatDoctor
    },
    {
        path: '/doctor-blog',
        name: 'doctor-blog',
        component: DoctorBlog
    },
    {
        path: '/doctor-pending-blog',
        name: 'doctor-pending-blog',
        component: DoctorPendingBlog
    },
    {
        path: '/doctor-add-blog',
        name: 'doctor-add-blog',
        component: DoctorAddBlog
    },
    {
        path: '/edit-blog',
        name: 'edit-blog',
        component: DoctorEditlog
    },
    {
        path: '/edit-prescription',
        name: 'edit-prescription',
        component: EditPrescription
    },
    {
        path: '/available-timings',
        name: 'available-timings',
        component: AvailableTiming
    },
    {
        path: '/accounts',
        name: 'accounts',
        component: Accounts
    },
    {
        path: '/social-media',
        name: 'social-media',
        component: SocialMedia
    },
    {
        path: '/doctor-change-password',
        name: 'doctor-changepassword',
        component: DoctorChangePassword
    },
    {
        path: '/register',
        name: 'register',
        component: Register
    },
    {
        path: '/patient-register-step1',
        name: 'patient-register-step1',
        component: PatientRegisterstep1
    },
    {
        path: '/patient-register-step2',
        name: 'patient-register-step2',
        component: PatientRegisterstep2
    },
    {
        path: '/patient-register-step3',
        name: 'patient-register-step3',
        component: PatientRegisterstep3
    },
    {
        path: '/patient-register-step4',
        name: 'patient-register-step4',
        component: PatientRegisterstep4
    },
    {
        path: '/patient-register-step5',
        name: 'patient-register-step5',
        component: PatientRegisterstep5
    },
    {
        path: '/onboarding-email',
        name: '/onboarding-email',
        component: OnboardingEmaill
    },
    {
        path: '/onboarding-email-otp',
        name: '/onboarding-email-otp',
        component: OnboardingEmaillOtp
    },
    {
        path: '/onboarding-phone',
        name: '/onboarding-phone',
        component: OnboardingPhone
    },
    {
        path: '/onboarding-phone-otp',
        name: '/onboarding-phone-otp',
        component: OnboardingPhoneOtp
    },
    {
        path: '/onboarding-password',
        name: '/onboarding-password',
        component: OnBoardingPassword
    },
    {
        path: '/onboarding-identity',
        name: '/onboarding-identity',
        component: OnBoardingIdentity
    },
    {
        path: '/onboarding-personalize',
        name: '/onboarding-personalize',
        component: OnBoardingPersonalize
    },
    {
        path: '/onboarding-verification',
        name: '/onboarding-verification',
        component: OnBoardingVerification
    },
    {
        path: '/onboarding-payments',
        name: '/onboarding-payments',
        component: OnBoardingPayments
    },
    {
        path: '/onboarding-preferences',
        name: '/onboarding-preferences',
        component: OnBoardingPreferences
    },
    {
        path: '/onboarding-availability',
        name: '/onboarding-availability',
        component: OnBoardingAvailability
    },
    {
        path: '/onboarding-consultation',
        name: '/onboarding-consultation',
        component: OnBoardingConsultation
    },
    {
        path: '/onboarding-cost',
        name: '/onboarding-cost',
        component: OnBoardingCost
    },
    {
        path: '/onboarding-email-step-2-verify',
        name: '/onboarding-email-step-2-verify',
        component: OnBoardingStepVerify
    },
    {
        path: '/onboarding-lock',
        name: '/onboarding-lock',
        component: OnBoardingLock
    },
    {
        path: '/onboarding-verify-account',
        name: '/onboarding-verify-account',
        component: OnBoardingVerifyAccount
    },

    /**************** Admin  *************/

    {
        path: '/quan-tri/menu-cong-dan',
        name: 'quan-tri/menucongdan',
        component: MenuCongDan
    },
    {
        path: '/quan-tri/menu',
        name: 'quan-tri/menu',
        component: Menu
    },
    {
        path: '/quan-tri/menu-footer',
        name: 'quan-tri/menu-footer',
        component: MenuFooter
    },
    {
        path: '/quan-tri/tao-bai-viet',
        name: 'quan-tri/tao-bai-viet',
        component: TaoBaiViet
    },
    {
        path: '/quan-tri/tai-khoan',
        name: 'quan-tri/tai-khoan',
        component: User
    },

    {
        path: '/quan-tri/tai-khoan-cong-dan',
        name: 'quan-tri/tai-khoan-cong-dan',
        component: UserCitizen
    },

    {
        path: '/quan-tri',
        name: 'quan-tri',
        component: Pharmacyindex
    },

    {
        path: '/quan-tri/danh-sach',
        name: 'quan-tri/danh-sach',
        component: DanhSach
    },
    {
        path: '/quan-tri/danh-sach/chi-tiet/:id?',
        name: 'quan-tri/danh-sach/chi-tiet/:id?',
        component: DanhSachCapNhat
    },

    {
        path: '/quan-tri/profile',
        name: '/quan-tri/profile',
        component: ProFile
    },
    {
        path: '/ban-tin/:id?',
        name: '/ban-tin/:id?',
        component: BaiViet
    },
    {
        path: '/ban-tin-mobi/:id?',
        name: '/ban-tin-mobi/:id?',
        component: BaiVietMobi
    },
    {
        path: '/bac-si-chi-tiet/:id?',
        name: '/bac-si-chi-tiet/:id?',
        component: BacSiChiTiet
    },
    {
        path: '/bao-tri/:id?',
        name: '/bao-tri/:id?',
        component: ComingSoon
    },
    {
        path: '/ban-tin/chi-tiet/:id?',
        name: '/ban-tin/chi-tiet/:id?',
        component: Chitiet
    },

    {
        path: '/dat-lich',
        name: '/dat-lich',
        component: DatLich
    },

    {
        path: '/dat-lich-test',
        name: '/dat-lich-test',
        component: DatLichTest
    },

    {
        path: '/gioi-thieu',
        name: 'gioi-thieu',
        component: GioiThieu
    },
    {
        path: '/gioi-thieu-mobi',
        name: 'gioi-thieu-mobi',
        component: GioiThieuMobi
    },




    {
        path: '/quan-tri/header',
        name: 'quan-tri/header',
        component: Header
    },
    {
        path: '/quan-tri/khong-gian-nha-khoa',
        name: 'quan-tri/khong-gian-nha-khoa',
        component: KhongGianNhaKhoa
    },
    {
        path: '/quan-tri/footer',
        name: 'quan-tri/footer',
        component: Footer
    },

    {
        path: '/quan-tri/cam-nhan-khach-hang',
        name: 'quan-tri/cam-nhan-khach-hang',
        component: BenhNhanSay
    },
    {
        path: '/quan-tri/cau-chuyen-thuong-hieu',
        name: 'quan-tri/cau-chuyen-thuong-hieu',
        component: CauChuyenThuongHieu
    },
    {
        path: '/thong-tin-ca-nhan-cong-dan',
        name: 'thong-tin-ca-nhan-cong-dan',
        component: ThongTinCaNhan
    },


    {
        path: '/quan-tri/dich-vu',
        name: 'quan-tri/dich-vu',
        component: DichVu
    },
    {
        path: '/quan-tri/dich-vu/chi-tiet/:id?',
        name: '/quan-tri/dich-vu/chi-tiet/:id?',
        component: DichVuCapNhap
    },
    {
        path: '/quan-tri/menuApp',
        name: 'quan-tri/menuApp',
        component: MenuApp
    },
    {
        path: '/quan-tri/slider',
        name: 'quan-tri/slider',
        component: Slider
    },
    {
        path: '/quan-tri/newsMobi',
        name: 'quan-tri/newsMobi',
        component: NewsSectionMobi
    },
    {
        path: '/quan-tri/don-vi',
        name: 'quan-tri/don-vi',
        component: DonVi
    },
    {
        path: '/quan-tri/phong-ban',
        name: 'quan-tri/phong-ban',
        component: PhongBan
    },
    {
        path: '/quan-tri/khoa',
        name: 'quan-tri/khoa',
        component: Khoa
    },
    {
        path: '/quan-tri/doctor',
        name: 'quan-tri/doctor',
        component: Doctor
    },
    {
        path: '/quan-tri/doctor/chi-tiet/:id?',
        name: 'quan-tri/doctor/chi-tiet/:id?',
        component: DoctorDetail
    },
    {
        path: '/quan-tri/common',
        name: 'quan-tri/common',
        component: DanhMuc
    },

    {
        path: '/ban-tin/dat-lich-theo-dich-vu',
        name: '/ban-tin/dat-lich-theo-dich-vu',
        component: SanPham
    },

    {
        path: '/ho-so-bac-si',
        name: '/ho-so-bac-si',
        component: HoSoBacSi
    },
    {
        path: '/dat-lich',
        name: '/dat-lich',
        component: DatLich
    },
    {
        path: '/thu-tuc',
        name: '/thu-tuc',
        component: ThuTuc
    },

    {
        path: '/quan-tri/tu-van',
        name: 'quan-tri/tu-van',
        component: TuVan
    },
    {
        path: '/quan-tri/thuoc',
        name: 'quan-tri/thuoc',
        component: TheThuoc
    },


    /**************** Admin  *************/


    /*************** WEB ***************/
    {
        path: '/san-pham',
        name: 'thuoc',
        component: Thuoc
    },
    {
        path: '/doi-ngu-bac-si',
        name: 'doi-ngu-bac-si',
        component: DoiNguBacSi
    },
    {
        path: '/san-pham/chi-tiet/:id?',
        name: 'san-pham/chi-tiet',
        component: ThuocChiTiet
    },
    {
        path: '/san-pham-all/:id?',
        name: 'san-pham-all',
        component: TheThuocAll
    },
    {
        path: '/thuoc-mobi/chi-tiet/:id?',
        name: '/thuoc-mobi/chi-tiet/:id?',
        component: ThuocChiTietMobi
    },
    {
        path: '/trong-rang-implant',
        name: '/trong-rang-implant',
        component: TrongRangImplant
    },
    {
        path: '/trong-rang-implant/trong-rang-implant-toan-ham',
        name: '/trong-rang-implant/trong-rang-implant-toan-ham',
        component: TrongRangImplantToanHam
    },
    {
        path: '/trong-rang-implant/trong-rang-implant-1-rang',
        name: '/trong-rang-implant/trong-rang-implant-1-rang',
        component: TrongRangImplant1Rang
    },
    {
        path: '/trong-rang-implant/trong-rang-implant-1-vai-rang',
        name: '/trong-rang-implant/trong-rang-implant-1-vai-rang',
        component: TrongRangImplant1VaiRang
    },

    {
        path: '/nieng-rang',
        name: '/nieng-rang',
        component: NiengRang
    },
    {
        path: '/nieng-rang/nieng-rang-trong-suot',
        name: '/nieng-rang/nieng-rang-trong-suot',
        component: NiengRangTrongSuot
    },
    {
        path: '/nieng-rang/nieng-rang-mac-cai',
        name: '/nieng-rang/nieng-rang-mac-cai',
        component: NiengRangMacCai
    },

    {
        path: '/nha-khoa-tre-em',
        name: '/nha-khoa-tre-em',
        component: NhaKhoaTreEm
    },
    {
        path: '/nha-khoa-tre-em/nieng-rang-cho-tre-em',
        name: '/nha-khoa-tre-em/nieng-rang-cho-tre-em',
        component: NiengRangTreEm
    },
    {
        path: '/nha-khoa-tre-em/phuc-hoi-su-tre-em',
        name: '/nha-khoa-tre-em/phuc-hoi-su-tre-em',
        component: PhucHoiSuTreEm
    },
    {
        path: '/nha-khoa-tre-em/tram-ngua-sau-rang',
        name: '/nha-khoa-tre-em/tram-ngua-sau-rang',
        component: TramNguaSauRang
    },

    {
        path: '/nha-khoa-tham-my',
        name: '/nha-khoa-tham-my',
        component: NhaKhoaThamMy
    },
    {
        path: '/nha-khoa-tham-my/mat-dan-su-veneer',
        name: '/nha-khoa-tham-my/mat-dan-su-veneer',
        component: DanSu
    },
    {
        path: '/nha-khoa-tham-my/boc-rang-su',
        name: '/nha-khoa-tham-my/boc-rang-su',
        component: BocRangSu
    },
    {
        path: '/nha-khoa-tham-my/phuc-hinh-rang-su-cad-cam',
        name: '/nha-khoa-tham-my/phuc-hinh-rang-su-cad-cam',
        component: PhucHinhRangSu
    },
    {
        path: '/nha-khoa-tham-my/dieu-tri-cuoi-ho-loi',
        name: '/nha-khoa-tham-my/dieu-tri-cuoi-ho-loi',
        component: CuoiHoLoi
    },
    {
        path: '/nha-khoa-tham-my/tay-trang-rang',
        name: '/nha-khoa-tham-my/tay-trang-rang',
        component: TayTrangRang
    },

    {
        path: '/nha-khoa-tong-quat',
        name: '/nha-khoa-tong-quat',
        component: NhaKhoaTongQuat
    },
    {
        path: '/nha-khoa-tong-quat/nho-rang-khon',
        name: '/nha-khoa-tong-quat/nho-rang-khon',
        component: NhoRangKhon
    },
    {
        path: '/nha-khoa-tong-quat/chua-tuy-rang',
        name: '/nha-khoa-tong-quat/chua-tuy-rang',
        component: ChuaTuyRang
    },
    {
        path: '/nha-khoa-tong-quat/tram-rang-tham-my',
        name: '/nha-khoa-tong-quat/tram-rang-tham-my',
        component: TramRangThamMy
    },
    {
        path: '/nha-khoa-tong-quat/cao-voi',
        name: '/nha-khoa-tong-quat/cao-voi',
        component: CaoVoi
    },
    {
        path: '/nha-khoa-tong-quat/roi-loan-thai-duong-ham',
        name: '/nha-khoa-tong-quat/roi-loan-thai-duong-ham',
        component: ThaiDuongHam
    },

    {
        path: '/lien-he',
        name: '/lien-he',
        component: LienHe
    },
]


export  const router = createRouter({
    history: createWebHistory(),
    routes,
});


router.beforeEach((to, from, next) => {
// Scroll to the top of the page
window.scrollTo({ top: 0, behavior: 'smooth' });

// Continue with the navigation
next();
});
