<template>
  <div class="container pt-60 pb-30">
    <div class="main-title text-center pb-30">Các tình trạng răng thường gặp</div>
      
    <!-- Khung 1 -->
    <div class="box-luu-y box-primary">
      <h2>Các vấn đề thường gặp liên quan đến cấu trúc răng</h2>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Sâu răng: Đây là tình trạng phổ biến nhất, do vi khuẩn tấn công men răng và ngà răng, tạo thành lỗ hổng trên răng. 
          Nếu không được điều trị, sâu răng có thể lan đến tủy răng gây đau nhức và nhiễm trùng.
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Mòn răng: Quá trình mất dần mô răng cứng do các yếu tố hóa học (axit) hoặc cơ học (chải răng quá mạnh). 
          Mòn răng có thể khiến răng trở nên nhạy cảm và dễ gãy.
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Sứt mẻ răng: Răng bị vỡ hoặc mẻ một phần do chấn thương hoặc cắn phải vật cứng. Sứt mẻ răng có thể ảnh hưởng đến thẩm mỹ và chức năng ăn nhai.
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Răng khôn mọc lệch: Răng khôn mọc lệch, kẹt, hoặc không đủ chỗ để mọc hoàn toàn có thể gây đau, sưng, nhiễm trùng, và ảnh hưởng đến các răng lân cận.
        </span>
      </div>
    </div>
    
    <!-- Khung 2 -->
    <div class="box-luu-y box-secondary">
      <h2>Các vấn đề thường gặp liên quan đến nướu</h2>
      <p class="desc-title">
        Sau phẫu thuật, không dùng nước băng nóng, không xì mũi trong 15 ngày. 
        Tránh máy bay trong tuần đầu tiên sau phẫu thuật.
      </p>
      <p class="desc-title">
        Viêm nướu: Tình trạng nướu bị viêm, sưng đỏ, dễ chảy máu khi đánh răng hoặc dùng chỉ nha khoa. 
        Viêm nướu thường do vệ sinh răng miệng kém.
      </p>
      <p class="desc-title">
        Viêm nha chu: Dạng nặng hơn của viêm nướu, ảnh hưởng đến các mô nâng đỡ răng, bao gồm xương ổ răng. 
        Viêm nha chu có thể dẫn đến mất răng nếu không được điều trị kịp thời.
      </p>
      <h2>Các vấn đề khác</h2>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Hôi miệng: Có thể do vệ sinh răng miệng kém, khô miệng, hoặc các vấn đề sức khỏe khác.
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Nhạy cảm răng: Cảm giác đau nhức hoặc ê buốt khi ăn uống đồ nóng, lạnh, ngọt, hoặc chua. 
          Nhạy cảm răng có thể do mòn men răng, sâu răng, hoặc các vấn đề về nướu.
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Khớp cắn sai: Răng hàm trên và hàm dưới không khớp với nhau đúng cách. 
          Khớp cắn sai có thể gây đau đầu, đau hàm, và khó khăn khi ăn nhai.
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Mất răng: Mất răng có thể do nhiều nguyên nhân, bao gồm sâu răng, viêm nha chu, chấn thương, hoặc lão hóa. 
          Mất răng ảnh hưởng đến chức năng ăn nhai, thẩm mỹ và phát âm.
        </span>
      </div>
    </div>

  </div>
  
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  data() {
    return {
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  created() {
  
  },
  methods: {

    toggleFavorite(item) {
      item.favorite = !item.favorite;
    },
  },
};
</script>

<style>

</style>
  