<template>
  <div class="container pt-60 pb-60">
    <div class="main-title text-center">Quy trình dán sứ veneer</div>
    <div class="timeline">
      <div class="pt-10 pb-10">
        <div class="card-time-line right">
          <div class="date">
            <img src="@/assets/img/phuongthanh/dichvu/buoc1.png" alt="">
          </div>
          <div class="content-time-line">
            <h2>Bước 1</h2>
            <p>
              Khám và tư vấn:
            </p>
            <p class="desc-title pt-2">
              Bác sĩ sẽ khám tổng quát tình trạng răng miệng, chụp X-quang để đánh giá sức khỏe răng, nướu và xương hàm.
              Bác sĩ sẽ thảo luận với bạn về mong muốn, mục tiêu thẩm mỹ, và tư vấn loại răng sứ phù hợp với tình trạng răng và ngân sách của bạn.
              Kế hoạch điều trị cụ thể sẽ được lập ra, bao gồm số lượng răng cần bọc, loại sứ sử dụng, và thời gian điều trị dự kiến.
            </p>
          </div>
        </div>
      </div>

      <div class="pt-10 pb-10">
        <div class="card-time-line left">
          <div class="date">
            <img src="@/assets/img/phuongthanh/dichvu/buoc2.png" alt="">
          </div>
          <div class="content-time-line">
            <h2>Bước 2</h2>
            <p>
              Mài răng, lấy dấu răng:
            </p>
            <p class="desc-title pt-2">
              Bác sĩ sẽ tiến hành mài một lớp men răng để tạo khoảng trống cho mão sứ. Lượng mô răng bị mài sẽ phụ thuộc vào loại răng sứ được lựa chọn.
            </p>
            <p class="desc-title pt-2">
              Thuốc tê sẽ được sử dụng để đảm bảo bạn không cảm thấy đau trong quá trình mài răng.
            </p>
            <p class="desc-title pt-2">
              Bác sĩ sẽ lấy dấu răng đã mài bằng vật liệu chuyên dụng. Mẫu dấu này sẽ được gửi đến phòng lab để kỹ thuật viên chế tạo mão sứ.
            </p>
          </div>
        </div>
      </div>

      <div class="pt-10 pb-10">
        <div class="card-time-line right">
          <div class="date">
            <img src="@/assets/img/phuongthanh/dichvu/buoc3.png" alt="">
          </div>
          <div class="content-time-line">
            <h2>Bước 3</h2>
            <p>
              Gắn răng tạm:
            </p>
            <p class="desc-title pt-2">
              Trong thời gian chờ đợi mão sứ được chế tạo, bác sĩ sẽ gắn răng tạm để bảo vệ răng đã mài và duy trì thẩm mỹ.
            </p>
          </div>
        </div>
      </div>

      <div class="pt-10 pb-10">
        <div class="card-time-line left">
          <div class="date" style="right: -300px;">
            <img src="@/assets/img/phuongthanh/dichvu/buoc4.png" alt="">
          </div>
          <div class="content-time-line">
            <h2>Bước 4</h2>
            <p>
              Thử sứ:
            </p>
            <p class="desc-title pt-2">
              Khi mão sứ hoàn thành, bạn sẽ đến nha khoa để thử sứ. Bác sĩ sẽ kiểm tra độ khít, màu sắc và hình dáng của mão sứ.
            </p>
            <p class="desc-title pt-2">
              Nếu cần thiết, bác sĩ sẽ điều chỉnh lại mão sứ để đảm bảo sự hài hòa và thoải mái cho bạn.
            </p>
          </div>
        </div>
      </div>

      <div class="pt-10 pb-10">
        <div class="card-time-line right">
          <div class="date">
            <img src="@/assets/img/phuongthanh/dichvu/buoc5.png" alt="">
          </div>
          <div class="content-time-line">
            <h2>Bước 5</h2>
            <p>
              Gắn cố định:
            </p>
            <p class="desc-title pt-2">
              Sau khi bạn hài lòng với mão sứ, bác sĩ sẽ gắn cố định mão sứ lên răng đã mài bằng một loại xi măng nha khoa chuyên dụng.
            </p>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import { ref } from "vue";
const currentDate = ref(new Date());
import AOS from "aos";
import "aos/dist/aos.css";
import 'vue3-carousel/dist/carousel.css'
export default {
  components: {
  },
  data() {
    return {
      startdate: currentDate,
      list: [],
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  props: {
    header: { type : Object}
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  watch:{
    '$props':{
      handler: function (val) {
        this.list = val.header;
        //  console.log("LOG WATCH LIST ", this.list )
      },
      deep: true
    }
  },
  methods: {
    submitForm() {
      this.$router.push("/search-2");
    },
  },
};
</script>
<style>
</style>
