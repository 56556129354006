<template>
    <div class="container pt-60 pb-60">
      <div class="main-title text-center pb-30">
        Nha Khoa Trẻ Em<br>
        Chăm Sóc Toàn Diện, Bảo Vệ Nụ Cười Tương Lai
      </div>
      <div class="desc-title text-center">
        Nha khoa trẻ em là một dịch vụ chuyên biệt giúp chăm sóc và bảo vệ sức khỏe răng miệng trong những năm đầu đời 
        của trẻ. Phương pháp này tập trung vào việc phòng ngừa, phát hiện và điều trị các vấn đề nha khoa phổ biến ở trẻ em, 
        sử dụng các kỹ thuật hiện đại cùng cách tiếp cận nhẹ nhàng, phù hợp với tâm lý trẻ nhỏ. Với mục tiêu xây dựng nền 
        tảng sức khỏe răng miệng lâu dài, nha khoa trẻ em mang lại những lợi ích vượt trội như:
      </div>
      <div class="list-lamrang pt-30 row">
        <div class="card-lamrang mb-3">
          Phòng ngừa sâu răng và các bệnh lý răng miệng
        </div>

        <div class="card-lamrang mb-3">
          Hỗ trợ trẻ phát triển cấu trúc hàm và răng cân đối
        </div>

        <div class="card-lamrang mb-3">
          Đảm bảo chức năng nhai tốt cho quá trình phát triển
        </div>

        <div class="card-lamrang mb-3">
          Tạo thói quen vệ sinh răng miệng đúng cách
        </div>

        <div class="card-lamrang mb-3">
          Mang lại nụ cười tự tin và rạng rỡ cho trẻ
        </div>

        <!-- <div class="card-lamrang mb-3">
          Thay đổi diện mạo khuôn mặt
        </div> -->
      </div>
    </div>

</template>

<script>
  import { ref } from "vue";
  const currentDate = ref(new Date());
  import AOS from "aos";
  import "aos/dist/aos.css";
  import 'vue3-carousel/dist/carousel.css'
  export default {
    components: {
    },
    data() {
      return {
        startdate: currentDate,
        list: [],
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    props: {
      header: { type : Object}
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
    watch:{
      '$props':{
        handler: function (val) {
          this.list = val.header;
        //  console.log("LOG WATCH LIST ", this.list )
        },
        deep: true
      }
    },
    methods: {
      submitForm() {
        this.$router.push("/search-2");
      },
    },
  };
</script>
<style>
</style>
