<template>
    <div class="container pt-60 pb-60">
      <div class="row">
        <div class="col-md-6">
            <div class="img-trongrang">
                <img src="@/assets/img/phuongthanh/dichvu/cautaorangimplant.png" alt="" style="max-height: 500px;">
            </div>
        </div>
        <div class="col-md-6">
            <div class="main-title pb-40">Cấu tạo niềng răng thẩm mỹ</div>

            <div class="item-cautaorang pb-60">
                <div class="number">
                    <img src="@/assets/img/phuongthanh/dichvu/1.png" alt="" class="img-number">
                    <img src="@/assets/img/phuongthanh/dichvu/in1.png" alt="" class="in-number">
                </div>
                <div class="info-cautaorang">
                    <div>
                        <div class="name-cautaorang">
                            Răng sứ trên Implant:
                        </div>
                        <div class="sub-cautaorang">
                            Là phần gắn lên Abutment để thực hiện chức năng ăn nhai.
                        </div>
                    </div>
                </div>
            </div>
            <div class="item-cautaorang pb-60">
                <div class="number">
                    <img src="@/assets/img/phuongthanh/dichvu/2.png" alt="" class="img-number">
                    <img src="@/assets/img/phuongthanh/dichvu/in2.png" alt="" class="in-number">
                </div>
                <div class="info-cautaorang">
                    <div>
                        <div class="name-cautaorang">
                            Trụ phục hình (Abutment):
                        </div>
                        <div class="sub-cautaorang">
                            Là phần ở giữa, kết nối chân niềng răng thẩm mỹ phía dưới và chụp răng sứ phía trên.
                            Abutment có thể được làm bằng Titanium hay Zircronia.
                        </div>
                    </div>
                </div>
            </div>
            <div class="item-cautaorang">
                <div class="number">
                    <img src="@/assets/img/phuongthanh/dichvu/3.png" alt="" class="img-number">
                    <img src="@/assets/img/phuongthanh/dichvu/in3.png" alt="" class="in-number">
                </div>
                <div class="info-cautaorang">
                    <div>
                        <div class="name-cautaorang">
                            Chân niềng răng thẩm mỹ:
                        </div>
                        <div class="sub-cautaorang">
                            Là phần được cấy vào trong xương hàm, có thiết kế dạng ren xoắn giống như một chiếc ốc vít.
                            Về bản chất thì chân niềng răng thẩm mỹ được cấu tạo từ Titanium nguyên chất, bề mặt được xử lý đặc biệt
                            đảm bảo cho các tế bào xương của cơ thể bám lên đó và tạo ra sự tích hợp sinh học đối với cơ thể.
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
  import { ref } from "vue";
  const currentDate = ref(new Date());
  import AOS from "aos";
  import "aos/dist/aos.css";
  import 'vue3-carousel/dist/carousel.css'
  export default {
    components: {
    },
    data() {
      return {
        startdate: currentDate,
        list: [],
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    props: {
      header: { type : Object}
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
    watch:{
      '$props':{
        handler: function (val) {
          this.list = val.header;
        //  console.log("LOG WATCH LIST ", this.list )
        },
        deep: true
      }
    },
    methods: {
      submitForm() {
        this.$router.push("/search-2");
      },
    },
  };
</script>
<style>
</style>
