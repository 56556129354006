<template>
  <div class="container pt-60 pb-60">
    <div class="row align-items-center">
      <div style="text-align: center; margin-bottom: 20px;">
          <div class="main-title pb-30">
            Trồng răng Implant một vài răng
          </div>
          <div class="decs-intro-doctor">
            Đối với Cô Chú bị mất nhiều răng hoặc mất răng toàn bộ, phương pháp Trồng răng Implant một vài răng
            được xem là giải pháp phục hình tối ưu. Phương pháp này nổi bật với khả năng khôi phục chức năng
            ăn nhai gần như răng thật, mang lại vẻ thẩm mỹ tự nhiên và dễ dàng vệ sinh. Đặc biệt, khi thực
            hiện cấy ghép Implant toàn hàm tại các cơ sở nha khoa uy tín, Cô Chú có thể yên tâm về kết quả
            bền đẹp lâu dài, thậm chí là vĩnh viễn nếu được chăm sóc đúng cách.
          </div>
      </div>
    </div>
  </div>



</template>

<script>
import { ref } from "vue";
const currentDate = ref(new Date());
import AOS from "aos";
import "aos/dist/aos.css";
import indexfivespecialities from "@/assets/json/indexfivespecialities.json";
import 'vue3-carousel/dist/carousel.css'
export default {
  components: {
  },
  data() {
    return {
      startdate: currentDate,
      indexfivespecialities: indexfivespecialities,
      list: [],
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  props: {
    header: { type : Object}
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  watch:{
    '$props':{
      handler: function (val) {
        this.list = val.header;
      //  console.log("LOG WATCH LIST ", this.list )
      },
      deep: true
    }
  },
  methods: {
    submitForm() {
      this.$router.push("/search-2");
    },
  },
};
</script>
<style>
</style>
