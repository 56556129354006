<template>
  <div class="main-Wrapper">
    <pharmacyheader></pharmacyheader>
    <pharmacysidebar></pharmacysidebar>
    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <div class="content container-fluid">
        <pharmacybreadcrumb2 :title="title" />
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <Form
                  :validation-schema="schema" v-slot="{ errors }" @submit="handleSubmit"
                >
                  <div class="row">
                    <div class="col-md-6">
                      <div class="cs-title-box">
                        <span class="font-size-13">THÔNG TIN BÀI VIẾT</span>
                      </div>
                    </div>
                    <div class="tt-end mext-2 col-md-6" style="display: flex; justify-content: flex-end;">
                      <b-button
                          type="submit"
                          style="background-color: #e9ab2e; border: none;"
                      >
                        Đăng bài viết
                      </b-button>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-2">
                        <label class="text-left mb-0">Trang tin</label>

                        <span style="color: red">&nbsp;*</span>
                        <Field name="menu" v-slot="{ field }">
                          <treeselect
                            v-bind="field"
                            ref="tbTreeSelect"
                            :options="treeView"
                            :default-expand-level="2"
                            :normalizer="normalizer"
                            v-on:select="addCoQuanToModel"
                            value-format="object"
                            :key="keyId"
                            placeholder="Nhấp vào để chọn"
                            :class="{ 'is-invalid': errors.menu }"
                          >
                          </treeselect>
                        </Field>
                        <div class="invalid-feedback">{{ errors.menu }}</div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-2">
                        <label class="text-left mb-0">Di động</label>
                        <!-- <span style="color: red">&nbsp;*</span> -->
                        <VueMultiselect
                            v-model="model.menuMobi"
                            :options="listMenuMobi"
                            label="name"
                            placeholder="Nhấp vào để chọn"
                            selectLabel="Nhấn vào để chọn"
                            deselectLabel="Nhấn vào để xóa"
                        >
                        </VueMultiselect>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-2">
                        <label class="text-left mb-0">Dịch vụ</label>
                        <!-- <span style="color: red">&nbsp;*</span> -->
                        <VueMultiselect
                            v-model="model.service"
                            :options="listService"
                            label="name"
                            placeholder="Nhấp vào để chọn"
                            selectLabel="Nhấn vào để chọn"
                            deselectLabel="Nhấn vào để xóa"
                        >
                        </VueMultiselect>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="mb-3">
                        <label class="text-left">Tiêu đề bài viết</label>
                        <span style="color: red">&nbsp;*</span>
                        <input type="hidden" v-model="model.id"/>
                        <Field
                            v-model="model.name"
                            placeholder="Vui lòng nhập tên tiêu đề"
                            name="name"
                            type="text"
                            class="form-control"
                            :class="{ 'is-invalid': errors.name }"
                        />
                            <div class="invalid-feedback">{{ errors.name }}</div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-check form-switch">
                        <label class="text-left mb-2">Xuất bản</label>
                        <input
                            v-model="model.isPublish"
                            class="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckChecked"
                            checked=""
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-8" >
                        <div class="mb-2 ">
                          <label class="text-left mb-0">Mô tả</label>
                          <span style="color: red" >&nbsp;*</span>
                          <Field
                              as="textarea"
                              v-model="model.describe"
                              placeholder="Vui lòng nhập mô tả"
                              name="describe"
                              type="text"
                              class="form-control"
                              :class="{ 'is-invalid': errors.describe }"
                          />
                              <div class="invalid-feedback">{{ errors.describe }}</div>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="col-md-12">
                          <div class="mb-2 ">
                            <label class="text-left mb-0">Ngày xuất bản</label>
                            <span style="color: red" >&nbsp;*</span>
                            <Field name="publicationDate" v-slot="{ field }">
                              <VueDatePicker
                                  v-bind="field"
                                  v-model="model.publicationDate"
                                  :enable-time-picker="false"
                                  :format="format"
                                  :locale="locale"
                                  validate="true"
                                  auto-apply
                                  ONCLICK=""
                                  :class="{ 'is-invalid': errors.publicationDate }"
                              />
                            </Field>
                            <div class="invalid-feedback">{{ errors.publicationDate }}</div>
                          </div>
                        </div>
                        <div class="col-md-12" >
                          <div class="mb-2 ">
                            <label for="formFileSm" class="text-left mb-0">Ảnh đại diện (600px * 400px)</label>
                            <span style="color: red">&nbsp;*</span>
                            <template  v-if="model.fileImage" >
                              <a
                                  class="ml-25"
                                  :href="`${urlFile}/${model.fileImage.fileId}`"
                              ><i
                                  :class="`${getColorWithExtFile(model.fileImage.ext)} me-2 ${getIconWithExtFile(model.fileImage.ext)}`"
                              ></i>{{model.fileImage.fileName }}</a>
                              <b-button
                                  variant="flat-danger"
                                  class="btn-icon-delete-img"
                                  @click="deleteImage()"
                              >
                                <i class="fe fe-trash"></i>
                              </b-button>

                            </template>
                            <Field
                                id="formFileSm"
                                name="fileImage"
                                ref="fileInput"
                                type="file"
                                accept="image/png, image/jpeg"
                                class="form-control"
                                @change="upload($event)"
                                :class="{ 'is-invalid': errors.fileImage }"
                            />
                            <div class="invalid-feedback">{{ errors.fileImage }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="mb-3">
                        <label class="text-left">Nội dung bài viết</label>
                        <span style="color: red" >&nbsp;*</span>
                        <Field name="content" v-slot="{ field }">
                          <CKEditorCustom
                            v-bind="field"
                            v-model="model.content"
                            :class="{ 'is-invalid': errors.content }"
                          >
                          </CKEditorCustom>
                        </Field>
                        <div class="invalid-feedback">{{ errors.content }}</div>
                      </div>
                    </div>


                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <pharmacymodel />
  <pharmacydelete />
</template>
<script >
import axios from 'axios';
import VueMultiselect from 'vue-multiselect'
import Loading from "vue3-loading-overlay";
import Paginate from "vuejs-paginate-next";
import 'vue-multiselect/dist/vue-multiselect.css';
import Treeselect from 'vue3-treeselect'
import {newsModel} from "@/models/newsModel";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import {notifyModel} from "@/models/notifyModel";
import CKEditorCustom from "@/utils/view/CKEditorCustom.vue";
import {defineComponent ,ref } from '@vue/runtime-core';
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
export default defineComponent ( {
  components: {
    Treeselect,
    loading: Loading,
    paginate: Paginate,
    VueMultiselect,
    VueDatePicker,
    CKEditorCustom,
    Form,
    Field,
  },
  data() {

    return {
      title: "TẠO BÀI VIẾT",
      treeView: [],
      listMenuMobi: [],
      listService: [],
      model: newsModel.baseJson(),
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      url:`${process.env.VUE_APP_API_URL}files/view/`,
      format : `dd/MM/yyyy`,
      isFileOver: false,
      file: null,
      filePreview: null,
      locale: 'vi',
      uploadedFiles: [],
      keyId : null,
    };
  },
  name: "pharmacy/user",

  created() {
    this.GetTreeList();
    this.GetAllNewsSectionMobi();
    this.GetAllService();
  //  console.log("LOG CREATED : ")
  },

  watch: {

  },

  setup() {
      const schema = Yup.object().shape({
          name: Yup.string().required("Tên không được bỏ trống !"),
          describe: Yup.string().required("Mô tả không được bỏ trống !"),
          publicationDate: Yup.string().required("Ngày xuất bản không được bỏ trống !"),
          content: Yup.string().required("Nội dung không được bỏ trống !"),
          menu: Yup.object().shape({
            name: Yup.string().required("Trang tin không được bỏ trống!"),
            // Thêm các trường khác nếu cần
          }).required("Trang tin không được bỏ trống!"),
          fileImage: Yup.mixed()
          .required("File không được bỏ trống!")
          .test("fileSize", "File quá lớn", value => {
            return value && value.size <= 2 * 1024 * 1024; // <= 2MB
          })
          .test("fileFormat", "Định dạng file không hợp lệ", value => {
            return value && ["image/jpeg", "image/png", "image/gif"].includes(value.type);
          }),
      });
      return {
          schema,
      };
  },

  methods: {
    addCoQuanToModel(node, instanceId ){
      if(node.id){
        this.model.menu = {id : node.id , name : node.name } ;
       }
    },
    normalizer(node){
        if(node.children == null || node.children == 'null'){
            delete node.children;
        }
    },


    // Phương thức để upload file
    async uploadFile(file) {
      try {
        // Tạo formData để gửi file
        const formData = new FormData();
        formData.append('files', file);
        formData.append('code', "NEWS")
        // Gọi API để upload file
        // const response = await axios.post(`${process.env.VUE_APP_API_URL}Files/upload`, formData);

        axios.post(`${process.env.VUE_APP_API_URL}Files/upload`,formData).then((response) => {
          // console.log("LOG UPDATE : ", response);
          let resultData = response.data
          if (response.data.code == 0){
            this.model.files.push({
              fileId: resultData.data.fileId,
              fileName: resultData.data.fileName,
              ext: resultData.data.ext,
            });
            // Thu thập tất cả các tệp tin đã tải lên
            this.uploadedFiles.forEach(uploadFile => {
              // Thu thập thông tin từ mỗi tệp tin và thêm vào this.model
              this.model.ext = uploadFile.ext; // Giả sử ext đã được lưu trữ trong uploadedFiles
              this.model.fileId = uploadFile.fileId; // Giả sử fileId đã được lưu trữ trong uploadedFiles
              this.model.fileName = uploadFile.fileName; // Giả sử fileName đã được lưu trữ trong uploadedFiles
            });
            this.uploadedFiles = this.model.files
            console.log("Upload thành công : ", this.model);
          }
        }).catch((error) => {
          // Handle error here
          console.error('Lỗi khi upload file:', error);
        });
        // Xử lý phản hồi từ API nếu cần
        // console.log('Upload thành công:', response.data.data);
      } catch (error) {
        console.error('Lỗi khi upload file:', error);
      }
    },
    onFileChange(event) {
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        // this.uploadedFiles.push({ // Thêm thông tin về tệp tin vào mảng uploadedFiles
        //     file: file,
        //     previewUrl: URL.createObjectURL(file)
        // });
        // Gọi phương thức uploadFile để upload file lên API
        this.uploadFile(file);
        // Sau khi upload, bạn có thể thực hiện các xử lý khác ở đây nếu cần
      }
    },
    onDragOver(event) {
      event.preventDefault();
      this.isFileOver = true;
    },
    onDragLeave(event) {
      event.preventDefault();
      this.isFileOver = false;
    },
    onFileDrop(event) {
      event.preventDefault();
      this.isFileOver = false;
      const files = event.dataTransfer.files;
      this.handleFiles(files);
    },
    handleFiles(files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        // Xử lý tệp tin ở đây, ví dụ: lưu trữ vào một mảng hoặc hiển thị xem trước
        // Ví dụ:
        console.log("FILE: ", file);
        this.uploadedFiles.push({
          file: file,
          previewUrl: URL.createObjectURL(file)
        });
      }
    },

    removeImage(index) {
      // Xóa tệp tin khỏi mảng uploadedFiles tại chỉ mục index
      this.uploadedFiles.splice(index, 1);
    },
    async handleSubmit() {
      console.log("SUBMIT : ", );
      await this.$store.dispatch("newsStore/create", this.model).then((res) => {
        if (res != null && res.code ===0) {
            this.model= {}
            this.$refs.tbTreeSelect.clear();
          // // Xóa các tệp tin đã tải lên sau khi gửi thành công
          this.uploadedFiles = [];
        }
        this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res));
      });
    },

    async GetTreeList(){
      await this.$store.dispatch("menuCongDanStore/getTreeList").then((res) => {
        if (res)
        {
          this.treeView = res.data;
        }
      })
    },
    async GetAllNewsSectionMobi(){
      await this.$store.dispatch("newsSectionMobiStore/getAllSelected").then((res) => {
        if (res)
        {
          this.listMenuMobi= res.data;
        }
      })
    },

    async GetAllService(){
      await this.$store.dispatch("serviceStore/getAllSelected").then((res) => {
        if (res)
        {
          this.listService= res.data;
        }
      })
    },
    async upload() {
      if ( event.target &&  event.target.files.length > 0 ) {
        const formData = new FormData()
        formData.append('files', event.target.files[0])
        formData.append('code', "AVATAR")
        axios.post(`${process.env.VUE_APP_API_URL}Files/upload`,formData).then((response) => {
          // console.log("LOG UPDATE : ", response);
          let resultData = response.data
          if (response.data.code == 0){
            this.model.fileImage={
              fileId: resultData.data.fileId,
              fileName: resultData.data.fileName,
              ext: resultData.data.ext,
            };
          //  console.log("LOG UPDATE : ", this.model);
          }
        })
      }
    },

    getColorWithExtFile(ext) {
      if (ext == '.png' || ext == '.jpg'|| ext == '.jpeg' )
        return 'text-danger';

    },
    getIconWithExtFile(ext) {
      if (ext == '.png' || ext == '.jpg'|| ext == '.jpeg')
        return 'mdi mdi-file-image-outline';
    },
    deleteImage(){
      if (this.model != null && this.model.fileImage != null)
      {
        //console.log("LOG this.model : ", this.model)
        axios.post(`${process.env.VUE_APP_API_URL}files/delete/${this.model.fileImage.fileId}`).then((response) => {
          this.model.fileImage = null;
          // console.log('log model file remove', this.model.fileImage);
        }).catch((error) => {
          // Handle error here
          //  console.error('Error deleting file:', error);
        });
      }
    },

  }
});
</script>
<style>
.vue-treeselect__control{
  height: 42px !important;
  padding-top: 5px;
  padding-bottom: 5px;
}

.btn-luu{
  background-color: #e9ab2e !important;
  border: 1px solid #ebdda0 !important;
  float: right;
}

</style>
