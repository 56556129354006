<template>
  <div class="container pt-60 pb-60">
    <div class="main-title text-center">Quy trình niềng răng trẻ em</div>
    <div class="timeline">
      <div class="pt-10 pb-10">
        <div class="card-time-line right">
          <div class="date">
            <img src="@/assets/img/phuongthanh/dichvu/buoc1.png" alt="">
          </div>
          <div class="content-time-line">
            <h2>Bước 1</h2>
            <p>
              Khám, chụp phim và tư vấn với bác sĩ
            </p>
            <p class="desc-title pt-2">
              Trước khi bắt đầu, bác sĩ sẽ thăm khám tổng quát về tình trạng răng miệng của trẻ. Nên có các bênh lý về 
              răng như sâu răng, viêm nướu, hoặc cần điều trị tại điểm đến trước khi niềng răng thẩm mỹ.
            </p>
            <p class="desc-title pt-2">
              Bác sĩ sẽ chỉ định chụp phim X-ray, phim Cone Beam CT để đánh giá cấu trúc răng và tìm hiểu hiện trạng xương hàm. 
              Trong trường hợp mất răng hoặc xương hàm bị thiếu, bác sĩ sẽ phải ghép xương trong quá trình chỉnh nha.
            </p>
            <p class="desc-title pt-2">
              Đánh giá hiện trạng mọc răng, bác sĩ sẽ đưa ra các lựa chọn khí cụ phù hợp nhất cho sự phát triển của răng trẻ em.
            </p>
          </div>
        </div>
      </div>

      <div class="pt-10 pb-10">
        <div class="card-time-line left">
          <div class="date">
            <img src="@/assets/img/phuongthanh/dichvu/buoc2.png" alt="">
          </div>
          <div class="content-time-line">
            <h2>Bước 2</h2>
            <p>
              Lên kế hoạch điều trị
            </p>
            <p class="desc-title pt-2">
              Dựa trên kết quả kiểm tra, bác sĩ sẽ lên kế hoạch điều trị chi tiết, bao gồm lựa chọn loại khí cụ và thời gian thực hiện. 
              Niềng răng cho trẻ em thường sử dụng khí cụ trong suốt, dễ điều chỉnh và đảm bảo sự thoải mái.
            </p>
            <p class="desc-title pt-2">
              Trong quá trình điều trị, bác sĩ sẽ kiểm tra định kỳ để điều chỉnh khí cụ nhằm tối ưu hiệu quả chỉnh nha. 
              Nếu có vấn đề nào phát sinh, bác sĩ sẽ kịp thời điều chỉnh để không ảnh hưởng đến sự phát triển răng miệng của trẻ.
            </p>
          </div>
        </div>
      </div>

      <div class="pt-10 pb-10">
        <div class="card-time-line right">
          <div class="date">
            <img src="@/assets/img/phuongthanh/dichvu/buoc3.png" alt="">
          </div>
          <div class="content-time-line">
            <h2>Bước 3</h2>
            <p>
              Thực hiện niềng răng
            </p>
            <p class="desc-title pt-2">
              Bác sĩ sẽ gắn mắc cài lên răng trẻ bằng phương pháp gắn trực tiếp hoặc sử dụng khí cụ trong suốt như khay nhựa trong suốt. 
              Các khí cụ này giúp điều chỉnh răng từ từ, nhẹ nhàng và hiệu quả, đồng thời giảm đau cho trẻ.
            </p>
            <p class="desc-title pt-2">
              Trẻ cần tuân thủ đúng lịch hẹn tái khám để bác sĩ có thể điều chỉnh khí cụ và theo dõi tiến độ chỉnh nha. Bác sĩ sẽ 
              giải thích rõ về cách chăm sóc răng miệng đúng cách trong thời gian niềng răng để đảm bảo hiệu quả điều trị tốt nhất.
            </p>
          </div>
        </div>
      </div>

      <div class="pt-10 pb-10">
        <div class="card-time-line left">
          <div class="date" style="right: -300px;">
            <img src="@/assets/img/phuongthanh/dichvu/buoc4.png" alt="">
          </div>
          <div class="content-time-line">
            <h2>Bước 4</h2>
            <p>
              Kiểm tra và bảo trì
            </p>
            <p class="desc-title pt-2">
              Sau khi niềng răng hoàn tất, trẻ cần tiếp tục theo dõi định kỳ để kiểm tra sự 
              phát triển của răng và điều chỉnh khí cụ nếu cần thiết.
            </p>
            <p class="desc-title pt-2">
              Bác sĩ cũng sẽ hướng dẫn cách bảo trì sau niềng răng, bao gồm việc đeo khí cụ duy trì (retainer) để giữ răng ở vị trí đúng. 
              Điều này giúp ngăn ngừa sự tái phát lệch răng sau khi niềng răng hoàn tất.
            </p>
          </div>
        </div>
      </div>

      <div class="pt-10 pb-10">
        <div class="card-time-line right">
          <div class="date">
            <img src="@/assets/img/phuongthanh/dichvu/buoc5.png" alt="">
          </div>
          <div class="content-time-line">
            <h2>Bước 5</h2>
            <p>
              Duy trì kết quả và điều trị hậu phẫu
            </p>
            <p class="desc-title pt-2">
              Để duy trì kết quả lâu dài, trẻ sẽ được hướng dẫn cách vệ sinh răng miệng đúng cách và kiểm tra định kỳ với bác sĩ nha khoa.
            </p>
            <p class="desc-title pt-2">
              Nếu có vấn đề phát sinh sau niềng răng như răng mọc lệch, bác sĩ sẽ điều chỉnh hoặc có kế hoạch điều trị bổ sung để đảm bảo 
              răng miệng của trẻ phát triển một cách khỏe mạnh và thẩm mỹ.
            </p>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import { ref } from "vue";
const currentDate = ref(new Date());
import AOS from "aos";
import "aos/dist/aos.css";
import 'vue3-carousel/dist/carousel.css'
export default {
  components: {
  },
  data() {
    return {
      startdate: currentDate,
      list: [],
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  props: {
    header: { type : Object}
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  watch:{
    '$props':{
      handler: function (val) {
        this.list = val.header;
        //  console.log("LOG WATCH LIST ", this.list )
      },
      deep: true
    }
  },
  methods: {
    submitForm() {
      this.$router.push("/search-2");
    },
  },
};
</script>
<style>
</style>
