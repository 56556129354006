<template>
    <div class="container pt-60 pb-60">
      <div class="row">
        <div class="col-md-12 who-trongrang">
          <div>
            <div class="main-title pb-30">Nguyên nhân gây ra rối loạn khớp thái dương hàm?</div>
            <div class="desc-title">
              Một tình trạng ảnh hưởng đến khớp thái dương hàm (nơi kết nối xương hàm dưới với hộp sọ) và các cơ, 
              dây chằng xung quanh. Tình trạng này có thể gây đau, khó chịu khi ăn nhai và thậm chí ảnh hưởng đến 
              chất lượng cuộc sống.
            </div>
            <div class="card-who-trongrang pt-30">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Hai hàm răng không đều, răng có tình trạng khấp khểnh, răng thưa hoặc bệnh nhân bị thiếu răng.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Người có thói quen nghiến răng là đối tượng có nguy cơ cao mắc phải rối loạn khớp thái dương hàm hơn người bình thường. Khi thói quen này diễn ra trong một thời gian dài sẽ dẫn đến mặt nhai các răng bị mòn đi và khớp cắn sai lệch dẫn đến rối loạn.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Bệnh nhân có tiền sử bị chấn thương hàm mặt, cụ thể là phần hàm khiến khớp thái dương hàm bị trật.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Thói quen ăn uống cũng là yếu tố tác động không nhỏ đến khả năng mắc bệnh này. Những người có thói quen ăn nhai một bên thường xuyên hoặc ăn các đồ cứng, dai sẽ là đối tượng có nguy cơ cao mắc phải rối loạn khớp thái dương hàm.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Công việc gặp nhiều căng thẳng khiến cho tâm lý không ổn định, dẫn đến xuất hiện phản xạ co cơ hàm xuất hiện bất thường.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Các đối tượng mắc các chứng bệnh liên quan đến hệ thần kinh cũng có ảnh hưởng đến phản xạ của hệ thống nhai, xuất hiện tình trạng nghiến răng và theo thời gian sẽ dẫn tới tình trạng rối loạn khớp thái dương hàm.
              </div>
            </div>
          </div>


        </div>
<!--        <div class="col-md-6">-->
<!--            <div class="img-trongrang">-->
<!--                <img src="@/assets/img/phuongthanh/dichvu/who.png" alt="" style="max-height: 500px;">-->
<!--            </div>-->
<!--        </div>-->
      </div>
    </div>



</template>

<script>
  import { ref } from "vue";
  const currentDate = ref(new Date());
  import AOS from "aos";
  import "aos/dist/aos.css";
  import 'vue3-carousel/dist/carousel.css'
  export default {
    components: {
    },
    data() {
      return {
        startdate: currentDate,
        list: [],
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    props: {
      header: { type : Object}
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
    watch:{
      '$props':{
        handler: function (val) {
          this.list = val.header;
        //  console.log("LOG WATCH LIST ", this.list )
        },
        deep: true
      }
    },
    methods: {
      submitForm() {
        this.$router.push("/search-2");
      },
    },
  };
</script>
<style>
</style>
