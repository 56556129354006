<template>
  <div class="container pt-60 pb-60">
    <div class="row align-items-center">
      <div style="text-align: center; margin-bottom: 20px;">
        <div class="main-title pb-30">
          RỐI LOẠN THÁI DƯƠNG HÀM
        </div>
        <div class="decs-intro-doctor">
          Rối loạn thái dương hàm (Temporomandibular Joint Disorder - TMD) là một tình trạng ảnh hưởng đến khớp 
          thái dương hàm (nơi kết nối xương hàm dưới với hộp sọ) và các cơ, dây chằng xung quanh. Tình trạng này 
          có thể gây đau, khó chịu khi ăn nhai và thậm chí ảnh hưởng đến chất lượng cuộc sống.
        </div>
      </div>
    </div>
  </div>



</template>

<script>
import { ref } from "vue";
const currentDate = ref(new Date());
import AOS from "aos";
import "aos/dist/aos.css";
import indexfivespecialities from "@/assets/json/indexfivespecialities.json";
import 'vue3-carousel/dist/carousel.css'
export default {
  components: {
  },
  data() {
    return {
      startdate: currentDate,
      indexfivespecialities: indexfivespecialities,
      list: [],
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  props: {
    header: { type : Object}
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  watch:{
    '$props':{
      handler: function (val) {
        this.list = val.header;
        //  console.log("LOG WATCH LIST ", this.list )
      },
      deep: true
    }
  },
  methods: {
    submitForm() {
      this.$router.push("/search-2");
    },
  },
};
</script>
<style>
</style>
