<template>
    <div class="container pt-60 pb-60">
      <div class="row">
        <div class="col-md-6 who-trongrang">
          <div>
            <div class="main-title pb-30">Những ai nên sử dụng nha khoa thẩm mỹ?</div>
            <div class="desc-title">Nha khoa thẩm mỹ là giải pháp hiện đại giúp cải thiện nụ cười và nâng cao sự tự tin, phù hợp với nhiều đối tượng trong các trường hợp sau:</div>
            <div class="card-who-trongrang pt-30">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">Người có răng xỉn màu, ố vàng hoặc không đều màu: Do thực phẩm, thuốc lá, hoặc tuổi tác làm răng mất đi vẻ trắng sáng. Mong muốn có nụ cười rạng rỡ và thẩm mỹ hơn.</div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">Người có khuyết điểm về hình dáng và vị trí răng. Răng thưa, hô, móm, lệch lạc hoặc chen chúc gây mất tự tin.
                Răng mẻ, vỡ hoặc kích thước không đồng đều. </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Người bị mất răng, ảnh hưởng đến thẩm mỹ và chức năng ăn nhai. Muốn phục hồi răng mất với giải pháp tự nhiên và bền vững như cấy ghép Implant hoặc cầu răng sứ.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Người có vấn đề về nướu, ảnh hưởng đến thẩm mỹ. Người chuẩn bị cho các sự kiện quan trọng. Người muốn cải thiện nụ cười để tăng sự tự tin và chất lượng cuộc sống
              </div>
            </div>
          </div>


        </div>
        <div class="col-md-6">
            <div class="img-trongrang">
                <img src="@/assets/img/phuongthanh/dichvu/who.png" alt="" style="max-height: 500px;">
            </div>
        </div>
      </div>
    </div>



</template>

<script>
  import { ref } from "vue";
  const currentDate = ref(new Date());
  import AOS from "aos";
  import "aos/dist/aos.css";
  import 'vue3-carousel/dist/carousel.css'
  export default {
    components: {
    },
    data() {
      return {
        startdate: currentDate,
        list: [],
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    props: {
      header: { type : Object}
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
    watch:{
      '$props':{
        handler: function (val) {
          this.list = val.header;
        //  console.log("LOG WATCH LIST ", this.list )
        },
        deep: true
      }
    },
    methods: {
      submitForm() {
        this.$router.push("/search-2");
      },
    },
  };
</script>
<style>
</style>
