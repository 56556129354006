<template>
  <!-- Footer -->
  <footer class="footer footer-one footer-ten">
    <!-- <div class="footer-ten-bg">
      <img src="@/assets/img/bg/hexagen-group-4.png" alt="Image" style="height: 100%;"/>
      <img src="@/assets/img/bg/hexagen-group-5.png" alt="Image" style="height: 100%;"/>
    </div> -->
    <div class="footer-top">
      <div class="container">
        <div class="footer-details">
          <div class="row-footer">
            <div class="" style="display: flex; justify-content: center; align-items: center;">
              <div class="footer-widget footer-about">
                <div class="footer-logo" v-if="this.listFooter">
                  <img :src="this.listFooter.logo?.urlFile" alt="logo" />
                </div>
                <div class="footer-about-content" v-if ="this.listFooter">
                  <p style="text-align: center; padding-bottom: 5px; font-size: 14px">
                    CTY TNHH MTV NHA KHOA PHƯƠNG THÀNH
                  </p>
                </div>
                <div class="footer-about-content" style="text-align: center; color: #5B2F39;">
                  <span style="text-align: center; padding-bottom: 5px; font-size: 14px;">
                    NƠI HỘI TỤ TÂM - TÀI - ĐỨC
                  </span>
                </div>

                <div class="" style="padding-top: 30px;">
                  <span style="text-align: center; font-size: 14px; font-weight: bold;">
                    Mạng xã hội
                  </span>
                </div>
                <div>
                  <img src="@/assets/img/phuongthanh/core/facebook.png" alt="" class="icon-mxh">
                  <img src="@/assets/img/phuongthanh/core/tiktok.png" alt="" class="icon-mxh">
                  <img src="@/assets/img/phuongthanh/core/youtube.png" alt="" class="icon-mxh">
                  <img src="@/assets/img/phuongthanh/core/Message.png" alt="" class="icon-mxh">
                </div>
              </div>
            </div>
            <div class="">
              <div class="" style="font-size: 14px; line-height: 24px;">
                <div class="" style="padding: 0;">
                  <div class="name-footer">TRUNG TÂM NHA KHOA PHƯƠNG THÀNH</div>
                  <div class="title-footer">
                    <img src="@/assets/img/phuongthanh/core/address.png">
<!--                    <i class="fa fa-solid fa-location-dot me-1" style="color: #BE8B43"></i>-->
                    Số 483 Đại lộ Hùng Vương, khóm 2, phường 1. <br> TP. Sa Đéc, tỉnh Đồng Tháp
                  </div>
                  <div class="title-footer">
                    <img src="@/assets/img/phuongthanh/core/phone.png">
<!--                    <i class="fa fa-solid fa-phone me-1" style="color: #BE8B43"></i>-->
                    Hotline: 0858 783 939
                  </div>
                </div>
              </div>
            </div>
            <div class="">
              <div class="name-footer" style="margin-top: 3px;">THÔNG TIN</div>
              <div class="title-footer">
                <a href="http://phuongthanh.goldenkeysoft.com/gioi-thieu" target="_blank" rel="noopener noreferrer">
                  <p>Về chúng tôi</p>
                </a>
              </div>
              <div class="title-footer">
                <a href="http://phuongthanh.goldenkeysoft.com/doi-ngu-bac-si" target="_blank" rel="noopener noreferrer">
                  <p>Đội ngũ bác sĩ</p>
                </a>
              </div>
              <div class="title-footer">
                <a href="#" target="_blank" rel="noopener noreferrer">
                  <p>Giải thưởng - Chứng nhận</p>
                </a>
              </div>
              <div class="title-footer">
                <a href="#" target="_blank" rel="noopener noreferrer">
                  <p>Câu chuyện khách hàng</p>
                </a>
              </div>
              <div class="title-footer">
                <a href="#" target="_blank" rel="noopener noreferrer">
                  <p>Kết quả điều trị</p>
                </a>
              </div>
              <div class="title-footer">
                <a href="http://phuongthanh.goldenkeysoft.com/ban-tin/669010361c6aeaea5f6390e4" target="_blank" rel="noopener noreferrer">
                  <p>Kiến thức nha Khoa</p>
                </a>
              </div>
              <div class="title-footer">
                <a href="#" target="_blank" rel="noopener noreferrer">
                  <p>Từ điển nha khoa</p>
                </a>
              </div>
            </div>
            <div class="">
              <div class="name-footer" style="margin-top: 3px;">DỊCH VỤ</div>
              <div class="title-footer">
                <a href="http://phuongthanh.goldenkeysoft.com/trong-rang-implant" target="_blank" rel="noopener noreferrer">
                  <p>Trồng răng Implant</p>
                </a>
              </div>
              <div class="title-footer">
                <a href="http://phuongthanh.goldenkeysoft.com/nieng-rang" target="_blank" rel="noopener noreferrer">
                  <p>Niềng răng cho người lớn</p>
                </a>
              </div>
              <div class="title-footer">
                <a href="http://phuongthanh.goldenkeysoft.com/nha-khoa-tre-em" target="_blank" rel="noopener noreferrer">
                  <p>Niềng răng cho trẻ em </p>
                </a>
              </div>
              <div class="title-footer">
                <a href="http://phuongthanh.goldenkeysoft.com/nha-khoa-tham-my" target="_blank" rel="noopener noreferrer">
                  <p>Nha khoa thẩm mỹ</p>
                </a>
              </div>
              <div class="title-footer">
                <a href="http://phuongthanh.goldenkeysoft.com/nha-khoa-tong-quat" target="_blank" rel="noopener noreferrer">
                  <p>Nha khoa tổng quát</p>
                </a>
              </div>
            </div>
            <!-- <div class="col-lg-2 col-md-6 col-sm-6" v-for="(item, index) in this.listFooterMenu">
              <div class="name-footer" style="margin-top: 3px;">{{item.name}}</div>
              <div class="title-footer" v-for="(children, index) in item.children">
                <router-link :to="`ban-tin/${children.menuId}`">
                  <p>{{children.name}}</p>
                </router-link>
              </div>
            </div> -->

          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="container">
        <!-- Copyright -->
        <div class="copyright">
          <div class="copyright-text">
            <p class="mb-0" style="color: #000;">
              © {{ new Date().getFullYear() }} Bản quyền thuộc về Nha Khoa Phương Thành.
            </p>
          </div>
        </div>
        <!-- /Copyright -->
      </div>
    </div>
  </footer>
  <!-- /Footer -->
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
export default {
  data() {
    return {
      listFooter: [],
      listFooterMenu: [],
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  props: {
    footer: { type : Object}
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  created() {
    this.getData();
    this.getListFooterMenu();
  },
  // watch:{
  //   '$props':{
  //     handler: function (val) {
  //       this.listFooter = val.footer;
  //     },
  //     deep: true
  //   }
  // },
  methods: {
    async getData() {
        await this.$store.dispatch("headerStore/getAllFooter" ).then(res => {
            this.listFooter = res.data;
        });
    },
    async getListFooterMenu() {
      await this.$store.dispatch("menuFooterStore/getTreeList" ).then(res => {
        this.listFooterMenu = res.data;
      });
    },
  },
};

</script>

<style>

</style>
