<template>
    <section class="section-form-cta pt-60 pb-60">
        <div class="container">
            <div class="box-form-main lazyloaded">
                <div class="row align-items-center">
                    <div class="col-lg-8 col-md-8">
                        <div class="box-content">
                            <p>
                                <span>
                                    Nha Khoa Phương Thành – Nơi chuyên môn vững vàng kết hợp cùng sự tận tâm, 
                                    luôn sẵn sàng đồng hành cùng bạn để kiến tạo sức khỏe răng miệng an toàn và hiệu quả.
                                </span>
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4">
                        <div class="box-form">
                            <form id="submit-register-form-cta" class="form-register">
                                <div class="ps-relative customer-name">
                                    <span class="wpcf7-form-control-wrap your-name">
                                        <div class="form-group">
                                            <div class="icon-form">
                                                <i class="fa-regular fa-user"></i>
                                            </div>
                                            <input type="text" name="your_name" id="customer-name" placeholder="Nhập họ và tên">
                                        </div>
                                    </span>
                                </div>
                                <div class="ps-relative customer-phone">
                                    <span class="wpcf7-form-control-wrap your-tel">
                                        <div class="form-group">
                                            <div class="icon-form">
                                                <i class="fa fa-solid fa-phone"></i>
                                            </div>
                                            <input type="text" name="your_tel" id="customer-phone" placeholder="Nhập số điện thoại">
                                        </div>
                                    </span>
                                </div>
                                <div class="ps-relative customer-mail">
                                    <span class="wpcf7-form-control-wrap your-mail">
                                        <div class="form-group">
                                            <div class="icon-form">
                                                <i class="fa-regular fa-message"></i>
                                            </div>
                                            <input type="text" name="your_email" id="customer-mail" placeholder="Nội dung">
                                        </div>
                                    </span>
                                </div>
                                <div class="ps-relative">
                                    <div class="form-group">
                                        <button id="form-page-submit" class="submit" fdprocessedid="936b3">
                                            <i class="fa-solid fa-calendar-days me-3"></i>
                                            ĐĂNG KÝ
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
import {Carousel, Pagination, Slide} from "vue3-carousel";
import 'vue3-carousel/dist/carousel.css'
export default {
components: {

    Pagination,
},
props: {
    clinicSpace: { type : Object}
},
data() {
    return {
    settings: {
        itemsToShow: 1,
        snapAlign: 'center',
    },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
        // 700px and up
        576: {
        itemsToShow: 2,
        snapAlign: 'start',
        },
        768: {
        itemsToShow: 2,
        snapAlign: 'start',
        },
        // 1024 and up
        992: {
        itemsToShow: 3,
        snapAlign: 'start',
        },
        1200: {
        itemsToShow: 3,
        snapAlign: 'start',
        },
    },
    listImage: [],
    url : `${process.env.VUE_APP_API_URL}files/view/`,
    urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
},
watch:{
    '$props':{
    handler: function (val) {
        this.listImage = val.clinicSpace;
    },
    deep: true
    }
},
mounted() {
    this.$nextTick(() => {
    AOS.init();
    });
},
computed: {

},
methods: {
    next() {
        this.$refs.flickity.next();
        },

        previous() {
        this.$refs.flickity.previous();
        },
    },
};
</script>

<style>


</style>
  
  