<template>
  <div class="container pt-60 pb-60">
    <div class="row align-items-center">
      <div style="text-align: center; margin-bottom: 20px;">
        <div class="main-title pb-30">
          Nha khoa tổng quát
        </div>
        <div class="decs-intro-doctor">
          Nha khoa tổng quát là lĩnh vực chăm sóc sức khỏe răng miệng toàn diện, bao gồm việc chẩn đoán, phòng ngừa, 
          điều trị các vấn đề răng miệng cơ bản và duy trì sức khỏe răng miệng tổng thể. Đây là dịch vụ nha khoa 
          cơ bản mà hầu hết mọi người cần thực hiện định kỳ để giữ cho răng miệng luôn khỏe mạnh.
        </div>
      </div>
    </div>
  </div>



</template>

<script>
import { ref } from "vue";
const currentDate = ref(new Date());
import AOS from "aos";
import "aos/dist/aos.css";
import indexfivespecialities from "@/assets/json/indexfivespecialities.json";
import 'vue3-carousel/dist/carousel.css'
export default {
  components: {
  },
  data() {
    return {
      startdate: currentDate,
      indexfivespecialities: indexfivespecialities,
      list: [],
      url: `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile: `${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  props: {
    header: { type: Object }
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  watch: {
    '$props': {
      handler: function (val) {
        this.list = val.header;
        //  console.log("LOG WATCH LIST ", this.list )
      },
      deep: true
    }
  },
  methods: {
    submitForm() {
      this.$router.push("/search-2");
    },
  },
};
</script>
<style></style>
