<template>
    <div class="main-wrapper home-ten">
        <layoutheader :class="{ 'header-space': showHeaderSpace }" ref="header" />
        <breadcrumb :title="title" :text="text" :text1="text1" />
        <!-- Giới thiệu chung -->
        <section id="section-2" class="section-brand-history pb-60 pt-60">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-12">
                        <div class="brand-history-content">
                            <div class="main-title pb-10">NHA KHOA PHƯƠNG THÀNH</div>
                            <div class="sub-title pb-30">Tiên phong dẫn đầu</div>
                            <p>Thành lập từ năm 2014, trên suốt chặng đường đi qua chúng tôi luôn làm việc với phương châm lấy tâm đức, sự chính trực và tri thức mang đến nụ cười hạnh phúc hơn mong đợi cho khách hàng.</p>
                            <p>Sự phát triển đa phương diện về tổ chức, cơ sở vật chất, trang thiết bị, nguồn nhân lực,kỹ thuật chuyên môn và hoạt động xã hội tích cực nha khoa ngày càng góp phần vào sự phát triển chung của xã hội.</p>
                            <p class="slogan">Đoàn kết - Kỷ luật - Nhân văn</p>
                            <p class="slogan">An toàn - Chất lượng - Hiệu quả</p>
                            <div class="button-brand-history">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12 mb-2">
                        <div class="brand-history-thumb">
                            <a class="popup-video" href="">
                                <img class=" ls-is-cached lazyloaded" width="1614" height="1332" src="@/assets/img/phuongthanh/core/box-image.png" data-src="@/assets/img/phuongthanh/core/box-image.png" alt="Câu chuyện thương hiệu Elite Dental">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- /Giới thiệu chung -->
        <div class="page-wrapper">

            <!-- TẦM NHÌN - SỨ MỆNH -->
            <div class="container pb-60">
                <div class="row vision-nhakhoa">
                    <div class="col-lg-6 col-md-6 img-vision">
                        <div class="row">
                            <div class="col-lg-6">

                            </div>
                            <div class="col-lg-6 bg-vision">
                            </div>
                        </div>
                        <img src="@/assets/img/phuongthanh/gioithieu/tamnhin.png" alt="">
                    </div>
                    <div class="col-lg-6 col-md-6 item-vision">
                        <div class="row">
                            <div class="col-lg-12 content-vision">
                                <div>
                                    <h2><i class="fa-solid fa-eye me-2"></i> Tầm nhìn</h2>
                                    <p class="font-light">
                                        Đến năm 2030, Công ty TNHH MTV Nha khoa Phương Thành phải là trung tâm điều trị, đào tạo, nghiên cứu, sản xuất lĩnh vực Răng Hàm Mặt hàng đầu Việt Nam.
                                    </p>
                                    <img src="@/assets/img/phuongthanh/vector.png" alt="">
                                    <h2><i class="fa-solid fa-bullseye me-2"></i> Sứ mệnh</h2>
                                    <p class="font-light">
                                        Mang sự hài lòng hơn mong đợi đến với khách hàng khi điều trị tại Nha khoa Phương Thành. Nâng cao sức khỏe răng miệng cho cộng đồng.
                                    </p>
                                    <div class="img-tamnhin">
                                        <img src="@/assets/img/phuongthanh/gioithieu/tamnhin.png" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /TẦM NHÌN - SỨ MỆNH -->

            <!-- GIÁ TRỊ CỐT LỖI -->
             <div class="value-nhakhoa">
                 <div class="container" style="padding-top: 30px; padding-bottom: 30px;">
                     <div class="row">
                         <div class="col-lg-2 col-md-12 title-value">
                            <h1 class="font-bold color">GIÁ TRỊ CỐT LÕI</h1>
                         </div>
                         <div class="col-lg-5 col-md-6">
                            <div class="item-value">
                                <h2 class="font-bold color">Thấu Cảm</h2>
                                <p>Luôn tìm cách để thấu hiểu, đồng cảm và trân trọng với từng khách hàng.</p>
                            </div>
                            <div class="item-value">
                                <h2 class="font-bold color">Chính Trực</h2>
                                <p>Luôn nổ lực để làm những điều đúng cho khách hàng và cộng đồng ở mọi thời điểm.</p>
                            </div>
                            <div class="item-value">
                                <h2 class="font-bold color">Trách Nhiệm</h2>
                                <p>Đội ngũ Công ty TNHH MTV Nha khoa Phương Thành luôn hành xử với tinh thần trách nhiệm,
                                    chuẩn mực và đạo đức nghề nghiệp cao nhất đối với khách hàng và cộng đồng.</p>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-6">
                            <div class="item-value">
                                <h2 class="font-bold color">Đoàn Kết</h2>
                                <p>Là nền móng trong hệ thống giá trị cốt lõi của Công ty TNHH MTV Nha khoa Phương Thành,
                                    sự đoàn kết của các thành viên của công ty là sức mạnh gắn tập thể thành một khối thống nhất
                                    tạo nên sức mạnh tổng thể góp phần cho sự thành công và phát triển bền vững của công ty.</p>
                            </div>
                            <div class="item-value">
                                <h2 class="font-bold color">Ưu việt</h2>
                                <p>Công ty TNHH MTV Nha khoa Phương Thành luôn hướng đến những kết quả điều trị tốt nhất, an toàn nhất,
                                    luôn tự hoàn thiện và phục vụ khách hàng tốt nhất.</p>
                            </div>
                        </div>
                     </div>
                 </div>
             </div>
            <!-- /GIÁ TRỊ CỐT LỖI -->

            <!-- TỔ CHỨC ĐOÀN THỂ -->
                <div class="container pt-60 pb-60">
                    <div class="row">
                        <div class="col-lg-12 title-group pb-20">
                            <h2 class="color font-bold">Các tổ chức - Đoàn thể</h2>
                        </div>
                        <div class="col-lg-4 col-md-4 item-group">
                            <div class="card-group">
                                <img src="@/assets/img/phuongthanh/gioithieu/chibo.png" alt="">
                                <div class="name-card-group">
                                    <span>CHI BỘ ĐẢNG CSVN</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 item-group">
                            <div class="card-group">
                                <img src="@/assets/img/phuongthanh/gioithieu/congdoan.png" alt="">
                                <div class="name-card-group">
                                    <span>CÔNG ĐOÀN CƠ SỞ</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 item-group">
                            <div class="card-group">
                                <img src="@/assets/img/phuongthanh/tncs.png" alt="">
                                <div class="name-card-group">
                                    <span>ĐOÀN TNCS HỒ CHÍ MINH</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <!-- /TỔ CHỨC ĐOÀN THỂ -->

            <!-- GIẢI THƯỞNG TIÊU BIỂU -->
            <div class="prize">
                <div class="container">
                    <div class="row" style="padding: 30px;">
                        <div class="col-lg-4 col-md-12 title-prize">
                            <h1 class="color font-bold">Giải thưởng tiêu biểu</h1>
                            <b-tabs pills nav-class="flex-column" card v-model="activeTab" style="flex-wrap: wrap; margin-right: 0px;">
                                <b-tab>
                                    <template #title>
                                        <i class="fa-solid fa-building" style="margin-right: 7px;"></i> Doanh nghiệp
                                    </template>
                                </b-tab>
                                <b-tab>
                                    <template #title>
                                        <i class="fa-solid fa-user-tie" style="margin-right: 7px;"></i>Cá nhân
                                    </template>
                                </b-tab>
                            <!-- Thêm các tab khác ở đây nếu cần -->
                            </b-tabs>
                        </div>
                        <div class="col-lg-8">
                            <!-- Nội dung cho "Thông tin chung" -->
                            <div v-if="activeTab === 0">
                                <div class="row">
                                    <div class="col-lg-4 col-md-4 col-sm-4">
                                        <div class="wrapper-prize">
                                            <ul class="sessions">
                                                <!-- <li>
                                                    <a href="#" @click.prevent="currentYear = '2019'" :class="{ active: currentYear === '2019' }" class="time-link">2019</a>
                                                </li>
                                                <li>
                                                    <a href="#" @click.prevent="currentYear = '2020'" :class="{ active: currentYear === '2020' }" class="time-link">2020</a>
                                                </li> -->
                                                <li>
                                                    <a href="#" @click.prevent="currentYear = '2021'" :class="{ active: currentYear === '2021' }" class="time-link">2021</a>
                                                </li>
                                                <!-- <li>
                                                    <a href="#" @click.prevent="currentYear = '2022'" :class="{ active: currentYear === '2022' }" class="time-link">2022</a>
                                                </li> -->
                                                <li>
                                                    <a href="#" @click.prevent="currentYear = '2023'" :class="{ active: currentYear === '2023' }" class="time-link">2023</a>
                                                </li>
                                                <li>
                                                    <a href="#" @click.prevent="currentYear = '2024'" :class="{ active: currentYear === '2024' }" class="time-link">2024</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-sm-8">
                                        <!-- <div v-show="currentYear === '2019'" class="card-giaithuong">
                                            <h3 class="color" style="text-align: justify; margin-bottom: 20px;">Đang cập nhật...</h3>
                                        </div>
                                        <div v-show="currentYear === '2020'" class="card-giaithuong">
                                            <h3 class="color" style="text-align: justify; margin-bottom: 20px;">Đang cập nhật...</h3>
                                        </div> -->
                                        <div v-show="currentYear === '2021'" class="card-giaithuong">
                                            <h3 class="color" style="text-align: justify; margin-bottom: 20px;">Bằng khen Công đoàn</h3>
                                            <p style="text-align: justify;">
                                                Bằng khen Công đoàn cơ sở Công ty TNHH MTV Nha khoa Phương Thành có thành tích xuất sắc 
                                                trong phong trào thi đua “Công nhân, viên chức, lao động nỗ lực vượt khó, sáng tạo, 
                                                quyết tâm chiến thắng đại dịch Covid 19” do Ban Chấp hành Liên đoàn lao động tỉnh Đồng Tháp 
                                                trao tặng  là minh chứng cho sự đồng lòng, chung sức của cả tập thể.
                                            </p>
                                            <!-- <img src="@/assets/img/phuongthanh/giaithuong.jpg" alt=""> -->
                                        </div>
                                        <!-- <div v-show="currentYear === '2022'" class="card-giaithuong">
                                            <h3 class="color" style="text-align: justify; margin-bottom: 20px;">Đang cập nhật...</h3>
                                        </div> -->
                                        <div v-show="currentYear === '2023'" class="card-giaithuong">
                                            <h3 class="color" style="text-align: justify; margin-bottom: 20px;">Nhận danh hiệu “Doanh nghiệp nhỏ và vừa tiêu biểu” do Chủ tịch UBND tỉnh Đồng Tháp</h3>
                                            <p style="text-align: justify;">Nha khoa Phương Thành vinh dự đón nhận danh hiệu “Doanh nghiệp nhỏ và vừa tiêu biểu” 
                                                do Chủ tịch UBND tỉnh Đồng Tháp. Đây không chỉ là sự ghi nhận của Lãnh đạo Tỉnh mà còn là động lực giúp công ty
                                                 ngày một phát triển hơn
                                            </p>
                                            <!-- <img src="@/assets/img/phuongthanh/giaithuong.jpg" alt=""> -->
                                        </div>
                                        <div v-show="currentYear === '2024'" class="card-giaithuong">
                                            <h3 class="color" style="text-align: justify; margin-bottom: 20px;">Doanh nghiệp đạt chứng nhận</h3>
                                            <p style="text-align: justify;">
                                                1. Doanh nghiệp đạt chứng nhận “Doanh nghiệp khoa học & công nghệ” do Sở Khoa học và Công nghệ tỉnh Đồng Tháp.<br>
                                                2. Doanh nghiệp đạt chứng nhận “ISO 9001:2015” về hệ thống quản lý chất lượng
                                            </p>
                                            <!-- <img src="@/assets/img/phuongthanh/giaithuong.jpg" alt=""> -->
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Nội dung cho "Tab khác" -->
                            <div v-if="activeTab === 1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-4 col-sm-4">
                                        <div class="wrapper-prize">
                                            <ul class="sessions">
                                                <!-- <li>
                                                    <a href="#" @click.prevent="currentYear = '2019'" :class="{ active: currentYear === '2019' }" class="time-link">2019</a>
                                                </li> -->
                                                <li>
                                                    <a href="#" @click.prevent="currentYear = '2020'" :class="{ active: currentYear === '2020' }" class="time-link">2020</a>
                                                </li>
                                                <!-- <li>
                                                    <a href="#" @click.prevent="currentYear = '2021'" :class="{ active: currentYear === '2021' }" class="time-link">2021</a>
                                                </li>
                                                <li>
                                                    <a href="#" @click.prevent="currentYear = '2022'" :class="{ active: currentYear === '2022' }" class="time-link">2022</a>
                                                </li> -->
                                                <li>
                                                    <a href="#" @click.prevent="currentYear = '2023'" :class="{ active: currentYear === '2023' }" class="time-link">2023</a>
                                                </li>
                                                <li>
                                                    <a href="#" @click.prevent="currentYear = '2024'" :class="{ active: currentYear === '2024' }" class="time-link">2024</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-sm-8">
                                        <!-- <div v-show="currentYear === '2019'" class="card-giaithuong">
                                            <h3 class="color" style="text-align: justify; margin-bottom: 20px;">Đang cập nhật...</h3>
                                        </div> -->
                                        <div v-show="currentYear === '2020'" class="card-giaithuong">
                                            <h3 class="color" style="text-align: justify; margin-bottom: 20px;">
                                                Bằng khen do UBND tỉnh Đồng Tháp trao tặng
                                            </h3>
                                            <p style="text-align: justify;">
                                                Bác sĩ CKI Phạm Kim Thành vinh dự nhận bằng khen do UBND tỉnh Đồng Tháp 
                                                trao tặng vì đã có thành tích xuất sắc trong đóp góp an sinh xã hội trên 
                                                địa bàn thành phố Sa Đéc, tỉnh Đồng Tháp
                                            </p>
                                            <!-- <img src="@/assets/img/phuongthanh/giaithuong.jpg" alt=""> -->
                                        </div>
                                        <!-- <div v-show="currentYear === '2021'" class="card-giaithuong">
                                            <h3 class="color" style="text-align: justify; margin-bottom: 20px;">Đang cập nhật...</h3>
                                        </div>
                                        <div v-show="currentYear === '2022'" class="card-giaithuong">
                                            <h3 class="color" style="text-align: justify; margin-bottom: 20px;">Đang cập nhật...</h3>
                                        </div> -->
                                        <div v-show="currentYear === '2023'" class="card-giaithuong">
                                            <h3 class="color" style="text-align: justify; margin-bottom: 20px;">
                                                Bằng khen, giấy chứng nhận, kỷ niệm chương.
                                            </h3>
                                            <p style="text-align: justify;">
                                               <strong>1.</strong> Kỷ niệm chương Vì sự nghiệp khuyến học do Ban chấp hành Trung ương Hội khuyến học Việt Nam khen tặng.<br>
                                               <strong>2.</strong> Bằng khen về thành tích xuất sắc trong công tác thực hiện Chiến dịch Thanh niên tình nguyện Hè năm 2023 trên địa bàn tỉnh Đồng Tháp – Chủ tịch UBND tỉnh Đồng Tháp khen tặng<br>
                                               <strong>3.</strong> Giấy chứng nhận Top 100 Doanh nhân trẻ khởi nghiệp xuất sắc 2023 - Uỷ ban Trung ương Hội Liên hiệp Thanh niên Việt Nam khen tặng<br>
                                               <strong>4.</strong> Bằng khen Danh hiệu Doanh nhân trẻ khởi nghiệp xuất sắc năm 2023 - Uỷ ban Trung ương Hội Liên hiệp Thanh niên Việt Nam<br>
                                               <strong>5.</strong> Bằng khen Doanh nhân trẻ năng động sáng tạo 2023 – UBND tỉnh Đồng Tháp khen tặng

                                            </p>
                                        </div>
                                        <div v-show="currentYear === '2024'" class="card-giaithuong">
                                            <h3 class="color" style="text-align: justify; margin-bottom: 20px;">
                                                Đoạt danh hiệu top 10 Doanh nhân trẻ khởi nghiệp xuất sắc năm 2024 
                                            </h3>
                                            <p style="text-align: justify;">
                                                Anh Lưu Tuấn Lâm – Tổng giám đốc công ty TNHH MTV Nha khoa Phương Thành 
                                                đoạt danh hiệu top 10 Doanh nhân trẻ khởi nghiệp xuất sắc năm 2024 - 
                                                Ủy ban Trung ương Hội Doanh nhân trẻ Việt Nam trao tặng
                                            </p>
                                            <!-- <img src="@/assets/img/phuongthanh/giaithuong.jpg" alt=""> -->
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <!-- /GIẢI THƯỞNG TIÊU BIỂU -->

            <!-- HỆ THỐNG CƠ SỞ VẬT CHẤT -->
            <div class="container hethong pt-60 pb-60">
                <div class="row" style="display: flex; flex-direction: row;">
                    <div class="col-lg-8 col-md-12 col-sm-12 img-hethong">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6" style="padding: 5px;">
                                <img src="@/assets/img/phuongthanh/coso1.jpg" alt="">
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12" style="padding: 5px;">
                                        <img src="@/assets/img/phuongthanh/coso2.jpg" alt="">
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6" style="padding: 5px;">
                                        <img src="@/assets/img/phuongthanh/coso3.jpg" alt="">
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6" style="padding: 5px;">
                                        <img src="@/assets/img/phuongthanh/coso4.jpg" alt="">
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12" style="padding: 5px;">
                                        <img src="@/assets/img/phuongthanh/coso5.jpg" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12 title-hethong">
                        <div>
                            <h2 class="color font-bold">Hệ thống</h2>
                            <h2 class="color font-bold">Cơ sở vật chất</h2>
                            <p style="color: #00003b; text-align: justify;">
                                Trung tâm Nha khoa Phương Thành luôn cập nhật các trang thiết bị máy móc
                                ngành nha trên Thế giới kịp thời mang đến những kỹ thuật điều trị mới
                                và hiệu quả cao đến người dân.
                            </p>
                            <button>
                                <div class="arrow">
                                    <i class="fa-solid fa-arrow-right"></i>
                                </div>
                                Xem thêm
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- /HỆ THỐNG CƠ SỞ VẬT CHẤT -->

            <!-- ĐỘI NGŨ BÁC SĨ -->
            <div class="team-doctor">
                <div class="container" style="padding-top: 30px;">
                    <div class="row">
                        <div class="col-lg-4 col-md-12 title-team-doctor">
                            <div>
                                <h2 class="color font-bold">Đội ngũ Bác sĩ</h2>

                                <p style="color: #00003b; text-align: justify;"> Với đội ngũ bác sĩ tốt nghiệp chính quy, hơn 40% sau đại học, chúng tôi cam kết ...
                                </p>
                                <button>
                                    <div class="arrow">
                                        <i class="fa-solid fa-arrow-right"></i>
                                    </div>
                                    Xem thêm
                                </button>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <div class="row list-doctor">
                              <div class="col-lg-4 col-md-4 col-sm-6 mb-3 item-doctor">
                                <img src="@/assets/img/phuongthanh/doctor/BS_Thanh.jpg" alt="">
                                <div class="name-doctor">
                                  <span>BS Phạm Kim Thành</span>
                                </div>
                              </div>
                                <div class="col-lg-4 col-md-4 col-sm-6 mb-3 item-doctor">
                                    <img src="@/assets/img/phuongthanh/doctor/BS_Lam.jpg" alt="">
                                    <div class="name-doctor">
                                        <span>BS. Lưu Tuấn Lâm</span>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-4 col-sm-6 mb-3 item-doctor">
                                    <img src="@/assets/img/phuongthanh/doctor/BS_Nguyen.jpg" alt="">
                                    <div class="name-doctor">
                                        <span>BS. Lê Nguyễn Hạnh Nguyên</span>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-6 mb-3 item-doctor">
                                    <img src="@/assets/img/phuongthanh/doctor/BS_Diep.jpg" alt="">
                                    <div class="name-doctor">
                                        <span>BS. Phạm Ngọc Diệp</span>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-6 mb-3 item-doctor">
                                    <img src="@/assets/img/phuongthanh/doctor/BS_Khoa.jpg" alt="">
                                    <div class="name-doctor">
                                        <span>BS. Bùi Minh Khoa</span>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-6 mb-3 item-doctor">
                                    <img src="@/assets/img/phuongthanh/doctor/BS_Man.jpg" alt="">
                                    <div class="name-doctor">
                                        <span>BS. Hà Minh Mẫn</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- /ĐỘI NGŨ BÁC SĨ -->

            <!-- ĐỐI TÁC -->

            <div class="container pt-60 pb-60">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <h2 class="color font-bold text-center">Đối tác của chúng tôi</h2>
                    </div>
                </div>
                <div class="row list-doitac">
                    <div class="col-lg-3 col-md-3 col-sm-6 mb-3 item-doitac">
                        <img src="@/assets/img/phuongthanh/doitac/doitac1.png" alt="">
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 mb-3 item-doitac">
                        <img src="@/assets/img/phuongthanh/doitac/doitac2.png" alt="">
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 mb-3 item-doitac">
                        <img src="@/assets/img/phuongthanh/doitac/doitac3.png" alt="">
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 mb-3 item-doitac">
                        <img src="@/assets/img/phuongthanh/doitac/doitac4.png" alt="">
                    </div>
                </div>
            </div>

            <!-- /ĐỐI TÁC -->

        </div>
    </div>
  <footerHome></footerHome>
</template>

<script>
import BlogGrid from "@/assets/json/blog-grid.json";
import {pageInfoModel} from "@/models/pageInfoModel";
export default {
  components: {},
  data() {
    return {
      title: 'Giới thiệu',
      text: 'Trang chủ',
      text1: 'Giới thiệu',
      numberOfElement: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      model: [],
      activeTab: 0, // Tab mặc định là tab đầu tiên
      BlogGrid: BlogGrid,
      currentYear: '2024', // Năm mặc định được chọn
      pageInfo : pageInfoModel.baseJson(),
    }
  },

  watch: {
    $route(to, from) {
      //  console.log("LOG WATCH DATA : ")
    //   this.getData();
    },
    perPage: {
      deep: true,
      handler(val){
        // this.getData();
      }
    },
    currentPage:{
      deep: true,
      handler(val){
        //   console.log("this.perpage", this.currentPage);
        // this.getData();
      }
    }
  },

  mounted() {
  },
  created() {
  },
  methods: {
    show2019() {
        this.currentYear = '2019';
    },
    show2020() {
        this.currentYear = '2020';
    },
    show2021() {
        this.currentYear = '2021';
    },
    show2022() {
        this.currentYear = '2022';
    },
    show2023() {
        this.currentYear = '2023';
    },
    show2024() {
        this.currentYear = '2024';
    },
    async getData() {
      await this.$store.dispatch("pageInfoStore/getInfo").then(res => {
        if (res != null && res.code ===0)
        {
          this.pageInfo = pageInfoModel.getJson(res.data);
        }

      });
    },
  },
}
</script>

<style>

.color{
    color: #D6A24D;
}

.font-bold{
    font-family: 'Poppins Bold';
}

.font-light{
    font-family: 'Poppins Regular';
}

.font-regular{
    font-family: 'Poppins Regular';
}


/* **************** GIỚI THIỆU CHUNG *************** */

.item-intro{
    padding-right: 20px !important;
}
.intro-nhakhoa .item-intro .title-intro{
    font-size: clamp(0.9375rem, 0.3472rem + 1.8889vw, 2rem); /*32 - 15*/
    margin-bottom: 10px;
    font-family: 'Poppins Bold';
    font-size: 42px;
}

.intro-nhakhoa .item-intro .title-intro-sub{
    font-size: clamp(0.9375rem, 0.4861rem + 1.4444vw, 1.75rem); /*28 - 15*/
    margin-bottom: 10px;
    font-family: 'Poppins Bold';
    font-size: 36px;
    text-transform: capitalize;
}

.intro-nhakhoa .item-intro .decs-intro{
    font-size: clamp(0.9375rem, 0.8333rem + 0.3333vw, 1.125rem); /*18 - 15*/
    text-align: justify;
    font-family: "Poppins Light",Serif;
    color: #00003b;
    margin-bottom: 10px;
}

.img-intro{
    padding-left: 20px !important;
}

.img-intro img{
    border-radius: 30px;
}

/* **************** HẾT GIỚI THIỆU CHUNG *************** */

/* **************** TẦM NHÌN - SỨ MỆNH *************** */
.img-vision {
    position: relative;
}

.img-vision img{
    position: absolute;
    top: 50%;
    padding-right: 20px;
    height: 400px;
    transform: translateY(-50%);
}

.img-vision .bg-vision{
    background-color: #D6A24D;
    height: 500px;
    border-radius: 30px 0 0 30px;
}

.img-tamnhin{
    display: none;
}

.item-vision{
    background-color: #D6A24D;
    height: 500px;
}

.item-vision .content-vision{
    height: 500px;
    display: flex;
    align-items: center;
    padding: 30px 60px 30px 20px;
}

.item-vision .content-vision h2, .item-vision .content-vision p{
    color: #fff;
    text-align: justify;
}

@media (max-width: 768px) {
    .img-vision{
        overflow: hidden;
        display: none;
    }

    .img-vision img{
       display: none;
    }

    .img-vision .bg-vision{
        background-color: inherit;
        height: 400px;
    }
    .img-tamnhin{
        display: block;
        text-align: center;
    }
    .item-vision{
        height: 100% !important;
    }
    .item-vision .content-vision{
        height: 100% !important;
        padding: 30px 20px !important;
    }
}

@media (max-width: 576px) {
    .img-vision .bg-vision{
        height: 300px;
    }
}
/* **************** HẾT TẦM NHÌN - SỨ MỆNH *************** */


/* **************** GIÁ TRỊ CỐT LỖI *************** */
.value-nhakhoa{
    background-color: #D6A24D1A;
}

.title-value{
    display: flex;
    justify-content: center;
    align-items: center;
}

.title-value h1{
    display: inline-block;
    transform-origin: center center;
    white-space: nowrap; /* Đảm bảo chữ không xuống dòng */
    transform: rotate(-90deg);
}

.item-value{
    padding: 20px 0;
    border-bottom: 2px solid #D6A24D;
}
.item-value p {
    /* color: #00003b03b; */
    text-align: justify;
    margin: 0;
}

@media (max-width: 992px) {
    .title-value h1{
        transform: rotate(0deg);
    }
}

/* **************** HẾT GIÁ TRỊ CỐT LỖI *************** */

/* **************** CÁC TỔ CHỨC ĐOÀN THỂ *************** */
.title-group{
    display: flex;
    justify-content: center;
    align-items: center;
}

.item-group{
    margin-bottom: 20px;
}

.card-group{
    border-radius: 10px 10px 0 0;
}

.card-group img{
    border-radius: 10px 10px 0 0;
    width: 100%;
    min-height: 230px;
    max-height: 230px;
    object-fit: cover;
}

.name-card-group{
    background-color: #D6A24D;
    padding: 7px;
    width: 100%;
    border-radius: 0 0 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.name-card-group{
    color: #fff;
    font-weight: bold;
}

@media (max-width: 768px) {
    .card-group img{
        max-height: 330px;
    }
}

/* **************** HẾT CÁC TỔ CHỨC ĐOÀN THỂ *************** */

/* **************** GIẢI THƯỞNG TIÊU BIỂU *************** */
.prize{
    background-color: #D6A24D1A;
}
.wrapper-prize ul,
.wrapper-prize li {
    list-style: none;
    padding: 0;
}

.wrapper-prize {
    /* background: #eaf6ff; */
    padding: 0 2rem;
    border-radius: 15px;
}
.wrapper-prize .sessions {
    margin-top: 2rem;
    border-radius: 12px;
    position: relative;
}
.wrapper-prize li {
    text-align: right;
    padding-bottom: 1.5rem;
    border-right: 1px solid #ccc;
    position: relative;
    padding-right: 20px;
    margin-right: 10px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.wrapper-prize li a {
    display: block;
    padding-bottom: 10px;
    color:#00003b; /* Màu chữ mặc định */
    text-decoration: none;
}

.wrapper-prize li a.active {
    font-weight: bold;
    color: #D6A24D; /* Màu chữ khi được chọn */
}

/* Nút tròn màu sắc cho từng năm */
.sessions li a:before {
    content: "";
    width: 15px;
    height: 15px;
    background: #ccc; /* Màu mặc định */
    border: 1px solid #ccc; /* Màu viền mặc định */
    border-radius: 50%;
    position: absolute;
    right: -7px;
    top: 5px;
    transition: background-color 0.3s, border-color 0.3s;
}

/* Màu sắc cho năm được chọn */
.sessions li a.active:before {
    background-color: #D6A24D; /* Màu nền cho nút tròn khi được chọn */
    border-color: #D6A24D; /* Màu viền cho nút tròn khi được chọn */
}


.wrapper-prize .time {
    color: #2a2839;
    font-family: "Poppins", sans-serif;
    font-weight: 500;

}
.wrapper-prize p {
    color: #4f4f4f;
    font-family: sans-serif;
    line-height: 1.5;
    margin-top: 0.4rem;
}
.year-content {
    display: none;
}

.year-content:target {
    display: block;
}

/* .time-link {
    display: block;
    padding: 10px 0;
    cursor: pointer;
} */

.time-link {
    display: block;
    padding-bottom: 10px;
    cursor: pointer;
    color: black;
    text-decoration: none;
}

.time-link.active {
    font-weight: bold;
    color: #D6A24D; /* Đổi màu khi được chọn */
}

.year-content {
    margin-top: 20px;
}

.card-giaithuong img{
    width: 100%;
    height: 350px;
    border-radius: 30px;
    object-fit: cover;
}

@media (max-width: 992px) {
    .title-prize{
        text-align: center;
        margin-bottom: 20px;
    }
    .wrapper-prize{
        padding: 0;
    }
}

.title-prize .nav-pills .nav-link.active{
    background-color: #D6A24D;
    text-align: left;
}

.title-prize .nav-pills .nav-link{
    width: 100%;
    color: #00003b;
    text-align: left;
}

.title-prize .tab-content{
    display: none;
}

@media (max-width: 1000px) {
    .title-prize .flex-column{
        flex-direction: row !important;
    }
}

/* **************** HẾT GIẢI THƯỞNG TIÊU BIỂU *************** */


/* **************** HỆ THỐNG CƠ SỞ VẬT CHẤT *************** */

.title-hethong{
    display: flex;
    justify-content: center;
    align-items: center;
}
.title-hethong div{
    padding: 20px;
}
.hethong img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.title-hethong button{
    background-color: inherit;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title-hethong button .arrow{
    margin-right: 10px;
    padding: 5px 10px;
    border: 2px solid #ccc;
    border-radius: 50%;
}

@media (max-width: 992px) {
    .img-hethong{
        order: 2;
    }
    .title-hethong{
        order: 1;
    }
}

/* **************** HẾT HỆ THỐNG CƠ SỞ VẬT CHẤT *************** */


/* **************** ĐỘI NGŨ BÁC SĨ *************** */

.team-doctor{
    background-color: #D6A24D1A;
}
.title-team-doctor{
    display: flex;
    align-items: center;
}
.title-team-doctor div{
    padding: 20px;
}

.title-team-doctor button{
    background-color: inherit;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title-team-doctor button .arrow{
    margin-right: 10px;
    padding: 5px 10px;
    border: 2px solid #ccc;
    border-radius: 50%;
}

.list-doctor .item-doctor img{
    width: 100%;
    height: 300px;
    object-fit: cover;
    background-color: #ccc;
}

.list-doctor .item-doctor .name-doctor{
    padding: 8px;
    background-color: #BE8B43;
    color: #fff;
    text-align: center;
}

.list-doctor .item-doctor .name-doctor span{
    font-size: 14px;
    font-weight: bold;
}

/* **************** HẾT ĐỘI NGŨ BÁC SĨ *************** */


/* **************** ĐỐI TÁC *************** */
.list-doitac{
    padding: 30px;
}

.list-doitac .item-doitac{
    height: 200px;
    text-align: center;
}

.list-doitac .item-doitac img{
    height: 100%;
}


/* **************** HẾT ĐỐI TÁC *************** */
</style>
