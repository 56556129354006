<template>
    <!-- Need To Know -->
    <section class="need-to-know-section section-list-service pt-60 pb-60">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="section-header-one section-header-slider text-center pb-30">
              <div class="main-title">Các phương pháp nha khoa trẻ em</div>
            </div>
          </div>
        </div>
        <div class="listing-service pt-30">
          <div class="row">
            <div class="col-xl-4 col-md-6 col-12">
              <router-link
                  :to="{
                    path: `/nha-khoa-tre-em/nieng-rang-cho-tre-em`,
                  }"
              >
                <div class="service-item">
                  <div class="service-thumb">
                    <a href="">
                      <img width="300" height="208"
                        src="@/assets/img/phuongthanh/dichvu/implant-1-rang.png"
                        class="attachment-medium size-medium wp-post-image img-dichvu"
                        alt="Ảnh"
                        decoding="async"
                        fetchpriority="high"
                        srcset=""
                        sizes="100vw">
                    </a>
                  </div>
                  <div class="service-content">
                    <span style="font-size: 14px;">Lấy lại nụ cười thẳng đều, chuẩn khớp cắn</span>
                    <div class="service-title">
                      <h3>
                        <a href="" data-wpel-link="internal">
                          Niềng Răng Trẻ Em
                        </a>
                      </h3>
                    </div>
                    <div class="service-excerpt">
                      Niềng răng trẻ em giúp điều chỉnh răng mọc lệch, khớp cắn sai, và hỗ trợ phát triển hàm đúng cách. 
                      Phương pháp này thường áp dụng cho trẻ từ 6-12 tuổi, nhằm ngăn ngừa các vấn đề về răng miệng trong tương lai.
                    </div>
                    <div class="service-button">
                      <a href="" data-wpel-link="internal">
                        <div>
                          Xem thêm
                        </div>
                        <i class="fa fa-solid fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-xl-4 col-md-6 col-12">
              <router-link
                  :to="{
                    path: `/nha-khoa-tre-em/phuc-hoi-su-tre-em`,
                  }"
              >
                <div class="service-item">
                  <div class="service-thumb">
                    <a href="">
                      <img width="300" height="208"
                        src="@/assets/img/phuongthanh/dichvu/implant-vai-rang.png"
                        class="attachment-medium size-medium wp-post-image img-dichvu"
                        alt="Ảnh"
                        decoding="async"
                        fetchpriority="high"
                        srcset=""
                        sizes="100vw">
                    </a>
                  </div>
                  <div class="service-content">
                    <span style="font-size: 14px;">Khôi phục răng đã mất, cải thiện thẩm mỹ</span>
                    <div class="service-title">
                      <h3>
                        <a href="" data-wpel-link="internal">
                          Phục Hồi Sứ Trẻ Em
                        </a>
                      </h3>
                    </div>
                    <div class="service-excerpt">
                      Phục hồi sứ cho trẻ em là giải pháp tối ưu để khôi phục răng sữa hoặc răng vĩnh viễn bị tổn thương. 
                      Bọc sứ không chỉ cải thiện chức năng ăn nhai mà còn giúp bảo vệ răng và duy trì thẩm mỹ nụ cười cho trẻ.
                    </div>
                    <div class="service-button">
                      <a href="" data-wpel-link="internal">
                        <div>
                          Xem thêm
                        </div>
                        <i class="fa fa-solid fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-xl-4 col-md-6 col-12">
              <router-link
                  :to="{
                    path: `/nha-khoa-tre-em/tram-ngua-sau-rang`,
                  }"
              >
                <div class="service-item">
                  <div class="service-thumb">
                    <a href="">
                      <img width="300" height="208"
                        src="@/assets/img/phuongthanh/dichvu/implant-toan-ham.png"
                        class="attachment-medium size-medium wp-post-image img-dichvu"
                        alt="Ảnh"
                        decoding="async"
                        fetchpriority="high"
                        srcset=""
                        sizes="100vw">
                    </a>
                  </div>
                  <div class="service-content">
                    <span style="font-size: 14px;">Phòng ngừa sâu răng, bảo vệ sức khỏe răng miệng</span>
                    <div class="service-title">
                      <h3>
                        <a href="" data-wpel-link="internal">
                          Trám Ngừa Sâu Răng
                        </a>
                      </h3>
                    </div>
                    <div class="service-excerpt">
                      Trám ngừa sâu răng giúp bảo vệ răng sữa và răng vĩnh viễn của trẻ khỏi nguy cơ sâu răng. 
                      Phương pháp này sử dụng vật liệu an toàn để lấp đầy các rãnh sâu, hạn chế vi khuẩn và duy trì hàm răng khỏe mạnh.
                    </div>
                    <div class="service-button">
                      <a href="" data-wpel-link="internal">
                        <div>
                          Xem thêm
                        </div>
                        <i class="fa fa-solid fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- /Need To Know -->

  </template>
  <script>
  import AOS from "aos";
  import "aos/dist/aos.css";
  import indexFiveService from "@/assets/json/indexfiveservice.json";
  export default {
    data() {
      return {
        indexFiveService: indexFiveService,
        list: [],
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
    created() {
      this.getData();
    },
    methods: {
      async getData() {
        let params = {
            start: this.currentPage,
            limit: this.perPage,
            sortBy: this.sortBy,
        }
        await this.$store.dispatch("dichVuStore/getPagingParams", params ).then(res => {
            this.list = res.data.data
        });
    },
      toggleFavorite(item) {
        item.favorite = !item.favorite;
      },
    },
  };
  </script>

  <style>
  .img-service{
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 12px;
  }

  @media (max-width: 576px) {
    .img-service{
      height: 300px;
    }
  }
  </style>
