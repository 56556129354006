<template>
    <div class="container pt-60 pb-60">
      <div class="main-title text-center">Quy trình Nha khoa tổng quát</div>
      <div class="timeline">
        <div class="pt-10 pb-10">
          <div class="card-time-line right">
            <div class="date">
              <img src="@/assets/img/phuongthanh/dichvu/buoc1.png" alt="">
            </div>
            <div class="content-time-line">
              <h2>Bước 1</h2>
              <p>
                Tư vấn và thăm khám ban đầu:
              </p>
              <p class="desc-title pt-2">
                Bác sĩ tiến hành khám tổng quát răng miệng để đánh giá tình trạng hiện tại, bao gồm: Kiểm tra răng, nướu, và các mô mềm trong miệng.
                Hỏi thăm bệnh sử và thói quen chăm sóc răng miệng của bệnh nhân. Chụp X-quang (nếu cần) để phát hiện các vấn đề sâu hơn.
              </p>
            </div>
          </div>
        </div>

        <div class="pt-10 pb-10">
          <div class="card-time-line left">
            <div class="date">
              <img src="@/assets/img/phuongthanh/dichvu/buoc2.png" alt="">
            </div>
            <div class="content-time-line">
              <h2>Bước 2</h2>
              <p>
                Lập kế hoạch điều trị:
              </p>
              <p class="desc-title pt-2">
                Sau khi kiểm tra, bác sĩ: Đưa ra chẩn đoán và thảo luận với bệnh nhân về các vấn đề cần xử lý.
                Tư vấn kế hoạch điều trị phù hợp: làm sạch răng, điều trị sâu răng, nhổ răng hoặc các phương pháp khác.
                Thông báo chi phí và thời gian thực hiện.
              </p>
            </div>
          </div>
        </div>

        <div class="pt-10 pb-10">
          <div class="card-time-line right">
            <div class="date">
              <img src="@/assets/img/phuongthanh/dichvu/buoc3.png" alt="">
            </div>
            <div class="content-time-line">
              <h2>Bước 3</h2>
              <p>
                Làm sạch răng miệng:
              </p>
              <p class="desc-title pt-2">
                Tiến hành cạo vôi răng, làm sạch mảng bám và đánh bóng răng.
              </p>
              <p class="desc-title pt-2">
                Đây là bước quan trọng để phòng ngừa các bệnh lý nha chu và cải thiện sức khỏe răng miệng.
              </p>
            </div>
          </div>
        </div>

        <div class="pt-10 pb-10">
          <div class="card-time-line left">
            <div class="date" style="right: -300px;">
              <img src="@/assets/img/phuongthanh/dichvu/buoc4.png" alt="">
            </div>
            <div class="content-time-line">
              <h2>Bước 4</h2>
              <p>
                Điều trị các vấn đề răng miệng:
              </p>
              <p class="desc-title pt-2">
                Trám răng: Lấp đầy các lỗ sâu hoặc vết nứt trên răng.
              </p>
              <p class="desc-title pt-2">
                Điều trị viêm nướu, nha chu: Xử lý nướu bị sưng, viêm hoặc chảy máu.
              </p>
              <p class="desc-title pt-2">
                Điều trị tủy răng (nội nha): Nếu răng bị nhiễm trùng hoặc tổn thương tủy.
              </p>
              <p class="desc-title pt-2">
                Nhổ răng: Loại bỏ răng hư hỏng nặng hoặc răng khôn mọc lệch.
              </p>
            </div>
          </div>
        </div>

        <div class="pt-10 pb-10">
          <div class="card-time-line right">
            <div class="date">
              <img src="@/assets/img/phuongthanh/dichvu/buoc5.png" alt="">
            </div>
            <div class="content-time-line">
              <h2>Bước 5</h2>
              <p>
                Chăm sóc và tái khám định kỳ
              </p>
              <p class="desc-title pt-2">
                Sau khi hoàn thành điều trị, bác sĩ sẽ hướng dẫn cách vệ sinh răng miệng đúng cách, bao gồm:
                Cách đánh răng, dùng chỉ nha khoa và nước súc miệng. Thói quen ăn uống và các biện pháp phòng ngừa sâu răng.
              </p>
              <p class="desc-title pt-2">
                Khuyến khích bệnh nhân quay lại sau 3-6 tháng để kiểm tra và duy trì sức khỏe răng miệng.
              </p>
            </div>
          </div>
        </div>
        
      </div>
    </div>

</template>
  
<script>
  import { ref } from "vue";
  const currentDate = ref(new Date());
  import AOS from "aos";
  import "aos/dist/aos.css";
  import 'vue3-carousel/dist/carousel.css'
  export default {
    components: {
    },
    data() {
      return {
        startdate: currentDate,
        list: [],
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    props: {
      header: { type : Object}
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
    watch:{
      '$props':{
        handler: function (val) {
          this.list = val.header;
        //  console.log("LOG WATCH LIST ", this.list )
        },
        deep: true
      }
    },
    methods: {
      submitForm() {
        this.$router.push("/search-2");
      },
    },
  };
</script>
<style>
</style>
  