<template>
    <div class="container pt-60 pb-60">
      <div class="row">
        <div class="col-md-6">
            <div class="img-trongrang">
                <img src="@/assets/img/phuongthanh/dichvu/cautaorangimplant.png" alt="" style="max-height: 500px;">
            </div>
        </div>
        <div class="col-md-6">
            <div class="main-title pb-40">Cấu tạo nha khoa thẩm mỹ</div>

            <div class="item-cautaorang pb-60">
                <div class="number">
                    <img src="@/assets/img/phuongthanh/dichvu/1.png" alt="" class="img-number">
                    <img src="@/assets/img/phuongthanh/dichvu/in1.png" alt="" class="in-number">
                </div>
                <div class="info-cautaorang">
                    <div>
                        <div class="name-cautaorang">
                            Răng:
                        </div>
                        <div class="sub-cautaorang">
                            Lớp ngoài cùng của răng, có màu trong suốt hoặc trắng ngà, chịu trách nhiệm về độ sáng bóng của răng.
                        </div>
                    </div>
                </div>
            </div>
            <div class="item-cautaorang pb-60">
                <div class="number">
                    <img src="@/assets/img/phuongthanh/dichvu/2.png" alt="" class="img-number">
                    <img src="@/assets/img/phuongthanh/dichvu/in2.png" alt="" class="in-number">
                </div>
                <div class="info-cautaorang">
                    <div>
                        <div class="name-cautaorang">
                          Nướu (Lợi):
                        </div>
                        <div class="sub-cautaorang">
                          Nướu khỏe mạnh thường có màu hồng nhạt. Nướu thâm hoặc không đều màu có thể ảnh hưởng đến thẩm mỹ.
                          Đường viền nướu cần đều đặn, ôm sát chân răng để tạo sự cân đối.
                          Trong nha khoa thẩm mỹ, nụ cười lộ nướu (gummy smile) thường được điều chỉnh để tạo sự hài hòa.
                        </div>
                    </div>
                </div>
            </div>
            <div class="item-cautaorang">
                <div class="number">
                    <img src="@/assets/img/phuongthanh/dichvu/3.png" alt="" class="img-number">
                    <img src="@/assets/img/phuongthanh/dichvu/in3.png" alt="" class="in-number">
                </div>
                <div class="info-cautaorang">
                    <div>
                        <div class="name-cautaorang">
                          Khớp cắn:
                        </div>
                        <div class="sub-cautaorang">
                          Khớp cắn cân đối giúp răng khép lại một cách tự nhiên, đồng thời tạo nên vẻ ngoài hài hòa cho khuôn mặt.
                          Răng cửa và răng nanh đóng vai trò nổi bật trong thẩm mỹ, vì chúng dễ thấy nhất khi cười.
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
  import { ref } from "vue";
  const currentDate = ref(new Date());
  import AOS from "aos";
  import "aos/dist/aos.css";
  import 'vue3-carousel/dist/carousel.css'
  export default {
    components: {
    },
    data() {
      return {
        startdate: currentDate,
        list: [],
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    props: {
      header: { type : Object}
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
    watch:{
      '$props':{
        handler: function (val) {
          this.list = val.header;
        //  console.log("LOG WATCH LIST ", this.list )
        },
        deep: true
      }
    },
    methods: {
      submitForm() {
        this.$router.push("/search-2");
      },
    },
  };
</script>
<style>
</style>
