<template>
  <div class="main-wrapper home-ten">
    <layoutheader :class="{ 'header-space': showHeaderSpace }" ref="header" />

    <bannerTDH></bannerTDH>

    <datlich></datlich>

    <contentTDH></contentTDH>

    <cauTaoTDH></cauTaoTDH>

    <aiSuDungTDH></aiSuDungTDH>

    <chamsocrang></chamsocrang>

    <khiNaoTDH></khiNaoTDH>

    <uuDiemTDH></uuDiemTDH>

    <quyTrinhTDH></quyTrinhTDH>

    <!-- <phuongPhapTDH></phuongPhapTDH> -->

    <dangky></dangky>

    <luuYTDH></luuYTDH>

    <camnhan :customerReviews="this.pageInfo.customerReviews"></camnhan>


    <viSaoChonTDH></viSaoChonTDH>

    <kienthuc></kienthuc>

    <cauhoi></cauhoi>

    <newsdichvu></newsdichvu>

    <lichlamviec></lichlamviec>
    <!--    <qrcode></qrcode>-->

    <footerHome></footerHome>

    <cursor />
  </div>

</template>

<script>
import BlogGrid from "@/assets/json/blog-grid.json";
import {pageInfoModel} from "@/models/pageInfoModel";
import {Carousel, Pagination, Slide} from "vue3-carousel";
import IndexfiveFaq from "@/assets/json/indexfivefaq.json";
import 'vue3-carousel/dist/carousel.css'

export default {
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
      IndexfiveFaq: IndexfiveFaq,
      title: 'Đội ngũ bác sĩ',
      text: 'Trang chủ',
      text1: 'Đội ngũ bác sĩ',
      numberOfElement: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      model: [],
      list: [],
      url: `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile: `${process.env.VUE_APP_API_URL}files/view`,
      activeTab: 0, // Tab mặc định là tab đầu tiên
      BlogGrid: BlogGrid,
      currentYear: '2024', // Năm mặc định được chọn
      pageInfo: pageInfoModel.baseJson(),
      settingss: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      settings: {
        itemsToShow: 4,
        snapAlign: 'center',
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      breakpoints: {
        578: {
          itemsToShow: 2,
          snapAlign: 'start',
        },
        // 700px and up
        768: {
          itemsToShow: 2,
          snapAlign: 'start',
        },
        // 1024 and up
        992: {
          itemsToShow: 3,
          snapAlign: 'start',
        },
        1200: {
          itemsToShow: 4,
          snapAlign: 'start',
        },
      },
    }
  },

  watch: {
    $route(to, from) {
      //  console.log("LOG WATCH DATA : ")
      //   this.getData();
    },
    perPage: {
      deep: true,
      handler(val) {
        // this.getData();
      }
    },
    currentPage: {
      deep: true,
      handler(val) {
        //   console.log("this.perpage", this.currentPage);
        // this.getData();
      }
    }
  },

  mounted() {
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      await this.$store.dispatch("pageInfoStore/getInfo").then(res => {
        if (res != null && res.code === 0) {
          this.pageInfo = pageInfoModel.getJson(res.data);
        }

      });
    },
    nextSlide() {
      this.$refs.carousel.next();
    },
    prevSlide() {
      this.$refs.carousel.prev();
    },
  },
}
</script>

<style>


</style>
