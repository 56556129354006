<template>
    <div class="container pt-60 pb-60">
      <div class="row">
        <div class="col-md-6 who-trongrang">
          <div>
            <div class="main-title pb-30">Những ai nên trồng răng Implant nguyên hàm?</div>
            <div class="desc-title">
              Trồng răng implant nguyên hàm là giải pháp tiên tiến dành cho những người mất răng 
              toàn hàm hoặc gần như toàn bộ răng trên một hàm. Tuy nhiên, không phải ai cũng phù hợp 
              để thực hiện phương pháp này. Dưới đây là những trường hợp phù hợp và điều kiện cần thiết:
            </div>
            <div class="card-who-trongrang pt-30">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div>
                <div class="name-sub">Người mất răng toàn bộ hàm trên hoặc hàm dưới:</div>
                <div class="desc-title">Những người mất hết răng nhưng muốn phục hồi thẩm mỹ và chức năng nhai như răng thật.</div>
              </div>
            </div>
            <div class="card-who-trongrang pt-30">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div>
                <div class="name-sub">Người mất hầu hết răng trên một hàm:</div>
                <div class="desc-title">Mặc dù còn lại một vài răng, nhưng nếu những răng này không đảm bảo chức năng hoặc sức khỏe, bác sĩ có thể chỉ định nhổ bỏ để trồng implant nguyên hàm.</div>
              </div>
            </div>
            <div class="card-who-trongrang pt-30">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div>
                <div class="name-sub">Người gặp khó khăn với hàm giả tháo lắp:</div>
                <div class="desc-title">Implant giúp ngăn ngừa tình trạng tiêu xương hàm, giữ cho khuôn mặt không bị biến dạng do mất răng.</div>
              </div>
            </div>
            <div class="card-who-trongrang pt-30">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div>
                <div class="name-sub">Người muốn cải thiện sức khỏe xương hàm:</div>
                <div class="desc-title">Implant giúp ngăn ngừa tình trạng tiêu xương hàm, giữ cho khuôn mặt không bị biến dạng do mất răng.</div>
              </div>
            </div>
            <div class="card-who-trongrang pt-30">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div>
                <div class="name-sub">Người muốn phục hồi toàn diện răng miệng:</div>
                <div class="desc-title">Giải pháp này không chỉ phục hồi thẩm mỹ mà còn giúp cải thiện chức năng nhai, phát âm và sức khỏe chung.</div>
              </div>
            </div>
          </div>

            
        </div>
        <div class="col-md-6">
            <div class="img-trongrang">
                <img src="@/assets/img/phuongthanh/dichvu/who-implant-toan-ham.png" alt="" style="max-height: 500px;">
            </div>
        </div>
      </div>
    </div>
  
  
  
</template>
  
<script>
  import { ref } from "vue";
  const currentDate = ref(new Date());
  import AOS from "aos";
  import "aos/dist/aos.css";
  import 'vue3-carousel/dist/carousel.css'
  export default {
    components: {
    },
    data() {
      return {
        startdate: currentDate,
        list: [],
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    props: {
      header: { type : Object}
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
    watch:{
      '$props':{
        handler: function (val) {
          this.list = val.header;
        //  console.log("LOG WATCH LIST ", this.list )
        },
        deep: true
      }
    },
    methods: {
      submitForm() {
        this.$router.push("/search-2");
      },
    },
  };
</script>
<style>
</style>
  