<template>
    <div class="container pt-60 pb-60">
      <div class="row">
        <div class="col-md-6">
            <div class="img-trongrang">
                <img src="@/assets/img/phuongthanh/dichvu/cautaorangimplant.png" alt="" style="max-height: 500px;">
            </div>
        </div>
        <div class="col-md-6">
            <div class="main-title pb-40">Cấu tạo niềng răng trẻ em</div>

            <div class="item-cautaorang pb-60">
                <div class="number">
                    <img src="@/assets/img/phuongthanh/dichvu/1.png" alt="" class="img-number">
                    <img src="@/assets/img/phuongthanh/dichvu/in1.png" alt="" class="in-number">
                </div>
                <div class="info-cautaorang">
                    <div>
                        <div class="name-cautaorang">
                          Mắc Cài (Brackets):
                        </div>
                        <div class="sub-cautaorang">
                          Được gắn trực tiếp lên bề mặt răng của trẻ. Có thể làm từ kim loại hoặc sứ để đảm bảo an toàn và thẩm mỹ. 
                          Là nơi giữ dây cung và truyền lực giúp dịch chuyển răng về vị trí mong muốn.
                        </div>
                    </div>
                </div>
            </div>
            <div class="item-cautaorang pb-60">
                <div class="number">
                    <img src="@/assets/img/phuongthanh/dichvu/2.png" alt="" class="img-number">
                    <img src="@/assets/img/phuongthanh/dichvu/in2.png" alt="" class="in-number">
                </div>
                <div class="info-cautaorang">
                    <div>
                        <div class="name-cautaorang">
                          Dây Cung (Archwire):
                        </div>
                        <div class="sub-cautaorang">
                          Là thanh kim loại nối các mắc cài với nhau, giúp tạo lực đều đặn để điều chỉnh răng.
                          Được thiết kế với độ bền cao, linh hoạt và phù hợp với cấu trúc hàm của trẻ em.
                        </div>
                    </div>
                </div>
            </div>
            <div class="item-cautaorang">
                <div class="number">
                    <img src="@/assets/img/phuongthanh/dichvu/3.png" alt="" class="img-number">
                    <img src="@/assets/img/phuongthanh/dichvu/in3.png" alt="" class="in-number">
                </div>
                <div class="info-cautaorang">
                    <div>
                        <div class="name-cautaorang">
                          Thun Nắn Chỉnh (Elastic Bands):
                        </div>
                        <div class="sub-cautaorang">
                          Được sử dụng để cố định dây cung vào mắc cài.
                          Có nhiều màu sắc khác nhau, tạo sự thú vị và giúp trẻ em thích thú hơn khi niềng răng.
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
  import { ref } from "vue";
  const currentDate = ref(new Date());
  import AOS from "aos";
  import "aos/dist/aos.css";
  import 'vue3-carousel/dist/carousel.css'
  export default {
    components: {
    },
    data() {
      return {
        startdate: currentDate,
        list: [],
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    props: {
      header: { type : Object}
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
    watch:{
      '$props':{
        handler: function (val) {
          this.list = val.header;
        //  console.log("LOG WATCH LIST ", this.list )
        },
        deep: true
      }
    },
    methods: {
      submitForm() {
        this.$router.push("/search-2");
      },
    },
  };
</script>
<style>
</style>
