<template>
  <section class="pricing-section pricing-section-ten pt-60 pb-60">
    <div class="container">
      <div class="banner-content-main">
        <div class="row">
          <div class="col-md-10">
            <div class="card-title">
              <p>Chăm sóc nụ cười của bạn là sứ mệnh của chúng tôi</p>
              <span>Hãy liên hệ với chúng tôi để được tư vấn tận tình</span>
              <div class="button">
                <i class="fa-solid fa-phone-volume me-3"></i>
                <div>0858 783 939</div>
              </div>
            </div>
          </div>
        </div>
        <img src="@/assets/img/phuongthanh/core/teeth-big.png" alt="">
      </div>
    </div>
  </section>

</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
import {Carousel, Pagination, Slide} from "vue3-carousel";
import 'vue3-carousel/dist/carousel.css'
export default {
  components: {

    Pagination,
  },
  props: {
    clinicSpace: { type : Object}
  },
  data() {
    return {
      settings: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      breakpoints: {
        // 700px and up
        576: {
          itemsToShow: 2,
          snapAlign: 'start',
        },
        768: {
          itemsToShow: 2,
          snapAlign: 'start',
        },
        // 1024 and up
        992: {
          itemsToShow: 3,
          snapAlign: 'start',
        },
        1200: {
          itemsToShow: 3,
          snapAlign: 'start',
        },
      },
      listImage: [],
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  watch:{
    '$props':{
      handler: function (val) {
        this.listImage = val.clinicSpace;
      },
      deep: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  computed: {
  },
  methods: {


    next() {
      this.$refs.flickity.next();
    },

    previous() {
      this.$refs.flickity.previous();
    },
  },
};
</script>

<style>

</style>

