<template>
    <div class="container pt-60 pb-60">
      <div class="row">
        <div class="col-md-6 who-trongrang">
          <div>
            <div class="main-title pb-30">Những ai nên sử dụng niềng răng trong suốt?</div>
            <div class="desc-title">Các trường hợp cần chỉnh nha gồm: sai lệch răng, sai lệch khớp cắn, bất hài hòa tương quan xương hai hàm. Đặc biệt phù hợp trong các trường hợp sau:</div>
            <div class="card-who-trongrang pt-30">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">Các trường hợp sai lệch về răng như : Răng nhô, chìa.Răng chen chúc, lệch lạc. Răng thưa, hở kẽ</div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">Các trường hợp sai khớp cắn như khớp cắn hở là một nhóm răng hàm trên và dưới không chạm nhau khi hai hàm đã cắn lại.
                Khớp cắn sâu là răng cửa hàm trên phủ lấp gần như toàn bộ răng cửa hàm dưới khi cắn lại.
                Và khớp cắn ngược là răng hàm dưới đưa ra trước, vượt ngoài răng hàm trên. </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Các trường hợp sai lệch về xương như xương hàm trên kém phát triển hoặc phát triển quá mức
                và xương hàm dưới kém phát triển hoặc phát triển quá mức
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Các trường hợp trên sẽ gây ảnh hưởng đến thẩm mỹ, sự cân đối của khuôn mặt, chức năng ăn nhai, tăng nguy cơ mắc các bệnh lý răng miệng do khó vệ sinh răng. Một số trường hợp còn gây ảnh hưởng đến phát âm và bệnh lý thái dương hàm.
              </div>
            </div>
          </div>


        </div>
        <div class="col-md-6">
            <div class="img-trongrang">
                <img src="@/assets/img/phuongthanh/dichvu/who.png" alt="" style="max-height: 500px;">
            </div>
        </div>
      </div>
    </div>



</template>

<script>
  import { ref } from "vue";
  const currentDate = ref(new Date());
  import AOS from "aos";
  import "aos/dist/aos.css";
  import 'vue3-carousel/dist/carousel.css'
  export default {
    components: {
    },
    data() {
      return {
        startdate: currentDate,
        list: [],
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    props: {
      header: { type : Object}
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
    watch:{
      '$props':{
        handler: function (val) {
          this.list = val.header;
        //  console.log("LOG WATCH LIST ", this.list )
        },
        deep: true
      }
    },
    methods: {
      submitForm() {
        this.$router.push("/search-2");
      },
    },
  };
</script>
<style>
</style>
