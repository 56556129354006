<template>
  <div class="main-wrapper home-ten">
    <layoutheader :class="{ 'header-space': showHeaderSpace }" ref="header" />
    <section class="banner-section view-lienhe">
        <div class="banner-slider-lienhe">
          <img src="@/assets/img/phuongthanh/banner.png" alt="" style="max-height: 90vh;  width: 100%;">
          <div class="name-lienhe-slider">LIÊN HỆ</div>
      </div>
      <div class="card-lien-he container">
        <div class="row">
          <div class="col-lg-7">
            <div class="card-lien-he-left">
              <p>Hãy gửi tinh nhắn cho chúng tôi</p>
              <p class="desc-title">
                Bạn có câu hỏi, khiếu nại, hay cần hỗ trợ chọn dịch vụ tại
                <span style="color: #5B2F39; font-weight: 700;">NHA KHOA PHƯƠNG THÀNH? </span>
                Đừng ngần ngại liên hệ với chúng tôi nhé!
              </p>
              <div class="form-lien-he">
                <div class="box-left">
                  <form id="submit-register-two-field" class="form-header" method="post" action="">
                    <div class="row">
                      <div class="col-lg-12 col-sm-12 col-12 mb-3">
                        <div class="ps-relative customer-name">
                          <div class="icon-form">
                            <img src="@/assets/img/phuongthanh/core/Profile.png" alt="" class="img-1">
                          </div>
                          <span class="wpcf7-form-control-wrap your-name">
                            <input type="text" class="form-control" name="your_name" id="customer-name" value="" placeholder="Nhập họ và tên">
                          </span>
                        </div>
                      </div>
                      <div class="col-lg-12 col-sm-12 col-12 mb-3">
                        <div class="ps-relative customer-phone">
                          <div class="icon-form">
                            <img src="@/assets/img/phuongthanh/core/Call.png" alt="" class="img-2">
                          </div>
                          <span class="wpcf7-form-control-wrap your-tel">
                            <input type="text" class="form-control" name="your_tel" id="customer-phone" value="" placeholder="Nhập số điện thoại">
                          </span>
                        </div>
                      </div>
                      <div class="col-lg-12 col-sm-12 col-12 mb-3">
                        <div class="ps-relative customer-phone">
                          <div class="icon-form">
                            <img src="@/assets/img/phuongthanh/core/Email.png" alt="" class="img-3">
                          </div>
                          <span class="wpcf7-form-control-wrap your-tel">
                            <input type="email" class="form-control" name="your_tel" id="customer-phone" value="" placeholder="Nhập địa chỉ email">
                          </span>
                        </div>
                      </div>
                      <div class="col-lg-12 col-sm-12 col-12 mb-3">
                        <div class="ps-relative customer-content">
                          <div class="icon-form">
                            <img src="@/assets/img/phuongthanh/core/Chat.png" alt="" class="img-4">
                          </div>
                          <span class="wpcf7-form-control-wrap your-content">
                            <textarea class="form-control" name="your_content" id="customer-content" value="" placeholder="Nội dung"></textarea>
                          </span>
                        </div>
                      </div>
                      <input type="hidden" name="get_ip_connect_two_field" id="get_ip_connect_two_field" value="113.161.198.122">
                      <div class="col-lg-12 col-sm-12 col-12" style="text-align: right">
                        <button class="submit"><i class="fa fa-regular fa-paper-plane"></i>Gửi tin nhắn</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-5">
            <div class="card-lien-he-right">
              <p class="title-lien-he-right">
                Xin chào! Chúng tôi luôn ở đây để hỗ trợ bạn và sẵn sàng giúp đỡ bất cứ điều gì bạn cần.
                Đừng ngần ngại liên hệ với chúng tôi nhé!
              </p>
              <div class="item">
                <div class="item-lien-he-right">
                  <img src="@/assets/img/phuongthanh/lienhe/lienhe1.png" alt="">
                  <div class="name-lien-he-right">
                    <p class="name">Hotline</p>
                    <p class="sub">(+84) 858 783 939</p>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="item-lien-he-right">
                  <img src="@/assets/img/phuongthanh/lienhe/lienhe2.png" alt="">
                  <div class="name-lien-he-right">
                    <p class="name">Hotline</p>
                    <p class="sub">(+84) 858 783 939</p>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="item-lien-he-right">
                  <img src="@/assets/img/phuongthanh/lienhe/lienhe3.png" alt="">
                  <div class="name-lien-he-right">
                    <p class="name">Email</p>
                    <p class="sub" style="word-break: break-word;">nhakhoaphuongthanh@gmail.com</p>
                  </div>
                </div>
              </div>

              <div class="item-lien-he-xa-hoi">
                <div class="name-lien-he-xa-hoi">
                  Mạng xã hội
                </div>
                <div class="icon-lien-he-xa-hoi">
                  <img src="@/assets/img/phuongthanh/lienhe/facebook.png" alt="" class="me-4">
                  <img src="@/assets/img/phuongthanh/lienhe/tiktok.png" alt="" class="me-4">
                  <img src="@/assets/img/phuongthanh/lienhe/youtube.png" alt="" class="me-4">
                  <img src="@/assets/img/phuongthanh/lienhe/Message.png" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="null"></div>

    <div class="container map-lien-he">
      <MapView />
    </div>


    <lichlamviec></lichlamviec>
  </div>
  <footerHome></footerHome>
</template>
<script>
import BlogGrid from "@/assets/json/blog-grid.json";
import BlogSidebar from "@/views/News/baiviet/newSidebar.vue";
import MapView from '@/components/map/index.vue';
export default {
  components: {BlogSidebar, MapView},
  data() {
    return {
      title: 'Giới thiệu',
      text: 'Trang chủ',
      text1: 'Giới thiệu',
      numberOfElement: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      BlogGrid: BlogGrid,
      url: `${process.env.VUE_APP_API_URL}files/view/`,
      isArray : true,
    }
  },

  watch: {
    $route(to, from) {
      //  console.log("LOG WATCH DATA : ")
      this.getData();
    },
    perPage: {
      deep: true,
      handler(val){
        this.getData();
      }
    },
    currentPage:{
      deep: true,
      handler(val){
        //   console.log("this.perpage", this.currentPage);
        this.getData();
      }
    }
  },

  mounted() {
  },
  methods: {

  },
}
</script>

<style>
#lienhe {
  text-align: center;
  margin-top: 20px;
}


</style>
