<template>
    <div class="container pt-60 pb-60">
      <div class="row">
        <div class="col-md-12 who-trongrang">
          <div>
            <div class="main-title pb-30">Độ tuổi nào có thể riềng răng?</div>
            <div class="desc-title">Thực tế, không có giới hạn nào về độ tuổi để chỉnh nha. Từ 5 – 50 tuổi hoặc thậm chí trên 50 tuổi vẫn có thể điều trị chỉnh nha. Nhưng để đạt được hiệu quả điều trị tốt nhất, điều quan trọng nhất là phải ĐÚNG THỜI ĐIỂM.</div>
            <div class="card-who-trongrang pt-30">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">Từ 5-11 tuổi: độ tuổi chỉnh nha và tầm soát răng miệng tốt nhất cho trẻ (Theo Hiệp hội Nha khoa Hoa Kỳ -ADA). Khám tầm soát răng miệng để phát hiện sớm các bất thường về răng như răng dư, thiếu răng, thiếu khoảng mọc răng, hẹp hàm, xương hàm phát triển quá mức hoặc kém phát triển, thói quen xấu như mút môi, đẩy lưỡi,...
                Đây là giai đoạn này trẻ đang thay răng sữa sang răng vĩnh viễn, răng và xương đang trong quá trình phát triển nên dễ dàng nắn chỉnh, đặc biệt là nắn chỉnh xương.
                Can thiệp trong giai đoạn này giúp ngăn chặn, thay đổi thói quen xấu ảnh hưởng sức khỏe răng miệng, hạn chế nhổ răng, tránh phải phẫu thuật hàm nếu sai lệch nặng khi lớn.</div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">Từ sau 12 tuổi: độ tuổi đã hoàn tất thay răng, chỉnh nha có thể cải thiện về răng nhưng những điều chỉnh sai hình xương hàm không thể đạt kết quả toàn diện như khi trẻ điều trị trong giai đoạn vàng thay răng.</div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Ở người lớn:  chỉnh nha có thể bắt đầu ở bất kỳ tuổi nào, miễn là răng và nướu đủ khỏe mạnh. Tuy nhiên, xương hàm của người lớn đã phát triển hoàn chỉnh, do đó việc điều chỉnh có thể mất nhiều thời gian hơn.
              </div>
            </div>
          </div>


        </div>
<!--        <div class="col-md-6">-->
<!--            <div class="img-trongrang">-->
<!--                <img src="@/assets/img/phuongthanh/dichvu/who.png" alt="" style="max-height: 500px;">-->
<!--            </div>-->
<!--        </div>-->
      </div>
    </div>



</template>

<script>
  import { ref } from "vue";
  const currentDate = ref(new Date());
  import AOS from "aos";
  import "aos/dist/aos.css";
  import 'vue3-carousel/dist/carousel.css'
  export default {
    components: {
    },
    data() {
      return {
        startdate: currentDate,
        list: [],
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    props: {
      header: { type : Object}
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
    watch:{
      '$props':{
        handler: function (val) {
          this.list = val.header;
        //  console.log("LOG WATCH LIST ", this.list )
        },
        deep: true
      }
    },
    methods: {
      submitForm() {
        this.$router.push("/search-2");
      },
    },
  };
</script>
<style>
</style>
