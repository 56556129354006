<template>
    <div class="container pt-60 pb-60">
      <div class="main-title text-center pb-30">
        Niềng răng thẩm mỹ<br>
        An toàn tối đa, Kết quả vượt trội
      </div>
      <div class="desc-title text-center">
        Niềng răng thẩm mỹ là một giải pháp điều trị chỉnh nha giúp cải thiện hình dáng, cấu trúc răng miệng và nụ cười của bệnh nhân.
      </div>
      <div class="desc-title text-center">
        Phương pháp này sử dụng chỉnh nha khay trong suốt, chỉnh nha mắc cài kim loại, chỉnh nha khí cụ cho trẻ em
      </div>
      <div class="desc-title text-center">
        Niềng răng thẩm mỹ là một phương pháp hiệu quả để không chỉ cải thiện ngoại hình mà còn nâng cao sức khỏe răng miệng lâu dài. Vì vậy niềng răng thẩm mỹ có những lợi ích như
      </div>
      <div class="list-lamrang pt-30 row">
        <div class="card-lamrang mb-3">
          Cải thiện thẩm mỹ
        </div>

        <div class="card-lamrang mb-3">
          Khắc phục tình trạng lệch lạc của răng
        </div>

        <div class="card-lamrang mb-3">
          Cải thiện chức năng nhai
        </div>

        <div class="card-lamrang mb-3">
          Tăng cường sức khỏe răng miệng
        </div>

        <div class="card-lamrang mb-3">
          Hạn chế nhiều biến chứng khác
        </div>

        <div class="card-lamrang mb-3">
          Thay đổi diện mạo khuôn mặt
        </div>
      </div>
    </div>

</template>

<script>
  import { ref } from "vue";
  const currentDate = ref(new Date());
  import AOS from "aos";
  import "aos/dist/aos.css";
  import 'vue3-carousel/dist/carousel.css'
  export default {
    components: {
    },
    data() {
      return {
        startdate: currentDate,
        list: [],
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    props: {
      header: { type : Object}
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
    watch:{
      '$props':{
        handler: function (val) {
          this.list = val.header;
        //  console.log("LOG WATCH LIST ", this.list )
        },
        deep: true
      }
    },
    methods: {
      submitForm() {
        this.$router.push("/search-2");
      },
    },
  };
</script>
<style>
</style>
