<template>
  <div class="container pt-60 pb-30">
    <div class="main-title text-center pb-30">Lời khuyên cho Cha mẹ khi lần đầu đưa trẻ em đến phòng khám Nha Khoa Trẻ em?</div>

    <!-- Khung 2 -->
    <div class="box-luu-y box-secondary">
      <h2>Trước khi đến nha khoa:</h2>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Chuẩn bị tâm lý cho bé: Nói chuyện với bé: Giải thích cho bé một cách đơn giản và nhẹ nhàng về việc khám răng. Có thể so sánh việc khám răng như đi chơi, gặp bác sĩ để kiểm tra răng xem có khỏe mạnh không.</span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Đọc sách hoặc xem phim về nha khoa: Có rất nhiều sách và phim hoạt hình dành cho trẻ em về chủ đề nha khoa, giúp bé hình dung rõ hơn về quá trình khám răng.</span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Chọn một nha khoa thân thiện với trẻ em: Tìm hiểu các nha khoa có phòng khám dành riêng cho trẻ em, có không gian vui nhộn và bác sĩ nha khoa thân thiện, dễ gần.</span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Chọn thời điểm thích hợp: Tránh giờ cao điểm: Nên chọn thời điểm phòng khám không quá đông để tránh làm bé cảm thấy căng thẳng.</span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Khi bé đang vui vẻ: Tránh đưa bé đi khám răng khi bé đang mệt mỏi, đói hoặc đang có tâm trạng không tốt.</span>
      </div>
      <h2>Trong khi khám răng:</h2>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Cùng bé vào phòng khám: Sự có mặt của cha mẹ sẽ giúp bé cảm thấy an tâm hơn.</span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Giữ bình tĩnh: Nếu bé sợ hãi và khóc, cha mẹ hãy cố gắng giữ bình tĩnh và nhẹ nhàng dỗ dành bé. Trẻ con rất nhạy cảm, nếu cha mẹ lo lắng, bé cũng sẽ lo lắng theo.</span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Hợp tác với bác sĩ: Nghe theo hướng dẫn của bác sĩ nha khoa và tạo điều kiện để bác sĩ khám răng cho bé.</span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Khen ngợi bé: Sau khi khám răng xong, hãy khen ngợi bé để bé cảm thấy tự hào và muốn quay lại nha khoa lần sau.</span>
      </div>
      <h2>Sau khi khám răng:</h2>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Tiếp tục động viên bé: Kể cho bé nghe những điều thú vị về việc khám răng và thưởng cho bé một món quà nhỏ để bé cảm thấy vui hơn.</span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Tạo thói quen đánh răng hàng ngày: Sau khi khám răng, hãy giúp bé hình thành thói quen đánh răng đều đặn hai lần một ngày để bảo vệ răng miệng.</span>
      </div>
      <h2>Một số lưu ý khác:</h2>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Trung thực với bé: Không nên dọa bé bằng những câu nói như "Nếu con không ngoan thì sẽ bị bác sĩ rút răng". Điều này sẽ khiến bé sợ hãi và không muốn đến nha khoa lần sau.</span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Làm gương cho bé: Cha mẹ nên thường xuyên chăm sóc răng miệng của mình để làm gương cho bé.</span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Chọn nha khoa uy tín: Nên tìm hiểu kỹ và chọn một nha khoa uy tín, có bác sĩ nha khoa chuyên khoa nhi để đảm bảo an toàn và hiệu quả cho bé.</span>
      </div>
    </div>

  </div>

</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  data() {
    return {
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  created() {

  },
  methods: {

    toggleFavorite(item) {
      item.favorite = !item.favorite;
    },
  },
};
</script>

<style>

</style>
