<template>
  <!-- Home Banner -->
  <section class="banner-section">
    <div class="banner-slider">
      <swiper
        :spaceBetween="0"
        :navigation="false"
        :pagination="true"
        :loop="true"
        :autoplay="{ delay: 5000, disableOnInteraction: false }"
        :modules="modules"
        class="swiper-news"
      >
        <swiper-slide class="slide-news" v-for="(item, index) in this.list" :key="index.id">
          <img
              v-if="item"
              :src="item.fileImage?.urlFile" alt="Image"
              style="width: 100%"
            />
        </swiper-slide>
      </swiper>
      <!-- <Carousel ref="carousel" v-bind="settings" :wrapAround="true" :transition="500" :autoplay="7000" v-if="this.list">
        <Slide v-for="(item, index) in this.list" :key="index.id">
            <img
              v-if="item"
              :src="item.fileImage?.urlFile" alt="Image"
              style="width: 100%"
            />
        </Slide>
        <template #addons>
          <Pagination />
        </template>
      </Carousel> -->
    </div>
  </section>
  <!-- /Home Banner -->

  <div class="container padding-card pt-60 pb-60">
    <div class="form-intro">
      <div class="row">
        <div class="col-lg-3 col-12 box-left">
          <h2>Đặt Lịch Tư Vấn</h2>
        </div>
        <div class="col-lg-6 col-xs-12 box-right">
          <div class="box-main">
            <div class="box-filter">
              <form id="submit-register-two-field" class="form-header" method="post" action="">
                <div class="row">
                  <div class="col-lg-6 col-sm-6 col-12">
                    <div class="ps-relative customer-name">
                      <div class="icon-form">
                        <img src="@/assets/img/phuongthanh/core/Profile.png" alt="" class="img-1">
                      </div>
                      <span class="wpcf7-form-control-wrap your-name">
                        <input type="text" class="form-control" name="your_name" id="customer-name" value="" placeholder="Nhập họ và tên">
                      </span>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6 col-12">
                    <div class="ps-relative customer-phone">
                      <div class="icon-form">
                        <img src="@/assets/img/phuongthanh/core/Call.png" alt="" class="img-2">
                      </div>
                      <span class="wpcf7-form-control-wrap your-tel">
                        <input type="text" class="form-control" name="your_tel" id="customer-phone" value="" placeholder="Nhập số điện thoại">
                      </span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-8 col-sm-9 col-12">
                    <div class="ps-relative customer-content">
                      <div class="icon-form">
                        <img src="@/assets/img/phuongthanh/core/Chat.png" alt="" class="img-4">
                      </div>
                      <span class="wpcf7-form-control-wrap your-content">
                        <input type="text" class="form-control" name="your_content" id="customer-content" value="" placeholder="Nội dung">
                      </span>
                    </div>
                  </div>
                  <input type="hidden" name="get_ip_connect_two_field" id="get_ip_connect_two_field" value="113.161.198.122">
                  <div class="col-lg-4 col-sm-3 col-12">
                    <button class="submit" style="display: flex; justify-content: center; align-items: center;">
                      <!-- <i class="fa fa-solid fa-calendar"></i> -->
                      <img src="@/assets/img/phuongthanh/core/Calendar.png" alt="" class="me-3">
                      Đăng ký
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-xs-12 box-right">
          <div class="card-qr-zalo">
            <span>Zalo</span>
            <img src="@/assets/img/phuongthanh/core/qrcode-zalo.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="vector-left">

    </div>
    <div class="vector-right">

    </div>
  </div>

  <section id="section-2" class="section-brand-history pb-60 pt-60">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="brand-history-content">
            <div class="main-title pb-30">Câu Chuyện Thương Hiệu Nha Khoa Phương Thành</div>
            <p>Thành lập từ năm 2014, trên suốt chặng đường đi qua chúng tôi luôn làm việc với phương châm lấy tâm đức, sự chính trực và tri thức mang đến nụ cười hạnh phúc hơn mong đợi cho khách hàng.</p>
            <p>Sự phát triển đa phương diện về tổ chức, cơ sở vật chất, trang thiết bị, nguồn nhân lực,kỹ thuật chuyên môn và hoạt động xã hội tích cực nha khoa ngày càng góp phần vào sự phát triển chung của xã hội.</p>
            <p class="slogan">Đoàn kết - Kỷ luật - Nhân văn</p>
            <p class="slogan">An toàn - Chất lượng - Hiệu quả</p>
            <div class="button-brand-history">
              <button>
                <a href="https://phuongthanh.vn/gioi-thieu">
                  <span>Tìm hiểu thêm về Nha khoa Phương Thành</span>
                  <i class="fa fa-solid fa-arrow-right"></i>
                </a>
              </button>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12 mb-2">
          <div class="brand-history-thumb">
            <a class="popup-video" href="">
                <noscript>
                    <img width="1614" height="1332" src="@/assets/img/phuongthanh/core/box-image.png" alt="Câu chuyện thương hiệu Nha khoa Phương Thành">
                </noscript>
                <img class=" ls-is-cached lazyloaded" width="1614" height="1332" src="@/assets/img/phuongthanh/core/box-image.png" data-src="@/assets/img/phuongthanh/core/box-image.png" alt="Câu chuyện thương hiệu Elite Dental">
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Specialities Section -->
  <section class="specialities-section-one pt-60 pb-60">
    <!-- <div class="floating-bg" style="margin-top: 120px">
      <img src="@/assets/img/phuongthanh/icon-teeth-72.png" alt="Image" />
    </div> -->
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-header-one section-header-slider">
            <h2 class="section-title">
              <div class="main-title pb-40" style="position: relative; z-index: 0;">
                5 lý do nên chọn <br> <span style="font-family: 'UTM'; text-transform: uppercase;">Nha khoa Phương Thành?</span>
                <img src="@/assets/img/phuongthanh/core/teeth.png" alt="">
              </div>
            </h2>
          </div>
        </div>
      </div>
      <div class="specialities-block">
        <ul>
          <li v-for="(speciality, index) in indexfivespecialities" :key="index">
            <div class="specialities-item">
              <div class="specialities-img">
                <div class="hexogen">
                  <img
                    :src="require(`@/assets/img/icons/${speciality.imageSrc}`)"
                    :alt="speciality.altText"
                    style="height: 100%"
                  />
                </div>
              </div>
              <p>{{ speciality.title }}</p>
            </div>
          </li>
        </ul>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="section-header-one section-header-slider mt-3 mb-3">
            <p class="section-title"
              style="
                color: #000;
                font-weight: bold;
              "
            >
              <span style="color: red;">*</span> Hợp tác với các chuyên gia đầu ngành trong nước và quốc tế
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from "vue";
const currentDate = ref(new Date());
import AOS from "aos";
import "aos/dist/aos.css";
import indexfivespecialities from "@/assets/json/indexfivespecialities.json";
import { computed } from "vue";
import * as Yup from "yup";
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      modules: [Navigation, Pagination, Autoplay],
      startdate: currentDate,
      indexfivespecialities: indexfivespecialities,
      list: [],
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  props: {
    header: { type : Object}
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  watch:{
    '$props':{
      handler: function (val) {
        this.list = val.header;
      //  console.log("LOG WATCH LIST ", this.list )
      },
      deep: true
    }
  },
  methods: {
    submitForm() {
      this.$router.push("/search-2");
    },
  },
};
</script>
<style>
.carousel__pagination{
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50px);
}

.carousel__pagination-button--active::after{
  background-color: #F5E7B2 !important;
  width: 10px !important;
  height: 10px !important;
  border-radius: 5px !important;
}

.carousel__pagination-button::after{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #F5E7B25d;
}
</style>
