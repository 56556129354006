<template>
  <div class="container pt-60 pb-30">
    <div class="main-title text-center pb-30">Triệu chứng của rối loạn khớp thái dương hàm</div>
    <p class="desc-title pt-2">
      Đau chính là triệu chứng thường xuất hiện và điển hình của bệnh. Những cơn đau có thể xuất hiện từ từ một cách 
      âm ỉ và đôi lúc sẽ đau nhói. Ngoài ra còn một số các triệu chứng cảnh báo rối loạn khớp thái dương hàm là: 
    </p>
    <!-- Khung 1 -->
    <div class="box-luu-y box-primary">
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Đau xảy ra ở vị trí các cơ góp phần cho hoạt động nhai nghiền thức ăn đều bị đau như vùng dưới hàm, vùng góc hàm.
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Người bệnh cảm thấy đau nhức hoặc mệt mỏi khi nhai hay cắn chặt thức ăn, nói chuyện, há miệng,...
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Há miệng hạn chế do cảm giác bị “kẹt hàm” khi há miệng.
        </span>
      </div>

      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Xuất hiện tiếng kêu lục cục khi cơ hàm hoạt động.
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Đau xuất hiện ở các vùng cơ quan lân cận như phần trong tai, trước tai, đau hai bên thái dương, 
          cổ, vai, gáy và thậm chí đau lan ra nửa đầu.
        </span>
      </div>
    </div>
  </div>

</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  data() {
    return {
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  created() {

  },
  methods: {

    toggleFavorite(item) {
      item.favorite = !item.favorite;
    },
  },
};
</script>

<style>

</style>
