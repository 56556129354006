<template>
  <div class="main-Wrapper">
    <pharmacyheader></pharmacyheader>
    <pharmacysidebar></pharmacysidebar>
    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <div class="content container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <Form :validation-schema="schema" v-slot="{ errors }" @submit="handleSubmitThongTin">
                    <div class="row mt-2 mb-4">
                      <div class="col-sm-12 col-md-6">
                        <div
                            class="col-sm-12 d-flex justify-content-left align-items-center"
                        >
                          <div
                              id="tickets-table_length"
                              class="dataTables_length m-1"
                              style="
                                    display: flex;
                                    justify-content: left;
                                    align-items: center;
                                  "
                          >
                            <div class="me-1" style="font-size: 20px; font-weight: bold;">Quản lý thông tin footer</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6" style="text-align: right;">
                        <b-button
                            class="btn cs-btn-primary btn- mb-2 me-2"
                            variant="success"
                            type="submit"
                        >
                          <i class="bx bx-save "></i>
                          Lưu thông tin
                        </b-button>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="mb-3">
                        <label class="text-left">Tiêu đề bài viết</label>
                        <span style="color: red">&nbsp;*</span>
                        <input type="hidden" v-model="model.id"/>
                        <Field
                            as="textarea"
                            v-model="model.describe"
                            placeholder="Vui lòng nhập mô tả"
                            name="describe"
                            type="text"
                            class="form-control"
                            :class="{ 'is-invalid': errors.describe }"
                        />
                        <div class="invalid-feedback">{{ errors.name }}</div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-8">
                        <div class="mb-3">
                          <label class="text-left">Nội dung bài viết</label>
                          <span style="color: red" >&nbsp;*</span>
                          <Field name="content" v-slot="{ field }">
                            <CKEditorCustom
                                v-bind="field"
                                v-model="model.content"
                                :class="{ 'is-invalid': errors.content }"
                            >
                            </CKEditorCustom>
                          </Field>
                          <div class="invalid-feedback">{{ errors.content }}</div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="col-md-12" >
                          <div class="mb-2 ">
                            <label for="formFileSm" class="text-left mb-0">Ảnh đại diện (600px * 400px)</label>
                            <span style="color: red">&nbsp;*</span>
                            <template  v-if="model.fileImage" >
                              <a
                                  class="ml-25"
                                  :href="`${urlFile}/${model.fileImage.fileId}`"
                              ><i
                                  :class="`${getColorWithExtFile(model.fileImage.ext)} me-2 ${getIconWithExtFile(model.fileImage.ext)}`"
                              ></i>{{model.fileImage.fileName }}</a>
                              <b-button
                                  variant="flat-danger"
                                  class="btn-icon-delete-img"
                                  yarn run ser@click="deleteImage()"
                              >
                                <i class="fe fe-trash"></i>
                              </b-button>

                            </template>
                            <Field
                                id="formFileSm"
                                name="fileImage"
                                ref="fileInput"
                                type="file"
                                accept="image/png, image/jpeg"
                                class="form-control"
                                @change="upload($event)"
                                :class="{ 'is-invalid': errors.fileImage }"
                            />
                            <div class="invalid-feedback">{{ errors.fileImage }}</div>
                          </div>
                        </div>
                      </div>
                    </div>



                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <pharmacymodel />
  <pharmacydelete />
</template>
<script >

import VueDatePicker from '@vuepic/vue-datepicker';
import { headerModel } from "@/models/headerModel";
import { footerModel } from "@/models/footerModel";
import {notifyModel} from "@/models/notifyModel";
import Treeselect from 'vue3-treeselect';
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { Modal } from 'bootstrap';
import CKEditorCustom from "@/utils/view/CKEditorCustom.vue";
export default {
  components: {
    CKEditorCustom,
    VueDatePicker,
    Treeselect,
    VueMultiselect,
    Form,
    Field,
  },
  data() {
    return {
      title: "DANH SÁCH",
      model: headerModel.baseJson(),
      modelfooter: footerModel.baseJson(),
      listHeader: [],
      currentPage: 1,
      numberOfElement: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50, 100],
      totalRows: 1,
      sortBy: 'age',
      sortDesc: false,
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  name: "pharmacy/user",

  created() {
    this.getData();
    this.getThongTinHeader();
    this.getThongTinFooter();
  },
  setup() {
    const schema = Yup.object().shape({
      companyName: Yup.string().required("Tên không được bỏ trống !"),
      address: Yup.string().required("Địa chỉ không được bỏ trống !"),
      email: Yup.string().required("Email không được bỏ trống !"),
      // phone: Yup.string().required("Số điện thoại không được bỏ trống !"),
      manager: Yup.string().required("Người quản lý không được bỏ trống !"),
      phone: Yup.number()
          .typeError("Chỉ được nhập số")
          .test("len", "Không được quá 11 chữ số", val => val && val.toString().length <= 10)
          .required("Số điện thoại số không được bỏ trống!"),
    });
    return {
      schema,
    };
  },

  watch: {

  },

  methods: {
    async getThongTinHeader(){
      let promise =  this.$store.dispatch("headerStore/getAllHeader")
      return promise.then(resp => {
        if(resp.data == null){
          return []
        }else{
          if (resp.data != null )
          {
            this.model = resp.data
          }
        }
      })
    },

    async getThongTinFooter(){
      let promise =  this.$store.dispatch("headerStore/getAllFooter")
      return promise.then(resp => {
        if(resp.data == null){
          return []
        }else{
          if (resp.data != null )
          {
            this.modelfooter = resp.data
            console.log("Footer: ", this.modelfooter);
          }
        }
      })
    },

    async handleSubmit(e) {
      e.preventDefault();
      this.submitted = true;
      await this.$store.dispatch("headerStore/updateHeader", this.model).then((res) => {
        if (res != null && res.code ===0) {
          this.model = res.data
        }
        this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res));
      });

      this.submitted = false;
    },

    async handleSubmitThongTin() {
      await this.$store.dispatch("headerStore/updateFooter", this.modelfooter).then((res) => {
        if (res != null && res.code ===0) {
          this.modelfooter = res.data
        }
        this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res));
      });
    },

    getColorWithExtFile(ext) {
      if (ext == '.png' || ext == '.jpg'|| ext == '.jpeg' )
        return 'text-danger';

    },
    getIconWithExtFile(ext) {
      if (ext == '.png' || ext == '.jpg'|| ext == '.jpeg')
        return 'mdi mdi-file-image-outline';
    },
    async getData() {
      let params = {
        start: this.currentPage,
        limit: this.perPage,
        sortBy: this.sortBy,
      }
      await this.$store.dispatch("headerStore/getAllHeader", params ).then(res => {
        this.listHeader = res.data
      });
    },
    async upload() {

      if (this.model != null && this.model.doctorImage != null)
      {
        // console.log("LOG UPDATE NE : ", event.target.files )
        axios.post(`${process.env.VUE_APP_API_URL}Files/delete/${this.model.doctorImage.fileId}`).then((response) => {
          this.model.files = null;
          //   console.log('log model file remove', this.model.files);
        }).catch((error) => {
          // Handle error here
          //    console.error('Error deleting file:', error);
        });
      }
      console.log("LOG UPLOAD : ", event.target)
      if ( event.target &&  event.target.files.length > 0 ) {
        const formData = new FormData()
        formData.append('files', event.target.files[0])
        axios.post(`${process.env.VUE_APP_API_URL}Files/upload`,formData).then((response) => {
          // console.log("LOG UPDATE : ", response);
          let resultData = response.data
          if (response.data.code == 0){
            this.model.doctorImage={
              fileId: resultData.data.fileId,
              fileName: resultData.data.fileName,
              ext: resultData.data.ext,
            };
            console.log("LOG UPDATE : ", this.model);
          }
        }).catch((error) => {
          // Handle error here
          console.error('Error deleting file:', error);
        });

      }
    },

    async uploadTeeth() {

      if (this.model != null && this.model.teethImage != null)
      {
        // console.log("LOG UPDATE NE : ", event.target.files )
        axios.post(`${process.env.VUE_APP_API_URL}Files/delete/${this.model.teethImage.fileId}`).then((response) => {
          this.model.files = null;
          //   console.log('log model file remove', this.model.files);
        }).catch((error) => {
          // Handle error here
          //    console.error('Error deleting file:', error);
        });
      }
      console.log("LOG UPLOAD : ", event.target)
      if ( event.target &&  event.target.files.length > 0 ) {
        const formData = new FormData()
        formData.append('files', event.target.files[0])
        axios.post(`${process.env.VUE_APP_API_URL}Files/upload`,formData).then((response) => {
          // console.log("LOG UPDATE : ", response);
          let resultData = response.data
          if (response.data.code == 0){
            this.model.teethImage={
              fileId: resultData.data.fileId,
              fileName: resultData.data.fileName,
              ext: resultData.data.ext,
            };
          }
        }).catch((error) => {
          // Handle error here
          console.error('Error deleting file:', error);
        });

      }
    },

    async uploadLogo() {

      // if (this.modelfooter != null && this.modelfooter.logo != null)
      // {
      // axios.post(`${process.env.VUE_APP_API_URL}Files/delete/${this.modelfooter.logo.fileId}`).then((response) => {
      //     this.modelfooter.files = null;
      // }).catch((error) => {
      // });
      // }
      if ( event.target &&  event.target.files.length > 0 ) {
        const formData = new FormData()
        formData.append('files', event.target.files[0])
        formData.append('code', "FOOTER")
        axios.post(`${process.env.VUE_APP_API_URL}Files/upload`,formData).then((response) => {
          // console.log("LOG UPDATE : ", response);
          let resultData = response.data
          console.log("UPLOAD: ", resultData);
          if (response.data.code == 0){
            this.modelfooter.logo={
              fileId: resultData.data.fileId,
              fileName: resultData.data.fileName,
              ext: resultData.data.ext,
            };
          }
        })
      }
    },

    addNodeToModel(node ){
      if(node != null && node.id){
      }
    },
    normalizer(node){
      if(node.children == null || node.children == 'null'){
        delete node.children;
      }
    },
    myProvider () {
      return [];
    }
  }
};
</script>

<style>

</style>

