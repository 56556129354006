<template>
    <div class="container pt-60 pb-60">
      <div class="row">
        <div class="col-md-6">
            <div class="img-trongrang">
                <img src="@/assets/img/phuongthanh/dichvu/cautaorangimplant.png" alt="" style="max-height: 500px;">
            </div>
        </div>
        <div class="col-md-6">
            <div class="main-title pb-40">Cấu tạo bọc răng sứ</div>

            <div class="item-cautaorang pb-60">
                <div class="number">
                    <img src="@/assets/img/phuongthanh/dichvu/1.png" alt="" class="img-number">
                    <img src="@/assets/img/phuongthanh/dichvu/in1.png" alt="" class="in-number">
                </div>
                <div class="info-cautaorang">
                    <div>
                        <div class="name-cautaorang">
                          Lớp lõi (Core) - Phần khung sườn
                        </div>
                        <div class="sub-cautaorang">
                          Bên trong răng sứ, đóng vai trò là "khung xương." Là phần nền tảng, chịu lực chính trong quá trình nhai.
                          Định hình và tạo độ cứng cho răng sứ. Chất liệu: Hợp kim như Cr-Co, Ni-Cr, hoặc Titan, Zirconia hoặc E.Max,
                          Vàng hoặc platin (dùng trong một số dòng cao cấp).
                        </div>
                    </div>
                </div>
            </div>
            <div class="item-cautaorang pb-60">
                <div class="number">
                    <img src="@/assets/img/phuongthanh/dichvu/2.png" alt="" class="img-number">
                    <img src="@/assets/img/phuongthanh/dichvu/in2.png" alt="" class="in-number">
                </div>
                <div class="info-cautaorang">
                    <div>
                        <div class="name-cautaorang">
                          Lớp đệm (Opaque Layer)
                        </div>
                        <div class="sub-cautaorang">
                          Vị trí: Lớp giữa, nằm giữa khung sườn và lớp sứ phủ ngoài. Che đi màu sắc của khung sườn, đặc biệt với khung kim loại.
                          Tạo nền tảng cho lớp phủ sứ bên ngoài, giúp tăng độ bám dính. Sứ hoặc vật liệu composite đặc biệt.
                        </div>
                    </div>
                </div>
            </div>
            <div class="item-cautaorang">
                <div class="number">
                    <img src="@/assets/img/phuongthanh/dichvu/3.png" alt="" class="img-number">
                    <img src="@/assets/img/phuongthanh/dichvu/in3.png" alt="" class="in-number">
                </div>
                <div class="info-cautaorang">
                    <div>
                        <div class="name-cautaorang">
                          Lớp phủ ngoài (Ceramic Layer)
                        </div>
                        <div class="sub-cautaorang">
                          Lớp ngoài cùng, bao phủ toàn bộ bề mặt răng sứ. Tái tạo thẩm mỹ, mang đến màu sắc và độ sáng tự nhiên.
                          Tăng khả năng chống mài mòn và bảo vệ các lớp bên trong. Sứ cao cấp (Lithium Disilicate, Zirconia).
                          Chất liệu này có khả năng bắt sáng tốt, tạo vẻ ngoài tự nhiên như men răng thật.
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
  import { ref } from "vue";
  const currentDate = ref(new Date());
  import AOS from "aos";
  import "aos/dist/aos.css";
  import 'vue3-carousel/dist/carousel.css'
  export default {
    components: {
    },
    data() {
      return {
        startdate: currentDate,
        list: [],
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    props: {
      header: { type : Object}
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
    watch:{
      '$props':{
        handler: function (val) {
          this.list = val.header;
        //  console.log("LOG WATCH LIST ", this.list )
        },
        deep: true
      }
    },
    methods: {
      submitForm() {
        this.$router.push("/search-2");
      },
    },
  };
</script>
<style>
</style>
