<template>
  <div class="container pt-60 pb-60">
    <div class="row align-items-center">
      <div style="text-align: center; margin-bottom: 20px;">
        <div class="main-title pb-30">
          BỌC RĂNG SỨ
        </div>
        <div class="decs-intro-doctor">
          Bọc răng sứ là một phương pháp phục hình răng cố định, trong đó mão răng sứ được chế tạo theo hình dạng và màu sắc 
          của răng thật, sau đó được gắn cố định lên răng thật đã được mài nhỏ. Mão sứ này bao bọc toàn bộ phần răng còn lại, 
          giúp khôi phục hình dáng, chức năng và thẩm mỹ của răng.
        </div>
        <div class="decs-intro-doctor">
          Bọc răng sứ thường được chỉ định trong các trường hợp sau: Răng bị sứt mẻ, gãy vỡ lớn, Răng bị sâu nặng hoặc đã điều trị tủy,
          Răng bị nhiễm màu nặng, không thể tẩy trắng, Răng bị mòn nhiều, Cải thiện hình dáng và kích thước của răng.
        </div>
        <div class="decs-intro-doctor">
          Bọc răng sứ có đau không? Câu trả lời ngắn gọn là: Thông thường không đau.
          Trước khi mài răng, bác sĩ sẽ tiêm thuốc tê cục bộ để làm mất cảm giác ở vùng răng cần điều trị. 
          Vì vậy, trong quá trình mài răng, bạn sẽ không cảm thấy đau. Sau khi hết thuốc tê, bạn có thể cảm thấy hơi 
          ê buốt nhẹ, đặc biệt là khi ăn nhai thức ăn nóng hoặc lạnh. Cảm giác này thường hết sau vài ngày hoặc vài tuần.
        </div>
      </div>
    </div>
  </div>



</template>

<script>
import { ref } from "vue";
const currentDate = ref(new Date());
import AOS from "aos";
import "aos/dist/aos.css";
import indexfivespecialities from "@/assets/json/indexfivespecialities.json";
import 'vue3-carousel/dist/carousel.css'
export default {
  components: {
  },
  data() {
    return {
      startdate: currentDate,
      indexfivespecialities: indexfivespecialities,
      list: [],
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  props: {
    header: { type : Object}
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  watch:{
    '$props':{
      handler: function (val) {
        this.list = val.header;
        //  console.log("LOG WATCH LIST ", this.list )
      },
      deep: true
    }
  },
  methods: {
    submitForm() {
      this.$router.push("/search-2");
    },
  },
};
</script>
<style>
</style>
