<template>
    <div class="container pt-60 pb-60">
      <div class="main-title text-center">Quy trình niềng răng</div>
      <div class="timeline">
        <div class="pt-10 pb-10">
          <div class="card-time-line right">
            <div class="date">
              <img src="@/assets/img/phuongthanh/dichvu/buoc1.png" alt="">
            </div>
            <div class="content-time-line">
              <h2>Bước 1</h2>
              <p>
                Kiểm tra và tư vấn ban đầu
              </p>
              <p class="desc-title pt-2">
                Đầu tiên, bác sĩ sẽ kiểm tra tình trạng răng miệng của trẻ để đánh giá các vấn đề như sâu răng, 
                viêm lợi hoặc các bất thường về cấu trúc hàm. Phụ huynh sẽ được tư vấn kỹ lưỡng về tình trạng sức 
                khỏe răng miệng hiện tại và phương pháp chăm sóc phù hợp cho trẻ.
              </p>
            </div>
          </div>
        </div>

        <div class="pt-10 pb-10">
          <div class="card-time-line left">
            <div class="date">
              <img src="@/assets/img/phuongthanh/dichvu/buoc2.png" alt="">
            </div>
            <div class="content-time-line">
              <h2>Bước 2</h2>
              <p>
                Vệ sinh răng miệng và phòng ngừa sâu răng
              </p>
              <p class="desc-title pt-2">
                Bác sĩ tiến hành vệ sinh răng miệng chuyên sâu, bao gồm loại bỏ mảng bám, làm sạch bề mặt răng và hướng dẫn 
                trẻ cách chải răng đúng cách. Các biện pháp phòng ngừa sâu răng như bôi fluoride hoặc trám răng phòng ngừa 
                sẽ được thực hiện nếu cần thiết.
              </p>
            </div>
          </div>
        </div>

        <div class="pt-10 pb-10">
          <div class="card-time-line right">
            <div class="date">
              <img src="@/assets/img/phuongthanh/dichvu/buoc3.png" alt="">
            </div>
            <div class="content-time-line">
              <h2>Bước 3</h2>
              <p>
                Điều chỉnh răng mọc lệch (nếu có)
              </p>
              <p class="desc-title pt-2">
                Nếu trẻ có vấn đề về răng mọc lệch, bác sĩ sẽ đưa ra kế hoạch điều trị sớm, chẳng hạn như sử dụng khí cụ 
                chỉnh nha hoặc các phương pháp hỗ trợ nhẹ nhàng để điều chỉnh răng mà không gây đau đớn cho trẻ.
              </p>
            </div>
          </div>
        </div>

        <div class="pt-10 pb-10">
          <div class="card-time-line left">
            <div class="date" style="right: -300px;">
              <img src="@/assets/img/phuongthanh/dichvu/buoc4.png" alt="">
            </div>
            <div class="content-time-line">
              <h2>Bước 4</h2>
              <p>
                Kiểm tra định kỳ và theo dõi phát triển
              </p>
              <p class="desc-title pt-2">
                Việc kiểm tra răng miệng định kỳ 3-6 tháng/lần giúp bác sĩ theo dõi sự phát triển của răng và hàm, 
                đồng thời phát hiện sớm các vấn đề tiềm ẩn. Điều này đảm bảo trẻ luôn có một hàm răng khỏe mạnh và 
                phát triển cân đối.
              </p>
            </div>
          </div>
        </div>

        <div class="pt-10 pb-10">
          <div class="card-time-line right">
            <div class="date">
              <img src="@/assets/img/phuongthanh/dichvu/buoc5.png" alt="">
            </div>
            <div class="content-time-line">
              <h2>Bước 5</h2>
              <p>
                Hướng dẫn thói quen chăm sóc răng miệng
              </p>
              <p class="desc-title pt-2">
                Bác sĩ và phụ huynh cùng hợp tác để xây dựng cho trẻ thói quen vệ sinh răng miệng tốt, bao gồm cách sử dụng 
                bàn chải, chỉ nha khoa và chế độ ăn uống lành mạnh. Điều này giúp duy trì nụ cười rạng rỡ và sức khỏe răng 
                miệng lâu dài.
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>

</template>

<script>
  import { ref } from "vue";
  const currentDate = ref(new Date());
  import AOS from "aos";
  import "aos/dist/aos.css";
  import 'vue3-carousel/dist/carousel.css'
  export default {
    components: {
    },
    data() {
      return {
        startdate: currentDate,
        list: [],
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    props: {
      header: { type : Object}
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
    watch:{
      '$props':{
        handler: function (val) {
          this.list = val.header;
        //  console.log("LOG WATCH LIST ", this.list )
        },
        deep: true
      }
    },
    methods: {
      submitForm() {
        this.$router.push("/search-2");
      },
    },
  };
</script>
<style>
</style>
