<template>
  <div class="container pt-60 pb-30">
    <div class="main-title text-center pb-30">Những lưu ý giúp niềng răng trẻ em thành công</div>

    <!-- Khung 1 -->
    <div class="box-luu-y box-primary">
      <h2>Tuân thủ lịch hẹn tái khám:</h2>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Trẻ cần tuân thủ lịch hẹn tái khám đúng định kỳ với bác sĩ nha khoa để kiểm tra tiến độ điều trị 
          và điều chỉnh khí cụ khi cần thiết. Việc này giúp đảm bảo hiệu quả chỉnh nha và ngăn ngừa các vấn đề 
          phát sinh trong quá trình điều trị.
        </span>
      </div>

      <h2>Vệ sinh răng miệng đúng cách:</h2>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Răng của trẻ cần được vệ sinh đúng cách hàng ngày, bao gồm đánh răng ít nhất 2 lần/ngày bằng bàn chải lông mềm, 
          dùng chỉ nha khoa hoặc bàn chải kẽ để làm sạch các kẽ răng và mắc cài.
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Tránh thực phẩm có tính axit cao hoặc quá cứng, gây hại cho khí cụ như kẹo cứng, bánh gạo, hoặc thực phẩm có đường. 
          Nên cho trẻ ăn các loại thực phẩm mềm, dễ nhai và không gây ảnh hưởng đến khí cụ niềng răng.
        </span>
      </div>

      <h2>Kiểm soát đau đớn và khó chịu:</h2>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Trong thời gian đầu niềng răng, trẻ có thể cảm thấy đau hoặc khó chịu. Điều này là bình thường và sẽ giảm dần sau vài ngày. 
          Nếu cơn đau kéo dài, bố mẹ cần liên hệ ngay với bác sĩ để điều chỉnh lại khí cụ.
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Sử dụng thuốc giảm đau theo chỉ dẫn của bác sĩ nếu cần thiết.
        </span>
      </div>
      
      <h2>Hỗ trợ trẻ trong quá trình điều trị:</h2>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Bố mẹ nên hỗ trợ trẻ trong việc duy trì thói quen chăm sóc răng miệng đúng cách và động viên trẻ trong suốt quá trình điều trị. 
          Đảm bảo trẻ cảm thấy thoải mái và không cảm thấy quá căng thẳng hay lo lắng về việc niềng răng.
        </span>
      </div>

      <h2>Kiểm soát thói quen xấu:</h2>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Tránh để trẻ có các thói quen xấu như mút tay, cắn bút hoặc cắn móng tay, vì những thói quen này 
          có thể ảnh hưởng đến hiệu quả điều trị.
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Nếu cần, bác sĩ có thể yêu cầu sử dụng thêm một khí cụ hỗ trợ để ngăn ngừa các thói quen này.
        </span>
      </div>

      <h2>Theo dõi chế độ ăn uống:</h2>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Khuyến khích trẻ ăn các loại thực phẩm giàu canxi và vitamin D để hỗ trợ sự phát triển của răng và xương hàm.
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Tránh thực phẩm có đường và đồ uống có gas để ngăn ngừa sâu răng và đảm bảo khí cụ không bị ảnh hưởng.
        </span>
      </div>
    </div>

    <!-- Khung 2 -->
    <!-- <div class="box-luu-y box-secondary">
      <h2>Trong trường hợp có nâng xoang</h2>
      <p class="desc-title">Sau phẫu thuật, không dùng nước băng nóng, không xì mũi trong 15 ngày. Tránh máy bay trong tuần đầu tiên sau phẫu thuật.</p>

      <h2>Chế độ sinh hoạt ăn uống</h2>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Tránh hút thuốc vì điều này có thể làm chậm quá trình lành thương.</span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Nên ăn đồ mềm, nguội, tránh dùng các loại chất kích thích như: bia, rượu, đồ ăn cay, nóng, lạnh.</span>
      </div>
    </div> -->

  </div>

</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  data() {
    return {
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  created() {

  },
  methods: {

    toggleFavorite(item) {
      item.favorite = !item.favorite;
    },
  },
};
</script>

<style>

</style>
