<template>
    <div class="container pt-60 pb-60">
      <div class="row">
        <div class="col-md-12 who-trongrang">
          <div>
            <div class="main-title pb-30">Thời điểm nào phù hợp để trẻ cần niềng răng?</div>
            <div class="desc-title">Niềng Răng Trẻ Em là giải pháp giúp điều chỉnh răng mọc lệch, khớp cắn sai và hỗ trợ phát triển hàm đúng cách. 
              Phương pháp này được khuyến nghị cho trẻ từ 6-12 tuổi để ngăn ngừa các vấn đề răng miệng về sau. Việc can thiệp sớm không chỉ cải thiện thẩm mỹ mà còn đảm bảo chức năng ăn nhai và sức khỏe toàn diện cho trẻ.</div>
            <div class="card-who-trongrang pt-30">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Từ 5-11 tuổi: độ tuổi chỉnh nha và tầm soát răng miệng tốt nhất cho trẻ (Theo Hiệp hội Nha khoa Hoa Kỳ -ADA) 
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Khám tầm soát răng miệng để phát hiện sớm các bất thường về răng như răng dư, thiếu răng, thiếu khoảng mọc răng, hẹp hàm, xương hàm phát triển quá mức hoặc kém phát triển, thói quen xấu như mút môi, đẩy lưỡi,...
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Đây là giai đoạn này trẻ đang thay răng sữa sang răng vĩnh viễn, răng và xương đang trong quá trình phát triển nên dễ dàng nắn chỉnh, đặc biệt là nắn chỉnh xương.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Can thiệp trong giai đoạn này giúp ngăn chặn, thay đổi thói quen xấu ảnh hưởng sức khỏe răng miệng, hạn chế nhổ răng, tránh phải phẫu thuật hàm nếu sai lệch nặng khi lớn.
              </div>
            </div>
          </div>


        </div>
<!--        <div class="col-md-6">-->
<!--            <div class="img-trongrang">-->
<!--                <img src="@/assets/img/phuongthanh/dichvu/who.png" alt="" style="max-height: 500px;">-->
<!--            </div>-->
<!--        </div>-->
      </div>
    </div>



</template>

<script>
  import { ref } from "vue";
  const currentDate = ref(new Date());
  import AOS from "aos";
  import "aos/dist/aos.css";
  import 'vue3-carousel/dist/carousel.css'
  export default {
    components: {
    },
    data() {
      return {
        startdate: currentDate,
        list: [],
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    props: {
      header: { type : Object}
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
    watch:{
      '$props':{
        handler: function (val) {
          this.list = val.header;
        //  console.log("LOG WATCH LIST ", this.list )
        },
        deep: true
      }
    },
    methods: {
      submitForm() {
        this.$router.push("/search-2");
      },
    },
  };
</script>
<style>
</style>
