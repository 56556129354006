<template>
    <div class="main-wrapper home-ten pt-60 pb-60">
        <layoutheader :class="{ 'header-space': showHeaderSpace }" ref="header" />
        <div class="page-wrapper">
            <div>
                <div class="container">
                <div>
                    <div class="row align-items-center">
                      <div style="text-align: center; margin-bottom: 20px;">
                          <div class="sub-title pb-10">BÁC SĨ</div>
                          <div class="main-title pb-20">
                              Kiến tạo chuẩn mực với đội ngũ chuyên gia hàng đầu
                          </div>
                          <div class="decs-intro-doctor">
                              Nha khoa Phương Thành tự hào là nơi hội tụ của đội ngũ chuyên gia nha khoa hàng đầu.
                              Bên cạnh việc cung cấp dịch vụ điều trị chất lượng vượt trội, các bác sĩ tại Nha khoa Phương Thành
                              luôn chủ động nâng cao kiến thức chuyên môn. Với các buổi hội thảo và chương trình chia sẻ
                              kiến thức chuyên sâu thường xuyên, chúng tôi không ngừng cập nhật các xu hướng và phương pháp điều
                              trị mới nhất, từ đó mang đến cho khách hàng những giải pháp nha khoa tối ưu và hiệu quả nhất.
                          </div>
                      </div>
                    <div class="aos pt-30" data-aos="fade-up">
                      <swiper
                        :breakpoints="{
                          576: { slidesPerView: 2 },  // Với màn hình nhỏ hơn 320px, hiển thị 1 slide
                          991: { slidesPerView: 3 },  // Với màn hình từ 640px trở lên, hiển thị 2 slide
                          1240: { slidesPerView: 4 }, // Với màn hình từ 1024px trở lên, hiển thị 3 slide
                        }"
                        :spaceBetween="0"
                        :navigation="true"
                        :pagination="true"
                        :loop="true"
                        :modules="modules"
                        class="swiper-news"
                      >
                        <swiper-slide class="slide-news" v-for="(item, index) in filter1" :key="index.id">
                          <div class="list-doingu">
                            <div class="item-doingu">
                              <img :src="require(`@/assets/img/phuongthanh/doctor/${item.Image}`)" alt="">
                              <div class="card-doingu">
                                <div>
                                  <div class="doctor-chuc-danh">
                                      <span>{{ item.Text }}</span>
                                  </div>
                                  <div class="doctor-name">
                                      <h3>{{ item.Name }}</h3>
                                  </div>
                                  <div class="doctor-desc">
                                    BS Phạm Kim Thành hiện là ..... chuyên môn tại Nha khoa Phương Thành,
                                    từng có nhiều năm tu nghiệp, giữ vị trí quan trọng trong các tổ chức nha khoa uy tín tại Việt Nam.
                                    <div class="doctor-button">
                                        <a>Tìm hiểu thêm</a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </swiper-slide>
                      </swiper>
                    </div>
                    <!-- <div class="aos pt-30" data-aos="fade-up">
                        <Carousel ref="carousel1" v-bind="settings" :breakpoints="breakpoints" :wrapAround="true" :transition="500">
                            <Slide v-for="(item, index) in filter1" :key="index.id">
                                <div class="list-doingu">
                                    <div class="item-doingu">
                                        <img :src="require(`@/assets/img/phuongthanh/doctor/${item.Image}`)" alt="">
                                        <div class="card-doingu">
                                            <div>
                                                <div class="doctor-chuc-danh">
                                                    <span>{{ item.Text }}</span>
                                                </div>
                                                <div class="doctor-name">
                                                    <h3>{{ item.Name }}</h3>
                                                </div>
                                                <div class="doctor-desc">
                                                    BS Phạm Kim Thành hiện là ..... chuyên môn tại Nha khoa Phương Thành,
                                                    từng có nhiều năm tu nghiệp, giữ vị trí quan trọng trong các tổ chức nha khoa uy tín tại Việt Nam.
                                                    <div class="doctor-button">
                                                       <a>Tìm hiểu thêm</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Slide>
                        </Carousel>
                        <div class="btn-w-slide">
                            <button class="btn-slide btn-prev" @click="prevSlide1"><i class="fa fa-solid fa-angle-left" aria-hidden="true"></i></button>
                            <button class="btn-slide btn-next"@click="nextSlide1"><i class="fa fa-solid fa-angle-right" aria-hidden="true"></i></button>
                        </div>
                    </div> -->
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BlogGrid from "@/assets/json/blog-grid.json";
import {pageInfoModel} from "@/models/pageInfoModel";
import {Carousel, Slide} from "vue3-carousel";
import indexFiveDoctor from "@/assets/json/indexfivedoctor.json";
import 'vue3-carousel/dist/carousel.css'
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Navigation, Pagination } from 'swiper/modules';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      modules: [Navigation, Pagination],
      title: 'Đội ngũ bác sĩ',
      text: 'Trang chủ',
      text1: 'Đội ngũ bác sĩ',
      numberOfElement: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      model: [],
      activeTab: 0, // Tab mặc định là tab đầu tiên
      BlogGrid: BlogGrid,
      currentYear: '2024', // Năm mặc định được chọn
      pageInfo : pageInfoModel.baseJson(),
      indexFiveDoctor: indexFiveDoctor,
      itemsToShow: Math.min(indexFiveDoctor.length, 4), // Tính số lượng item hiển thị
        settings: {
            slidesToShow: this.itemsToShow,
            slidesToScroll: 1,
            dots: true,
            infinite: indexFiveDoctor.length > 4, // Chỉ cho phép lướt nếu có nhiều hơn 4 item
            // Các thiết lập khác
        },
        breakpoints: {
            578: {
                itemsToShow: 2,
                snapAlign: 'start',
            },
            // 700px and up
            768: {
                itemsToShow: 2,
                snapAlign: 'start',
            },
            // 1024 and up
            992: {
                itemsToShow: 3,
                snapAlign: 'start',
            },
            1200: {
                itemsToShow: 4,
                snapAlign: 'start',
            },
        },
      url : `${process.env.VUE_APP_URL}`,
    }
  },

  watch: {
    $route(to, from) {
      //  console.log("LOG WATCH DATA : ")
    //   this.getData();
    },
    perPage: {
      deep: true,
      handler(val){
        // this.getData();
      }
    },
    currentPage:{
      deep: true,
      handler(val){
        //   console.log("this.perpage", this.currentPage);
        // this.getData();
      }
    }
  },
  computed: {
    // Lọc danh sách bác sĩ có badge 'Hàm tháo lắp'
    filter1() {
        return this.indexFiveDoctor.filter(item => item.Badge === '1');
    },

},

  mounted() {
    // Chặn phím F12
    // window.addEventListener('keydown', (event) => {
    //   if (event.key === 'F12') {
    //     event.preventDefault();
    //   }
    // });

    // Chặn chuột phải
    // window.addEventListener('contextmenu', (event) => {
    //   event.preventDefault();
    // });
  },
  created() {
  },
  methods: {
    nextSlide1() {
        this.$refs.carousel1.next();
    },
    prevSlide1() {
        this.$refs.carousel1.prev();
    },


    newTab(url) {
      window.open(url)
    }


  },
}
</script>

<style>



</style>
