<template>
  <!-- Blog Sidebar -->
  <div class="col-lg-4 col-md-12 sidebar-right theiaStickySidebar">
    <div class="stickysidebar">
      <!-- Search -->
      <div class="card post-widget">
        <div class="card-news-sidebar">
          <h4 class="card-title" style="margin-bottom: 0px;">DỊCH VỤ LIÊN QUAN</h4>
        </div>
        <div class="card-body news-sidebar" style="padding: 20px 10px;">
          <a href="http://phuongthanh.goldenkeysoft.com/trong-rang-implant" target="_blank" rel="noopener noreferrer">
            <img src="@/assets/img/phuongthanh/tintuc/dichvu1.png" alt="">
            <span style="font-size: 16px; margin-left: 10px;">Trồng răng Implant</span>
          </a>
        </div>
        <div class="card-body" style="padding: 20px 10px;">
          <a href="http://phuongthanh.goldenkeysoft.com/nieng-rang" target="_blank" rel="noopener noreferrer">
            <img src="@/assets/img/phuongthanh/tintuc/dichvu2.png" alt="">
            <span style="font-size: 16px; margin-left: 10px;">Niềng răng</span>
          </a>
        </div>
        <div class="card-body" style="padding: 20px 10px;">
          <a href="http://phuongthanh.goldenkeysoft.com/nha-khoa-tham-my" target="_blank" rel="noopener noreferrer">
            <img src="@/assets/img/phuongthanh/tintuc/dichvu3.png" alt="">
            <span style="font-size: 16px; margin-left: 10px;">Nha khoa thẩm mỹ</span>
          </a>
        </div>
        <div class="card-body" style="padding: 20px 10px;">
          <a href="http://phuongthanh.goldenkeysoft.com/nha-khoa-tong-quat" target="_blank" rel="noopener noreferrer">
            <img src="@/assets/img/phuongthanh/tintuc/dichvu4.png" alt="">
            <span style="font-size: 16px; margin-left: 10px;">Nha khoa tổng quát</span>
          </a>
        </div>
      </div>

      <div class="post-widget">
        <div class="card-news-sidebar" >
          <h4 class="card-title" style="margin-bottom: 0px;">KIẾN THỨC NHA KHOA</h4>
        </div>
        <div class="card-body" style="padding: 20px 10px;">
          <div class="row mb-4 item-news-sidebar" v-for="news in newList" :key="news.id">
              <div class="col-md-4 col-sm-4">
                <router-link :to="{
                        path: `/ban-tin/${news.id}`,
                    }">
                  <img
                      v-if="news.fileImage"
                      class="img-fluid img-sidebar"
                      :src="news.fileImage.urlFile"
                      alt="Post Image"
                  />
                </router-link>

              </div>
              <div class="col-md-8 col-sm-8" style="display: flex; align-items: center">
                <router-link :to="{
                        path: `/ban-tin/${news.id}`,
                    }">
                  <div class="title-sidebar">
                    <p class="name-sidebar">
                      {{ news.name }}
                    </p>
                    <p class="desc-sidebar">
                      {{ news.describe}}
                    </p>
                  </div>
                </router-link>

              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Blog Sidebar -->
</template>

<script>
import BlogSidebar from "@/assets/json/blogsidebar.json";
export default {
  data() {
    return {
      BlogSidebar: BlogSidebar,
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      newList: [],
    };
  },
  created() {
    this.getData();
  },

  methods: {
    async getData() {
      let params = {
        start: 0,
        limit: 4,
      }
      await this.$store.dispatch("newsStore/getPagingParamsDentalKnowledge", params).then(res => {
        this.newList = res.data;
      });
    },

  },
};
</script>

<style>

</style>
