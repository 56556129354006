<template>
    <!-- Need To Know -->
    <section class="need-to-know-section section-list-service pt-60 pb-60">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="section-header-one section-header-slider text-center pb-30">
              <div class="main-title">Các phương pháp trồng răng Implant</div>
            </div>
          </div>
        </div>
        <div class="listing-service pt-30">
          <div class="row">
            <div class="col-xl-4 col-md-6 col-12">
              <router-link
                  :to="{
                    path: `/trong-rang-implant/trong-rang-implant-1-rang`,
                  }"
              >
                <div class="service-item">
                  <div class="service-thumb">
                    <a href="">
                      <img width="300" height="208" 
                        src="@/assets/img/phuongthanh/dichvu/implant-1-rang.png"
                        class="attachment-medium size-medium wp-post-image img-dichvu" 
                        alt="Ảnh" 
                        decoding="async" 
                        fetchpriority="high" 
                        srcset="" 
                        sizes="100vw">
                    </a>
                  </div>
                  <div class="service-content">
                    <span style="font-size: 14px;">Giải pháp tiên tiến cho người mất răng</span>
                    <div class="service-title">
                      <h3>
                        <a href="" data-wpel-link="internal">
                          Trồng răng Implant 1 răng
                        </a>
                      </h3>
                    </div>
                    <div class="service-excerpt">
                      Cắm răng Implant thay thế 1 răng đã mất nhằm tránh hậu quả do mất răng như 
                      suy giảm chức năng ăn nhai, xô lệch răng, tiêu xương hàm…
                    </div>
                    <div class="service-button">
                      <a href="" data-wpel-link="internal">
                        <div>
                          Xem thêm
                        </div>
                        <i class="fa fa-solid fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-xl-4 col-md-6 col-12">
              <router-link
                  :to="{
                    path: `/trong-rang-implant/trong-rang-implant-1-vai-rang`,
                  }"
              >
                <div class="service-item">
                  <div class="service-thumb">
                    <a href="">
                      <img width="300" height="208" 
                        src="@/assets/img/phuongthanh/dichvu/implant-vai-rang.png"
                        class="attachment-medium size-medium wp-post-image img-dichvu" 
                        alt="Ảnh" 
                        decoding="async" 
                        fetchpriority="high" 
                        srcset="" 
                        sizes="100vw">
                    </a>
                  </div>
                  <div class="service-content">
                    <span style="font-size: 14px;">Giải pháp tiên tiến cho người mất răng</span>
                    <div class="service-title">
                      <h3>
                        <a href="" data-wpel-link="internal">
                          Trồng răng Implant vài răng
                        </a>
                      </h3>
                    </div>
                    <div class="service-excerpt">
                      Cấy ghép răng Implant kết hợp cầu răng hoặc Implant nhiều răng đơn lẻ, 
                      áp dụng khi mất nhiều răng liền kề hoặc xen kẽ, giúp khắc phục tình trạng mất răng.
                    </div>
                    <div class="service-button">
                      <a href="" data-wpel-link="internal">
                        <div>
                          Xem thêm
                        </div>
                        <i class="fa fa-solid fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-xl-4 col-md-6 col-12">
              <router-link
                  :to="{
                    path: `/trong-rang-implant/trong-rang-implant-toan-ham`,
                  }"
              >
                <div class="service-item">
                  <div class="service-thumb">
                    <a href="">
                      <img width="300" height="208" 
                        src="@/assets/img/phuongthanh/dichvu/implant-toan-ham.png"
                        class="attachment-medium size-medium wp-post-image img-dichvu" 
                        alt="Ảnh" 
                        decoding="async" 
                        fetchpriority="high" 
                        srcset="" 
                        sizes="100vw">
                    </a>
                  </div>
                  <div class="service-content">
                    <span style="font-size: 14px;">Giải pháp tiên tiến cho người mất răng</span>
                    <div class="service-title">
                      <h3>
                        <a href="" data-wpel-link="internal">
                          Trồng răng Implant toàn hàm
                        </a>
                      </h3>
                    </div>
                    <div class="service-excerpt">
                      Cấy ghép răng Implant kết hợp cầu răng hoặc Implant nhiều răng đơn lẻ, 
                      áp dụng khi mất nhiều răng liền kề hoặc xen kẽ, giúp khắc phục tình trạng mất răng.
                    </div>
                    <div class="service-button">
                      <a href="" data-wpel-link="internal">
                        <div>
                          Xem thêm
                        </div>
                        <i class="fa fa-solid fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- /Need To Know -->
  
  </template>
  <script>
  import AOS from "aos";
  import "aos/dist/aos.css";
  import indexFiveService from "@/assets/json/indexfiveservice.json";
  export default {
    data() {
      return {
        indexFiveService: indexFiveService,
        list: [],
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
    created() {
      this.getData();
    },
    methods: {
      async getData() {
        let params = {
            start: this.currentPage,
            limit: this.perPage,
            sortBy: this.sortBy,
        }
        await this.$store.dispatch("dichVuStore/getPagingParams", params ).then(res => {
            this.list = res.data.data
        });
    },
      toggleFavorite(item) {
        item.favorite = !item.favorite;
      },
    },
  };
  </script>
  
  <style>
  .img-service{
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 12px;
  }
  
  @media (max-width: 576px) {
    .img-service{
      height: 300px;
    }
  }
  </style>
  