<template>
  <div class="container pt-60 pb-60">
    <div class="row align-items-center">
      <div style="text-align: center; margin-bottom: 20px;">
        <div class="main-title pb-30">
          DÁN SỨ VENEER
        </div>
        <div class="decs-intro-doctor">
          Mặt dán sứ Veneer là một lớp sứ mỏng, được chế tạo riêng biệt cho từng răng và dán lên bề mặt ngoài của răng 
          thật bằng keo đặc biệt. Nó chủ yếu dùng để cải thiện thẩm mỹ, che đi các khuyết điểm nhỏ như răng sứt mẻ, 
          lệch lạc nhẹ, khoảng cách giữa các răng, hay răng bị đổi màu. Về cơ bản, Veneer như một "mặt nạ" mỏng làm 
          đẹp cho răng.
        </div>
        <div class="decs-intro-doctor">
          Khác với Veneer, bọc răng sứ bao phủ toàn bộ bề mặt răng. Nó dày hơn Veneer và đòi hỏi mài mòn nhiều mô răng hơn. 
          Bọc sứ thường được chỉ định cho răng bị hư tổn nặng, chẳng hạn như sâu răng lớn, răng đã điều trị tủy, hoặc 
          răng cần phục hồi chức năng ăn nhai. Về bản chất, bọc sứ giống như "thay thế" toàn bộ lớp vỏ ngoài của răng thật.
        </div>
        <div class="decs-intro-doctor">
          Về độ bền, bọc sứ thường bền hơn Veneer và chịu lực tốt hơn. Tuy nhiên, về mặt thẩm mỹ, Veneer thường được 
          đánh giá cao hơn vì độ mỏng và khả năng tạo ra nụ cười tự nhiên. Chi phí cho Veneer thường cao hơn bọc sứ. 
          Thời gian thực hiện Veneer cũng thường nhanh hơn.
        </div>
      </div>
    </div>
  </div>



</template>

<script>
import { ref } from "vue";
const currentDate = ref(new Date());
import AOS from "aos";
import "aos/dist/aos.css";
import indexfivespecialities from "@/assets/json/indexfivespecialities.json";
import 'vue3-carousel/dist/carousel.css'
export default {
  components: {
  },
  data() {
    return {
      startdate: currentDate,
      indexfivespecialities: indexfivespecialities,
      list: [],
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  props: {
    header: { type : Object}
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  watch:{
    '$props':{
      handler: function (val) {
        this.list = val.header;
        //  console.log("LOG WATCH LIST ", this.list )
      },
      deep: true
    }
  },
  methods: {
    submitForm() {
      this.$router.push("/search-2");
    },
  },
};
</script>
<style>
</style>
