<template>
    <!-- Need To Know -->
    <section class="need-to-know-section section-list-service pt-60 pb-60">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="section-header-one section-header-slider text-center pb-30">
              <div class="main-title">Các phương pháp điều trị rối loạn thái dương hàm</div>
            </div>
          </div>
        </div>
        <div class="listing-service pt-30">
          <div class="row">
            <div class="col-xl-4 col-md-6 col-12">
              <router-link
                  :to="{
                    path: `/nieng-rang/nieng-rang-trong-suot`,
                  }"
              >
                <div class="service-item">
                  <div class="service-thumb">
                    <a href="">
                      <img width="300" height="208"
                        src="@/assets/img/phuongthanh/dichvu/implant-1-rang.png"
                        class="attachment-medium size-medium wp-post-image img-dichvu"
                        alt="Ảnh"
                        decoding="async"
                        fetchpriority="high"
                        srcset=""
                        sizes="100vw">
                    </a>
                  </div>
                  <div class="service-content">
                    <span style="font-size: 14px;">Lấy lại nụ cười thẳng đều, chuẩn khớp cắn</span>
                    <div class="service-title">
                      <h3>
                        <a href="" data-wpel-link="internal">
                          Chỉnh nha khay trong suốt
                        </a>
                      </h3>
                    </div>
                    <div class="service-excerpt">
                      Chỉnh nha khay trong suốt sử dụng khay nhựa trong, ôm sát răng để điều chỉnh vị trí răng, mang lại thẩm mỹ cao, thoải mái và ít ảnh hưởng đến sinh hoạt hằng ngày.
                    </div>
                    <div class="service-button">
                      <a href="" data-wpel-link="internal">
                        <div>
                          Xem thêm
                        </div>
                        <i class="fa fa-solid fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-xl-4 col-md-6 col-12">
              <router-link
                  :to="{
                    path: `/nieng-rang/nieng-rang-mac-cai`,
                  }"
              >
                <div class="service-item">
                  <div class="service-thumb">
                    <a href="">
                      <img width="300" height="208"
                        src="@/assets/img/phuongthanh/dichvu/implant-vai-rang.png"
                        class="attachment-medium size-medium wp-post-image img-dichvu"
                        alt="Ảnh"
                        decoding="async"
                        fetchpriority="high"
                        srcset=""
                        sizes="100vw">
                    </a>
                  </div>
                  <div class="service-content">
                    <span style="font-size: 14px;">Lấy lại nụ cười thẳng đều, chuẩn khớp cắn</span>
                    <div class="service-title">
                      <h3>
                        <a href="" data-wpel-link="internal">
                          Chỉnh nha mắc cài kim loại
                        </a>
                      </h3>
                    </div>
                    <div class="service-excerpt">
                      Chỉnh nha mắc cài kim loại là phương pháp niềng răng truyền thống, sử dụng mắc cài kim loại và dây cung để điều chỉnh răng lệch, mang lại hiệu quả cao và chi phí hợp lý.
                    </div>
                    <div class="service-button">
                      <a href="" data-wpel-link="internal">
                        <div>
                          Xem thêm
                        </div>
                        <i class="fa fa-solid fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-xl-4 col-md-6 col-12">
              <router-link
                  :to="{
                    path: `/nieng-rang/nieng-rang-mac-cai`,
                  }"
              >
                <div class="service-item">
                  <div class="service-thumb">
                    <a href="">
                      <img width="300" height="208"
                        src="@/assets/img/phuongthanh/dichvu/implant-toan-ham.png"
                        class="attachment-medium size-medium wp-post-image img-dichvu"
                        alt="Ảnh"
                        decoding="async"
                        fetchpriority="high"
                        srcset=""
                        sizes="100vw">
                    </a>
                  </div>
                  <div class="service-content">
                    <span style="font-size: 14px;">Lấy lại nụ cười thẳng đều, chuẩn khớp cắn</span>
                    <div class="service-title">
                      <h3>
                        <a href="" data-wpel-link="internal">
                          Chỉnh nha khí cụ cho trẻ em
                        </a>
                      </h3>
                    </div>
                    <div class="service-excerpt">
                      Chỉnh nha khí cụ cho trẻ em giúp điều chỉnh răng lệch, khớp cắn sai, hỗ trợ phát triển hàm đúng cách. Phương pháp này thường áp dụng cho trẻ từ 6-12 tuổi để phòng ngừa vấn đề răng miệng sau này.
                    </div>
                    <div class="service-button">
                      <a href="" data-wpel-link="internal">
                        <div>
                          Xem thêm
                        </div>
                        <i class="fa fa-solid fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- /Need To Know -->

  </template>
  <script>
  import AOS from "aos";
  import "aos/dist/aos.css";
  import indexFiveService from "@/assets/json/indexfiveservice.json";
  export default {
    data() {
      return {
        indexFiveService: indexFiveService,
        list: [],
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
    created() {
      this.getData();
    },
    methods: {
      async getData() {
        let params = {
            start: this.currentPage,
            limit: this.perPage,
            sortBy: this.sortBy,
        }
        await this.$store.dispatch("dichVuStore/getPagingParams", params ).then(res => {
            this.list = res.data.data
        });
    },
      toggleFavorite(item) {
        item.favorite = !item.favorite;
      },
    },
  };
  </script>

  <style>
  .img-service{
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 12px;
  }

  @media (max-width: 576px) {
    .img-service{
      height: 300px;
    }
  }
  </style>
