<template>
    <div class="main-wrapper home-ten">
        <layoutheader :class="{ 'header-space': showHeaderSpace }" ref="header" />
        <div class="page-wrapper banner-section">

          <!-- SLOGAN -->
          <div class="container pt-60">
            <div class="slogan">
              <img src="@/assets/img/phuongthanh/doctor/name-slogan.png" alt="" class="pb-30">
              <p class="desc-title" style="font-size: 15px">
                Tại Trung tâm Nha khoa Phương Thành, chúng tôi tự hào sở hữu đội ngũ bác sĩ chuyên môn cao và tận tâm. 
                Mỗi bác sĩ đều có kinh nghiệm dày dạn trong lĩnh vực nha khoa, với những chứng chỉ và bằng cấp uy tín. 
                Chúng tôi luôn cập nhật kiến thức mới và công nghệ hiện đại để đảm bảo mang lại dịch vụ tốt nhất cho 
                khách hàng.
              </p>
              <p class="desc-title" style="font-size: 15px">
                Đội ngũ bác sĩ của chúng tôi không chỉ giỏi chuyên môn mà còn luôn lắng nghe và thấu hiểu nhu cầu của 
                từng bệnh nhân, giúp bạn cảm thấy thoải mái và an tâm trong suốt quá trình điều trị. Hãy đến với Trung tâm 
                Nha khoa Phương Thành để được chăm sóc sức khỏe răng miệng một cách hoàn hảo nhất!
              </p>
              <img src="@/assets/img/phuongthanh/doctor/sub-slogan.png" alt="" class="pb-30">
            </div>
            <div class="doctors">
              <img src="@/assets/img/phuongthanh/doctor/doctors.png" alt="" class="">
            </div>
          </div>


          <!-- ĐỐI TƯỢNG TRỒNG RĂNG -->
          <section class="section-doctor-intro lazyloaded">
            <div class="container">
              <div class="row">
                <div class="col-lg-8">
                  <div class="box-main-bacsi">
                    <!-- <div class="box-icon">
                        <i class="fa fa-solid fa-quote-right"></i>
                    </div> -->
                    <div class="box-content">
                      <h2 class="pb-20" style="color: #fff; font-size: 30px">Câu chuyện của chúng tôi.</h2>
                      <p>
                        Hơn 10 năm qua, giá trị mà Nha khoa Phương Thành luôn theo đuổi là trở thành một trong những 
                        trung tâm nha khoa chuyên sâu uy tín tại Việt Nam. Mỗi bác sĩ của Nha khoa Phương Thành nắm vững 
                        kiến thức chuyên môn, dày dạn kinh nghiệm ở một chuyên khoa và thành thạo trong lĩnh vực riêng 
                        của mình. Điều này giúp khách hàng an tâm điều trị các vấn đề răng miệng đang gặp phải, sớm sở hữu 
                        nụ cười rạng rỡ và bền vững theo thời gian.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                    <div class="box-img">
                      <img class="pt-60 lazyloaded" src="@/assets/img/phuongthanh/doctor/BS_Thanh-remove.png"  alt="hình ảnh bác sĩ">
                    </div>
                </div>
              </div>
            </div>
          </section>

          <!-- TRỒNG RĂNG IMPLANT -->
          <div class="container pt-60 pb-60">
            <div class="trong-rang-implant">
              <div style="text-align: end;">
                <img src="@/assets/img/phuongthanh/doctor/teeth.png" alt="" class="">
              </div>
              <div class="main-title pb-30 text-center">
                Trồng răng Implant toàn hàm
              </div>
              <div class="desc-title pb-30 text-center">
                Đối với Cô Chú bị mất nhiều răng hoặc mất răng toàn bộ, phương pháp trồng răng Implant toàn hàm được xem 
                là giải pháp phục hình tối ưu. Phương pháp này nổi bật với khả năng khôi phục chức năng ăn nhai gần như 
                răng thật, mang lại vẻ thẩm mỹ tự nhiên và dễ dàng vệ sinh. Đặc biệt, khi thực hiện cấy ghép Implant 
                toàn hàm tại các cơ sở nha khoa uy tín, Cô Chú có thể yên tâm về kết quả bền đẹp lâu dài, thậm chí là 
                vĩnh viễn nếu được chăm sóc đúng cách.
              </div>
              <div class="row pt-10">
                <div class="col-lg-3 col-md-6 mb-3">
                  <div class="card-doctors-implant">
                    <div class="title-doctors-implant">
                      <div class="name-doctors-implant pb-20">
                        Đồng hành và thấu hiểu
                      </div>
                      <div class="desc-title">
                        Luôn lắng nghe và chia sẻ để mang lại sự thoải mái, an tâm trong suốt quá trình điều trị.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 mb-3">
                  <div class="card-doctors-implant">
                    <div class="title-doctors-implant">
                      <div class="name-doctors-implant pb-20">
                        Tận tâm và minh bạch
                      </div>
                      <div class="desc-title">
                        Cung cấp tư vấn trung thực, rõ ràng và xây dựng kế hoạch điều trị phù hợp nhất với nhu cầu của từng khách hàng.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 mb-3">
                  <div class="card-doctors-implant">
                    <div class="title-doctors-implant">
                      <div class="name-doctors-implant pb-20">
                        Hỗ trợ tận tình
                      </div>
                      <div class="desc-title">
                        Sẵn sàng giải đáp mọi thắc mắc, giúp khách hàng hiểu rõ từng bước trong quá trình chăm sóc răng miệng.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="card-doctors-implant">
                    <div class="title-doctors-implant">
                      <div class="name-doctors-implant pb-20">
                        Điều trị an toàn, tối ưu
                      </div>
                      <div class="desc-title">
                        Ưu tiên phương pháp xâm lấn tối thiểu, bảo tồn răng tự nhiên tối đa để mang lại hiệu quả lâu dài và bền vững.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img src="@/assets/img/phuongthanh/doctor/teeth-big.png" alt="" class="teeth-big">
            </div>
          </div>

          <!-- ĐỘI NGŨ BÁC SĨ -->
          <div class="pt-60">
            <div class="container pt-40 pb-40">
                <div>
                  <div class="row align-items-center">
                    <div style="text-align: center; margin-bottom: 20px;">
                      <div class="sub-title pb-10">BÁC SĨ</div>
                      <div class="main-title pb-20">
                          Đội ngũ quản lý
                      </div>
                      <div class="decs-intro-doctor">
                        Nha khoa Phương Thành tự hào là nơi hội tụ của đội ngũ chuyên gia nha khoa hàng đầu. 
                        Bên cạnh việc cung cấp dịch vụ điều trị chất lượng vượt trội, các bác sĩ tại Nha khoa Phương Thành 
                        luôn chủ động nâng cao kiến thức chuyên môn. Với các buổi hội thảo và chương trình chia sẻ kiến thức 
                        chuyên sâu thường xuyên, chúng tôi không ngừng cập nhật các xu hướng và phương pháp điều trị 
                        mới nhất, từ đó mang đến cho khách hàng những giải pháp nha khoa tối ưu và hiệu quả nhất.
                      </div>
                    </div>
                    <div class="pt-30">
                      <swiper
                        :breakpoints="{
                          576: { slidesPerView: 2 },  // Với màn hình nhỏ hơn 320px, hiển thị 1 slide
                          991: { slidesPerView: 3 },  // Với màn hình từ 640px trở lên, hiển thị 2 slide
                          1240: { slidesPerView: 4 }, // Với màn hình từ 1024px trở lên, hiển thị 3 slide
                        }"
                        :spaceBetween="0"
                        :navigation="true"
                        :pagination="true"
                        :loop="true"
                        :modules="modules"
                        class="swiper-news"
                      >
                        <swiper-slide class="slide-news" v-for="(item, index) in filter1" :key="index.id">
                          <div class="list-doingu">
                            <div class="item-doingu">
                              <img :src="require(`@/assets/img/phuongthanh/doctor/${item.Image}`)" alt="">
                              <div class="card-doingu">
                                <div>
                                  <div class="doctor-chuc-danh">
                                      <span>{{ item.Text }}</span>
                                  </div>
                                  <div class="doctor-name">
                                      <h3>{{ item.Name }}</h3>
                                  </div>
                                  <div class="doctor-desc">
                                    BS Phạm Kim Thành hiện là ..... chuyên môn tại Nha khoa Phương Thành,
                                    từng có nhiều năm tu nghiệp, giữ vị trí quan trọng trong các tổ chức nha khoa uy tín tại Việt Nam.
                                    <div class="doctor-button">
                                        <a>Tìm hiểu thêm</a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </swiper-slide>
                      </swiper>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div style="margin-top: 20px;">
            <div class="container pt-40 pb-40">
                <div>
                  <div class="row align-items-center">
                    <div style="text-align: center;">
                      <div class="sub-title pb-10">BÁC SĨ</div>
                      <div class="main-title pb-20">
                        Implant & Phục hình thẩm mỹ
                      </div>
                    </div>
                    <div class="">
                      <swiper
                        :breakpoints="{
                          576: { slidesPerView: 2 },  // Với màn hình nhỏ hơn 320px, hiển thị 1 slide
                          991: { slidesPerView: 3 },  // Với màn hình từ 640px trở lên, hiển thị 2 slide
                          1240: { slidesPerView: 4 }, // Với màn hình từ 1024px trở lên, hiển thị 3 slide
                        }"
                        :spaceBetween="0"
                        :navigation="true"
                        :pagination="true"
                        :loop="true"
                        :modules="modules"
                        class="swiper-news"
                      >
                        <swiper-slide class="slide-news" v-for="(item, index) in filter2" :key="index.id">
                          <div class="list-doingu">
                            <div class="item-doingu">
                              <img :src="require(`@/assets/img/phuongthanh/doctor/${item.Image}`)" alt="">
                              <div class="card-doingu">
                                <div>
                                  <div class="doctor-chuc-danh">
                                      <span>{{ item.Text }}</span>
                                  </div>
                                  <div class="doctor-name">
                                      <h3>{{ item.Name }}</h3>
                                  </div>
                                  <div class="doctor-desc">
                                    BS Phạm Kim Thành hiện là ..... chuyên môn tại Nha khoa Phương Thành,
                                    từng có nhiều năm tu nghiệp, giữ vị trí quan trọng trong các tổ chức nha khoa uy tín tại Việt Nam.
                                    <div class="doctor-button">
                                        <a>Tìm hiểu thêm</a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </swiper-slide>
                      </swiper>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div style="margin-top: 20px;">
            <div class="container pt-40 pb-40">
                <div>
                  <div class="row align-items-center">
                    <div style="text-align: center;">
                      <div class="sub-title pb-10">BÁC SĨ</div>
                      <div class="main-title pb-20">
                        Chỉnh nha
                      </div>
                    </div>
                    <div class="">
                      <swiper
                        :breakpoints="{
                          576: { slidesPerView: 2 },  // Với màn hình nhỏ hơn 320px, hiển thị 1 slide
                          991: { slidesPerView: 3 },  // Với màn hình từ 640px trở lên, hiển thị 2 slide
                          1240: { slidesPerView: 4 }, // Với màn hình từ 1024px trở lên, hiển thị 3 slide
                        }"
                        :spaceBetween="0"
                        :navigation="true"
                        :pagination="true"
                        :loop="true"
                        :modules="modules"
                        class="swiper-news"
                      >
                        <swiper-slide class="slide-news" v-for="(item, index) in filter3" :key="index.id">
                          <div class="list-doingu">
                            <div class="item-doingu">
                              <img :src="require(`@/assets/img/phuongthanh/doctor/${item.Image}`)" alt="">
                              <div class="card-doingu">
                                <div>
                                  <div class="doctor-chuc-danh">
                                      <span>{{ item.Text }}</span>
                                  </div>
                                  <div class="doctor-name">
                                      <h3>{{ item.Name }}</h3>
                                  </div>
                                  <div class="doctor-desc">
                                    BS Phạm Kim Thành hiện là ..... chuyên môn tại Nha khoa Phương Thành,
                                    từng có nhiều năm tu nghiệp, giữ vị trí quan trọng trong các tổ chức nha khoa uy tín tại Việt Nam.
                                    <div class="doctor-button">
                                        <a>Tìm hiểu thêm</a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </swiper-slide>
                      </swiper>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div style="margin-top: 20px;">
            <div class="container pt-40 pb-60">
                <div>
                  <div class="row align-items-center">
                    <div style="text-align: center;">
                      <div class="sub-title pb-10">BÁC SĨ</div>
                      <div class="main-title pb-20">
                        Phẩu thuật
                      </div>
                    </div>
                    <div class="">
                      <swiper
                        :breakpoints="{
                          576: { slidesPerView: 2 },  // Với màn hình nhỏ hơn 320px, hiển thị 1 slide
                          991: { slidesPerView: 3 },  // Với màn hình từ 640px trở lên, hiển thị 2 slide
                          1240: { slidesPerView: 4 }, // Với màn hình từ 1024px trở lên, hiển thị 3 slide
                        }"
                        :spaceBetween="0"
                        :navigation="true"
                        :pagination="true"
                        :loop="true"
                        :modules="modules"
                        class="swiper-news"
                      >
                        <swiper-slide class="slide-news" v-for="(item, index) in filter5" :key="index.id">
                          <div class="list-doingu">
                            <div class="item-doingu">
                              <img :src="require(`@/assets/img/phuongthanh/doctor/${item.Image}`)" alt="">
                              <div class="card-doingu">
                                <div>
                                  <div class="doctor-chuc-danh">
                                      <span>{{ item.Text }}</span>
                                  </div>
                                  <div class="doctor-name">
                                      <h3>{{ item.Name }}</h3>
                                  </div>
                                  <div class="doctor-desc">
                                    BS Phạm Kim Thành hiện là ..... chuyên môn tại Nha khoa Phương Thành,
                                    từng có nhiều năm tu nghiệp, giữ vị trí quan trọng trong các tổ chức nha khoa uy tín tại Việt Nam.
                                    <div class="doctor-button">
                                        <a>Tìm hiểu thêm</a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </swiper-slide>
                      </swiper>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <!-- /ĐỘI NGŨ BÁC SĨ -->


          <section class="specialities-section-one pt-60 pb-60">
            <!-- <div class="floating-bg" style="margin-top: 120px">
              <img src="@/assets/img/phuongthanh/icon-teeth-72.png" alt="Image" />
            </div> -->
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="section-header-one section-header-slider">
                    <h2 class="section-title">
                      <div class="main-title pb-30" style="position: relative; z-index: 0;">
                        Đối tác
                        <img src="@/assets/img/phuongthanh/core/teeth.png" alt="" class="img-doitac">
                      </div>
                    </h2>
                  </div>
                </div>
              </div>
              <div class="specialities-block">
                <ul>
                  <li v-for="(speciality, index) in indexfivespecialities" :key="index">
                    <div class="specialities-item">
                      <div class="specialities-img">
                        <div class="hexogen">
                          <img
                            :src="require(`@/assets/img/icons/${speciality.imageSrc}`)"
                            :alt="speciality.altText"
                            style="height: 100%"
                          />
                        </div>
                      </div>
                      <p>{{ speciality.title }}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <!-- <div class="container pt-60 pb-30">
            <div class="main-title text-center pb-30">5 Lý do chọn nha khoa phương thành </div>
            <div class="box-luu-y box-secondary">
              <h2>An toàn</h2>
              <div class="item-luu-y mb-3">
                <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
                <span class="desc-title">Chúng tôi luôn đặt sự an toàn của khách hàng lên hàng đầu, với quy trình tiệt trùng nghiêm ngặt và thiết bị hiện đại</span>
              </div>
              <h2>Tận tâm</h2>
              <div class="item-luu-y mb-3">
                <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
                <span class="desc-title">Với đội ngũ bác sĩ chuyên môn cao và công nghệ tiên tiến, Nha khoa Phương Thành cam kết mang đến dịch vụ chất lượng, đáp ứng tiêu chuẩn quốc tế và sự hài lòng tuyệt đối cho khách hàng.</span>
              </div>
              <h2>Chất lượng</h2>
              <div class="item-luu-y mb-3">
                <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
                <span class="desc-title">Chúng tôi sử dụng các phương pháp điều trị hiện đại và vật liệu chất lượng cao, mang lại kết quả lâu dài, hiệu quả và thẩm mỹ, giúp khách hàng tự tin với nụ cười của mình.</span>
              </div>
              <h2>Hiệu quả</h2>
              <div class="item-luu-y mb-3">
                <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
                <span class="desc-title">Mỗi khách hàng là một mối quan tâm đặc biệt. Đội ngũ của chúng tôi luôn lắng nghe, tư vấn tận tình và chăm sóc chu đáo, giúp bạn cảm thấy an tâm và thoải mái trong suốt quá trình điều trị.</span>
              </div>
              <h2>Trách nhiệm</h2>
              <div class="item-luu-y mb-3">
                <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
                <span class="desc-title">Chúng tôi luôn thực hiện công việc với trách nhiệm cao, cam kết mang đến kết quả điều trị chính xác, phù hợp và đảm bảo sức khỏe răng miệng lâu dài cho khách hàng.Nha khoa Phương Thành - nơi bạn tìm thấy sự an tâm, chất lượng và hiệu quả trong mỗi nụ cười!</span>
              </div>
            </div>
          </div> -->

          <lichlamviec></lichlamviec>
        </div>
    </div>
  <footerHome></footerHome>
</template>

<script>
import BlogGrid from "@/assets/json/blog-grid.json";
import {pageInfoModel} from "@/models/pageInfoModel";
import {Carousel, Slide} from "vue3-carousel";
import indexFiveDoctor from "@/assets/json/indexfivedoctor.json";
import indexfivespecialities from "@/assets/json/indexfivespecialities.json";
import 'vue3-carousel/dist/carousel.css'
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Navigation, Pagination } from 'swiper/modules';

export default {
  components: {
    Carousel,
    Slide,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      modules: [Navigation, Pagination],
      title: 'Đội ngũ bác sĩ',
      text: 'Trang chủ',
      text1: 'Đội ngũ bác sĩ',
      numberOfElement: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      model: [],
      activeTab: 0, // Tab mặc định là tab đầu tiên
      BlogGrid: BlogGrid,
      currentYear: '2024', // Năm mặc định được chọn
      pageInfo : pageInfoModel.baseJson(),
      indexFiveDoctor: indexFiveDoctor,
      indexfivespecialities: indexfivespecialities,
      itemsToShow: Math.min(indexFiveDoctor.length, 4), // Tính số lượng item hiển thị
        settings: {
            slidesToShow: this.itemsToShow,
            slidesToScroll: 1,
            dots: true,
            infinite: indexFiveDoctor.length > 4, // Chỉ cho phép lướt nếu có nhiều hơn 4 item
            // Các thiết lập khác
        },
        breakpoints: {
            578: {
                itemsToShow: 2,
                snapAlign: 'start',
            },
            // 700px and up
            768: {
                itemsToShow: 2,
                snapAlign: 'start',
            },
            // 1024 and up
            992: {
                itemsToShow: 3,
                snapAlign: 'start',
            },
            1200: {
                itemsToShow: 4,
                snapAlign: 'start',
            },
        },
    }
  },

  watch: {
    $route(to, from) {
      //  console.log("LOG WATCH DATA : ")
    //   this.getData();
    },
    perPage: {
      deep: true,
      handler(val){
        // this.getData();
      }
    },
    currentPage:{
      deep: true,
      handler(val){
        //   console.log("this.perpage", this.currentPage);
        // this.getData();
      }
    }
  },
  computed: {
    // Lọc danh sách bác sĩ có badge 'Hàm tháo lắp'
    filter1() {
        return this.indexFiveDoctor.filter(item => item.Badge === '1');
    },
    filter2() {
        return this.indexFiveDoctor.filter(item => item.Badge === '2');
    },
    filter3() {
        return this.indexFiveDoctor.filter(item => item.Badge === '3');
    },
    filter4() {
        return this.indexFiveDoctor.filter(item => item.Badge === '4');
    },
    filter5() {
        return this.indexFiveDoctor.filter(item => item.Badge === '5');
    }
},

  mounted() {
    // Chặn phím F12
    // window.addEventListener('keydown', (event) => {
    //   if (event.key === 'F12') {
    //     event.preventDefault();
    //   }
    // });

    // Chặn chuột phải
    // window.addEventListener('contextmenu', (event) => {
    //   event.preventDefault();
    // });
  },
  created() {
  },
  methods: {
    nextSlide1() {
        this.$refs.carousel1.next();
    },
    prevSlide1() {
        this.$refs.carousel1.prev();
    },
    nextSlide2() {
        this.$refs.carousel2.next();
    },
    prevSlide2() {
        this.$refs.carousel2.prev();
    },
    nextSlide3() {
        this.$refs.carousel3.next();
    },
    prevSlide3() {
        this.$refs.carousel3.prev();
    },
    nextSlide4() {
        this.$refs.carousel4.next();
    },
    prevSlide4() {
        this.$refs.carousel4.prev();
    },
    nextSlide5() {
        this.$refs.carousel5.next();
    },
    prevSlide5() {
        this.$refs.carousel5.prev();
    },
  },
}
</script>

<style>

</style>
