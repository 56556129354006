<template>
  <div class="container pt-60 pb-30">
    <div class="main-title text-center pb-30">Vì sao nên chọn niềng răng tại Nha Khoa Phương Thành?</div>

    <!-- Khung 2 -->
    <div class="box-luu-y box-secondary">
      <h2>Đội ngũ bác sĩ chỉnh nha chuyên sâu</h2>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Tránh hút thuốc vì điều này có thể làm chậm quá trình lành thương.</span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Đội ngũ bác sĩ chỉnh nha giàu kinh nghiệm về chỉnh nha, không ngừng cập nhật kiến thức chỉnh nha trong và ngoài nước.Là thành viên của Hội nắn chỉnh răng Việt Nam (VAO).</span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Chứng chỉ đào tạo chỉnh nha của Bệnh viện Răng hàm mặt Thành phố Hồ Chí Minh, khóa 2016-2017.</span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Hoàn thành khóa đào tạo chỉnh nha đương đại toàn diện của giáo sư Vittorio Cacciafesta, năm 2019.</span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Hoàn thành khóa đào tạo nội trú chỉnh nha tại Đại học Yonsei- Hàn Quốc, năm 2022.</span>
      </div>
      <h2>Trang thiết bị</h2>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Nha khoa Phương Thành đầu tư trang thiết bị hiện đại hàng đầu phục vụ cho chỉnh nha.</span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Máy chụp phim Xquang CT cone beam .</span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Máy scan răng iTero element 5D giúp lấy dấu răng kỹ thuật số chính xác, nhanh chóng.</span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Phần mềm dự đoán và mô phỏng kế hoạch di chuyển răng giúp dễ dàng hình dung kết quả điều trị.</span>
      </div>
      <h2>Vật liệu chỉnh nha </h2>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Hãng khay chỉnh nha trong suốt Invisalign, Angel Aligner: dẫn đầu thế giới về công nghệ, chất lượng và số lượng ca chỉnh nha khay trong
Dây cung, mắc cài sản xuất bởi hãng 3M (Mỹ), với 65 năm kinh nghiệm trong lĩnh vực sản xuất vật liệu chỉnh nha.</span>
      </div>
    </div>

  </div>

</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  data() {
    return {
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  created() {

  },
  methods: {

    toggleFavorite(item) {
      item.favorite = !item.favorite;
    },
  },
};
</script>

<style>

</style>
