<template>
  <div class="schedule">
    <div class="container card-lich">
      <div class="card-img-lich">
        <img src="@/assets/img/phuongthanh/core/lich.png" alt="">
      </div>
      <!-- <div class="custom-new-table">
          <div class="table-responsive">
              <table>
                <thead>
                  <tr>
                      <th></th>
                      <th>T2</th>
                      <th>T3</th>
                      <th class="closed">T4</th>
                      <th>T5</th>
                      <th>T6</th>
                      <th>T7</th>
                      <th>CN</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="morning">
                      <td class="font-bold">Sáng</td>
                      <td colspan="2">8h00 - 13h00</td>
                      <td rowspan="2" class="closed-title">Đóng cửa</td>
                      <td colspan="3">8h00 - 13h00</td>
                      <td>8h00 - 13h00</td>
                  </tr>
                  <tr>
                      <td class="font-bold">Chiều</td>
                      <td colspan="2">14h30 - 20h00</td>
                      <td colspan="3">14h30 - 20h00</td>
                      <td>14h30 - 17h00</td>
                  </tr>
                </tbody>
              </table>
          </div>
      </div> -->
    </div>

    <div class="intro-lich">
      <div>
        <img src="@/assets/img/phuongthanh/dichvu/text-nu-cuoi-cho-cuoc-song.png" alt="">
        <div class="form-dangky">
            <button id="form-page-submit" class="submit btn-dangky">
                <i class="fa-solid fa-calendar-days me-3"></i>
                Đăng ký tư vấn ngay
            </button>
        </div>
      </div>
    </div>
  </div>
  </template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
import IndexfiveFaq from "@/assets/json/indexfivefaq.json";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { EffectCoverflow, Pagination } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';

import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

export default {
components: {
    Pagination,
    Swiper,
    SwiperSlide,
},
data() {
    return {
    IndexfiveFaq: IndexfiveFaq,
    modules: [EffectCoverflow, Pagination],
    settings: {
        itemsToShow: 1,
        snapAlign: 'center',
    },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
        // 700px and up
        576: {
        itemsToShow: 2,
        snapAlign: 'start',
        },
        768: {
        itemsToShow: 2,
        snapAlign: 'start',
        },
        // 1024 and up
        992: {
        itemsToShow: 3,
        snapAlign: 'start',
        },
        1200: {
        itemsToShow: 3,
        snapAlign: 'start',
        },
    },

    url : `${process.env.VUE_APP_API_URL}files/view/`,
    urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
},
mounted() {
    this.$nextTick(() => {
    AOS.init();
    });
},
computed: {
},
methods: {

    next() {
        this.$refs.flickity.next();
        },

        previous() {
        this.$refs.flickity.previous();
        },

    },
};
</script>
  
<style scoped>
.schedule {
  background-color: #BE8B43;
  padding: 50px 20px;
  display: flex;
  justify-content: center;
  position: relative;
  min-height: 350px;
  margin-top: 200px;
  align-items: flex-end;
}

.card-lich{
  position: absolute;
  top: -170px;
}

.card-img-lich{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 990.98px) {
  .schedule {
    margin-top: 180px;
  }
  .card-lich{
    top: -130px;
  }
}

@media (max-width: 767.98px) {
  .schedule {
    margin-top: 150px;
    min-height: 300px;
  }
  .card-lich{
    top: -100px;
  }
}

@media (max-width: 575.98px) {
  .schedule {
    margin-top: 100px;
    min-height: 250px
  }
  .card-lich{
    top: -60px;
  }
}

.intro-lich{
  display: flex;
  justify-content: center;
  align-items: center;
}

.intro-lich p{
  font-size: clamp(1rem, -0.25rem + 4vw, 2.25rem);
  color: #fff;
  font-weight: bold;
}

.custom-new-table{
    border: 1px solid #E2E8F0;
    border-radius: 10px;
}

.custom-new-table .table-responsive {
    border-radius: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  font-size: 20px;
}

table thead {
    background-color: #F9F9F9;
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
    height: 50px;
}
table thead tr th{
  color: #5B2F39;
}

tbody tr:first-child {
    background: #fcf1c4;
}
tbody tr:last-child {
    background: #F9F9F9;
}
th, td {
  padding: 12px 22px;
}

th {
  /* background-color: #fff; */
  color: #000;
  font-weight: bold;
}

/* tbody td {
  background-color: #fcfafa;
} */

.closed {
  background-color: #5e2c35; /* Màu đỏ rượu */
  color: #fff;
  font-weight: bold;
  text-align: center;
  vertical-align: middle; /* Giúp nội dung căn giữa cả hàng */
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.closed-title {
  background-color: #5e2c35; /* Màu đỏ rượu */
  color: #fff;
  font-weight: bold;
  text-align: center;
  vertical-align: middle; /* Giúp nội dung căn giữa cả hàng */
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

tr:nth-child(odd) td {
  
}

/* tr:first-child th {
  padding-bottom: 20px;
} */

@media (max-width: 768px) {
  table {
    font-size: 14px;
  }

  th, td {
    padding: 8px 10px;
  }
}
  
</style>
  
  