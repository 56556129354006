<template>
  <div class="container pt-60 pb-30">
    <div class="main-title text-center pb-30">Hậu quả của rối loạn khớp thái dương hàm?</div>
    <div class="desc-title pb-20">
      Khi bệnh nhân bị rối loạn ở mức độ nhẹ thì chỉ gặp phải cản trở chức năng ăn một chút kèm theo cảm giác đau nhức, mỏi mệt. 
      Sau một thời gian bệnh có thể thuyên giảm mà không cần phải can thiệp gì.
    </div>
    <!-- Khung 2 -->
    <div class="box-luu-y box-secondary">
      <h2>Biến chứng:</h2>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Bệnh nhân bị suy nhược, thiếu hụt chất dinh dưỡng do hoạt động ăn nhai phải chịu nhiều áp lực như đau nhức hay mỏi cơ hàm. 
          Dần dần hình thành cảm giác biếng ăn.
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Cơn đau xuất hiện thường xuyên hơn, thậm chí ngay cả khi há miệng ra.
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Nếu không được điều trị kịp thời thì rối loạn khớp thái dương hàm có thể làm ảnh hưởng đến các phần xương khớp 
          có liên quan và xuất hiện viêm nhiễm khó điều trị.
        </span>
      </div>
      
    </div>

  </div>

</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  data() {
    return {
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  created() {

  },
  methods: {

    toggleFavorite(item) {
      item.favorite = !item.favorite;
    },
  },
};
</script>

<style>

</style>
