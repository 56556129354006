<template>
  <div class="main-wrapper home-ten">
    <layoutheader :class="{ 'header-space': showHeaderSpace }" ref="header" />
    <breadcrumb :title="title" :text="text" :text1="text1" />
    <div class="page-wrapper">
      <!-- Page Content -->
      <div class="container" style="padding-top: 30px ;">
        <div class="row">
          <div class="col-lg-8 col-md-12">
            <div class="blog-view">
              <div class="terms-content"  v-if="model && model.content">
                <div style="font-family: 'Poppins Light',Serif !important;" class="noidung" v-html="model.content">
                </div>
              </div>
            </div>
          </div>
          <blog-sidebar></blog-sidebar>
        </div>
        <div class="row mt-4"  v-if="list.length > 0" >

          <div class="col-md-12" >
            <div class="section-header-one section-header-slider text-center mb-2">
              <h2 class="section-title" style="color: #be8b43;"><span>Bạn có thể quan tâm</span></h2>
              <div>{{list}}</div>
            </div>
          </div>
        </div>
        <div class="row mb-3" >
          <div class="col-lg-4 col-md-4 col-sm-12 aos" data-aos="fade-up" v-for="(item, index) in list">
            <div class="relate-item">
              <div class="relate-img img-lienquan">
                <img
                    v-if="item.fileImage"
                    :src="item.fileImage.urlFile"
                    alt="">
              </div>
              <div class="relate-content">
                <div class="relate-title">
                  <p style="
                    font-size: 15px;
                    display: -webkit-box !important;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    font-family: 'Poppins Bold';
                    margin-bottom: 0px;"
                     class=""
                  >
                    {{ item.name }}
                  </p>
                </div>
                <div class="relate-desc">
                  <p>
                   {{item.Describe}}
                  </p>
                </div>
                <div class="relate-btn">
                  <b-button
                      class="btn-chitiet"
                  >
                  Xem thêm
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->
    </div>
  </div>
  <footerHome></footerHome>
</template>
<script>
import BlogSidebar from "@/views/News/baiviet/newSidebar.vue";
export default {
  components: {BlogSidebar},
  data() {
    return {
      title: 'Terms & Condition',
      text: 'Trang chủ',
      text1: 'Terms & Condition',
      model: null,
      showHeaderSpace: false,
      list: [],
      url : `${process.env.VUE_APP_API_URL}files/view/`,
    }
  },
  watch: {
    $route(to, from) {
      var data = localStorage.getItem('menu')
      if (data != null) {
        console.log("LOG DATA :", data)
        var dataMenu = JSON.parse(data);
        if (dataMenu != null) {
          console.log("LOG DATA MENU :", dataMenu.label)
          this.menu = dataMenu.label;
        }
      }
    }
  },
  mounted() {
    this.getData();
  },

  methods: {
    async getData(){
      const params = {
        id: this.$route.params.id
      };
    //  console.log("LOG MODEL :", this.model)
      await this.$store.dispatch("newsStore/getById",params ).then((res) => {
        if (res.data != null && res.data.data != null && Array.isArray(res.data.data))
        {
          //    console.log("LA ARRAY : ", res.data)
          this.isArray = true;
          this.model = res.data.data;
          this.title = (res.data != null && res.data.data.length > 0) ? res.data.data[0].menu.name : "";
          this.text1= (res.data != null && res.data.data.length > 0) ? res.data.data[0].menu.name : "";
          this.totalRows = res.data.totalRows;
          this.numberOfElement = res.data.data.length;
        }else if (res.data != null) {
          //    console.log("KHÔNG LA ARRAY 123: ", res)
          this.isArray = false;
          this.model = res.data;
          this.title = this.model != null ? this.model.name : "";
          this.text1 = (this.model != null && this.model.menu) ? this.model.menu.name : "";
          // if (this.model.content && this.model.content != null) {
          //   this.model.content = this.model.content.replaceAll("http://phuongthanh.goldenkeysoft.com/api/v1/files/view/", this.url)
          // }

          // this.model = res.data;
          // this.list = res.data.listNews;
          // console.log("LOG NE : ", this.list)
          // if(this.model.content && this.model.content != null){
          //   this.model.content = this.model.content.replaceAll("https://localhost:5001/api/v1/filesminio/view" , this.url)
          //   this.title = this.model != null ? this.model.name : "";
          //   this.text1= (this.model != null && this.model.menu) ? this.model.menu.name : "";
          // }
          //    console.log("BAI VIET: ", res.data);
        }
      })
    },
  },

  created() {
    console.log("LOG CREATED CHI TIẾT :")
  }
}
</script>

<style>

.noidung{
  text-align: justify;
  font-family: 'Poppins Light',Serif !important;
  font-weight: 500;
}

.noidung .image{
  text-align: center;
  width: 100% !important;
}

.content-title{
  text-align: center;
}

</style>
