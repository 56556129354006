<template>
    <div class="container pt-60 pb-60">
      <div class="row">
        <div class="col-md-12 who-trongrang">
          <div>
            <div class="main-title pb-30">Khi Nào Nên Làm Răng Veneer?</div>
            <div class="desc-title">Veneer là một giải pháp thẩm mỹ tuyệt vời, nhưng không phải ai cũng phù hợp. Dưới đây là một số trường hợp nên xem xét làm răng Veneer:</div>
            <div class="card-who-trongrang pt-30">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Răng bị đổi màu: Veneer có thể che phủ các vết ố vàng, xỉn màu, hoặc đổi màu do thuốc kháng sinh 
                tetracycline mà các phương pháp tẩy trắng thông thường không hiệu quả.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Răng bị sứt mẻ hoặc gãy: Veneer có thể phục hồi hình dạng của răng bị sứt mẻ hoặc gãy nhẹ, giúp răng trở nên hoàn chỉnh và thẩm mỹ hơn.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Khoảng cách giữa các răng: Veneer có thể đóng kín các khoảng cách nhỏ giữa các răng, tạo ra nụ cười đều đặn hơn.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Răng lệch lạc nhẹ: Trong một số trường hợp, veneer có thể được sử dụng để chỉnh sửa răng bị lệch lạc nhẹ, thay thế cho niềng răng. Tuy nhiên, 
                nếu răng lệch lạc nghiêm trọng, niềng răng vẫn là phương pháp điều trị tốt nhất.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Răng có hình dạng bất thường: Veneer có thể cải thiện hình dạng của răng ngắn, răng nhỏ, hoặc răng có hình dạng không đều.
              </div>
            </div>

            <div class="desc-title pt-30">Tuy nhiên, bạn KHÔNG NÊN làm răng Veneer trong các trường hợp sau:</div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Vệ sinh răng miệng kém: Nếu bạn không duy trì vệ sinh răng miệng tốt, vi khuẩn có thể tấn công đường viền giữa veneer và răng thật, gây sâu răng và các vấn đề khác.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Nghiến răng: Lực nghiến răng có thể làm vỡ hoặc bong tróc veneer. Nếu bạn nghiến răng, bạn cần điều trị dứt điểm tình trạng này trước khi làm veneer hoặc cân nhắc lựa chọn bọc răng sứ.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Men răng quá mỏng hoặc yếu: Việc mài mòn men răng để dán veneer có thể làm suy yếu răng hơn nữa.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Sâu răng hoặc bệnh nha chu: Bạn cần điều trị triệt để các vấn đề này trước khi làm veneer.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Răng bị hư tổn nặng: Trong trường hợp răng bị hư tổn nặng, bọc răng sứ là lựa chọn phù hợp hơn veneer.
              </div>
            </div>
          </div>


        </div>
<!--        <div class="col-md-6">-->
<!--            <div class="img-trongrang">-->
<!--                <img src="@/assets/img/phuongthanh/dichvu/who.png" alt="" style="max-height: 500px;">-->
<!--            </div>-->
<!--        </div>-->
      </div>
    </div>



</template>

<script>
  import { ref } from "vue";
  const currentDate = ref(new Date());
  import AOS from "aos";
  import "aos/dist/aos.css";
  import 'vue3-carousel/dist/carousel.css'
  export default {
    components: {
    },
    data() {
      return {
        startdate: currentDate,
        list: [],
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    props: {
      header: { type : Object}
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
    watch:{
      '$props':{
        handler: function (val) {
          this.list = val.header;
        //  console.log("LOG WATCH LIST ", this.list )
        },
        deep: true
      }
    },
    methods: {
      submitForm() {
        this.$router.push("/search-2");
      },
    },
  };
</script>
<style>
</style>
