<template>
    <div class="container pt-60 pb-60">
      <div class="row">
        <div class="col-md-6 who-trongrang">
          <div>
            <div class="main-title pb-30">Khi nào cha mẹ nên đưa con đi khám nha khoa?</div>
            <div class="desc-title">
              Việc đưa trẻ đi khám nha khoa định kỳ là một phần quan trọng trong việc chăm sóc sức khỏe răng miệng cho bé. 
              Tuy nhiên, nhiều bậc phụ huynh vẫn còn băn khoăn không biết nên đưa con đi khám từ khi nào. Thời điểm lý tưởng 
              để đưa trẻ đi khám nha khoa lần đầu là khi chiếc răng sữa đầu tiên mọc hoặc khi trẻ được khoảng 1 tuổi.
            </div>
            <div class="card-who-trongrang pt-30">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Phát hiện sớm các vấn đề: Việc khám răng định kỳ giúp phát hiện sớm các vấn đề về răng miệng như sâu răng, 
                viêm lợi, hoặc các bất thường về cấu trúc răng hàm mặt. Khi phát hiện sớm, các vấn đề này sẽ dễ điều trị 
                hơn và ít gây đau đớn cho trẻ.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Tạo thói quen khám răng định kỳ: Việc đưa trẻ đi khám răng từ nhỏ sẽ giúp trẻ làm quen với môi trường nha 
                khoa, không còn cảm thấy sợ hãi khi đến nha sĩ. Từ đó, trẻ sẽ hình thành thói quen khám răng định kỳ, 
                đảm bảo sức khỏe răng miệng lâu dài.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Tư vấn về cách chăm sóc răng miệng: Bác sĩ nha khoa sẽ hướng dẫn cha mẹ cách chăm sóc răng miệng đúng cách 
                cho trẻ, bao gồm cách đánh răng, sử dụng chỉ nha khoa, chế độ ăn uống,...
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Các trường hợp trên sẽ gây ảnh hưởng đến thẩm mỹ, sự cân đối của khuôn mặt, chức năng ăn nhai, tăng nguy cơ mắc các bệnh lý răng miệng do khó vệ sinh răng. Một số trường hợp còn gây ảnh hưởng đến phát âm và bệnh lý thái dương hàm.
              </div>
            </div>
          </div>


        </div>
        <div class="col-md-6">
            <div class="img-trongrang">
                <img src="@/assets/img/phuongthanh/dichvu/who.png" alt="" style="max-height: 500px;">
            </div>
        </div>
      </div>
    </div>



</template>

<script>
  import { ref } from "vue";
  const currentDate = ref(new Date());
  import AOS from "aos";
  import "aos/dist/aos.css";
  import 'vue3-carousel/dist/carousel.css'
  export default {
    components: {
    },
    data() {
      return {
        startdate: currentDate,
        list: [],
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    props: {
      header: { type : Object}
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
    watch:{
      '$props':{
        handler: function (val) {
          this.list = val.header;
        //  console.log("LOG WATCH LIST ", this.list )
        },
        deep: true
      }
    },
    methods: {
      submitForm() {
        this.$router.push("/search-2");
      },
    },
  };
</script>
<style>
</style>
