<template>
  <div class="container pt-60 pb-60">
    <div class="row align-items-center">
      <div style="text-align: center; margin-bottom: 20px;">
        <div class="main-title pb-30">
          Nha khoa thẩm mỹ
        </div>
        <div class="decs-intro-doctor">
          Nha khoa thẩm mỹ là một chuyên ngành của nha khoa tập trung vào việc cải thiện vẻ ngoài của hàm răng, nướu
          và/hoặc khớp cắn của bạn. Nó không chỉ quan tâm đến sức khỏe răng miệng mà còn chú trọng đến tính hài hòa,
          thẩm mỹ của nụ cười, khuôn mặt.
        </div>
      </div>
    </div>
  </div>



</template>

<script>
import { ref } from "vue";
const currentDate = ref(new Date());
import AOS from "aos";
import "aos/dist/aos.css";
import indexfivespecialities from "@/assets/json/indexfivespecialities.json";
import 'vue3-carousel/dist/carousel.css'
export default {
  components: {
  },
  data() {
    return {
      startdate: currentDate,
      indexfivespecialities: indexfivespecialities,
      list: [],
      url: `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile: `${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  props: {
    header: { type: Object }
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  watch: {
    '$props': {
      handler: function (val) {
        this.list = val.header;
        //  console.log("LOG WATCH LIST ", this.list )
      },
      deep: true
    }
  },
  methods: {
    submitForm() {
      this.$router.push("/search-2");
    },
  },
};
</script>
<style></style>
