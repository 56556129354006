<template>
  <section class="pricing-section pricing-section-ten pt-60 pb-60">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <div class="row">
            <div class="col-md-12">
              <div class="section-header-slider">
                <div class="sub-title">Hình ảnh</div>
                <div class="pb-30" style="display: flex; align-items: center">
                  <span class="main-title me-4">Không gian Nha Khoa</span>
                  <i class="fa fa-solid fa-arrow-right btn-right-chitiet"></i>
                </div>
              </div>
            </div>
            <div class="col-md-6 slider-khonggian-first">
              <img src="@/assets/img/phuongthanh/khonggian/1.jpg" alt="">
            </div>
            <div class="col-md-6 slider-khonggian-first">
              <img src="@/assets/img/phuongthanh/khonggian/2.jpg" alt="">
            </div>
          </div>
        </div>
        <div class="col-md-4 slider-khonggian">
          <img src="@/assets/img/phuongthanh/khonggian/3.jpg" alt="">
        </div>
      </div>
      <div class="row">
        <div class="col-md-8 slider-khonggian">
          <img src="@/assets/img/phuongthanh/khonggian/4.jpg" alt="">
        </div>
        <div class="col-md-4 slider-khonggian">
          <img src="@/assets/img/phuongthanh/khonggian/5.jpg" alt="">
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-md-12 text-center">
          <div class="section-header-one section-header-slider">
            <h2 class="section-title" style="margin-bottom: 0px;"><span>Hình ảnh</span> không gian nha khoa</h2>
          </div>
        </div>
      </div>
      <div class="">
        <Carousel ref="" v-bind="settings" :breakpoints="breakpoints" :wrap-around="true" :transition="500" :autoplay="5000">
          <Slide v-for="item in listImage" :key="item">
            <div class="list-card-nk">
              <div class="item-doctor">
                <div class="img-library">
                  <div style="height: 100%;" class="img-doctor-sub">
                    <img
                      :src="item.fileImage.urlFile"
                      class=""
                      style="height: 100%; width: 100%;"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Slide>
        </Carousel>
      </div> -->

    </div>
  </section>
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
import {Carousel, Pagination, Slide} from "vue3-carousel";
import 'vue3-carousel/dist/carousel.css'
export default {
  components: {

    Pagination,
  },
  props: {
    clinicSpace: { type : Object}
  },
  data() {
    return {
      settings: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      breakpoints: {
        // 700px and up
        576: {
          itemsToShow: 2,
          snapAlign: 'start',
        },
        768: {
          itemsToShow: 2,
          snapAlign: 'start',
        },
        // 1024 and up
        992: {
          itemsToShow: 3,
          snapAlign: 'start',
        },
        1200: {
          itemsToShow: 3,
          snapAlign: 'start',
        },
      },
      listImage: [],
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  watch:{
    '$props':{
      handler: function (val) {
        this.listImage = val.clinicSpace;
      },
      deep: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  computed: {
  },
  methods: {


    next() {
      this.$refs.flickity.next();
    },

    previous() {
      this.$refs.flickity.previous();
    },
  },
};
</script>

<style>


</style>

