<template>
    <div class="main-wrapper home-ten">
        <layoutheader :class="{ 'header-space': showHeaderSpace }" ref="header" />
        <div id="primary" class="page-chitietbacsi">
			<div class="site-main" role="main">
				<div class="container" >
                    <div class="row" >
                        <div class="col-lg-8 col-sm-7 col-xs-12">
                            <div class="gioi-thieu">
                                <h2>GIỚI THIỆU</h2>
                                <p><span style="font-weight: 400;">Bác sĩ Trần Châu Bảo Phúc là bác sĩ Chuyên khoa Cấy ghép Implant tại <a href="https://elitedental.com.vn/gioi-thieu-elite-dental" data-wpel-link="internal">Elite Dental</a>. Bác sĩ Bảo Phúc sinh ra và lớn lên tại thành phố biển Phan Thiết cùng truyền thống gia đình theo ngành Y, nên ước mơ trở thành bác sĩ đã được hình thành từ những ngày bé. Sau này với niềm đam mê với giải phẫu, bác sĩ đã chọn con đường trở thành bác sĩ phẫu thuật, chuyên khoa <a href="https://elitedental.com.vn/trong-rang-implant" data-wpel-link="internal">Cấy ghép Implant</a>.</span></p>
                                <p>Không ngừng nỗ lực và phát triển nâng cao chuyên môn cũng như tay nghề của mình, bác sĩ Bảo Phúc liên tục nghiên cứu và tham gia nhiều khóa học chuyên sâu với hy vọng phục hồi được nụ cười đã mất cho bệnh nhân của mình.</p>
                            </div>
							<div class="chuyen-mon">
					            <h2>CHUYÊN MÔN, HỌC VẤN,<br>
                                KINH NGHIỆM CÔNG TÁC</h2>
                                <p><noscript><img decoding="async" class="alignnone size-full wp-image-2767" src="https://elitedental.com.vn/wp-content/uploads/2022/04/checkmark-detail-post.png" alt="" width="24" height="25" /></noscript><img decoding="async" class="alignnone size-full wp-image-2767 ls-is-cached lazyloaded" src="https://elitedental.com.vn/wp-content/uploads/2022/04/checkmark-detail-post.png" data-src="https://elitedental.com.vn/wp-content/uploads/2022/04/checkmark-detail-post.png" alt="" width="24" height="25">Tốt nghiệp khoa RHM trường Đại học Y dược Tp.HCM năm 2016.</p>
                                <p><noscript><img decoding="async" class="alignnone size-full wp-image-2767" src="https://elitedental.com.vn/wp-content/uploads/2022/04/checkmark-detail-post.png" alt="" width="24" height="25" /></noscript><img decoding="async" class="alignnone size-full wp-image-2767 ls-is-cached lazyloaded" src="https://elitedental.com.vn/wp-content/uploads/2022/04/checkmark-detail-post.png" data-src="https://elitedental.com.vn/wp-content/uploads/2022/04/checkmark-detail-post.png" alt="" width="24" height="25">Chủ tịch ITI Study Club Gia Định.</p>
                                <p><noscript><img decoding="async" class="alignnone size-full wp-image-2767" src="https://elitedental.com.vn/wp-content/uploads/2022/04/checkmark-detail-post.png" alt="" width="24" height="25" /></noscript><img decoding="async" class="alignnone size-full wp-image-2767 ls-is-cached lazyloaded" src="https://elitedental.com.vn/wp-content/uploads/2022/04/checkmark-detail-post.png" data-src="https://elitedental.com.vn/wp-content/uploads/2022/04/checkmark-detail-post.png" alt="" width="24" height="25">Nguyên phó khoa Răng Hàm Mặt Bệnh viện EMCAS.</p>
                                <p><noscript><img decoding="async" class="alignnone size-full wp-image-2767" src="https://elitedental.com.vn/wp-content/uploads/2022/04/checkmark-detail-post.png" alt="" width="24" height="25" /></noscript><img decoding="async" class="alignnone size-full wp-image-2767 ls-is-cached lazyloaded" src="https://elitedental.com.vn/wp-content/uploads/2022/04/checkmark-detail-post.png" data-src="https://elitedental.com.vn/wp-content/uploads/2022/04/checkmark-detail-post.png" alt="" width="24" height="25">Tốt nghiệp chương trình Master Clinician in Implant Dentistry của Học viện gIDE và Đại học Loma Linda (USA) năm 2018.</p>
                                <p><noscript><img decoding="async" class="alignnone size-full wp-image-2767" src="https://elitedental.com.vn/wp-content/uploads/2022/04/checkmark-detail-post.png" alt="" width="24" height="25" /></noscript><img decoding="async" class="alignnone size-full wp-image-2767 ls-is-cached lazyloaded" src="https://elitedental.com.vn/wp-content/uploads/2022/04/checkmark-detail-post.png" data-src="https://elitedental.com.vn/wp-content/uploads/2022/04/checkmark-detail-post.png" alt="" width="24" height="25">Tham dự chương trình Clinical Masters in Bone Grafting and Soft Tissue Management – Prof. Fouad Khoury (Đức).</p>
                                <p><noscript><img decoding="async" class="alignnone size-full wp-image-2767" src="https://elitedental.com.vn/wp-content/uploads/2022/04/checkmark-detail-post.png" alt="" width="24" height="25" /></noscript><img decoding="async" class="alignnone size-full wp-image-2767 ls-is-cached lazyloaded" src="https://elitedental.com.vn/wp-content/uploads/2022/04/checkmark-detail-post.png" data-src="https://elitedental.com.vn/wp-content/uploads/2022/04/checkmark-detail-post.png" alt="" width="24" height="25">2018: Hoàn thành khóa học nâng cao về “Implant Zygoma” – Học viện ILABEO (Brazil).</p>
                                <p><noscript><img decoding="async" class="alignnone size-full wp-image-2767" src="https://elitedental.com.vn/wp-content/uploads/2022/04/checkmark-detail-post.png" alt="" width="24" height="25" /></noscript><img decoding="async" class="alignnone size-full wp-image-2767 ls-is-cached lazyloaded" src="https://elitedental.com.vn/wp-content/uploads/2022/04/checkmark-detail-post.png" data-src="https://elitedental.com.vn/wp-content/uploads/2022/04/checkmark-detail-post.png" alt="" width="24" height="25">2019: Hoàn thành khóa học “Phẫu thuật tái tạo mô mềm quanh Implant” – GS Giovanni Zucchelli (Ý).</p>
                                <p><noscript><img decoding="async" class="alignnone size-full wp-image-2767" src="https://elitedental.com.vn/wp-content/uploads/2022/04/checkmark-detail-post.png" alt="" width="24" height="25" /></noscript><img decoding="async" class="alignnone size-full wp-image-2767 ls-is-cached lazyloaded" src="https://elitedental.com.vn/wp-content/uploads/2022/04/checkmark-detail-post.png" data-src="https://elitedental.com.vn/wp-content/uploads/2022/04/checkmark-detail-post.png" alt="" width="24" height="25">2020: Hoàn thành khóa học “Ghép xương theo chiều đứng” – GS Itsvan Urban (Hungary).</p>
                                <p><noscript><img decoding="async" class="alignnone size-full wp-image-2767" src="https://elitedental.com.vn/wp-content/uploads/2022/04/checkmark-detail-post.png" alt="" width="24" height="25" /></noscript><img decoding="async" class="alignnone size-full wp-image-2767 ls-is-cached lazyloaded" src="https://elitedental.com.vn/wp-content/uploads/2022/04/checkmark-detail-post.png" data-src="https://elitedental.com.vn/wp-content/uploads/2022/04/checkmark-detail-post.png" alt="" width="24" height="25">Tham dự khóa học “Zygoma nâng cao” – GS Carlos Aparacio (Tây Ban Nha).</p>
                                <p><noscript><img decoding="async" class="alignnone size-full wp-image-2767" src="https://elitedental.com.vn/wp-content/uploads/2022/04/checkmark-detail-post.png" alt="" width="24" height="25" /></noscript><img decoding="async" class="alignnone size-full wp-image-2767 ls-is-cached lazyloaded" src="https://elitedental.com.vn/wp-content/uploads/2022/04/checkmark-detail-post.png" data-src="https://elitedental.com.vn/wp-content/uploads/2022/04/checkmark-detail-post.png" alt="" width="24" height="25">Chứng chỉ “Thiết kế máng hướng dẫn phẫu thuật” – BS. Jan Paulics, 3Shape Center (Singapore).</p>
                                <p><noscript><img decoding="async" class="alignnone size-full wp-image-2767" src="https://elitedental.com.vn/wp-content/uploads/2022/04/checkmark-detail-post.png" alt="" width="24" height="25" /></noscript><img decoding="async" class="alignnone size-full wp-image-2767 ls-is-cached lazyloaded" src="https://elitedental.com.vn/wp-content/uploads/2022/04/checkmark-detail-post.png" data-src="https://elitedental.com.vn/wp-content/uploads/2022/04/checkmark-detail-post.png" alt="" width="24" height="25">Chứng chỉ “Implant kỹ thuật số” – GS.Byung-Ho Choi, Dio Center (Hàn Quốc).</p>
                                <p><noscript><img decoding="async" class="alignnone size-full wp-image-2767" src="https://elitedental.com.vn/wp-content/uploads/2022/04/checkmark-detail-post.png" alt="" width="24" height="25" /></noscript><img decoding="async" class="alignnone size-full wp-image-2767 ls-is-cached lazyloaded" src="https://elitedental.com.vn/wp-content/uploads/2022/04/checkmark-detail-post.png" data-src="https://elitedental.com.vn/wp-content/uploads/2022/04/checkmark-detail-post.png" alt="" width="24" height="25">Chứng chỉ “Phục hình toàn hàm ProArch/All-on-X – TS.Trần Hùng Lâm, THL Academy (TP.HCM).</p>
                                <p><noscript><img decoding="async" class="alignnone size-full wp-image-2767" src="https://elitedental.com.vn/wp-content/uploads/2022/04/checkmark-detail-post.png" alt="" width="24" height="25" /></noscript><img decoding="async" class="alignnone size-full wp-image-2767 ls-is-cached lazyloaded" src="https://elitedental.com.vn/wp-content/uploads/2022/04/checkmark-detail-post.png" data-src="https://elitedental.com.vn/wp-content/uploads/2022/04/checkmark-detail-post.png" alt="" width="24" height="25">Chứng chỉ “Phục hình Implant A-Z” – TS. Trần Hùng Lâm, THL Academy (TP.HCM).</p>
				            </div>
						</div>
                        <div class="col-lg-4 col-sm-5 col-xs-12 col-sticky">
                            <div class="box-doctor">
                                <div class="box-thumb">
                                    <img width="292" height="300" src="https://elitedental.com.vn/wp-content/uploads/2023/08/hinh-anh-bs-tran-chau-bao-phuc-292x300.png" class="attachment-medium size-medium wp-post-image" alt="" decoding="async" fetchpriority="high" srcset="https://elitedental.com.vn/wp-content/uploads/2023/08/hinh-anh-bs-tran-chau-bao-phuc-292x300.png 292w, https://elitedental.com.vn/wp-content/uploads/2023/08/hinh-anh-bs-tran-chau-bao-phuc.png 506w" sizes="100vw">				</div>
                                <div class="box-description">
                                    <div class="box-name">
                                        <h2>Trần Châu Bảo Phúc</h2>
                                    </div>
                                    <div class="box-chuc-vu">
                                        Giám đốc chuyên môn Trung tâm nha khoa Elite Dental Metropole. <br>Bác sĩ chuyên khoa Cấy ghép Implant					</div>
                                </div>
                                <div class="box-contact">
                                    <button>
                                        <a href="/lien-he" data-wpel-link="internal">
                                            ĐẶT HẸN TƯ VẤN NGAY
                                        </a>
                                    </button>
                                </div>
                            </div>
                        </div>
	                </div>
	                <div class="list-post-of-doctor">
						<h2>Bài viết đã tham vấn</h2>
			            <div class="row blog-slider-mobile">
							<div class="col-lg-4 col-sm-4 col-12">
						        <div class="blog-item">
                                    <div class="blog-thumb">
                                        <a href="https://elitedental.com.vn/lam-rang-gia-nguyen-ham-thao-lap-gia-bao-nhieu-co-nen-khong.html" data-wpel-link="internal">
                                            <noscript><img width="600" height="400" src="https://elitedental.com.vn/wp-content/uploads/2024/07/lam-rang-gia-nguyen-ham-thao-lap-e1720698656795.jpg" class="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt="Làm răng giả nguyên hàm tháo lắp" decoding="async" srcset="https://elitedental.com.vn/wp-content/uploads/2024/07/lam-rang-gia-nguyen-ham-thao-lap-e1720698656795.jpg 600w, https://elitedental.com.vn/wp-content/uploads/2024/07/lam-rang-gia-nguyen-ham-thao-lap-e1720698656795-300x200.jpg 300w" sizes="100vw" /></noscript><img width="600" height="400" src="https://elitedental.com.vn/wp-content/uploads/2024/07/lam-rang-gia-nguyen-ham-thao-lap-e1720698656795.jpg" data-src="https://elitedental.com.vn/wp-content/uploads/2024/07/lam-rang-gia-nguyen-ham-thao-lap-e1720698656795.jpg" class="attachment-post-thumbnail size-post-thumbnail wp-post-image lazyloaded" alt="Làm răng giả nguyên hàm tháo lắp" decoding="async" data-srcset="https://elitedental.com.vn/wp-content/uploads/2024/07/lam-rang-gia-nguyen-ham-thao-lap-e1720698656795.jpg 600w, https://elitedental.com.vn/wp-content/uploads/2024/07/lam-rang-gia-nguyen-ham-thao-lap-e1720698656795-300x200.jpg 300w" data-sizes="100vw" sizes="100vw" srcset="https://elitedental.com.vn/wp-content/uploads/2024/07/lam-rang-gia-nguyen-ham-thao-lap-e1720698656795.jpg 600w, https://elitedental.com.vn/wp-content/uploads/2024/07/lam-rang-gia-nguyen-ham-thao-lap-e1720698656795-300x200.jpg 300w">								</a>
                                    </div>
                                    <div class="blog-content">
                                        <div class="blog-title">
                                            <h3><a href="https://elitedental.com.vn/lam-rang-gia-nguyen-ham-thao-lap-gia-bao-nhieu-co-nen-khong.html" data-wpel-link="internal">Làm răng giả nguyên hàm tháo lắp giá bao nhiêu? Có nên không?</a></h3>
                                        </div>
                                        <div class="blog-desc">
                                            Làm răng giả nguyên hàm tháo lắp là phương pháp được nhiều người quan tâm, đặc biệt...								</div>
                                        <div class="blog-button">
                                            <a href="https://elitedental.com.vn/lam-rang-gia-nguyen-ham-thao-lap-gia-bao-nhieu-co-nen-khong.html" data-wpel-link="internal">Xem thêm</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
							<div class="col-lg-4 col-sm-4 col-12">
                                <div class="blog-item">
                                    <div class="blog-thumb">
                                        <a href="https://elitedental.com.vn/viet-kieu-ve-viet-nam-trong-rang-implant-can-luu-y-gi.html" data-wpel-link="internal">
                                            <noscript><img width="600" height="399" src="https://elitedental.com.vn/wp-content/uploads/2024/02/elite-dental-metropole-co-the-giao-tiep-duoc-nhieu-ngon-ngu.jpg" class="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt="Elite Dental Metropole có thể giao tiếp được nhiều ngôn ngữ" decoding="async" srcset="https://elitedental.com.vn/wp-content/uploads/2024/02/elite-dental-metropole-co-the-giao-tiep-duoc-nhieu-ngon-ngu.jpg 600w, https://elitedental.com.vn/wp-content/uploads/2024/02/elite-dental-metropole-co-the-giao-tiep-duoc-nhieu-ngon-ngu-300x200.jpg 300w" sizes="100vw" /></noscript><img width="600" height="399" src="https://elitedental.com.vn/wp-content/uploads/2024/02/elite-dental-metropole-co-the-giao-tiep-duoc-nhieu-ngon-ngu.jpg" data-src="https://elitedental.com.vn/wp-content/uploads/2024/02/elite-dental-metropole-co-the-giao-tiep-duoc-nhieu-ngon-ngu.jpg" class="attachment-post-thumbnail size-post-thumbnail wp-post-image lazyloaded" alt="Elite Dental Metropole có thể giao tiếp được nhiều ngôn ngữ" decoding="async" data-srcset="https://elitedental.com.vn/wp-content/uploads/2024/02/elite-dental-metropole-co-the-giao-tiep-duoc-nhieu-ngon-ngu.jpg 600w, https://elitedental.com.vn/wp-content/uploads/2024/02/elite-dental-metropole-co-the-giao-tiep-duoc-nhieu-ngon-ngu-300x200.jpg 300w" data-sizes="100vw" sizes="100vw" srcset="https://elitedental.com.vn/wp-content/uploads/2024/02/elite-dental-metropole-co-the-giao-tiep-duoc-nhieu-ngon-ngu.jpg 600w, https://elitedental.com.vn/wp-content/uploads/2024/02/elite-dental-metropole-co-the-giao-tiep-duoc-nhieu-ngon-ngu-300x200.jpg 300w">								</a>
                                    </div>
                                    <div class="blog-content">
                                        <div class="blog-title">
                                            <h3><a href="https://elitedental.com.vn/viet-kieu-ve-viet-nam-trong-rang-implant-can-luu-y-gi.html" data-wpel-link="internal">[Giải đáp] Cô Chú Việt kiều về Việt Nam trồng răng Implant cần lưu ý gì?</a></h3>
                                        </div>
                                        <div class="blog-desc">
                                            Hiện nay nhiều Cô Chú Việt kiều có xu hướng về Việt Nam thăm quê hương, du...								</div>
                                        <div class="blog-button">
                                            <a href="https://elitedental.com.vn/viet-kieu-ve-viet-nam-trong-rang-implant-can-luu-y-gi.html" data-wpel-link="internal">Xem thêm</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
							<div class="col-lg-4 col-sm-4 col-12">
                                <div class="blog-item">
                                    <div class="blog-thumb">
                                        <a href="https://elitedental.com.vn/giai-dap-trong-rang-su-khong-co-chan-rang-duoc-khong.html" data-wpel-link="internal">
                                            <noscript><img width="600" height="510" src="https://elitedental.com.vn/wp-content/uploads/2023/12/trong-rang-su-bi-mat-chan-rang.jpg" class="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt="trồng răng sứ bị mất chân răng" decoding="async" srcset="https://elitedental.com.vn/wp-content/uploads/2023/12/trong-rang-su-bi-mat-chan-rang.jpg 600w, https://elitedental.com.vn/wp-content/uploads/2023/12/trong-rang-su-bi-mat-chan-rang-300x255.jpg 300w" sizes="100vw" /></noscript><img width="600" height="510" src="https://elitedental.com.vn/wp-content/uploads/2023/12/trong-rang-su-bi-mat-chan-rang.jpg" data-src="https://elitedental.com.vn/wp-content/uploads/2023/12/trong-rang-su-bi-mat-chan-rang.jpg" class="attachment-post-thumbnail size-post-thumbnail wp-post-image lazyloaded" alt="trồng răng sứ bị mất chân răng" decoding="async" data-srcset="https://elitedental.com.vn/wp-content/uploads/2023/12/trong-rang-su-bi-mat-chan-rang.jpg 600w, https://elitedental.com.vn/wp-content/uploads/2023/12/trong-rang-su-bi-mat-chan-rang-300x255.jpg 300w" data-sizes="100vw" sizes="100vw" srcset="https://elitedental.com.vn/wp-content/uploads/2023/12/trong-rang-su-bi-mat-chan-rang.jpg 600w, https://elitedental.com.vn/wp-content/uploads/2023/12/trong-rang-su-bi-mat-chan-rang-300x255.jpg 300w">								</a>
                                    </div>
                                    <div class="blog-content">
                                        <div class="blog-title">
                                            <h3><a href="https://elitedental.com.vn/giai-dap-trong-rang-su-khong-co-chan-rang-duoc-khong.html" data-wpel-link="internal">[Giải đáp] Trồng răng sứ không có chân răng được không?</a></h3>
                                        </div>
                                        <div class="blog-desc">
                                            Khi mất răng, người bệnh nên phục hình răng càng sớm càng tốt. Tuy nhiên đối với...								</div>
                                        <div class="blog-button">
                                            <a href="https://elitedental.com.vn/giai-dap-trong-rang-su-khong-co-chan-rang-duoc-khong.html" data-wpel-link="internal">Xem thêm</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
							<div class="col-lg-4 col-sm-4 col-12">
                                <div class="blog-item">
                                    <div class="blog-thumb">
                                        <a href="https://elitedental.com.vn/gay-rang-cua-phai-lam-sao-cach-khac-phuc-hieu-qua-tham-my.html" data-wpel-link="internal">
                                            <noscript><img width="600" height="357" src="https://elitedental.com.vn/wp-content/uploads/2023/12/gay-rang-cua.jpg" class="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt="gãy răng cửa" decoding="async" srcset="https://elitedental.com.vn/wp-content/uploads/2023/12/gay-rang-cua.jpg 600w, https://elitedental.com.vn/wp-content/uploads/2023/12/gay-rang-cua-300x179.jpg 300w" sizes="100vw" /></noscript><img width="600" height="357" src="https://elitedental.com.vn/wp-content/uploads/2023/12/gay-rang-cua.jpg" data-src="https://elitedental.com.vn/wp-content/uploads/2023/12/gay-rang-cua.jpg" class="attachment-post-thumbnail size-post-thumbnail wp-post-image lazyloaded" alt="gãy răng cửa" decoding="async" data-srcset="https://elitedental.com.vn/wp-content/uploads/2023/12/gay-rang-cua.jpg 600w, https://elitedental.com.vn/wp-content/uploads/2023/12/gay-rang-cua-300x179.jpg 300w" data-sizes="100vw" sizes="100vw" srcset="https://elitedental.com.vn/wp-content/uploads/2023/12/gay-rang-cua.jpg 600w, https://elitedental.com.vn/wp-content/uploads/2023/12/gay-rang-cua-300x179.jpg 300w">								</a>
                                    </div>
                                    <div class="blog-content">
                                        <div class="blog-title">
                                            <h3><a href="https://elitedental.com.vn/gay-rang-cua-phai-lam-sao-cach-khac-phuc-hieu-qua-tham-my.html" data-wpel-link="internal">Gãy răng cửa phải làm sao? Cách khắc phục hiệu quả và an toàn</a></h3>
                                        </div>
                                        <div class="blog-desc">
                                            Gãy răng cửa tưởng chừng là một khiếm khuyết nhỏ nhưng lại có ảnh hưởng rất lớn...								</div>
                                        <div class="blog-button">
                                            <a href="https://elitedental.com.vn/gay-rang-cua-phai-lam-sao-cach-khac-phuc-hieu-qua-tham-my.html" data-wpel-link="internal">Xem thêm</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
							<div class="col-lg-4 col-sm-4 col-12">
                                <div class="blog-item">
                                    <div class="blog-thumb">
                                        <a href="https://elitedental.com.vn/rang-so-3-la-rang-nao-nhung-hau-qua-khi-mat-rang-so-3.html" data-wpel-link="internal">
                                            <noscript><img width="600" height="450" src="https://elitedental.com.vn/wp-content/uploads/2023/11/rang-ham-so-3.png" class="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt="răng hàm số 3" decoding="async" srcset="https://elitedental.com.vn/wp-content/uploads/2023/11/rang-ham-so-3.png 600w, https://elitedental.com.vn/wp-content/uploads/2023/11/rang-ham-so-3-300x225.png 300w" sizes="100vw" /></noscript><img width="600" height="450" src="https://elitedental.com.vn/wp-content/uploads/2023/11/rang-ham-so-3.png" data-src="https://elitedental.com.vn/wp-content/uploads/2023/11/rang-ham-so-3.png" class="attachment-post-thumbnail size-post-thumbnail wp-post-image lazyloaded" alt="răng hàm số 3" decoding="async" data-srcset="https://elitedental.com.vn/wp-content/uploads/2023/11/rang-ham-so-3.png 600w, https://elitedental.com.vn/wp-content/uploads/2023/11/rang-ham-so-3-300x225.png 300w" data-sizes="100vw" sizes="100vw" srcset="https://elitedental.com.vn/wp-content/uploads/2023/11/rang-ham-so-3.png 600w, https://elitedental.com.vn/wp-content/uploads/2023/11/rang-ham-so-3-300x225.png 300w">								</a>
                                    </div>
                                    <div class="blog-content">
                                        <div class="blog-title">
                                            <h3><a href="https://elitedental.com.vn/rang-so-3-la-rang-nao-nhung-hau-qua-khi-mat-rang-so-3.html" data-wpel-link="internal">Răng nào là răng số 3? Những hệ lụy khi mất răng số 3</a></h3>
                                        </div>
                                        <div class="blog-desc">
                                            Mất răng số 3 là điều không ai mong muốn, vì có thể ảnh hưởng lớn đến...								</div>
                                        <div class="blog-button">
                                            <a href="https://elitedental.com.vn/rang-so-3-la-rang-nao-nhung-hau-qua-khi-mat-rang-so-3.html" data-wpel-link="internal">Xem thêm</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
							<div class="col-lg-4 col-sm-4 col-12">
                                <div class="blog-item">
                                    <div class="blog-thumb">
                                        <a href="https://elitedental.com.vn/mat-rang-so-2-co-sao-khong-nen-lam-gi-de-khac-phuc.html" data-wpel-link="internal">
                                            <noscript><img width="600" height="399" src="https://elitedental.com.vn/wp-content/uploads/2023/11/hinh-anh-thieu-rang-so-2.jpg" class="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt="hình ảnh thiếu răng số 2" decoding="async" srcset="https://elitedental.com.vn/wp-content/uploads/2023/11/hinh-anh-thieu-rang-so-2.jpg 600w, https://elitedental.com.vn/wp-content/uploads/2023/11/hinh-anh-thieu-rang-so-2-300x200.jpg 300w" sizes="100vw" /></noscript><img width="600" height="399" src="https://elitedental.com.vn/wp-content/uploads/2023/11/hinh-anh-thieu-rang-so-2.jpg" data-src="https://elitedental.com.vn/wp-content/uploads/2023/11/hinh-anh-thieu-rang-so-2.jpg" class="attachment-post-thumbnail size-post-thumbnail wp-post-image lazyloaded" alt="hình ảnh thiếu răng số 2" decoding="async" data-srcset="https://elitedental.com.vn/wp-content/uploads/2023/11/hinh-anh-thieu-rang-so-2.jpg 600w, https://elitedental.com.vn/wp-content/uploads/2023/11/hinh-anh-thieu-rang-so-2-300x200.jpg 300w" data-sizes="100vw" sizes="100vw" srcset="https://elitedental.com.vn/wp-content/uploads/2023/11/hinh-anh-thieu-rang-so-2.jpg 600w, https://elitedental.com.vn/wp-content/uploads/2023/11/hinh-anh-thieu-rang-so-2-300x200.jpg 300w">								</a>
                                    </div>
                                    <div class="blog-content">
                                        <div class="blog-title">
                                            <h3><a href="https://elitedental.com.vn/mat-rang-so-2-co-sao-khong-nen-lam-gi-de-khac-phuc.html" data-wpel-link="internal">Răng số 2 bị mất có sao không? Nên làm gì để khắc phục?</a></h3>
                                        </div>
                                        <div class="blog-desc">
                                            Răng số 2 thuộc nhóm răng cửa, nằm ở vị trí dễ thấy nhất. Do đó, khi...								</div>
                                        <div class="blog-button">
                                            <a href="https://elitedental.com.vn/mat-rang-so-2-co-sao-khong-nen-lam-gi-de-khac-phuc.html" data-wpel-link="internal">Xem thêm</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
						</div>
			        </div>
                </div>
                <section id="section-4" class="section-list-doctor">
                    <div class="main-doctor-width">
                        <div class="pattern-doctor">
                            <noscript><img width="1345" height="94" src="/wp-content/themes/fpelitedental/assets/images/image-pattern-ornament-6.png" alt="Pattern images 6"></noscript><img class=" ls-is-cached lazyloaded" width="1345" height="94" src="/wp-content/themes/fpelitedental/assets/images/image-pattern-ornament-6.png" data-src="/wp-content/themes/fpelitedental/assets/images/image-pattern-ornament-6.png" alt="Pattern images 6">
                        </div>
                        <div class="background-doctor-main" style="background: #faf0f7;">
                            <div class="container">
                                <div class="content-doctor-main">
                                    <h2>CÁC BÁC SĨ KHÁC</h2>
                                </div>
								<div class="listing-doctor-main owl-theme owl-carousel owl-loaded owl-drag">
									<div class="owl-stage-outer"><div class="owl-stage" style="transform: translate3d(0px, 0px, 0px); transition: all; width: 3584px;"><div class="owl-item active" style="width: 374.133px; margin-right: 24px;"><div class="doctor-item">
                                        <div class="doctor-thumb">
                                            <noscript><img width="292" height="300" src="https://elitedental.com.vn/wp-content/uploads/2022/03/Mask-group-2-292x300.png" class="attachment-medium size-medium wp-post-image" alt="" decoding="async" srcset="https://elitedental.com.vn/wp-content/uploads/2022/03/Mask-group-2-292x300.png 292w, https://elitedental.com.vn/wp-content/uploads/2022/03/Mask-group-2.png 506w" sizes="100vw" /></noscript><img width="292" height="300" src="https://elitedental.com.vn/wp-content/uploads/2022/03/Mask-group-2-292x300.png" data-src="https://elitedental.com.vn/wp-content/uploads/2022/03/Mask-group-2-292x300.png" class="attachment-medium size-medium wp-post-image ls-is-cached lazyloaded" alt="" decoding="async" data-srcset="https://elitedental.com.vn/wp-content/uploads/2022/03/Mask-group-2-292x300.png 292w, https://elitedental.com.vn/wp-content/uploads/2022/03/Mask-group-2.png 506w" data-sizes="100vw" sizes="100vw" srcset="https://elitedental.com.vn/wp-content/uploads/2022/03/Mask-group-2-292x300.png 292w, https://elitedental.com.vn/wp-content/uploads/2022/03/Mask-group-2.png 506w">
                                        </div>
                                        <div class="doctor-content slide-up">
                                            <div class="doctor-chuc-danh">
                                                <span>Tiến sĩ, Bác sĩ</span>
                                            </div>
                                            <div class="doctor-name">
                                                <h3><a href="https://elitedental.com.vn/doctors/tran-hung-lam" data-wpel-link="internal">Trần Hùng Lâm</a></h3>
                                            </div>
                                            <div class="doctor-desc">
                                                TS.BS Trần Hùng Lâm hiện là Giám đốc chuyên môn tại Elite Dental, từng có nhiều năm tu nghiệp tại Pháp, giữ vị trí quan trọng trong các tổ chức nha khoa uy tín tại Việt Nam.
                                                <div class="doctor-button">
                                                    <a href="https://elitedental.com.vn/doctors/tran-hung-lam" data-wpel-link="internal">Tìm hiểu thêm</a>
                                                </div>
                                            </div>
                                        </div>
							        </div>
                                </div>
                                <div class="owl-item active" style="width: 374.133px; margin-right: 24px;">
                                    <div class="doctor-item">
                                        <div class="doctor-thumb">
                                            <noscript><img width="292" height="300" src="https://elitedental.com.vn/wp-content/uploads/2022/04/hinh-anh-bac-si-hang-292x300.png" class="attachment-medium size-medium wp-post-image" alt="Bác sĩ Hằng" decoding="async" srcset="https://elitedental.com.vn/wp-content/uploads/2022/04/hinh-anh-bac-si-hang-292x300.png 292w, https://elitedental.com.vn/wp-content/uploads/2022/04/hinh-anh-bac-si-hang.png 506w" sizes="100vw" /></noscript><img width="292" height="300" src="https://elitedental.com.vn/wp-content/uploads/2022/04/hinh-anh-bac-si-hang-292x300.png" data-src="https://elitedental.com.vn/wp-content/uploads/2022/04/hinh-anh-bac-si-hang-292x300.png" class="attachment-medium size-medium wp-post-image ls-is-cached lazyloaded" alt="Bác sĩ Hằng" decoding="async" data-srcset="https://elitedental.com.vn/wp-content/uploads/2022/04/hinh-anh-bac-si-hang-292x300.png 292w, https://elitedental.com.vn/wp-content/uploads/2022/04/hinh-anh-bac-si-hang.png 506w" data-sizes="100vw" sizes="100vw" srcset="https://elitedental.com.vn/wp-content/uploads/2022/04/hinh-anh-bac-si-hang-292x300.png 292w, https://elitedental.com.vn/wp-content/uploads/2022/04/hinh-anh-bac-si-hang.png 506w">
                                        </div>
                                        <div class="doctor-content slide-up">
                                            <div class="doctor-chuc-danh">
                                                <span>Thạc sĩ, Bác sĩ</span>
                                            </div>
                                            <div class="doctor-name">
                                                <h3><a href="https://elitedental.com.vn/doctors/nguyen-phuong-hang" data-wpel-link="internal">Nguyễn Phượng Hằng</a></h3>
                                            </div>
                                            <div class="doctor-desc">
                                                Với niềm đam mê Y khoa cùng sự tâm đắc những giá trị mà một nụ cười rạng rỡ mang lại đã thôi thúc BS Phượng Hằng trở thành một nha sĩ chuyên ngành Phục hình thẩm mỹ.
                                                <div class="doctor-button">
                                                    <a href="https://elitedental.com.vn/doctors/nguyen-phuong-hang" data-wpel-link="internal">Tìm hiểu thêm</a>
                                                </div>
                                            </div>
                                        </div>
							        </div>
                                </div>
                                <div class="owl-item active" style="width: 374.133px; margin-right: 24px;">
                                    <div class="doctor-item">
                                        <div class="doctor-thumb">
                                            <noscript><img width="292" height="300" src="https://elitedental.com.vn/wp-content/uploads/2022/03/hinh-bac-si-diep-292x300.png" class="attachment-medium size-medium wp-post-image" alt="" decoding="async" srcset="https://elitedental.com.vn/wp-content/uploads/2022/03/hinh-bac-si-diep-292x300.png 292w, https://elitedental.com.vn/wp-content/uploads/2022/03/hinh-bac-si-diep.png 506w" sizes="100vw" /></noscript><img width="292" height="300" src="https://elitedental.com.vn/wp-content/uploads/2022/03/hinh-bac-si-diep-292x300.png" data-src="https://elitedental.com.vn/wp-content/uploads/2022/03/hinh-bac-si-diep-292x300.png" class="attachment-medium size-medium wp-post-image lazyloaded" alt="" decoding="async" data-srcset="https://elitedental.com.vn/wp-content/uploads/2022/03/hinh-bac-si-diep-292x300.png 292w, https://elitedental.com.vn/wp-content/uploads/2022/03/hinh-bac-si-diep.png 506w" data-sizes="100vw" sizes="100vw" srcset="https://elitedental.com.vn/wp-content/uploads/2022/03/hinh-bac-si-diep-292x300.png 292w, https://elitedental.com.vn/wp-content/uploads/2022/03/hinh-bac-si-diep.png 506w">
                                        </div>
                                        <div class="doctor-content slide-up">
                                            <div class="doctor-chuc-danh">
                                                <span>Bác sĩ</span>
                                            </div>
                                            <div class="doctor-name">
                                                <h3><a href="https://elitedental.com.vn/doctors/mai-thi-ngoc-diep" data-wpel-link="internal">Mai Thị Ngọc Diệp</a></h3>
                                            </div>
                                            <div class="doctor-desc">
                                                BS Ngọc Diệp có hơn 20 năm điều trị trong lĩnh vực nha khoa tổng quát &amp; phục hình thẩm mỹ. Bác rất tận tâm, thăm khám kỹ, lên kế hoạch &amp; thực hiện điều trị tốt nhất.
                                                <div class="doctor-button">
                                                    <a href="https://elitedental.com.vn/doctors/mai-thi-ngoc-diep" data-wpel-link="internal">Tìm hiểu thêm</a>
                                                </div>
                                            </div>
                                        </div>
							        </div>
                                </div>
                                <div class="owl-item active" style="width: 374.133px; margin-right: 24px;">
                                    <div class="doctor-item">
                                        <div class="doctor-thumb">
                                            <noscript><img width="253" height="260" src="https://elitedental.com.vn/wp-content/uploads/2022/03/Vo-The-Dzu.png" class="attachment-medium size-medium wp-post-image" alt="" decoding="async" sizes="100vw" /></noscript><img width="253" height="260" src="https://elitedental.com.vn/wp-content/uploads/2022/03/Vo-The-Dzu.png" data-src="https://elitedental.com.vn/wp-content/uploads/2022/03/Vo-The-Dzu.png" class="attachment-medium size-medium wp-post-image lazyloaded" alt="" decoding="async" data-sizes="100vw" sizes="100vw">
                                        </div>
                                        <div class="doctor-content slide-up">
                                            <div class="doctor-chuc-danh">
                                                <span>Thạc sĩ, Bác sĩ</span>
                                            </div>
                                            <div class="doctor-name">
                                                <h3><a href="https://elitedental.com.vn/doctors/vo-the-dzu" data-wpel-link="internal">Võ Thế Dzũ</a></h3>
                                            </div>
                                            <div class="doctor-desc">
                                                Có 14 năm kinh nghiệm điều trị nha khoa tiểu phẫu. BS Thế Dzũ không ngừng học tập, nghiên cứu để tìm ra kỹ thuật đúng, mang đến trải nghiệm nhổ răng an toàn, hiệu quả.
                                                <div class="doctor-button">
                                                    <a href="https://elitedental.com.vn/doctors/vo-the-dzu" data-wpel-link="internal">Tìm hiểu thêm</a>
                                                </div>
                                            </div>
                                        </div>
							        </div>
                                </div>
                                <div class="owl-item" style="width: 374.133px; margin-right: 24px;">
                                    <div class="doctor-item">
                                        <div class="doctor-thumb">
                                            <noscript><img width="292" height="300" src="https://elitedental.com.vn/wp-content/uploads/2022/04/hinh-anh-bs-huynh-thi-anh-thuy-292x300.png" class="attachment-medium size-medium wp-post-image" alt="" decoding="async" srcset="https://elitedental.com.vn/wp-content/uploads/2022/04/hinh-anh-bs-huynh-thi-anh-thuy-292x300.png 292w, https://elitedental.com.vn/wp-content/uploads/2022/04/hinh-anh-bs-huynh-thi-anh-thuy.png 506w" sizes="100vw" /></noscript><img width="292" height="300" src="https://elitedental.com.vn/wp-content/uploads/2022/04/hinh-anh-bs-huynh-thi-anh-thuy-292x300.png" data-src="https://elitedental.com.vn/wp-content/uploads/2022/04/hinh-anh-bs-huynh-thi-anh-thuy-292x300.png" class="attachment-medium size-medium wp-post-image lazyloaded" alt="" decoding="async" data-srcset="https://elitedental.com.vn/wp-content/uploads/2022/04/hinh-anh-bs-huynh-thi-anh-thuy-292x300.png 292w, https://elitedental.com.vn/wp-content/uploads/2022/04/hinh-anh-bs-huynh-thi-anh-thuy.png 506w" data-sizes="100vw" sizes="100vw" srcset="https://elitedental.com.vn/wp-content/uploads/2022/04/hinh-anh-bs-huynh-thi-anh-thuy-292x300.png 292w, https://elitedental.com.vn/wp-content/uploads/2022/04/hinh-anh-bs-huynh-thi-anh-thuy.png 506w">
                                        </div>
                                        <div class="doctor-content slide-up">
                                            <div class="doctor-chuc-danh">
                                                <span>Bác sĩ</span>
                                            </div>
                                            <div class="doctor-name">
                                                <h3><a href="https://elitedental.com.vn/doctors/huynh-thi-anh-thuy" data-wpel-link="internal">Huỳnh Thị Anh Thùy</a></h3>
                                            </div>
                                            <div class="doctor-desc">
                                                BS Anh Thùy tốt nghiệp khoa Răng Hàm Mặt tại trường ĐHYD, TPHCM. Trong điều trị, bác Thùy luôn nỗ lực với mục tiêu xâm lấn tối thiểu và bảo tồn mô răng thật tối đa.
                                                <div class="doctor-button">
                                                    <a href="https://elitedental.com.vn/doctors/huynh-thi-anh-thuy" data-wpel-link="internal">Tìm hiểu thêm</a>
                                                </div>
                                            </div>
                                        </div>
							        </div>
                                </div>
                                <div class="owl-item" style="width: 374.133px; margin-right: 24px;">
                                    <div class="doctor-item">
                                        <div class="doctor-thumb">
                                            <noscript><img width="292" height="300" src="https://elitedental.com.vn/wp-content/uploads/2022/04/7-292x300.png" class="attachment-medium size-medium wp-post-image" alt="" decoding="async" srcset="https://elitedental.com.vn/wp-content/uploads/2022/04/7-292x300.png 292w, https://elitedental.com.vn/wp-content/uploads/2022/04/7.png 506w" sizes="100vw" /></noscript><img width="292" height="300" src="https://elitedental.com.vn/wp-content/uploads/2022/04/7-292x300.png" data-src="https://elitedental.com.vn/wp-content/uploads/2022/04/7-292x300.png" class="attachment-medium size-medium wp-post-image lazyloaded" alt="" decoding="async" data-srcset="https://elitedental.com.vn/wp-content/uploads/2022/04/7-292x300.png 292w, https://elitedental.com.vn/wp-content/uploads/2022/04/7.png 506w" data-sizes="100vw" sizes="100vw" srcset="https://elitedental.com.vn/wp-content/uploads/2022/04/7-292x300.png 292w, https://elitedental.com.vn/wp-content/uploads/2022/04/7.png 506w">
                                        </div>
                                        <div class="doctor-content slide-up">
                                            <div class="doctor-chuc-danh">
                                                <span>Bác sĩ</span>
                                            </div>
                                            <div class="doctor-name">
                                                <h3><a href="https://elitedental.com.vn/doctors/tran-bao-anh" data-wpel-link="internal">Trần Bảo Anh</a></h3>
                                            </div>
                                            <div class="doctor-desc">
                                                Trong điều trị, BS Bảo Anh luôn theo đuổi triết lý: “xâm lấn tối thiểu, thấu hiểu tối đa”, ưu tiên việc bảo tồn răng thật và luôn đồng hành cùng khách hàng suốt chặng đường điều trị.&nbsp;
                                                <div class="doctor-button">
                                                    <a href="https://elitedental.com.vn/doctors/tran-bao-anh" data-wpel-link="internal">Tìm hiểu thêm</a>
                                                </div>
                                            </div>
                                        </div>
							        </div>
                                </div>
                                <div class="owl-item" style="width: 374.133px; margin-right: 24px;">
                                    <div class="doctor-item">
                                        <div class="doctor-thumb">
                                            <noscript><img width="292" height="300" src="https://elitedental.com.vn/wp-content/uploads/2023/02/bac-si-tran-ngoc-nhu-y-292x300.png" class="attachment-medium size-medium wp-post-image" alt="Bác sĩ Trần Ngọc Như Ý" decoding="async" srcset="https://elitedental.com.vn/wp-content/uploads/2023/02/bac-si-tran-ngoc-nhu-y-292x300.png 292w, https://elitedental.com.vn/wp-content/uploads/2023/02/bac-si-tran-ngoc-nhu-y.png 506w" sizes="100vw" /></noscript><img width="292" height="300" src="https://elitedental.com.vn/wp-content/uploads/2023/02/bac-si-tran-ngoc-nhu-y-292x300.png" data-src="https://elitedental.com.vn/wp-content/uploads/2023/02/bac-si-tran-ngoc-nhu-y-292x300.png" class="attachment-medium size-medium wp-post-image lazyloaded" alt="Bác sĩ Trần Ngọc Như Ý" decoding="async" data-srcset="https://elitedental.com.vn/wp-content/uploads/2023/02/bac-si-tran-ngoc-nhu-y-292x300.png 292w, https://elitedental.com.vn/wp-content/uploads/2023/02/bac-si-tran-ngoc-nhu-y.png 506w" data-sizes="100vw" sizes="100vw" srcset="https://elitedental.com.vn/wp-content/uploads/2023/02/bac-si-tran-ngoc-nhu-y-292x300.png 292w, https://elitedental.com.vn/wp-content/uploads/2023/02/bac-si-tran-ngoc-nhu-y.png 506w">								</div>
                                        <div class="doctor-content slide-up">
                                            <div class="doctor-chuc-danh">
                                                <span>Bác sĩ</span>
                                            </div>
                                            <div class="doctor-name">
                                                <h3><a href="https://elitedental.com.vn/doctors/tran-ngoc-nhu-y" data-wpel-link="internal">Trần Ngọc Như Ý</a></h3>
                                            </div>
                                            <div class="doctor-desc">
                                                Điều trị bằng cách tiếp cận ít xâm lấn và đặc biệt là ít dùng lực nhất có thể để đem đến trải nghiệm nhổ răng êm ái, nhẹ nhàng nhất cho bệnh nhân của mình.
                                                <div class="doctor-button">
                                                    <a href="https://elitedental.com.vn/doctors/tran-ngoc-nhu-y" data-wpel-link="internal">Tìm hiểu thêm</a>
                                                </div>
                                            </div>
                                        </div>
							        </div>
                                </div>
                                <div class="owl-item" style="width: 374.133px; margin-right: 24px;">
                                    <div class="doctor-item">
                                        <div class="doctor-thumb">
                                            <noscript><img width="292" height="300" src="https://elitedental.com.vn/wp-content/uploads/2023/03/hinh-bac-si-hung-292x300.png" class="attachment-medium size-medium wp-post-image" alt="" decoding="async" srcset="https://elitedental.com.vn/wp-content/uploads/2023/03/hinh-bac-si-hung-292x300.png 292w, https://elitedental.com.vn/wp-content/uploads/2023/03/hinh-bac-si-hung.png 506w" sizes="100vw" /></noscript><img width="292" height="300" src="data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%20292%20300%22%3E%3C/svg%3E" data-src="https://elitedental.com.vn/wp-content/uploads/2023/03/hinh-bac-si-hung-292x300.png" class="lazyload attachment-medium size-medium wp-post-image" alt="" decoding="async" data-srcset="https://elitedental.com.vn/wp-content/uploads/2023/03/hinh-bac-si-hung-292x300.png 292w, https://elitedental.com.vn/wp-content/uploads/2023/03/hinh-bac-si-hung.png 506w" data-sizes="100vw">
                                        </div>
                                        <div class="doctor-content slide-up">
                                            <div class="doctor-chuc-danh">
                                                <span>Bác sĩ</span>
                                            </div>
                                            <div class="doctor-name">
                                                <h3><a href="https://elitedental.com.vn/doctors/bui-manh-hung" data-wpel-link="internal">Bùi Mạnh Hưng</a></h3>
                                            </div>
                                            <div class="doctor-desc">
                                                Triết lý điều trị của bác sĩ Hưng trong con đường hành nghề Y của mình chính là điều trị xâm lấn tối thiểu nhất, đem đến kết quả bền vững nhất.
                                                <div class="doctor-button">
                                                    <a href="https://elitedental.com.vn/doctors/bui-manh-hung" data-wpel-link="internal">Tìm hiểu thêm</a>
                                                </div>
                                            </div>
                                        </div>
							        </div>
                                </div>
                                <div class="owl-item" style="width: 374.133px; margin-right: 24px;">
                                    <div class="doctor-item">
                                        <div class="doctor-thumb">
                                            <noscript><img width="292" height="300" src="https://elitedental.com.vn/wp-content/uploads/2023/04/hinh-anh-bac-si-duc-anh-292x300.png" class="attachment-medium size-medium wp-post-image" alt="Bác sĩ Đức Anh" decoding="async" srcset="https://elitedental.com.vn/wp-content/uploads/2023/04/hinh-anh-bac-si-duc-anh-292x300.png 292w, https://elitedental.com.vn/wp-content/uploads/2023/04/hinh-anh-bac-si-duc-anh.png 506w" sizes="100vw" /></noscript><img width="292" height="300" src="data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%20292%20300%22%3E%3C/svg%3E" data-src="https://elitedental.com.vn/wp-content/uploads/2023/04/hinh-anh-bac-si-duc-anh-292x300.png" class="lazyload attachment-medium size-medium wp-post-image" alt="Bác sĩ Đức Anh" decoding="async" data-srcset="https://elitedental.com.vn/wp-content/uploads/2023/04/hinh-anh-bac-si-duc-anh-292x300.png 292w, https://elitedental.com.vn/wp-content/uploads/2023/04/hinh-anh-bac-si-duc-anh.png 506w" data-sizes="100vw">
                                        </div>
                                        <div class="doctor-content slide-up">
                                            <div class="doctor-chuc-danh">
                                                <span>Bác sĩ</span>
                                            </div>
                                            <div class="doctor-name">
                                                <h3><a href="https://elitedental.com.vn/doctors/pham-hung-duc-anh" data-wpel-link="internal">Phạm Hùng Đức Anh</a></h3>
                                            </div>
                                            <div class="doctor-desc">
                                                Triết lý điều trị của bác sĩ Đức Anh là ưu tiên trải nghiệm của bệnh nhân. Anh ấy liên tục cập nhật và trau dồi các kỹ năng chuyên môn của mình để đảm bảo kết quả tốt nhất.
                                                <div class="doctor-button">
                                                    <a href="https://elitedental.com.vn/doctors/pham-hung-duc-anh" data-wpel-link="internal">Tìm hiểu thêm</a>
                                                </div>
                                            </div>
                                        </div>
							        </div>
                                </div>
                            </div>
                        </div>
                        <div class="owl-nav">
                            <div class="owl-prev disabled">
                                <i class="fa-light fa-arrow-left"></i>
                            </div>
                            <div class="owl-next">
                                <i class="fa-light fa-arrow-right"></i>
                            </div>
                        </div>
                        <div class="owl-dots disabled"></div>
                    </div>
                    <div class="button-doctor-main">
                        <a href="/doi-ngu-bac-si-elite-dental" data-wpel-link="internal">Tìm hiểu thêm về đội ngũ bác sĩ</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div><!-- #main -->

		</div>
    </div>
  <footerHome></footerHome>
</template>

<script>
import BlogGrid from "@/assets/json/blog-grid.json";
import {pageInfoModel} from "@/models/pageInfoModel";
import {Carousel, Pagination, Slide} from "vue3-carousel";
import indexFiveDoctor from "@/assets/json/indexfivedoctor.json";
import 'vue3-carousel/dist/carousel.css'
export default {
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
      title: 'Đội ngũ bác sĩ',
      text: 'Trang chủ',
      text1: 'Đội ngũ bác sĩ',
      numberOfElement: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      model: [],
      activeTab: 0, // Tab mặc định là tab đầu tiên
      BlogGrid: BlogGrid,
      currentYear: '2024', // Năm mặc định được chọn
      pageInfo : pageInfoModel.baseJson(),
      indexFiveDoctor: indexFiveDoctor,
      itemsToShow: Math.min(indexFiveDoctor.length, 4), // Tính số lượng item hiển thị
        settings: {
            slidesToShow: this.itemsToShow,
            slidesToScroll: 1,
            dots: true,
            infinite: indexFiveDoctor.length > 4, // Chỉ cho phép lướt nếu có nhiều hơn 4 item
            // Các thiết lập khác
        },
        breakpoints: {
            578: {
                itemsToShow: 2,
                snapAlign: 'start',
            },
            // 700px and up
            768: {
                itemsToShow: 2,
                snapAlign: 'start',
            },
            // 1024 and up
            992: {
                itemsToShow: 3,
                snapAlign: 'start',
            },
            1200: {
                itemsToShow: 4,
                snapAlign: 'start',
            },
        },
    }
  },

  watch: {
    $route(to, from) {
      //  console.log("LOG WATCH DATA : ")
    //   this.getData();
    },
    perPage: {
      deep: true,
      handler(val){
        // this.getData();
      }
    },
    currentPage:{
      deep: true,
      handler(val){
        //   console.log("this.perpage", this.currentPage);
        // this.getData();
      }
    }
  },
  computed: {

},

  mounted() {
    // Chặn phím F12
    // window.addEventListener('keydown', (event) => {
    //   if (event.key === 'F12') {
    //     event.preventDefault();
    //   }
    // });

    // Chặn chuột phải
    // window.addEventListener('contextmenu', (event) => {
    //   event.preventDefault();
    // });
  },
  created() {
  },
  methods: {

  },
}
</script>

<style>
/* .page-chitietbacsi {
    width: 100%;
} */

.page-chitietbacsi .site-main .row .col-lg-8 {
    color: #000;
    font-size: 18px;
    font-family: "SVN Poppins Regular";
    text-align: justify
}

@media screen and (max-width: 767px) {
    .page-chitietbacsi .site-main .row .col-lg-8 {
        order:2
    }
}

.page-chitietbacsi .site-main .row .col-lg-8 h1,.page-chitietbacsi .site-main .row .col-lg-8 h2,.page-chitietbacsi .site-main .row .col-lg-8 h3,.page-chitietbacsi .site-main .row .col-lg-8 h4,.page-chitietbacsi .site-main .row .col-lg-8 h5,.page-chitietbacsi .site-main .row .col-lg-8 h6 {
    color: #F5E7B2;
    font-weight: 700
}

.page-chitietbacsi .site-main .row .col-lg-8 h2 {
    color: #F5E7B2;
    font-size: 40px;
    font-family: "SVN Poppins Bold";
    margin-bottom: 10px;
    margin-top: 0
}

@media screen and (min-width: 320px) and (max-width:1200px) {
    .page-chitietbacsi .site-main .row .col-lg-8 h2 {
        font-size:32px
    }
}

@media screen and (max-width: 991px) {
    .page-chitietbacsi .site-main .row .col-lg-8 h2 {
        font-size:24px;
        margin-top: 1em;
        margin-bottom: 15px;
        text-align: center
    }
}

.page-chitietbacsi .site-main .row .col-lg-8 .gioi-thieu {
    margin-bottom: 30px;
    margin-top: 140px;
}

.page-chitietbacsi .site-main .row .col-lg-8 .chuyen-mon {
    background: #fff;
    box-shadow: 0px 10px 15px #d29d5361, 0px 4px 30px #d29d5362;
    border-radius: 20px;
    padding: 50px
}

@media screen and (max-width: 991px) {
    .page-chitietbacsi .site-main .row .col-lg-8 .chuyen-mon {
        padding:15px
    }
}

.page-chitietbacsi .site-main .row .col-lg-8 .chuyen-mon p {
    display: flex;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #e0cee0
}

.page-chitietbacsi .site-main .row .col-lg-8 .chuyen-mon p:last-child {
    border: none;
    padding-bottom: 0;
    margin-bottom: 0
}

.page-chitietbacsi .site-main .row .col-lg-8 .chuyen-mon img {
    width: 24px;
    height: 25px;
    margin-right: 10px;
    margin-top: 10px
}

.page-chitietbacsi .site-main .row .col-lg-8 .box-wrapper {
    background: #fff;
    box-shadow: 0px 10px 15px #d29d5361, 0px 4px 30px #d29d5362;
    border-radius: 20px;
    padding: 50px;
    margin-top: 50px
}

@media screen and (max-width: 991px) {
    .page-chitietbacsi .site-main .row .col-lg-8 .box-wrapper {
        padding:15px
    }
}

@media screen and (min-width: 1024px) {
    .page-chitietbacsi .site-main .row .col-lg-4.col-sticky .box-doctor {
        position:-webkit-sticky;
        position: sticky;
        top: 140px
    }
}

@media screen and (max-width: 767px) {
    .page-chitietbacsi .site-main .row .col-lg-4 {
        order:1
    }
}

.page-chitietbacsi .site-main .row .col-lg-4 .box-doctor {
    text-align: center;
    background: #fff;
    box-shadow: 0px 10px 15px #d29d5361, 0px 4px 30px #d29d5362;
    border-radius: 22px;
    padding: 30px 15px
}

.page-chitietbacsi .site-main .row .col-lg-4 .box-doctor .box-thumb img {
    width: 80%
}

.page-chitietbacsi .site-main .row .col-lg-4 .box-doctor .box-description {
    background: rgba(212, 160, 76, 0.67);
    border-radius: 0 0 19px 19px;
    padding: 20px;
    margin-bottom: 30px
}

.page-chitietbacsi .site-main .row .col-lg-4 .box-doctor .box-description .box-name h2 {
    color: #000;
    font-size: 26px;
    font-family: "SVN Poppins Regular";
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 10px;
    text-transform: uppercase
}

@media screen and (max-width: 991px) {
    .page-chitietbacsi .site-main .row .col-lg-4 .box-doctor .box-description .box-name h2 {
        font-size:24px
    }
}

.page-chitietbacsi .site-main .row .col-lg-4 .box-doctor .box-description .box-chuc-vu {
    color: #000;
    font-size: 16px;
    font-family: "SVN Poppins Regular"
}

.page-chitietbacsi .site-main .row .col-lg-4 .box-doctor .box-contact button {
    background: rgba(212, 160, 76, 0.67);
    border-radius: 100px;
    width: 100%;
    height: 80px;
}

.page-chitietbacsi .site-main .row .col-lg-4 .box-doctor .box-contact button a {
    display: block;
    align-items: center;
    color: #fff;
    font-size: 20px;
    font-family: "SVN Poppins Bold"
}

@media screen and (max-width: 767px) {
    .page-chitietbacsi .site-main .row .col-lg-4 .box-doctor .box-contact button a {
        justify-content:center
    }
}

.page-chitietbacsi .site-main .row .col-lg-4 .box-doctor .box-contact button a i {
    font-size: 20px;
    background: #fff;
    border-radius: 50%;
    color: #F5E7B2;
    max-width: 40px;
    min-width: 40px;
    height: 40px;
    margin-right: 15px;
    position: relative;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center
}

@media screen and (max-width: 991px) {
    .page-chitietbacsi .site-main .row .col-lg-4 .box-doctor .box-contact button a {
        font-size:16px
    }
}

.page-chitietbacsi .site-main .list-doctor-orther h2 {
    color: #F5E7B2;
    font-family: "SVN Poppins Bold";
    margin-bottom: 30px;
    margin-top: 0;
    text-align: center
}

.page-chitietbacsi .site-main .list-doctor-orther .box-main {
    background: linear-gradient(180deg,rgba(255,255,255,.4) 0%,rgba(255,255,255,.4) 100%);
    border-radius: 15px;
    margin-bottom: 30px;
    text-align: center
}

.page-chitietbacsi .site-main .list-doctor-orther .box-main .box-img {
    position: relative
}

.page-chitietbacsi .site-main .list-doctor-orther .box-main .box-img img {
    width: 100%;
    height: auto;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px
}

.page-chitietbacsi .site-main .list-doctor-orther .box-main .box-img .description {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(128,82,139,.86);
    border-radius: 15px 15px 0 0;
    margin-bottom: 0;
    color: #f1f1f1;
    font-size: 15px;
    font-family: "SVN Poppins Regular";
    display: none
}

.page-chitietbacsi .site-main .list-doctor-orther .box-main .box-img .description a {
    color: #f1f1f1;
    display: block;
    padding: 65px 15px;
    height: 100%
}

.page-chitietbacsi .site-main .list-doctor-orther .box-main .box-content {
    padding: 15px
}

.page-chitietbacsi .site-main .list-doctor-orther .box-main .box-content .title .position {
    color: #000;
    font-size: 14px;
    font-family: "SVN Poppins Regular"
}

.page-chitietbacsi .site-main .list-doctor-orther .box-main .box-content .title h3 {
    color: #701659;
    font-size: 18px;
    font-family: "SVN Poppins Bold"
}

.page-chitietbacsi .site-main .list-doctor-orther .box-main .box-content .view-more button {
    background: 0 0;
    border: 1px solid #c8319f;
    box-sizing: border-box;
    border-radius: 15px;
    padding: 0;
    width: 134px;
    height: 34px
}

.page-chitietbacsi .site-main .list-doctor-orther .box-main .box-content .view-more button a {
    display: block;
    color: #c8319f;
    font-size: 15px;
    font-family: "SVN Poppins Regular";
    text-transform: uppercase
}

.page-chitietbacsi .site-main .list-doctor-orther .box-main:hover {
    cursor: pointer;
    filter: drop-shadow(0px 4px 30px rgba(245,185,255,.46));
    background-color: #fff
}

.page-chitietbacsi .site-main .list-doctor-orther .box-main:hover .box-img .description {
    display: block
}

.page-chitietbacsi .site-main .list-doctor-orther .box-main:hover .view-more button {
    background: linear-gradient(8.24deg,#b12d91 4.82%,#cf339e 21.67%,#ea73cc 92.25%)
}

.page-chitietbacsi .site-main .list-doctor-orther .box-main:hover .view-more button a {
    color: #fff;
    font-family: "SVN Poppins Bold"
}

.page-chitietbacsi .site-main .list-post-of-doctor {
    padding: 50px 0;
    position: relative
}

.page-chitietbacsi .site-main .list-post-of-doctor h2 {
    color: #F5E7B2;
    font-family: "SVN Poppins Bold";
    margin-bottom: 30px;
    text-align: center;
    text-transform: uppercase
}

@media screen and (max-width: 576px) {
    .page-chitietbacsi .site-main .list-post-of-doctor .blog-slider-mobile {
        width:calc(100% + 24px)
    }

    .page-chitietbacsi .site-main .list-post-of-doctor .blog-slider-mobile .owl-stage-outer .owl-stage {
        padding-left: 0 !important
    }

    .page-chitietbacsi .site-main .list-post-of-doctor .blog-slider-mobile .owl-dots {
        margin: 30px 0
    }

    .page-chitietbacsi .site-main .list-post-of-doctor .blog-slider-mobile .owl-dots .owl-dot span {
        border-radius: 50% !important;
        width: 10px !important;
        height: 10px !important
    }

    .page-chitietbacsi .site-main .list-post-of-doctor .blog-slider-mobile .owl-dots .owl-dot.active span {
        background: #F5E7B2 !important
    }
}

@media screen and (max-width: 767px) {
    .page-chitietbacsi .site-main .list-post-of-doctor .blog-item {
        margin-bottom:24px
    }
}

.page-chitietbacsi .site-main .list-post-of-doctor .blog-item:hover .blog-thumb a img {
    transform: scale(1.1)
}

.page-chitietbacsi .site-main .list-post-of-doctor .blog-item:hover .blog-content .blog-button a {
    background: #F5E7B2;
    color: #fff
}

.page-chitietbacsi .site-main .list-post-of-doctor .blog-item .blog-thumb {
    overflow: hidden;
    border-radius: 20px
}

.page-chitietbacsi .site-main .list-post-of-doctor .blog-item .blog-thumb a {
    display: block
}

.page-chitietbacsi .site-main .list-post-of-doctor .blog-item .blog-thumb a img {
    width: 100%;
    height: auto;
    border-radius: 20px;
    transition: all .4s;
    min-height: 260px;
    max-height: 260px
}

@media screen and (min-width: 767px) and (max-width:991px) {
    .page-chitietbacsi .site-main .list-post-of-doctor .blog-item .blog-thumb a img {
        min-height:180px;
        max-height: 180px
    }
}

.page-chitietbacsi .site-main .list-post-of-doctor .blog-item .blog-content {
    color: #525560;
    font-size: 16px;
    font-family: "SVN Poppins Regular";
    padding: 20px 0
}

@media screen and (max-width: 576px) {
    .page-chitietbacsi .site-main .list-post-of-doctor .blog-item .blog-content {
        padding-bottom:0
    }
}

.page-chitietbacsi .site-main .list-post-of-doctor .blog-item .blog-content .blog-title h3 {
    font-family: "SVN Poppins Semibold";
    color: #272b2f;
    font-size: 20px;
    min-height: 75px
}

@media screen and (max-width: 576px) {
    .page-chitietbacsi .site-main .list-post-of-doctor .blog-item .blog-content .blog-title h3 {
        min-height:unset
    }
}

.page-chitietbacsi .site-main .list-post-of-doctor .blog-item .blog-content .blog-title h3 a {
    color: #272b2f
}

.page-chitietbacsi .site-main .list-post-of-doctor .blog-item .blog-content .blog-desc {
    margin-bottom: 24px;
    min-height: 72px
}

@media screen and (max-width: 767px) {
    .page-chitietbacsi .site-main .list-post-of-doctor .blog-item .blog-content .blog-desc {
        min-height:unset
    }
}

.page-chitietbacsi .site-main .list-post-of-doctor .blog-item .blog-content .blog-button a {
    display: block;
    border: 1px solid #F5E7B2;
    height: 48px;
    line-height: 48px;
    font-family: "SVN Poppins Bold";
    font-size: 14px;
    padding: 0 20px;
    max-width: 120px;
    margin: 0 auto 0 0;
    border-radius: 24px;
    color: #F5E7B2;
    text-align: center;
    transition: all .4s
}

.page-chitietbacsi .site-main section.section-list-doctor {
    margin-bottom: 100px
}

@media screen and (max-width: 991px) {
    .page-chitietbacsi .site-main section.section-list-doctor {
        margin-bottom:50px
    }
}

@media screen and (max-width: 767px) {
    .page-chitietbacsi .site-main section.section-list-doctor {
        margin-bottom:30px
    }
}

.page-chitietbacsi .site-main section.section-list-doctor a {
    display: block
}

.page-chitietbacsi .site-main section.section-list-doctor .main-doctor-width {
    max-width: 1440px;
    margin: 0 auto
}

.page-chitietbacsi .site-main section.section-list-doctor .main-doctor-width .pattern-doctor img {
    width: 100%;
    height: auto
}

.page-chitietbacsi .site-main section.section-list-doctor .main-doctor-width .background-doctor-main {
    border-radius: 40px;
    padding: 50px 0
}

@media screen and (max-width: 576px) {
    .page-chitietbacsi .site-main section.section-list-doctor .main-doctor-width .background-doctor-main {
        border-radius:10px
    }
}

.page-chitietbacsi .site-main section.section-list-doctor .main-doctor-width .container {
    position: relative
}

.page-chitietbacsi .site-main section.section-list-doctor .main-doctor-width .container img.pattern-ornament-7 {
    bottom: 50px;
    right: 0
}

.page-chitietbacsi .site-main section.section-list-doctor .main-doctor-width .container .content-doctor-main {
    max-width: 900px;
    margin: 0 auto 30px auto;
    color: #2a2f38;
    font-size: 16px;
    font-family: "SVN Poppins Regular";
    text-align: center
}

.page-chitietbacsi .site-main section.section-list-doctor .main-doctor-width .container .content-doctor-main h2 {
    font-family: "SVN Poppins Bold";
    color: #F5E7B2
}

.page-chitietbacsi .site-main section.section-list-doctor .main-doctor-width .container .listing-doctor-main {
    margin-bottom: 50px
}

@media screen and (max-width: 576px) {
    .page-chitietbacsi .site-main section.section-list-doctor .main-doctor-width .container .listing-doctor-main {
        margin-bottom:15px
    }
}

.page-chitietbacsi .site-main section.section-list-doctor .main-doctor-width .container .listing-doctor-main .owl-stage-outer {
    padding-bottom: 50px
}

@media screen and (max-width: 576px) {
    .page-chitietbacsi .site-main section.section-list-doctor .main-doctor-width .container .listing-doctor-main .owl-stage-outer .owl-stage {
        padding-left:0 !important
    }
}

.page-chitietbacsi .site-main section.section-list-doctor .main-doctor-width .container .listing-doctor-main .owl-nav .owl-prev,.page-chitietbacsi .site-main section.section-list-doctor .main-doctor-width .container .listing-doctor-main .owl-nav .owl-next {
    transition: all .4s
}

.page-chitietbacsi .site-main section.section-list-doctor .main-doctor-width .container .listing-doctor-main .owl-nav .owl-prev:hover,.page-chitietbacsi .site-main section.section-list-doctor .main-doctor-width .container .listing-doctor-main .owl-nav .owl-next:hover {
    background: #F5E7B2 !important;
    color: #fff !important
}

.page-chitietbacsi .site-main section.section-list-doctor .main-doctor-width .container .listing-doctor-main .doctor-item {
    position: relative;
    cursor: pointer
}

.page-chitietbacsi .site-main section.section-list-doctor .main-doctor-width .container .listing-doctor-main .doctor-item .doctor-thumb img {
    width: 100%;
    height: auto;
    border-radius: 16px
}

.page-chitietbacsi .site-main section.section-list-doctor .main-doctor-width .container .listing-doctor-main .doctor-item .doctor-content {
    position: absolute;
    bottom: 0;
    background: rgba(88,97,116,.9);
    left: 0;
    right: 0;
    padding: 10px;
    margin: 30px;
    border-radius: 11px;
    text-align: center;
    color: #fff;
    font-family: "SVN Poppins Light";
    transition: visibility 0s,height .3s ease
}

.page-chitietbacsi .site-main section.section-list-doctor .main-doctor-width .container .listing-doctor-main .doctor-item .doctor-content a {
    color: #fff
}

.page-chitietbacsi .site-main section.section-list-doctor .main-doctor-width .container .listing-doctor-main .doctor-item .doctor-content .doctor-chuc-danh {
    font-size: 12px
}

.page-chitietbacsi .site-main section.section-list-doctor .main-doctor-width .container .listing-doctor-main .doctor-item .doctor-content .doctor-name h3 {
    font-family: "SVN Poppins Bold";
    font-size: 14px
}

.page-chitietbacsi .site-main section.section-list-doctor .main-doctor-width .container .listing-doctor-main .doctor-item .doctor-content .doctor-desc {
    color: #edeef2;
    font-size: 11px;
    visibility: hidden;
    height: 0
}

.page-chitietbacsi .site-main section.section-list-doctor .main-doctor-width .container .listing-doctor-main .doctor-item .doctor-content .doctor-desc .doctor-button {
    margin-top: 10px
}

.page-chitietbacsi .site-main section.section-list-doctor .main-doctor-width .container .listing-doctor-main .doctor-item .doctor-content .doctor-desc .doctor-button a {
    background: #F5E7B2;
    border-radius: 24px;
    font-family: "SVN Poppins Semibold";
    font-size: 12px;
    height: 30px;
    padding: 0 15px;
    line-height: 30px;
    max-width: 150px;
    margin: 0 auto
}

.page-chitietbacsi .site-main section.section-list-doctor .main-doctor-width .container .listing-doctor-main .doctor-item:hover .doctor-content {
    bottom: -25%;
    animation: slideUp .5s ease forwards;
    transition: visibility 0s,height .3s ease
}

.page-chitietbacsi .site-main section.section-list-doctor .main-doctor-width .container .listing-doctor-main .doctor-item:hover .doctor-content .doctor-desc {
    visibility: visible;
    height: auto
}

.page-chitietbacsi .site-main section.section-list-doctor .main-doctor-width .container .button-doctor-main {
    text-align: center
}

.page-chitietbacsi .site-main section.section-list-doctor .main-doctor-width .container .button-doctor-main a {
    border: 1px solid #F5E7B2;
    box-shadow: 0px 13px 12.9px -8px #ecb1d7de;
    height: 48px;
    line-height: 48px;
    font-family: "SVN Poppins Bold";
    font-size: 14px;
    padding: 0 20px;
    max-width: 280px;
    margin: 0 auto;
    border-radius: 24px;
    color: #F5E7B2;
    text-align: center
}

</style>
