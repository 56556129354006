<template>
  <div class="container pt-60 pb-60">
    <div class="row align-items-center">
      <div style="text-align: center; margin-bottom: 20px;">
          <div class="main-title pb-30">
            Trồng răng Implant toàn hàm là gì?<br>
            Gồm phương pháp nào
          </div>
          <div class="decs-intro-doctor">
            Trồng răng Implant toàn hàm All-on-X là phương pháp cấy từ 4-6 trụ Implant vào hướng thuận lợi của
            xương hàm để làm trụ vững chắc, giúp nâng đỡ phục hình cho 12-14 răng bên trên. Qua đó giúp phục hình
            hàm răng cho bệnh nhân mất nhiều răng, sắp mất toàn bộ hay mất răng toàn hàm (chủ yếu xảy ra ở
            giai đoạn trung niên hoặc người cao tuổi). Hiện nay, có 2 phương án cấy ghép Implant toàn hàm phổ biến
            là All-On-4 và All-On-6
          </div>
      </div>
    </div>
    <div class="row pt-10">
      <div class="col-md-6 mb-3">
        <div class="card-phuong-phap-implant">
          <img src="@/assets/img/phuongthanh/dichvu/all-on-4.png" alt="">
          <div class="title-phuong-phap-implant">
            <div class="name-phuong-phap-implant pb-20">
              Trồng răng Implant toàn hàm All On 4
            </div>
            <div class="desc-title">
              Implant All on 4 giúp thay thế toàn bộ răng đã mất bằng cách đặt 4 trụ Implant vào xương hàm.
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card-phuong-phap-implant">
          <img src="@/assets/img/phuongthanh/dichvu/all-on-6.png" alt="">
          <div class="title-phuong-phap-implant">
            <div class="name-phuong-phap-implant pb-20">
              Trồng răng Implant toàn hàm All On 6
            </div>
            <div class="desc-title">
              Implant All on 6 giúp thay thế toàn bộ răng đã mất bằng cách đặt 6 trụ Implant vào xương hàm.
              Giúp nâng đỡ tốt và vững chắc, ổn định hơn, có thể dễ dàng nâng đỡ 1 hàm với 14 chiếc răng sứ phía trên.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



</template>

<script>
import { ref } from "vue";
const currentDate = ref(new Date());
import AOS from "aos";
import "aos/dist/aos.css";
import indexfivespecialities from "@/assets/json/indexfivespecialities.json";
import 'vue3-carousel/dist/carousel.css'
export default {
  components: {
  },
  data() {
    return {
      startdate: currentDate,
      indexfivespecialities: indexfivespecialities,
      list: [],
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  props: {
    header: { type : Object}
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  watch:{
    '$props':{
      handler: function (val) {
        this.list = val.header;
      //  console.log("LOG WATCH LIST ", this.list )
      },
      deep: true
    }
  },
  methods: {
    submitForm() {
      this.$router.push("/search-2");
    },
  },
};
</script>
<style>
</style>
