<template>
    <!-- Need To Know -->
    <section class="need-to-know-section section-list-service pt-60 pb-60">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="section-header-one section-header-slider text-center pb-30">
              <div class="sub-title pb-10">BLOG</div>
              <div class="main-title">Kiến thức <span>Nha Khoa</span></div>
            </div>
          </div>
        </div>
        <div class="listing-service pt-30">
          <div class="row">
            <div class="col-xl-4 col-md-6 col-12" v-for="(item, index) in this.listDentalKnowledge" :key="index">
              <router-link
                  :to="{
                    path: `/ban-tin/${item.id}`,
                  }"
              >
                <div class="service-item">
                  <div class="service-thumb">
                    <a href="">
                      <img width="300" height="208" 
                        :src="item.fileImage.urlFile"
                        class="attachment-medium size-medium wp-post-image img-dichvu" 
                        alt="Ảnh" 
                        decoding="async" 
                        fetchpriority="high" 
                        srcset="" 
                        sizes="100vw">
                    </a>
                  </div>
                  <div class="service-content">
                    <span>Giải pháp tiên tiến cho người mất răng</span>
                    <div class="service-title">
                      <h3>
                        <a href="" data-wpel-link="internal">
                          {{ item.name}}
                        </a>
                      </h3>
                    </div>
                    <div class="service-excerpt">
                      <!-- <p>Giải pháp tiên tiến cho người mất răng</p> -->
                      <p>{{ item.describe }}</p>
                    </div>
                    <div class="service-button">
                      <a href="" data-wpel-link="internal">
                        <div>
                          Xem thêm
                        </div>
                        <i class="fa fa-solid fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-6 aos" data-aos="fade-up"
               v-for="(value) in this.listDentalKnowledge" :key="value.id"
          >
            <div class="service-item">
              <router-link :to="{
                          path: `/ban-tin/${value.id}`,
                      }">
                <div>
                  <img
                      v-if="value.fileImage"
                      class="img-fluid img-service"
                      :src="value.fileImage.urlFile"
                      alt="Post Image"
                  />
                </div>
                <div class="service-content">
                  <div class="service-title mt-3">
                    <h3
                      style="
                        color: #00003b;
                        margin-bottom: 8px;
                        font-family: 'Poppins Bold';
                        text-transform: uppercase;
                        display: -webkit-box !important;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        min-height: 55px;"
                      >
                      <a>
                        {{value.name}}
                      </a>
                    </h3>
                  </div>
                  <div class="service-desc mb-3">
                    <p>
                      {{value.describe}}
                    </p>
                  </div>
                  <div class="service-btn">
                    <b-button
                        class="btn-chitiet"
                    >
                      Xem thêm
                    </b-button>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div> -->
      </div>
    </section>
    <!-- /Need To Know -->
  
  </template>
  <script>
  import AOS from "aos";
  import "aos/dist/aos.css";
  import indexFiveService from "@/assets/json/indexfiveservice.json";
  export default {
    data() {
      return {
        indexFiveService: indexFiveService,
        listDentalKnowledge: [],
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
    created() {
      this.getDataDentalKnowledge();
    },
    methods: {
      async getDataDentalKnowledge() {
        let params = {
          start: 1,
          limit: 6,
          sortBy: this.sortBy,
        }
        await this.$store.dispatch("newsStore/getPagingParamsDentalKnowledge", params ).then(res => {
          this.listDentalKnowledge = res.data;
        });
      },
      toggleFavorite(item) {
        item.favorite = !item.favorite;
      },
    },
  };
  </script>
  
  <style>
  .img-service{
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 12px;
  }
  
  @media (max-width: 576px) {
    .img-service{
      height: 300px;
    }
  }
  </style>
  