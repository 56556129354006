<template>
  <div class="container padding-card pt-60 pb-60">
    <div class="form-intro">
      <div class="row">
        <div class="col-lg-3 col-12 box-left">
          <h2>Đặt Lịch Tư Vấn</h2>
        </div>
        <div class="col-lg-6 col-xs-12 box-right">
          <div class="box-main align-items-center">
            <div class="box-filter">
              <form id="submit-register-two-field" class="form-header" method="post" action="">
                <div class="row">
                  <div class="col-lg-6 col-sm-6 col-12">
                    <div class="ps-relative customer-name">
                      <div class="icon-form">
                        <img src="@/assets/img/phuongthanh/core/Profile.png" alt="" class="img-1">
                      </div>
                      <span class="wpcf7-form-control-wrap your-name">
                        <input type="text" class="form-control" name="your_name" id="customer-name" value="" placeholder="Nhập họ và tên">
                      </span>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6 col-12">
                    <div class="ps-relative customer-phone">
                      <div class="icon-form">
                        <img src="@/assets/img/phuongthanh/core/Call.png" alt="" class="img-2">
                      </div>
                      <span class="wpcf7-form-control-wrap your-tel">
                        <input type="text" class="form-control" name="your_tel" id="customer-phone" value="" placeholder="Nhập số điện thoại">
                      </span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-8 col-sm-9 col-12">
                    <div class="ps-relative customer-content">
                      <div class="icon-form">
                        <img src="@/assets/img/phuongthanh/core/Chat.png" alt="" class="img-4">
                      </div>
                      <span class="wpcf7-form-control-wrap your-content">
                        <input type="text" class="form-control" name="your_content" id="customer-content" value="" placeholder="Nội dung">
                      </span>
                    </div>
                  </div>
                  <input type="hidden" name="get_ip_connect_two_field" id="get_ip_connect_two_field" value="113.161.198.122">
                  <div class="col-lg-4 col-sm-3 col-12">
                    <button class="submit" style="display: flex; justify-content: center; align-items: center;">
                      <!-- <i class="fa fa-solid fa-calendar"></i> -->
                      <img src="@/assets/img/phuongthanh/core/Calendar.png" alt="" class="me-3">
                      Đăng ký
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-xs-12 box-right">
          <div class="card-qr-zalo">
            <span>Zalo</span>
            <img src="@/assets/img/phuongthanh/core/qrcode-zalo.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { ref } from "vue";
const currentDate = ref(new Date());
import AOS from "aos";
import "aos/dist/aos.css";
import indexfivespecialities from "@/assets/json/indexfivespecialities.json";
import 'vue3-carousel/dist/carousel.css'
export default {
  components: {
  },
  data() {
    return {
      startdate: currentDate,
      indexfivespecialities: indexfivespecialities,
      list: [],
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  props: {
    header: { type : Object}
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  watch:{
    '$props':{
      handler: function (val) {
        this.list = val.header;
      //  console.log("LOG WATCH LIST ", this.list )
      },
      deep: true
    }
  },
  methods: {
    submitForm() {
      this.$router.push("/search-2");
    },
  },
};
</script>
<style>
</style>
