<template>
  <div class="container pt-60 pb-30">
    <div class="main-title text-center pb-30">Một số lưu ý khi làm nha khoa tổng quát</div>
    
    <!-- Khung 2 -->
    <div class="box-luu-y box-secondary">
      <h2>Thời điểm và tần suất thăm khám</h2>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Nên thăm khám nha khoa định kỳ 6 tháng/lần để phát hiện và xử lý sớm các vấn đề răng miệng.
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Nếu có các dấu hiệu bất thường như đau nhức răng, sưng nướu, chảy máu chân răng, hoặc hôi 
          miệng kéo dài, hãy đến gặp bác sĩ ngay.
        </span>
      </div>

      <h2>Lựa chọn nha khoa uy tín</h2>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Chọn phòng khám nha khoa có đội ngũ bác sĩ chuyên môn cao, giàu kinh nghiệm.
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Trang thiết bị hiện đại và quy trình vệ sinh nghiêm ngặt là yếu tố quan trọng để đảm bảo an toàn.
        </span>
      </div>

      <h2>Trước khi thực hiện điều trị</h2>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Thông báo đầy đủ bệnh sử: Nếu bạn có tiền sử bệnh lý (tim mạch, tiểu đường, dị ứng thuốc...), 
          hãy báo với bác sĩ để điều chỉnh phương pháp điều trị phù hợp.
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Tránh ăn uống trước khi thăm khám nếu bác sĩ yêu cầu các thủ thuật cần gây tê hoặc chụp X-quang.
        </span>
      </div>

      <h2>Sau khi điều trị nha khoa</h2>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Tuân thủ hướng dẫn của bác sĩ về chăm sóc răng miệng, đặc biệt khi thực hiện các thủ thuật như trám răng, 
          điều trị tủy hoặc nhổ răng.
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Hạn chế ăn đồ cứng, cay nóng hoặc quá lạnh trong vòng 24 giờ sau điều trị.
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Vệ sinh răng miệng kỹ lưỡng nhưng nhẹ nhàng, tránh tác động mạnh vào khu vực vừa điều trị.
        </span>
      </div>

      <h2>Chăm sóc răng miệng đúng cách</h2>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Đánh răng ít nhất 2 lần/ngày với kem đánh răng chứa fluoride.
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Sử dụng chỉ nha khoa hoặc máy tăm nước để làm sạch kẽ răng.
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Hạn chế ăn uống nhiều đường, đồ uống có ga hoặc thực phẩm dễ gây mảng bám.
        </span>
      </div>

      <h2>Lưu ý đặc biệt cho trẻ em</h2>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Nên cho trẻ thăm khám nha khoa từ khi mọc răng sữa để phát hiện sớm các vấn đề và tạo thói quen chăm sóc răng miệng tốt.
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Khuyến khích trẻ sử dụng bàn chải phù hợp với lứa tuổi và tránh nuốt kem đánh răng.
        </span>
      </div>
    </div>

  </div>
  
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  data() {
    return {
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  created() {
  
  },
  methods: {

    toggleFavorite(item) {
      item.favorite = !item.favorite;
    },
  },
};
</script>

<style>

</style>
  