<template>
    <div class="container pt-60 pb-60">
      <div class="row">
        <div class="col-md-6">
            <div class="img-trongrang">
                <img src="@/assets/img/phuongthanh/dichvu/cautaorangimplant.png" alt="" style="max-height: 500px;">
            </div>
        </div>
        <div class="col-md-6">
            <div class="main-title pb-40">Cấu tạo rối loạn thái dương hàm</div>

            <div class="item-cautaorang pb-60">
                <div class="number">
                    <img src="@/assets/img/phuongthanh/dichvu/1.png" alt="" class="img-number">
                    <img src="@/assets/img/phuongthanh/dichvu/in1.png" alt="" class="in-number">
                </div>
                <div class="info-cautaorang">
                    <div>
                        <div class="name-cautaorang">
                          Xương và ổ khớp:
                        </div>
                        <div class="sub-cautaorang">
                          Bao gồm xương thái dương và xương hàm dưới. Xương thái dương chứa hố khớp (ổ khớp), 
                          trong khi lồi cầu hàm dưới của xương hàm dưới là phần di động trong khớp.
                        </div>
                    </div>
                </div>
            </div>
            <div class="item-cautaorang pb-60">
                <div class="number">
                    <img src="@/assets/img/phuongthanh/dichvu/2.png" alt="" class="img-number">
                    <img src="@/assets/img/phuongthanh/dichvu/in2.png" alt="" class="in-number">
                </div>
                <div class="info-cautaorang">
                    <div>
                        <div class="name-cautaorang">
                          Đĩa khớp (Articular Disc):
                        </div>
                        <div class="sub-cautaorang">
                          Là một lớp sụn mỏng nằm giữa xương thái dương và lồi cầu hàm dưới, đóng vai trò giảm ma sát, 
                          hấp thụ lực và giúp chuyển động mượt mà.
                        </div>
                    </div>
                </div>
            </div>
            <div class="item-cautaorang">
                <div class="number">
                    <img src="@/assets/img/phuongthanh/dichvu/3.png" alt="" class="img-number">
                    <img src="@/assets/img/phuongthanh/dichvu/in3.png" alt="" class="in-number">
                </div>
                <div class="info-cautaorang">
                    <div>
                        <div class="name-cautaorang">
                          Cơ và dây chằng:
                        </div>
                        <div class="sub-cautaorang">
                          Cơ nhai: Các cơ như cơ cắn, cơ thái dương, và cơ chân bướm giúp di chuyển hàm dưới.
                          Dây chằng: Như dây chằng bao khớp giữ ổn định khớp và giới hạn phạm vi di chuyển của hàm.
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
  import { ref } from "vue";
  const currentDate = ref(new Date());
  import AOS from "aos";
  import "aos/dist/aos.css";
  import 'vue3-carousel/dist/carousel.css'
  export default {
    components: {
    },
    data() {
      return {
        startdate: currentDate,
        list: [],
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    props: {
      header: { type : Object}
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
    watch:{
      '$props':{
        handler: function (val) {
          this.list = val.header;
        //  console.log("LOG WATCH LIST ", this.list )
        },
        deep: true
      }
    },
    methods: {
      submitForm() {
        this.$router.push("/search-2");
      },
    },
  };
</script>
<style>
</style>
