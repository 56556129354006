<template>
    <!-- FAQ Section -->
    <section class="faq-section faq-section-ten pt-60 pb-60">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-12 text-center pb-30">
            <div class="section-header-one text-center">
              <div class="sub-title pb-10">Q & A</div>
              <div class="main-title"><span>Câu hỏi</span> thường gặp?</div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="faq-img">
              <swiper
                :effect="'coverflow'"
                :grabCursor="true"
                :centeredSlides="true"
                :slidesPerView="'auto'"
                :initialSlide="1"
                :loop="true"
                :coverflowEffect="{
                  rotate: 0,
                  stretch: 0,
                  depth: 400,
                  modifier: 1,
                  slideShadows: false,
                }"
                :modules="modules"
                class="swiper-cauhoi"
              >
                <swiper-slide class="slide-cauhoi">
                  <img src="@/assets/img/phuongthanh/core/cauhoi1.png" />
                </swiper-slide>
                <swiper-slide class="slide-cauhoi">
                  <img src="@/assets/img/phuongthanh/core/cauhoi1.png" />
                </swiper-slide>
                <swiper-slide class="slide-cauhoi">
                  <img src="@/assets/img/phuongthanh/core/cauhoi1.png" />
                </swiper-slide>
              </swiper>

            </div>
            <!-- <div class="faq-img">
              <img src="@/assets/img/phuongthanh/core/home.jpg" class="img-fluid" alt="img" />
            </div> -->
          </div>
          <div class="col-lg-6 col-md-12 pt-30">
            <div class="faq-info">
              <div class="accordion" id="faq-details">
                <!-- FAQ Item -->
                <div class="accordion-item" v-for="item in IndexfiveFaq" :key="item.id">
                  <h2 class="accordion-header" :id="item.faqid">
                    <a
                      href="javascript:void(0);"
                      :class="item.accordion"
                      data-bs-toggle="collapse"
                      :data-bs-target="item.target"
                      :aria-expanded="item.expanded"
                      :aria-controls="item.targetid"
                    >
                      {{ item.question }}
                    </a>
                  </h2>
                  <div
                    :id="item.targetid"
                    :class="item.class"
                    :aria-labelledby="item.faqid"
                    data-bs-parent="#faq-details"
                  >
                    <div class="accordion-body">
                      <div class="accordion-content">
                        <p>{{ item.answer }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /FAQ Item -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </template>
  <script>
  import AOS from "aos";
  import "aos/dist/aos.css";
  import IndexfiveFaq from "@/assets/json/indexfivefaq.json";
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import { EffectCoverflow, Pagination } from 'swiper/modules';
  // Import Swiper styles
  import 'swiper/css';

  import 'swiper/css/effect-coverflow';
  import 'swiper/css/pagination';

  export default {
    components: {
      Pagination,
      Swiper,
      SwiperSlide,
    },
    data() {
      return {
        IndexfiveFaq: IndexfiveFaq,
        modules: [EffectCoverflow, Pagination],
        settings: {
          itemsToShow: 1,
          snapAlign: 'center',
        },
        // breakpoints are mobile first
        // any settings not specified will fallback to the carousel settings
        breakpoints: {
          // 700px and up
          576: {
            itemsToShow: 2,
            snapAlign: 'start',
          },
          768: {
            itemsToShow: 2,
            snapAlign: 'start',
          },
          // 1024 and up
          992: {
            itemsToShow: 3,
            snapAlign: 'start',
          },
          1200: {
            itemsToShow: 3,
            snapAlign: 'start',
          },
        },

        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
    computed: {
    },
    methods: {

      next() {
        this.$refs.flickity.next();
      },
  
      previous() {
        this.$refs.flickity.previous();
      },

    },
  };
  </script>
  
<style>

  
</style>
  
  