<template>
    <!-- Need To Know -->
    <section class="need-to-know-section section-list-service pt-60 pb-60">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="section-header-one section-header-slider text-center pb-30">
              <div class="main-title">Các phương pháp nha khoa tổng quát</div>
            </div>
          </div>
        </div>
        <div class="listing-service pt-30">
          <div class="row d-flex justify-content-center">
            <div class="col-xl-4 col-md-6 col-12">
              <router-link
                  :to="{
                    path: `/nha-khoa-tong-quat/nho-rang-khon`,
                  }"
              >
                <div class="service-item">
                  <div class="service-thumb">
                    <a href="">
                      <img width="300" height="208" 
                        src="@/assets/img/phuongthanh/dichvu/dan-su.png"
                        class="attachment-medium size-medium wp-post-image img-dichvu" 
                        alt="Ảnh" 
                        decoding="async" 
                        fetchpriority="high" 
                        srcset="" 
                        sizes="100vw">
                    </a>
                  </div>
                  <div class="service-content">
                    <span style="font-size: 14px;">Giải pháp tiên tiến cho người mất răng</span>
                    <div class="service-title">
                      <h3>
                        <a href="" data-wpel-link="internal">
                          Nhổ răng khôn
                        </a>
                      </h3>
                    </div>
                    <div class="service-excerpt">
                      Răng khôn thường gây đau nhức, ảnh hưởng đến các răng kế cận nếu mọc lệch. 
                      Quy trình nhổ răng khôn đảm bảo an toàn và hạn chế tối đa biến chứng.
                    </div>
                    <div class="service-button">
                      <a href="" data-wpel-link="internal">
                        <div>
                          Xem thêm
                        </div>
                        <i class="fa fa-solid fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-xl-4 col-md-6 col-12">
              <router-link
                  :to="{
                    path: `/nha-khoa-tong-quat/chua-tuy-rang`,
                  }"
              >
                <div class="service-item">
                  <div class="service-thumb">
                    <a href="">
                      <img width="300" height="208" 
                        src="@/assets/img/phuongthanh/dichvu/boc-rang-su.png"
                        class="attachment-medium size-medium wp-post-image img-dichvu" 
                        alt="Ảnh" 
                        decoding="async" 
                        fetchpriority="high" 
                        srcset="" 
                        sizes="100vw">
                    </a>
                  </div>
                  <div class="service-content">
                    <span style="font-size: 14px;">Giải pháp tiên tiến cho người mất răng</span>
                    <div class="service-title">
                      <h3>
                        <a href="" data-wpel-link="internal">
                          Điều trị tủy răng
                        </a>
                      </h3>
                    </div>
                    <div class="service-excerpt">
                      Phương pháp loại bỏ tủy bị viêm nhiễm, giúp bảo tồn răng thật. Điều trị đúng cách giúp giảm đau 
                      và ngăn ngừa mất răng. 
                    </div>
                    <div class="service-button">
                      <a href="" data-wpel-link="internal">
                        <div>
                          Xem thêm
                        </div>
                        <i class="fa fa-solid fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-xl-4 col-md-6 col-12">
              <router-link
                  :to="{
                    path: `/nha-khoa-tong-quat/tram-rang-tham-my`,
                  }"
              >
                <div class="service-item">
                  <div class="service-thumb">
                    <a href="">
                      <img width="300" height="208" 
                        src="@/assets/img/phuongthanh/dichvu/phuc-hinh-rang-su.png"
                        class="attachment-medium size-medium wp-post-image img-dichvu" 
                        alt="Ảnh" 
                        decoding="async" 
                        fetchpriority="high" 
                        srcset="" 
                        sizes="100vw">
                    </a>
                  </div>
                  <div class="service-content">
                    <span style="font-size: 14px;">Giải pháp tiên tiến cho người mất răng</span>
                    <div class="service-title">
                      <h3>
                        <a href="" data-wpel-link="internal">
                          Trám răng
                        </a>
                      </h3>
                    </div>
                    <div class="service-excerpt">
                      Kỹ thuật phục hồi hình dáng và chức năng răng bị sâu hoặc sứt mẻ, mang lại nụ cười tự nhiên.
                    </div>
                    <div class="service-button">
                      <a href="" data-wpel-link="internal">
                        <div>
                          Xem thêm
                        </div>
                        <i class="fa fa-solid fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-xl-4 col-md-6 col-12">
              <router-link
                  :to="{
                    path: `/nha-khoa-tong-quat/cao-voi`,
                  }"
              >
                <div class="service-item">
                  <div class="service-thumb">
                    <a href="">
                      <img width="300" height="208" 
                        src="@/assets/img/phuongthanh/dichvu/cuoi-ho-loi.png"
                        class="attachment-medium size-medium wp-post-image img-dichvu" 
                        alt="Ảnh" 
                        decoding="async" 
                        fetchpriority="high" 
                        srcset="" 
                        sizes="100vw">
                    </a>
                  </div>
                  <div class="service-content">
                    <span style="font-size: 14px;">Giải pháp tiên tiến cho người mất răng</span>
                    <div class="service-title">
                      <h3>
                        <a href="" data-wpel-link="internal">
                          Cao vôi đánh bóng răng
                        </a>
                      </h3>
                    </div>
                    <div class="service-excerpt">
                      Loại bỏ mảng bám và cao răng giúp răng sạch sẽ, hơi thở thơm mát và ngăn ngừa bệnh lý nướu.
                    </div>
                    <div class="service-button">
                      <a href="" data-wpel-link="internal">
                        <div>
                          Xem thêm
                        </div>
                        <i class="fa fa-solid fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-xl-4 col-md-6 col-12">
              <router-link
                  :to="{
                    path: `/nha-khoa-tong-quat/roi-loan-thai-duong-ham`,
                  }"
              >
                <div class="service-item">
                  <div class="service-thumb">
                    <a href="">
                      <img width="300" height="208" 
                        src="@/assets/img/phuongthanh/dichvu/tay-trang-rang.png"
                        class="attachment-medium size-medium wp-post-image img-dichvu" 
                        alt="Ảnh" 
                        decoding="async" 
                        fetchpriority="high" 
                        srcset="" 
                        sizes="100vw">
                    </a>
                  </div>
                  <div class="service-content">
                    <span style="font-size: 14px;">Giải pháp tiên tiến cho người mất răng</span>
                    <div class="service-title">
                      <h3>
                        <a href="" data-wpel-link="internal">
                          Rối loạn thái dương hàm
                        </a>
                      </h3>
                    </div>
                    <div class="service-excerpt">
                      Gây đau nhức hàm, khó ăn nhai và có thể liên quan đến stress. Điều trị sớm giúp cải thiện chất lượng cuộc sống.
                    </div>
                    <div class="service-button">
                      <a href="" data-wpel-link="internal">
                        <div>
                          Xem thêm
                        </div>
                        <i class="fa fa-solid fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- /Need To Know -->
  
  </template>
  <script>
  import AOS from "aos";
  import "aos/dist/aos.css";
  import indexFiveService from "@/assets/json/indexfiveservice.json";
  export default {
    data() {
      return {
        indexFiveService: indexFiveService,
        list: [],
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
    created() {
      this.getData();
    },
    methods: {
      async getData() {
        let params = {
            start: this.currentPage,
            limit: this.perPage,
            sortBy: this.sortBy,
        }
        await this.$store.dispatch("dichVuStore/getPagingParams", params ).then(res => {
            this.list = res.data.data
        });
    },
      toggleFavorite(item) {
        item.favorite = !item.favorite;
      },
    },
  };
  </script>
  
  <style>
  .img-service{
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 12px;
  }
  
  @media (max-width: 576px) {
    .img-service{
      height: 300px;
    }
  }
  </style>
  