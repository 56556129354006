<template>
  <div class="container pt-60 pb-60">
    <div class="row align-items-center">
      <div style="text-align: center; margin-bottom: 20px;">
          <div class="main-title pb-30">
            Trồng răng Implant
          </div>
          <div class="decs-intro-doctor">
            Trồng răng Implant (còn gọi là cấy ghép Implant hay cắm Implant) là phương pháp phục hình răng mất tiên tiến nhất
             hiện nay với trụ Implant được cấy vào xương hàm nhằm thay thế cho chân răng bị mất, nhờ đó đảm bảo chức năng ăn nhai
             và giữ được thẩm mỹ nụ cười. Nhiều khách hàng tin tưởng chọn dịch vụ cấy ghép Implant tại Nha Khoa Phương Thành
            đã hoàn toàn hài lòng về trải nghiệm cũng như kết quả điều trị, ăn ngon – sống khỏe, cải thiện chất lượng
            cuộc sống đáng kể.
          </div>
      </div>
    </div>
  </div>



</template>

<script>
import { ref } from "vue";
const currentDate = ref(new Date());
import AOS from "aos";
import "aos/dist/aos.css";
import indexfivespecialities from "@/assets/json/indexfivespecialities.json";
import 'vue3-carousel/dist/carousel.css'
export default {
  components: {
  },
  data() {
    return {
      startdate: currentDate,
      indexfivespecialities: indexfivespecialities,
      list: [],
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  props: {
    header: { type : Object}
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  watch:{
    '$props':{
      handler: function (val) {
        this.list = val.header;
      //  console.log("LOG WATCH LIST ", this.list )
      },
      deep: true
    }
  },
  methods: {
    submitForm() {
      this.$router.push("/search-2");
    },
  },
};
</script>
<style>
</style>
