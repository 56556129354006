<template>
  <div class="container pt-60 pb-30">
    <div class="main-title text-center pb-30">Những lưu ý giúp niềng răng thẩm mỹ thành công</div>

    <!-- Khung 1 -->
    <div class="box-luu-y box-primary">
      <h2>Chăm sóc và vệ sinh sau khi niềng răng</h2>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Dùng thuốc theo đơn của Bác sĩ chuyên khoa Răng – Hàm – Mặt.</span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Trong 2-3 ngày đầu có thể chảy máu ít. Những lúc đó không nên súc miệng và khạc nhổ nhiều.</span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Trong trường hợp chảy máu nhiều, dùng gạc vô trùng gấp lại cắn chặt và dùng túi chườm đá phía ngoài.</span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Trường hợp có sưng nề dùng túi chườm má. Khi chườm bệnh nhân cần lưu ý chườm khoảng 5 phút và nghỉ 5 phút để tránh tình trạng bị phỏng lạnh.</span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Chỉ khâu có thể tự tiêu sau 2 tuần hoặc cắt sau khoảng 12 ngày tùy theo hướng dẫn Bác sĩ.</span>
      </div>
    </div>

    <!-- Khung 2 -->
    <div class="box-luu-y box-secondary">
      <h2>Trong trường hợp có nâng xoang</h2>
      <p class="desc-title">Sau phẫu thuật, không dùng nước băng nóng, không xì mũi trong 15 ngày. Tránh máy bay trong tuần đầu tiên sau phẫu thuật.</p>

      <h2>Chế độ sinh hoạt ăn uống</h2>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Tránh hút thuốc vì điều này có thể làm chậm quá trình lành thương.</span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Nên ăn đồ mềm, nguội, tránh dùng các loại chất kích thích như: bia, rượu, đồ ăn cay, nóng, lạnh.</span>
      </div>
    </div>

  </div>

</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  data() {
    return {
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  created() {

  },
  methods: {

    toggleFavorite(item) {
      item.favorite = !item.favorite;
    },
  },
};
</script>

<style>

</style>
