<template>
  <div class="container pt-60 pb-30">
    <div class="main-title text-center pb-30">Lợi ích khi làm thẩm mỹ răng</div>

    <!-- Khung 2 -->
    <div class="box-luu-y box-secondary">
      <h2>Thẩm mỹ</h2>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Nụ cười đẹp hơn: Đây là lợi ích rõ ràng nhất. Thẩm mỹ răng giúp cải thiện hình dáng, màu sắc, 
          và sự sắp xếp của răng, mang lại cho bạn nụ cười rạng rỡ và tự tin hơn.
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Gương mặt hài hòa hơn: Một nụ cười đẹp có thể làm thay đổi đáng kể diện mạo của khuôn mặt, 
          giúp gương mặt trở nên cân đối và hài hòa hơn.
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Tăng sự tự tin: Nụ cười đẹp giúp bạn tự tin hơn trong giao tiếp, công việc và cuộc sống.
        </span>
      </div>
      
      <h2>Chức năng</h2>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Cải thiện khả năng ăn nhai: Một số phương pháp thẩm mỹ răng, chẳng hạn như bọc răng sứ hoặc implant, 
          có thể khôi phục chức năng ăn nhai của răng bị hư tổn hoặc mất.
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Cải thiện khớp cắn: Niềng răng và các phương pháp chỉnh nha khác có thể điều chỉnh khớp cắn, 
          giúp giảm đau hàm, đau đầu, và các vấn đề khác liên quan đến khớp cắn sai.
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Cải thiện phát âm: Răng đóng vai trò quan trọng trong việc phát âm. Thẩm mỹ răng có thể giúp cải thiện phát âm, 
          đặc biệt là trong trường hợp mất răng hoặc răng bị lệch lạc.
        </span>
      </div>

      <h2>Sức khỏe răng miệng</h2>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Dễ dàng vệ sinh răng miệng hơn: Một số phương pháp thẩm mỹ răng, chẳng hạn như niềng răng, có thể giúp bạn dễ dàng 
          vệ sinh răng miệng hơn, giảm nguy cơ mắc các bệnh về răng miệng.
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Bảo vệ răng thật: Bọc răng sứ có thể bảo vệ răng thật khỏi sâu răng và các tác động bên ngoài khác.
        </span>
      </div>

      <h2>Lợi ích tâm lý</h2>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Tăng cường sự tự tin: Như đã đề cập, nụ cười đẹp giúp bạn tự tin hơn trong giao tiếp và cuộc sống.
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Cải thiện chất lượng cuộc sống: Nụ cười đẹp và hàm răng khỏe mạnh có thể cải thiện đáng kể chất lượng cuộc sống của bạn.
        </span>
      </div>
    </div>

  </div>

</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  data() {
    return {
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  created() {

  },
  methods: {

    toggleFavorite(item) {
      item.favorite = !item.favorite;
    },
  },
};
</script>

<style>

</style>
