<template>
  <div class="container pt-60 pb-30">
    <div class="main-title text-center pb-30">Những dấu hiệu cho thấy trẻ cần được đưa đi khám nha khoa</div>

    <!-- Khung 1 -->
    <div class="box-luu-y box-primary">
      <h2>Dấu hiệu nhận biết:</h2>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Răng bị sâu: Răng có màu vàng hoặc nâu, xuất hiện lỗ nhỏ trên bề mặt răng.</span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Viêm lợi: Lợi sưng đỏ, chảy máu khi đánh răng.</span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Răng mọc lệch, hô, móm: Răng mọc không đều, ảnh hưởng đến thẩm mỹ và chức năng ăn nhai.</span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Trẻ thường xuyên kêu đau răng.</span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Trẻ có thói quen ngậm mút tay, mút môi, đẩy lưỡi.</span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Trẻ bị chấn thương răng.</span>
      </div>
    </div>

    <!-- Khung 2 -->
    <div class="box-luu-y box-secondary">
      <h2>Lợi ích của việc khám răng định kỳ</h2>
      <p class="desc-title">
        Việc đưa trẻ đi khám răng định kỳ là vô cùng quan trọng. Hãy tạo cho bé thói quen khám răng từ nhỏ để đảm bảo sức khỏe răng miệng tốt nhất cho bé.
      </p>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Phòng ngừa bệnh lý răng miệng: Khám răng định kỳ giúp phát hiện và điều trị sớm các bệnh lý răng miệng, tránh những biến chứng nguy hiểm.</span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Giúp răng mọc đều và đẹp: Bác sĩ sẽ theo dõi sự phát triển của răng hàm mặt và đưa ra các biện pháp can thiệp sớm nếu cần thiết.</span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Tạo thói quen chăm sóc răng miệng tốt: Khám răng định kỳ giúp trẻ hình thành thói quen chăm sóc răng miệng đúng cách.</span>
      </div>
    </div>

  </div>

</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  data() {
    return {
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  created() {

  },
  methods: {

    toggleFavorite(item) {
      item.favorite = !item.favorite;
    },
  },
};
</script>

<style>

</style>
