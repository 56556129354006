<template>
  <section class="service-section section-list-service pt-60 pb-60">
    <div class="container" v-for="(value) in this.list" :key="value.id">
      <div class="row"  >
        <div class="col-md-12" >
          <div class="section-header-one section-header-slider pb-30">
            <div class="sub-title pt-10">TIN TỨC</div>
            <div class="main-title"><span> {{value.name}}</span></div>

          </div>
        </div>
      </div>
      <div class="listing-service pt-30">
        <div class="row" style="padding: 10px;">
          <swiper
            :breakpoints="{
              320: { slidesPerView: 1 },  // Với màn hình nhỏ hơn 320px, hiển thị 1 slide
              640: { slidesPerView: 2 },  // Với màn hình từ 640px trở lên, hiển thị 2 slide
              1024: { slidesPerView: 3 }, // Với màn hình từ 1024px trở lên, hiển thị 3 slide
            }"
            :spaceBetween="20"
            :navigation="true"
            :pagination="true"
            :loop="true"
            :modules="modules"
            class="swiper-news"
          >
            <swiper-slide class="slide-news" v-for="(item, index) in value.newsList" :key="index">
              <router-link
                  :to="{
                    path: `/dich-vu/${item.id}`,
                  }"
              >
                <div class="service-item">
                  <div class="service-thumb">
                    <a href="">
                      <img width="300" height="208" :src="item.fileImage.urlFile" class="attachment-medium img-dichvu size-medium wp-post-image" alt="Ảnh" decoding="async" fetchpriority="high" srcset="" sizes="100vw">
                    </a>
                  </div>
                  <div class="service-content">
                    <span>Giải pháp tiên tiến cho người mất răng</span>
                    <div class="service-title">
                      <h3>
                        <a href="" data-wpel-link="internal">
                          {{ item.name}}
                        </a>
                      </h3>
                    </div>
                    <div class="service-excerpt">
                      <p>{{ item.describe }}</p>
                    </div>
                    <div class="service-button">
                      <a href="" data-wpel-link="internal">
                        <div>
                          Xem thêm
                        </div>
                        <i class="fa fa-solid fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </router-link>
            </swiper-slide>
          </swiper>
          <!-- <div class="col-xl-4 col-md-6 col-12" v-for="(item, index) in value.newsList" :key="index">
            <router-link
                :to="{
                  path: `/dich-vu/${item.id}`,
                }"
            >
              <div class="service-item">
                <div class="service-thumb">
                  <a href="">
                    <img width="300" height="208" :src="item.fileImage.urlFile" class="attachment-medium img-dichvu size-medium wp-post-image" alt="Ảnh" decoding="async" fetchpriority="high" srcset="" sizes="100vw">
                  </a>
                </div>
                <div class="service-content">
                  <span>Giải pháp tiên tiến cho người mất răng</span>
                  <div class="service-title">
                    <h3>
                      <a href="" data-wpel-link="internal">
                        {{ item.name}}
                      </a>
                    </h3>
                  </div>
                  <div class="service-excerpt">
                    <p>{{ item.describe }}</p>
                  </div>
                  <div class="service-button">
                    <a href="" data-wpel-link="internal">
                      <div>
                        Xem thêm
                      </div>
                      <i class="fa fa-solid fa-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </router-link>
          </div> -->
        </div>
      </div>
    </div>
  </section>

<!--    <section class="book-appointment-section aos" data-aos="fade-up">-->
<!--      <div class="container">-->
<!--        <div  v-for="(value) in this.list" :key="value.id">-->
<!--          <div class="row mb-3">-->
<!--            <div class="col-md-12" style="display: flex; justify-content: space-between; align-items: center;">-->
<!--              <div style="font-size: 28px; text-transform: uppercase; font-weight: bold; color: #be8b43;">{{value.name}}</div>-->
<!--              <div>-->
<!--                <router-link-->
<!--                    :to="{-->
<!--                        path: `/ban-tin/${value.newsList[0].menu.id}`,-->
<!--                    }"-->
<!--                >-->
<!--                  <b-button-->
<!--                      class="btn-chitiet"-->
<!--                      v-if ="value.newsList"-->

<!--                  >-->
<!--                    Xem tất cả-->
<!--                  </b-button>-->
<!--                </router-link>-->


<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="row" v-if="value.newsList.length > 3">-->
<!--            <div class="col-md-6 pb-3">-->
<!--              <div class="card-news" >-->
<!--                <div>-->
<!--                  <router-link-->
<!--                      :to="{-->
<!--                        path: `/ban-tin/${value.newsList[0].id}`,-->
<!--                    }"-->
<!--                  >-->
<!--                    <div class="img-news mb-3">-->
<!--                      <img-->
<!--                          v-if="value.newsList[0].fileImage"-->
<!--                          class="img-fluid"-->
<!--                          :src="value.newsList[0].fileImage.urlFile"-->
<!--                          alt="Post Image"-->
<!--                      />-->
<!--                    </div>-->
<!--                    <div class="content-news">-->
<!--                      <div class="title-news mb-2">-->
<!--                        <p style="font-size: 18px; font-family: 'Poppins Bold'; color: #000;">-->
<!--                          {{ value.newsList[0].name }}-->
<!--                        </p>-->
<!--                      </div>-->
<!--                      <div class="decs-news mb-3">-->
<!--                        {{ value.newsList[0].describe }}-->
<!--                      </div>-->
<!--                      <b-button-->
<!--                          class="btn-chitiet"-->
<!--                          :to="{-->
<!--                        path: `/ban-tin/${value.newsList[0].id}`,-->
<!--                    }"-->
<!--                      >-->
<!--                        Xem thêm-->
<!--                      </b-button>-->
<!--                    </div>-->
<!--                  </router-link>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="col-md-6">-->
<!--              <div class="col-md-12 card-news" v-for="(item, index) in value.newsList.slice(1, 4)" :key="index.id" style="padding: 0 10px 10px;">-->
<!--                <div class="row" >-->
<!--                  <div class="col-md-6">-->
<!--                    <router-link-->
<!--                      :to="{-->
<!--                        path: `/ban-tin/${item.id}`,-->
<!--                    }"-->
<!--                    >-->
<!--                      <div class="">-->
<!--                        <div class="img-news">-->
<!--                          <img-->
<!--                              v-if="item.fileImage"-->
<!--                              class="img-fluid"-->
<!--                              :src="item.fileImage.urlFile"-->
<!--                              alt="Post Image"-->
<!--                          />-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </router-link>-->
<!--                  </div>-->
<!--                  <div class="col-md-6 card-news-sub">-->
<!--                    <div class="content-news">-->
<!--                      <router-link-->
<!--                        :to="{-->
<!--                          path: `/ban-tin/${item.id}`,-->
<!--                      }"-->
<!--                      >-->
<!--                        <div class="title-news mb-2">-->
<!--                          <p>-->
<!--                            {{ item.name }}-->
<!--                          </p>-->
<!--                        </div>-->
<!--                        <div class="decs-news">-->
<!--                          {{ item.describe }}-->
<!--                        </div>-->
<!--                        <div style="float: right; margin-top: 10px;">-->
<!--                          <b-button-->
<!--                              class="btn-chitiet"-->
<!--                              style="font-size: 14px;"-->
<!--                          >-->
<!--                            Xem thêm-->
<!--                          </b-button>-->
<!--                        </div>-->
<!--                      </router-link>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </section>-->

  </template>
  <script>
  import AOS from "aos";
  import "aos/dist/aos.css";

  import { Swiper, SwiperSlide } from 'swiper/vue';

  // Import Swiper styles
  import 'swiper/css';
  import 'swiper/css/pagination';
  // import required modules
  import { Navigation, Pagination } from 'swiper/modules';

  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    data() {
      return {
        modules: [Navigation, Pagination],
        settings: {
          itemsToShow: 1,
          snapAlign: 'center',
        },
        // breakpoints are mobile first
        // any settings not specified will fallback to the carousel settings
        breakpoints: {
          // 700px and up
          576: {
            itemsToShow: 2,
            snapAlign: 'start',
          },
          768: {
            itemsToShow: 2,
            snapAlign: 'start',
          },
          // 1024 and up
          992: {
            itemsToShow: 3,
            snapAlign: 'start',
          },
          1200: {
            itemsToShow: 3,
            snapAlign: 'start',
          },
        },
        flickityOptions: {
          initialIndex: 0,
          initialSlides: 4,
          prevNextButtons: false,
          pageDots: false,
          wrapAround: true,
          autoPlay: 4000

          // any options from Flickity can be used
        },
        list: [],
        first: {},
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
      this.getData();
    },
    computed: {
    },
    methods: {
      async getData() {
        let params = {
            start: 0,
            limit: 4,
            sortBy: this.sortBy,
        }
        await this.$store.dispatch("newsStore/getPagingFeaturedNews", params ).then(res => {
          if (res != null && res.code ===0)
          {
            this.list = res.data;
            console.log("LOG DU LIEU NE : ", this.list)
          }

        });
      },

      next() {
        this.$refs.flickity.next();
      },

      previous() {
        this.$refs.flickity.previous();
      },
      toggleFavorite(item) {
        item.favorite = !item.favorite;
      },
    },
  };
  </script>

  <style>


  </style>

