<template>
  <div class="container pt-60 pb-60">
    <div class="main-title text-center">Quy trình dán sứ veneer</div>
    <div class="timeline">
      <div class="pt-10 pb-10">
        <div class="card-time-line right">
          <div class="date">
            <img src="@/assets/img/phuongthanh/dichvu/buoc1.png" alt="">
          </div>
          <div class="content-time-line">
            <h2>Bước 1</h2>
            <p>
              Khám tổng quát và kiểm tra tình trạng sức khỏe
            </p>
            <p class="desc-title pt-2">
              Để quy trình dán sứ Veneer diễn ra an toàn và hiệu quả, bác sĩ sẽ tiến hành thăm khám và kiểm tra 
              sức khỏe tổng quát. Nếu răng miệng của bạn đang gặp các vấn đề như sâu răng, viêm nha chu,.. thì cần phải 
              điều trị dứt điểm, sau đó mới tiến hành mài răng và dán Veneer.
            </p>
          </div>
        </div>
      </div>

      <div class="pt-10 pb-10">
        <div class="card-time-line left">
          <div class="date">
            <img src="@/assets/img/phuongthanh/dichvu/buoc2.png" alt="">
          </div>
          <div class="content-time-line">
            <h2>Bước 2</h2>
            <p>
              Tiến hành gây tê, mài răng
            </p>
            <p class="desc-title pt-2">
              Tiếp theo, bác sĩ sẽ vệ sinh răng miệng, gây tê và mài một lớp mỏng (0,3 – 0,6 mm) trên bề mặt răng thật 
              để tạo độ bám cho mặt dán sứ. Do không phải mài răng quá nhiều nên răng thật được bảo tồn.
            </p>
            <p class="desc-title pt-2">
              Sau khi mài răng, bác sĩ sẽ lấy dấu hàm để thiết kế mặt dán sứ Veneer phù hợp với răng thật.
            </p>
            <p class="desc-title pt-2">
              Công đoạn này đòi hỏi tay nghề của bác sĩ phải giỏi để thực hiện mọi thao tác một cách chính xác nhất.
            </p>
          </div>
        </div>
      </div>

      <div class="pt-10 pb-10">
        <div class="card-time-line right">
          <div class="date">
            <img src="@/assets/img/phuongthanh/dichvu/buoc3.png" alt="">
          </div>
          <div class="content-time-line">
            <h2>Bước 3</h2>
            <p>
              Lấy dấu răng gửi về phòng Labo tại nha khoa
            </p>
            <p class="desc-title pt-2">
              Sau khi đã mài răng xong, bác sĩ sẽ lấy dấu răng và gửi mẫu về phòng Labo để các kỹ thuật viên chế tác 
              mặt dán sứ chính xác theo khuôn răng của bệnh nhân.
            </p>
            <p class="desc-title pt-2">
              Các loại răng sứ hiện nay đều được thiết kế và cắt theo công nghệ CAD/CAM hiện đại. Nhằm đảm bảo mỗi 
              răng sứ hoàn thành đều đạt độ chính xác và tính thẩm mỹ cao nhất.
            </p>
          </div>
        </div>
      </div>

      <div class="pt-10 pb-10">
        <div class="card-time-line left">
          <div class="date" style="right: -300px;">
            <img src="@/assets/img/phuongthanh/dichvu/buoc4.png" alt="">
          </div>
          <div class="content-time-line">
            <h2>Bước 4</h2>
            <p>
              Bác sĩ kiểm tra khớp cắn, so sánh và tiến hành gắn răng sứ cố định
            </p>
            <p class="desc-title pt-2">
              Mặt sứ sẽ được bác sĩ dán thử lên mặt ngoài của răng, nếu đảm bảo mọi tiêu chí về thẩm mỹ, khớp cắn… thì 
              sẽ tiến hành gắn cố định bằng chất kết dính chuyên biệt của nha khoa. Đảm bảo mặt dán sứ không bị xê dịch 
              và mang lại hiệu quả thẩm mỹ tối đa.
            </p>
          </div>
        </div>
      </div>

      <div class="pt-10 pb-10">
        <div class="card-time-line right">
          <div class="date">
            <img src="@/assets/img/phuongthanh/dichvu/buoc5.png" alt="">
          </div>
          <div class="content-time-line">
            <h2>Bước 5</h2>
            <p>
              Chăm sóc và tái khám định kỳ
            </p>
            <p class="desc-title pt-2">
              Bạn nên đến nha khoa tái khám theo lịch hẹn, để bác sĩ có thể theo dõi răng sứ hoạt động có hiệu quả không. 
              Nếu có vấn đề phát sinh, bác sĩ có thể xử lý kịp thời.
            </p>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import { ref } from "vue";
const currentDate = ref(new Date());
import AOS from "aos";
import "aos/dist/aos.css";
import 'vue3-carousel/dist/carousel.css'
export default {
  components: {
  },
  data() {
    return {
      startdate: currentDate,
      list: [],
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  props: {
    header: { type : Object}
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  watch:{
    '$props':{
      handler: function (val) {
        this.list = val.header;
        //  console.log("LOG WATCH LIST ", this.list )
      },
      deep: true
    }
  },
  methods: {
    submitForm() {
      this.$router.push("/search-2");
    },
  },
};
</script>
<style>
</style>
