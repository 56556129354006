<template>
  <div class="container pt-60 pb-60">
    <div class="row">
      <div class="col-md-6 who-trongrang">
        <div>
          <div class="main-title pb-30">Những ai nên trồng răng Implant?</div>
          <div class="desc-title">Cấy ghép Implant là phương pháp phục hình răng hiện đại và tối ưu, đặc biệt phù hợp trong các trường hợp sau:</div>
          <div class="card-who-trongrang pt-30">
            <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
            <div class="desc-title">Bệnh nhân mất răng có nhu cầu làm răng cố định mà không cần mài răng thật.</div>
          </div>
          <div class="card-who-trongrang pt-20">
            <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
            <div class="desc-title">Người bị mất răng không muốn dùng hàm tháo lắp, các răng còn lại trên hàm không đủ sức để làm trụ cầu – đặc biệt là trong trường hợp bị mất nhiều răng.</div>
          </div>
          <div class="card-who-trongrang pt-20">
            <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
            <div class="desc-title">
              Người bệnh có nhu cầu làm răng giả nhưng muốn bảo tồn, không gây tiêu xương hàm ở những vùng răng đã bị mất đi.
              Implant là 1 loại vít nhỏ có kích thước vừa bằng 1 chân răng thật, được làm từ Titanium một loại vật liệu có khả năng tương thích sinh học khá tốt với xương.
            </div>
          </div>
        </div>


      </div>
      <div class="col-md-6">
        <div class="img-trongrang">
          <img src="@/assets/img/phuongthanh/dichvu/who.png" alt="" style="max-height: 500px;">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref } from "vue";
  const currentDate = ref(new Date());
  import AOS from "aos";
  import "aos/dist/aos.css";
  import 'vue3-carousel/dist/carousel.css'
  export default {
    components: {
    },
    data() {
      return {
        startdate: currentDate,
        list: [],
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    props: {
      header: { type : Object}
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
    watch:{
      '$props':{
        handler: function (val) {
          this.list = val.header;
        //  console.log("LOG WATCH LIST ", this.list )
        },
        deep: true
      }
    },
    methods: {
      submitForm() {
        this.$router.push("/search-2");
      },
    },
  };
</script>
<style>
</style>
