<template>
    <div class="container pt-60 pb-60">
      <div class="row">
        <div class="col-md-12 who-trongrang">
          <div>
            <div class="main-title pb-30">Trường Hợp Nào Làm Răng Sứ Thẩm Mỹ?</div>
            <div class="desc-title">
              Bọc răng sứ là một giải pháp thẩm mỹ tuyệt vời, nhưng không phải ai cũng phù hợp. Dưới đây là một số trường hợp nên xem xét bọc răng sứ:
            </div>
            <div class="card-who-trongrang pt-30">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Răng bị nhiễm màu: Nhiễm màu Tetracycline: Đây là tình trạng răng bị đổi màu do sử dụng kháng sinh tetracycline trong thời kỳ răng đang phát triển. 
                Răng sứ có thể che phủ hoàn toàn vết nhiễm màu này, mang lại màu sắc trắng sáng tự nhiên. Nhiễm màu Fluor: Răng bị nhiễm fluor có thể xuất hiện các 
                đốm trắng hoặc nâu trên bề mặt răng. Răng sứ giúp cải thiện màu sắc và vẻ ngoài của răng. Nhiễm màu do thực phẩm và đồ uống: Răng bị ố vàng do cà phê,
                 trà, rượu vang đỏ,... có thể được cải thiện bằng răng sứ.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Răng bị khiếm khuyết về hình dạng: Răng sứt mẻ, gãy vỡ: Răng sứ giúp phục hồi hình dạng và chức năng của răng bị sứt mẻ hoặc gãy vỡ.
                Răng mòn: Răng bị mòn do nghiến răng, chải răng quá mạnh hoặc các yếu tố khác có thể được phục hồi bằng răng sứ.
                Răng dị dạng: Răng có hình dạng bất thường, quá nhỏ hoặc quá to có thể được điều chỉnh bằng răng sứ.
                Răng thưa, hở kẽ: Răng sứ có thể giúp đóng kín khoảng trống giữa các răng, tạo nụ cười đều đặn hơn. (Tuy nhiên, trong nhiều trường hợp, niềng răng hoặc mặt dán sứ Veneer có thể là lựa chọn tốt hơn).
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Răng bị hư tổn do sâu răng hoặc bệnh lý: Răng đã điều trị tủy: Răng sau khi điều trị tủy thường trở nên giòn và dễ gãy. Bọc răng sứ giúp bảo vệ răng và phục hồi chức năng ăn nhai.
                Răng bị sâu nặng: Khi răng bị sâu quá nhiều, không thể trám lại được nữa, bọc răng sứ là giải pháp tốt để bảo tồn răng.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Cải thiện khớp cắn: Trong một số trường hợp, răng sứ có thể được sử dụng để cải thiện khớp cắn, đặc biệt là khi kết hợp với các phương pháp chỉnh nha khác.
              </div>
            </div>

            <div class="desc-title pt-30">Tuy nhiên, bạn KHÔNG NÊN Bọc răng sứ trong các trường hợp sau:</div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Vệ sinh răng miệng kém: Vi khuẩn có thể tích tụ ở viền nướu xung quanh mão sứ nếu không được vệ sinh kỹ lưỡng. Điều này có thể dẫn đến viêm nướu, viêm nha chu và thậm chí mất răng. 
                Nếu bạn không tự tin duy trì vệ sinh răng miệng tốt, bọc răng sứ có thể không phải là lựa chọn tốt.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Nghiến răng hoặc siết chặt răng: Áp lực mạnh từ việc nghiến hoặc siết chặt răng có thể làm nứt, vỡ hoặc mẻ mão sứ. Nếu bạn có thói quen này, cần điều trị trước hoặc 
                sử dụng máng chống nghiến răng vào ban đêm để bảo vệ mão sứ.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Trẻ em và thanh thiếu niên: Răng và xương hàm của trẻ em và thanh thiếu niên vẫn đang phát triển. Bọc răng sứ có thể can thiệp vào quá trình phát triển tự nhiên này. 
                Chỉ nên bọc răng sứ cho trẻ em và thanh thiếu niên trong những trường hợp thực sự cần thiết và sau khi đã được bác sĩ nha khoa đánh giá kỹ lưỡng.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Răng bị sâu hoặc nhiễm trùng: Cần điều trị triệt để sâu răng và nhiễm trùng trước khi bọc răng sứ. Bọc răng sứ lên trên răng bị bệnh có thể khiến tình trạng nặng hơn và dẫn đến mất răng.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Thiếu mô răng nâng đỡ: Nếu răng bị mất quá nhiều mô cứng, không đủ để nâng đỡ mão sứ, bác sĩ có thể đề nghị các phương pháp khác như cấy ghép implant.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Dị ứng với vật liệu làm mão sứ: Mặc dù hiếm gặp, nhưng một số người có thể bị dị ứng với kim loại hoặc các thành phần khác trong mão sứ. Hãy thông báo cho bác sĩ nếu bạn có bất kỳ tiền sử dị ứng nào.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Mong đợi phi thực tế: Bọc răng sứ có thể cải thiện đáng kể nụ cười, nhưng không phải là phép màu. Hãy thảo luận kỹ với bác sĩ về những gì bạn mong đợi để đảm bảo rằng bạn có những kỳ vọng thực tế về kết quả.
              </div>
            </div>
          </div>


        </div>
<!--        <div class="col-md-6">-->
<!--            <div class="img-trongrang">-->
<!--                <img src="@/assets/img/phuongthanh/dichvu/who.png" alt="" style="max-height: 500px;">-->
<!--            </div>-->
<!--        </div>-->
      </div>
    </div>



</template>

<script>
  import { ref } from "vue";
  const currentDate = ref(new Date());
  import AOS from "aos";
  import "aos/dist/aos.css";
  import 'vue3-carousel/dist/carousel.css'
  export default {
    components: {
    },
    data() {
      return {
        startdate: currentDate,
        list: [],
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    props: {
      header: { type : Object}
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
    watch:{
      '$props':{
        handler: function (val) {
          this.list = val.header;
        //  console.log("LOG WATCH LIST ", this.list )
        },
        deep: true
      }
    },
    methods: {
      submitForm() {
        this.$router.push("/search-2");
      },
    },
  };
</script>
<style>
</style>
