<template>
  <div class="container pt-60 pb-60">
    <div class="main-title text-center pb-30">
      Làm răng Implant<br>
      An toàn tối đa, Kết quả vượt trội
    </div>
    <div class="desc-title text-center">
      Cấy ghép Implant là giải pháp điều trị mất răng ưu việt nhất hiện nay và có những ưu điể vượt trội khắc phục
      những hạn chế của kỹ thuật trồng răng truyền thống, mang đến nhiều lợi ích hơn cho bệnh nhân đang có nhu cầu
      điều trị mất răng.
    </div>
    <div class="desc-title text-center">
      Lúc trước để phục hồi răng mất có 2 phương pháp phổ biến là trồng răng giả tháo lắp và lắp cầu răng sứ.
    </div>
    <div class="desc-title text-center">
      Hiện nay, với sự phát triển mạnh mẽ của nền y học, việc phục hồi răng đã mất đã được nâng lên một tầm cao mới với một giải pháp mới – Cấy ghép Implant.
    </div>
    <div class="list-lamrang pt-30 row">
      <div class="card-lamrang mb-3">
        Cấy ghép Implant không làm tiêu xương hàm.
      </div>

      <div class="card-lamrang mb-3">
        Răng Implant chắc khỏe như răng thật và thẩm mỹ tự nhiên.
      </div>

      <div class="card-lamrang mb-3">
        Phương pháp cấy ghép Implant không gây khó chịu.
      </div>

      <div class="card-lamrang mb-3">
        Bảo tồn răng thật xung quanh khỏe mạnh.
      </div>

      <div class="card-lamrang mb-3">
        Vệ sinh răng thuận tiện vô cùng.
      </div>

      <div class="card-lamrang mb-3">
        Implant giúp cố định răng vĩnh viễn.
      </div>

      <div class="card-lamrang mb-3">
        Hình dáng khuôn mặt không bị biến dạng.
      </div>

      <div class="card-lamrang mb-3">
        Răng Implant giúp bạn có thể thoải mái ăn uống.
      </div>

      <div class="card-lamrang mb-3">
        Trồng răng Implant có tuổi thọ lâu dài, có thể tồn tại suốt đời.
      </div>
    </div>
    <div class="pt-30" style="height: 430px; text-align: center;">
      <img src="@/assets/img/phuongthanh/dichvu/rang-implant.png" alt="" style="height: 100%;">
    </div>
  </div>

</template>

<script>
import { ref } from "vue";
const currentDate = ref(new Date());
import AOS from "aos";
import "aos/dist/aos.css";
import 'vue3-carousel/dist/carousel.css'
export default {
  components: {
  },
  data() {
    return {
      startdate: currentDate,
      list: [],
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  props: {
    header: { type : Object}
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  watch:{
    '$props':{
      handler: function (val) {
        this.list = val.header;
        //  console.log("LOG WATCH LIST ", this.list )
      },
      deep: true
    }
  },
  methods: {
    submitForm() {
      this.$router.push("/search-2");
    },
  },
};
</script>
<style>
</style>

