<template>
  <div class="container pt-60 pb-60">
    <div class="main-title text-center">Quy trình niềng răng trẻ em</div>
    <div class="timeline">
      <div class="pt-10 pb-10">
        <div class="card-time-line right">
          <div class="date">
            <img src="@/assets/img/phuongthanh/dichvu/buoc1.png" alt="">
          </div>
          <div class="content-time-line">
            <h2>Bước 1</h2>
            <p>
              Đánh giá và chuẩn đoán:
            </p>
            <p class="desc-title pt-2">
              Bác sĩ chuyên khoa sẽ tiến hành thăm khám chi tiết, bao gồm việc kiểm tra tình trạng khớp thái dương hàm, 
              kiểm tra cử động miệng, chụp X-quang và thực hiện các xét nghiệm cần thiết để xác định nguyên nhân gây 
              ra rối loạn thái dương hàm.
            </p>
          </div>
        </div>
      </div>

      <div class="pt-10 pb-10">
        <div class="card-time-line left">
          <div class="date">
            <img src="@/assets/img/phuongthanh/dichvu/buoc2.png" alt="">
          </div>
          <div class="content-time-line">
            <h2>Bước 2</h2>
            <p>
              Lập kế hoạch điều trị:
            </p>
            <p class="desc-title pt-2">
              Sau khi xác định được tình trạng bệnh lý, bác sĩ sẽ thiết lập kế hoạch điều trị cá nhân hóa. 
              Kế hoạch này có thể bao gồm việc sử dụng khí cụ nha khoa, thuốc giảm đau, điều trị vật lý trị 
              liệu hoặc kỹ thuật điều chỉnh khớp.
            </p>
          </div>
        </div>
      </div>

      <div class="pt-10 pb-10">
        <div class="card-time-line right">
          <div class="date">
            <img src="@/assets/img/phuongthanh/dichvu/buoc3.png" alt="">
          </div>
          <div class="content-time-line">
            <h2>Bước 3</h2>
            <p>
              Điều chỉnh và quản lý đau:
            </p>
            <p class="desc-title pt-2">
              Bác sĩ sẽ điều chỉnh khí cụ hoặc hướng dẫn cách sử dụng để giảm đau, cải thiện sự thoải mái cho người bệnh. 
              Điều này có thể bao gồm việc đeo máng bảo vệ hoặc sử dụng các dụng cụ hỗ trợ khác.
            </p>
          </div>
        </div>
      </div>

      <div class="pt-10 pb-10">
        <div class="card-time-line left">
          <div class="date" style="right: -300px;">
            <img src="@/assets/img/phuongthanh/dichvu/buoc4.png" alt="">
          </div>
          <div class="content-time-line">
            <h2>Bước 4</h2>
            <p>
              Phục hồi chức năng và kiểm tra định kỳ:
            </p>
            <p class="desc-title pt-2">
              Trong quá trình điều trị, người bệnh sẽ được theo dõi định kỳ để kiểm tra tiến triển và điều chỉnh kế hoạch 
              điều trị nếu cần thiết. Mục tiêu là phục hồi chức năng khớp và giảm thiểu các triệu chứng của 
              rối loạn thái dương hàm.
            </p>
          </div>
        </div>
      </div>

      <div class="pt-10 pb-10">
        <div class="card-time-line right">
          <div class="date">
            <img src="@/assets/img/phuongthanh/dichvu/buoc5.png" alt="">
          </div>
          <div class="content-time-line">
            <h2>Bước 5</h2>
            <p>
              Bảo trì và duy trì kết quả:
            </p>
            <p class="desc-title pt-2">
              Sau khi kết thúc điều trị, người bệnh cần duy trì các biện pháp bảo vệ như sử dụng máng bảo vệ hoặc tuân thủ 
              các hướng dẫn chăm sóc từ bác sĩ để tránh tái phát và duy trì kết quả điều trị lâu dài.
            </p>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import { ref } from "vue";
const currentDate = ref(new Date());
import AOS from "aos";
import "aos/dist/aos.css";
import 'vue3-carousel/dist/carousel.css'
export default {
  components: {
  },
  data() {
    return {
      startdate: currentDate,
      list: [],
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  props: {
    header: { type : Object}
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  watch:{
    '$props':{
      handler: function (val) {
        this.list = val.header;
        //  console.log("LOG WATCH LIST ", this.list )
      },
      deep: true
    }
  },
  methods: {
    submitForm() {
      this.$router.push("/search-2");
    },
  },
};
</script>
<style>
</style>
