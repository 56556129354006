<template>
    <div class="container pt-60 pb-60">
      <div class="main-title text-center">Quy trình niềng răng</div>
      <div class="timeline">
        <div class="pt-10 pb-10">
          <div class="card-time-line right">
            <div class="date">
              <img src="@/assets/img/phuongthanh/dichvu/buoc1.png" alt="">
            </div>
            <div class="content-time-line">
              <h2>Bước 1</h2>
              <p>
                Khám, scan răng, chụp phim và tư vấn với bác sĩ
              </p>
              <p class="desc-title pt-2">
                Trước tiên, Bác sĩ khám tổng quát về tình trạng răng miệng cho bệnh nhân. Nếu có các bệnh lý về răng như:
                nha chu, viêm nướu,…thì cần điều trị dứt điểm trước khi niềng răng thẩm mỹ. Đồng thời nếu bệnh nhân có các
                bệnh lý nền như huyết áp, tiểu đường, tim mạch,… thì nên khai báo rõ để Bác sĩ có đầy đủ thông tin và lên
                kế hoạch điều trị phù hợp.
              </p>
              <p class="desc-title pt-2">
                Bác sĩ còn chỉ định chụp phim X-ray, phim Cone Beam CT hàm có răng mất để kiểm tra mật độ xương, độ dày
                xương hàm. Trong trường hợp mất răng đã lâu, xương hàm bị tiêu nhiều thì cần phải ghép xương trong quá
                trình cấy Implant. Ngoài ra, các xét nghiệm máu cũng cần thực hiện để biết chính xác khả năng đông máu
                cũng như tầm soát các chỉ số sức khỏe khác.
              </p>
              <p class="desc-title pt-2">
                Dựa trên kết quả kiểm tra, Bác sĩ sẽ tư vấn kỹ càng, đưa ra kế hoạch điều trị phù hợp, chi phí và thời gian
                thực hiện cụ thể. Nếu đồng ý với kế hoạch này, bệnh nhân sẽ lấy hẹn để thực hiện niềng răng thẩm mỹ.
              </p>
            </div>
          </div>
        </div>

        <div class="pt-10 pb-10">
          <div class="card-time-line left">
            <div class="date">
              <img src="@/assets/img/phuongthanh/dichvu/buoc2.png" alt="">
            </div>
            <div class="content-time-line">
              <h2>Bước 2</h2>
              <p>
                Lên kế hoạch điều trị
              </p>
              <p class="desc-title pt-2">
                Bác sĩ vệ sinh răng miệng của Khách hàng, sau đó gây tê tại chổ và tiến hành cấy ghép trụ Implant trực tiếp
                vào xương hàm. Phòng Phẫu thuật niềng răng thẩm mỹ được thiết kế theo các tiêu chuẩn quốc tế. Ghế Phẫu thuật
                cao cấp nhập khẩu từ Hàn Quốc với hệ thống nước được vô trùng tuyệt đối, đảm bảo sự an toàn tối đa cho
                khách hàng.
              </p>
              <p class="desc-title pt-2">
                Bên cạnh các trang thiết bị hiện đại, sự dày dặn kinh nghiệm từ đội ngũ Bác sĩ chuyên môn sâu mang đến
                những thao tác khéo léo, chính xác, nhờ đó hạn chế tối đa xâm lấn và giảm sự sưng đau cho bệnh nhân sau phẫu thuật.
              </p>
              <p class="desc-title pt-2">
                Đối với những trường hợp niềng răng thẩm mỹ vùng thẩm mỹ hoặc Implant toàn hàm All On nhờ ứng dụng kỹ thuật
                số hiện đại như sử dụng hệ thống PIC giúp lấy dấu vị trí Implant nhanh chóng, có thể chuẩn bị răng tạm
                nhanh chóng cho bệnh nhân yên tâm sinh hoạt hàng ngày, không bị mất tự tin do mất răng.
              </p>
            </div>
          </div>
        </div>

        <div class="pt-10 pb-10">
          <div class="card-time-line right">
            <div class="date">
              <img src="@/assets/img/phuongthanh/dichvu/buoc3.png" alt="">
            </div>
            <div class="content-time-line">
              <h2>Bước 3</h2>
              <p>
                Gắn mắc cài,  khí cụ chỉnh nha
              </p>
              <p class="desc-title pt-2">
                Sau 7-14 ngày, Khách hàng cần quay lại phòng khám để Bác sĩ thăm khám, kiểm tra vết thương sau phẫu thuật.
              </p>
            </div>
          </div>
        </div>

        <div class="pt-10 pb-10">
          <div class="card-time-line left">
            <div class="date" style="right: -300px;">
              <img src="@/assets/img/phuongthanh/dichvu/buoc4.png" alt="">
            </div>
            <div class="content-time-line">
              <h2>Bước 4</h2>
              <p>
                Tái khám ( mỗi 3-4 tuần)
              </p>
              <p class="desc-title pt-2">
                Sau 6 tuần – 6 tháng bệnh nhân quay lại phòng khám để Bác sĩ kiểm tra trụ Implant đã tương thích tốt chưa.
                Nếu đã đủ điều kiện, Bác sĩ tiến hành gắn răng sứ trên Implant. Đối với bệnh nhân mất răng toàn hàm và
                tiêu xương nhiều thì thời gian này có thể dài hơn (khoảng 3-6 tháng). Trong lúc đó, bệnh nhân vẫn có
                răng tạm để sử dụng và sinh hoạt bình thường.
              </p>
              <p class="desc-title pt-2">
                Ở giái đoạn phục hình răng sứ trên Implant, bệnh nhân sẽ cần từ 3-4 buổi hẹn diễn ra trong vòng từ 10-14
                ngày. Buổi đầu tiên lấy dấu răng. Đây là bước vô cùng quan trọng và cần sự tỉ mỉ, chính xác để bộ răng
                sau cùng đáp ứng các tiêu chuẩn kỹ thuật. Nhờ đó, bệnh nhân có thể ăn uống, sử dụng tốt và lâu dài.
              </p>
              <p class="desc-title pt-2">
                Sau buổi hẹn lấy dấu, bệnh nhân sẽ cần thêm 2-3 buổi hẹn nữa để Bác sĩ thử răng và gắn răng hoàn tất.
              </p>
              <p class="desc-title pt-2">
                Tại Nha khoa Phương Thành, nhờ ứng dụng kỹ thuật số hiện đại trong Implant toàn hàm, giờ đây trong
                nhiều trường hợp, quy trình điều trị đã rút ngắn hơn. Chỉ trong vòng 2 tuần (14 ngày)
              </p>
              <p class="desc-title pt-2">
                Bệnh nhân đáp ứng đủ điều kiện để phục hình tức thì trên Implant sẽ có ngay hàm răng mới hoàn chỉnh,
                đảm bảo thẩm mỹ khi nói cười, thoải mái ăn uống và ngăn ngừa tiêu xương hàm tối đa. Hơn hết, quá trình
                điều trị được rút ngắn và không cần làm răng tạm, nên bệnh nhân tiết kiệm chi phí thăm khám và không
                cần phải quay lại nha khoa để làm phục hình sau cùng.
              </p>
            </div>
          </div>
        </div>

        <div class="pt-10 pb-10">
          <div class="card-time-line right">
            <div class="date">
              <img src="@/assets/img/phuongthanh/dichvu/buoc5.png" alt="">
            </div>
            <div class="content-time-line">
              <h2>Bước 5</h2>
              <p>
                Chăm sóc và tái khám định kỳ
              </p>
              <p class="desc-title pt-2">
                Sau khi hoàn tất Bác sĩ sẽ hướng dẫn khách hàng chăm sóc răng miệng tại nhà và dặn dò tái khám định
                kỳ 6 tháng/lần.
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>

</template>

<script>
  import { ref } from "vue";
  const currentDate = ref(new Date());
  import AOS from "aos";
  import "aos/dist/aos.css";
  import 'vue3-carousel/dist/carousel.css'
  export default {
    components: {
    },
    data() {
      return {
        startdate: currentDate,
        list: [],
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    props: {
      header: { type : Object}
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
    watch:{
      '$props':{
        handler: function (val) {
          this.list = val.header;
        //  console.log("LOG WATCH LIST ", this.list )
        },
        deep: true
      }
    },
    methods: {
      submitForm() {
        this.$router.push("/search-2");
      },
    },
  };
</script>
<style>
</style>
