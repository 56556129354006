<template>
  <div class="container pt-60 pb-60">
    <div class="row align-items-center">
      <div style="text-align: center; margin-bottom: 20px;">
          <div class="main-title pb-30">
            6 Ưu Điểm Vượt Trội<br>
            Khi Cấy Implant 1 vài răng
          </div>
          <div class="decs-intro-doctor">
            Cấy Implant 1 vài răng đang trở thành lựa chọn hàng đầu cho những người mất răng toàn hàm,
            không chỉ bởi tính thẩm mỹ mà còn vì nhiều lợi ích sức khỏe vượt trội.
          </div>
      </div>
    </div>
    <div class="container pt-60">
      <div class="row">
        <div class="col-md-6">
            <div class="img-trongrang">
                <img src="@/assets/img/phuongthanh/dichvu/uu-diem-implant-toan-ham.png" alt="" style="max-height: 500px;">
            </div>
        </div>
        <div class="col-md-6">
          <div class="card-uu-diem-implant">
            <div class="name-phuong-phap-implant pb-10">
              1. Phục hồi chức năng ăn nhai như răng thật
            </div>
            <div class="desc-title pb-10">
              Implant toàn hàm được gắn chắc chắn vào xương hàm, giúp bạn ăn nhai dễ dàng mà không lo bị lung lay như hàm giả truyền thống.
            </div>
            <div class="desc-title">
              Bạn có thể thưởng thức mọi loại thực phẩm, kể cả những món cứng hoặc dai, mà không gặp bất kỳ khó khăn nào.
            </div>
          </div>
          <div class="card-uu-diem-implant">
            <div class="name-phuong-phap-implant pb-10">
              2. Ngăn ngừa tiêu xương hàm
            </div>
            <div class="desc-title pb-10">
              Mất răng lâu ngày dẫn đến tiêu xương hàm, làm gương mặt lão hóa và biến dạng.
              Cấy ghép implant toàn hàm giúp kích thích xương hàm phát triển, ngăn ngừa tiêu xương hiệu quả,
              giúp khuôn mặt bạn giữ được nét trẻ trung.
            </div>
          </div>
          <div class="card-uu-diem-implant">
            <div class="name-phuong-phap-implant pb-10">
              3. Tuổi thọ cao, sử dụng lâu dài
            </div>
            <div class="desc-title pb-10">
              Với chất liệu titanium bền bỉ, răng implant có thể tồn tại 20-30 năm, thậm chí trọn đời nếu được chăm sóc
              đúng cách. Đây là giải pháp tiết kiệm về lâu dài so với hàm giả tháo lắp, vốn cần thay thế định kỳ.
            </div>
          </div>
          <div class="card-uu-diem-implant">
            <div class="name-phuong-phap-implant pb-10">
              4. Tính thẩm mỹ cao
            </div>
            <div class="desc-title pb-10">
              Răng được gắn trên trụ implant được thiết kế tự nhiên, khớp với hình dáng và màu sắc răng thật.
              Điều này giúp bạn tự tin nở nụ cười rạng rỡ, không sợ bị lộ dấu hiệu mất răng như khi dùng hàm
              giả truyền thống.
            </div>
          </div>
          <div class="card-uu-diem-implant">
            <div class="name-phuong-phap-implant pb-10">
              5. Cải thiện chất lượng cuộc sống
            </div>
            <div class="desc-title pb-10">
              Với hàm răng chắc khỏe và thẩm mỹ, bạn sẽ tự tin hơn khi giao tiếp, phát âm chuẩn hơn và tận hưởng
              cuộc sống một cách trọn vẹn. Đây không chỉ là giải pháp phục hồi răng mà còn giúp bạn cải thiện toàn
              diện sức khỏe và tinh thần.
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>



</template>

<script>
import { ref } from "vue";
const currentDate = ref(new Date());
import AOS from "aos";
import "aos/dist/aos.css";
import indexfivespecialities from "@/assets/json/indexfivespecialities.json";
import 'vue3-carousel/dist/carousel.css'
export default {
  components: {
  },
  data() {
    return {
      startdate: currentDate,
      indexfivespecialities: indexfivespecialities,
      list: [],
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  props: {
    header: { type : Object}
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  watch:{
    '$props':{
      handler: function (val) {
        this.list = val.header;
      //  console.log("LOG WATCH LIST ", this.list )
      },
      deep: true
    }
  },
  methods: {
    submitForm() {
      this.$router.push("/search-2");
    },
  },
};
</script>
<style>
</style>
