<template>
    <div class="container pt-60 pb-60">
      <div class="row">
        <div class="col-md-6 who-trongrang">
          <div>
            <div class="main-title pb-30">Dán Sứ Veneer Có Tốt Không? Vì Sao Ngày Càng Phổ Biến?</div>
            <div class="desc-title">Dán sứ Veneer mang lại nhiều ưu điểm vượt trội, giúp nó ngày càng trở nên phổ biến. Tuy nhiên, cũng cần cân nhắc kỹ lưỡng một số hạn chế trước khi quyết định thực hiện.</div>
            <p class="pt-20" style="margin-bottom: 0; font-size: 14">Những ưu điểm khiến dán sứ Veneer được ưa chuộng:</p>
            <div class="card-who-trongrang pt-10">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Thẩm mỹ vượt trội: Veneer che phủ hoàn hảo các khuyết điểm về màu sắc, hình dáng và kích thước răng, mang lại nụ cười trắng sáng, đều đặn và tự nhiên.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Bảo tồn răng tối đa: So với bọc răng sứ, Veneer chỉ cần mài một lớp mỏng men răng, bảo tồn răng thật nhiều hơn. 
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Bề mặt sứ bóng mượt, không bám màu: Veneer có khả năng chống bám màu từ thực phẩm và đồ uống, giúp duy trì màu sắc trắng sáng lâu dài.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Tương thích sinh học cao: Sứ là vật liệu lành tính, không gây kích ứng nướu và mô mềm.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Thời gian thực hiện nhanh chóng: Thường chỉ cần 2-3 lần hẹn với bác sĩ là hoàn tất quá trình.
              </div>
            </div>

            <p class="pt-30" style="margin-bottom: 0; font-size: 14">Vì sao Veneer ngày càng phổ biến?</p>
            <div class="card-who-trongrang pt-10">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Nhu cầu thẩm mỹ ngày càng cao: Trong xã hội hiện đại, nụ cười đẹp được xem là một yếu tố quan trọng, góp phần tạo nên sự tự tin và thành công.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Công nghệ nha khoa phát triển: Kỹ thuật chế tạo và dán Veneer ngày càng tiên tiến, cho kết quả chính xác và thẩm mỹ hơn.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Chi phí hợp lý hơn so với trước: Mặc dù chi phí Veneer vẫn cao hơn một số phương pháp khác, nhưng đã trở nên dễ tiếp cận hơn với nhiều người.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Ảnh hưởng từ truyền thông và mạng xã hội: Hình ảnh những nụ cười đẹp với Veneer được lan truyền rộng rãi trên truyền thông và mạng xã hội, 
                tạo nên xu hướng và sự quan tâm của công chúng.
              </div>
            </div>
          </div>


        </div>
        <div class="col-md-6">
            <div class="img-trongrang">
                <img src="@/assets/img/phuongthanh/dichvu/who.png" alt="" style="max-height: 500px;">
            </div>
        </div>
      </div>
    </div>



</template>

<script>
  import { ref } from "vue";
  const currentDate = ref(new Date());
  import AOS from "aos";
  import "aos/dist/aos.css";
  import 'vue3-carousel/dist/carousel.css'
  export default {
    components: {
    },
    data() {
      return {
        startdate: currentDate,
        list: [],
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    props: {
      header: { type : Object}
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
    watch:{
      '$props':{
        handler: function (val) {
          this.list = val.header;
        //  console.log("LOG WATCH LIST ", this.list )
        },
        deep: true
      }
    },
    methods: {
      submitForm() {
        this.$router.push("/search-2");
      },
    },
  };
</script>
<style>
</style>
