<template>
  <div class="container pt-60 pb-30">
    <div class="main-title text-center pb-30">Khi Bọc Răng Sứ Thẩm Mỹ, Nên Chọn Loại Răng Sứ Nào?</div>

    <!-- Khung 1 -->
    <div class="box-luu-y box-primary">
      <h2>Nên chọn loại răng toàn sứ ở vùng thẩm mỹ:</h2>
      <h2>Ưu điểm:</h2>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Cấu tạo: Hoàn toàn bằng sứ, không có sườn kim loại. Có nhiều loại sứ khác nhau như Cercon, Zirconia, Emax, Lithium Disilicate...</span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Tính thẩm mỹ cao, màu sắc tự nhiên, trong mờ như răng thật.</span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Tương thích sinh học tốt, không gây kích ứng nướu.</span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Không bị oxy hóa, không gây viền đen ở chân răng.</span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Độ bền cao (tùy loại sứ).</span>
      </div>
      <h2>Nhược điểm:</h2>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Chi phí cao hơn răng sứ kim loại.</span>
      </div>
    </div>

    <!-- Khung 2 -->
    <!-- <div class="box-luu-y box-secondary">
      <h2>Nhược điểm</h2>
      <p class="desc-title">Chi phí cao hơn răng sứ kim loại.</p>

      <h2>Chế độ sinh hoạt ăn uống</h2>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Tránh hút thuốc vì điều này có thể làm chậm quá trình lành thương.</span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">Nên ăn đồ mềm, nguội, tránh dùng các loại chất kích thích như: bia, rượu, đồ ăn cay, nóng, lạnh.</span>
      </div>
    </div> -->

  </div>

</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  data() {
    return {
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  created() {

  },
  methods: {

    toggleFavorite(item) {
      item.favorite = !item.favorite;
    },
  },
};
</script>

<style>

</style>
