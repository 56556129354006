<template>
  <div class="container pt-60 pb-60">
    <div class="row align-items-center">
      <div style="text-align: center; margin-bottom: 20px;">
          <div class="main-title pb-30">
            Trồng răng Implant 1 răng
          </div>
          <div class="decs-intro-doctor" style="margin-bottom: 20px;">
            Trồng răng implant 1 răng là phương pháp hiện đại, mang lại hiệu quả cao trong việc khôi phục răng 
            đã mất cả về thẩm mỹ lẫn chức năng. Với kỹ thuật này, Bác sĩ sẽ cấy một trụ Titanium vào xương hàm 
            để thay thế chân răng tự nhiên bị mất. Trụ Titanium đã được chứng minh là an toàn, tương thích sinh học cao, 
            và không gây hại cho cơ thể.
          </div>
          <div class="decs-intro-doctor">
            Sau khi trụ implant ổn định, một mão răng sứ sẽ được gắn lên trên, tái tạo hoàn hảo hình dáng và chức năng 
            của răng thật. Phương pháp này không chỉ giúp bạn khôi phục khả năng ăn nhai mà còn giữ cho nụ cười của 
            bạn luôn tự tin và rạng rỡ. Đây là giải pháp tối ưu cho những ai muốn phục hồi răng mất một cách an toàn, 
            bền vững và lâu dài.
          </div>
      </div>
    </div>
  </div>



</template>

<script>
import { ref } from "vue";
const currentDate = ref(new Date());
import AOS from "aos";
import "aos/dist/aos.css";
import indexfivespecialities from "@/assets/json/indexfivespecialities.json";
import 'vue3-carousel/dist/carousel.css'
export default {
  components: {
  },
  data() {
    return {
      startdate: currentDate,
      indexfivespecialities: indexfivespecialities,
      list: [],
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  props: {
    header: { type : Object}
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  watch:{
    '$props':{
      handler: function (val) {
        this.list = val.header;
      //  console.log("LOG WATCH LIST ", this.list )
      },
      deep: true
    }
  },
  methods: {
    submitForm() {
      this.$router.push("/search-2");
    },
  },
};
</script>
<style>
</style>
