<template>
    <div class="container pt-60 pb-60">
      <div class="row">
        <div class="col-md-6 who-trongrang">
          <div>
            <div class="main-title pb-30">Các Tình Trạng Răng Cần Khám Nha Khoa Tổng Quát?</div>
            <div class="desc-title">Nha khoa tổng quát là giải pháp hiện đại giúp cải thiện nụ cười và nâng cao sự tự tin, phù hợp với nhiều đối tượng trong các trường hợp sau:</div>
            <div class="card-who-trongrang pt-30">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Khách hàng gặp các vấn đề răng miệng : sâu răng , viêm nướu , viêm nha chu, răng bị chấn thương ..gây đau nhức khó chịu , ảnh hưởng đến ăn nhai và chất lượng cuộc sống. 
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Những người có nhu cầu khám răng tổng quát định kỳ để làm sạch và phòng ngừa các bệnh răng miệng.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Cha mẹ muốn theo dõi và  chăm sóc sức khoẻ răng miệng cho con từ sớm. Đặc biệt khi nhận thấy trẻ có các dấu hiệu bất thường như sâu răng , răng mọc lệch,... 
              </div>
            </div>
          </div>


        </div>
        <div class="col-md-6">
            <div class="img-trongrang">
                <img src="@/assets/img/phuongthanh/dichvu/who.png" alt="" style="max-height: 500px;">
            </div>
        </div>
      </div>
    </div>



</template>

<script>
  import { ref } from "vue";
  const currentDate = ref(new Date());
  import AOS from "aos";
  import "aos/dist/aos.css";
  import 'vue3-carousel/dist/carousel.css'
  export default {
    components: {
    },
    data() {
      return {
        startdate: currentDate,
        list: [],
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    props: {
      header: { type : Object}
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
    watch:{
      '$props':{
        handler: function (val) {
          this.list = val.header;
        //  console.log("LOG WATCH LIST ", this.list )
        },
        deep: true
      }
    },
    methods: {
      submitForm() {
        this.$router.push("/search-2");
      },
    },
  };
</script>
<style>
</style>
