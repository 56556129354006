<template>
    <div class="container pt-60 pb-60">
      <div class="main-title text-center pb-30">
        Niềng răng trẻ em<br>
        An toàn tối đa, Kết quả vượt trội
      </div>
      <div class="desc-title text-center">
        Niềng răng trẻ em giúp điều chỉnh răng lệch, đảm bảo sự phát triển răng miệng khỏe mạnh và thẩm mỹ. 
        Phương pháp này sử dụng các khí cụ nhẹ nhàng, an toàn cho sự phát triển của răng trẻ em.
      </div>
      <div class="list-lamrang pt-30 row">
        <div class="card-lamrang mb-3">
          Đảm bảo an toàn cho răng mới phát triển.
        </div>

        <div class="card-lamrang mb-3">
          Giảm đau và khó chịu nhờ khí cụ nhẹ nhàng.
        </div>

        <div class="card-lamrang mb-3">
          Cải thiện hình dáng và chức năng của răng.
        </div>

        <!-- <div class="card-lamrang mb-3">
          Hiệu quả điều chỉnh trong thời gian ngắn.
        </div> -->
      </div>
    </div>

</template>

<script>
  import { ref } from "vue";
  const currentDate = ref(new Date());
  import AOS from "aos";
  import "aos/dist/aos.css";
  import 'vue3-carousel/dist/carousel.css'
  export default {
    components: {
    },
    data() {
      return {
        startdate: currentDate,
        list: [],
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    props: {
      header: { type : Object}
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
    watch:{
      '$props':{
        handler: function (val) {
          this.list = val.header;
        //  console.log("LOG WATCH LIST ", this.list )
        },
        deep: true
      }
    },
    methods: {
      submitForm() {
        this.$router.push("/search-2");
      },
    },
  };
</script>
<style>
</style>
