<template>
  <div class="container pt-60 pb-30">
    <div class="main-title text-center pb-30">Tại sao nên bọc răng sứ tại Nha Khoa Phương Thành?</div>

    <!-- Khung 2 -->
    <div class="box-luu-y box-secondary">
      <h2>Đội ngũ bác sĩ giàu kinh nghiệm và tay nghề cao</h2>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Đội ngũ bác sĩ giỏi: Nha khoa hàng đầu thường thu hút và giữ chân được những bác sĩ có tay nghề cao, 
          giàu kinh nghiệm, được đào tạo chuyên sâu và cập nhật kiến thức liên tục.
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Công nghệ hiện đại: Họ thường đầu tư vào công nghệ tiên tiến nhất, ví dụ như máy scan 3D, phần mềm 
          thiết kế nụ cười, máy CAD/CAM chế tạo răng sứ, giúp cho quá trình điều trị chính xác, nhanh chóng và hiệu quả hơn.
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Vật liệu chất lượng cao: Nha khoa hàng đầu thường sử dụng vật liệu sứ cao cấp, nhập khẩu từ những thương hiệu uy tín, 
          đảm bảo độ bền, tính thẩm mỹ và an toàn cho sức khỏe.
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Cơ sở vật chất hiện đại: Không gian nha khoa được thiết kế hiện đại, tiện nghi, tạo cảm giác thoải mái cho khách hàng.
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Dịch vụ chuyên nghiệp: Từ khâu tiếp đón, tư vấn đến quá trình điều trị và chăm sóc sau điều trị đều được thực hiện chuyên nghiệp, tận tâm.
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Chế độ bảo hành tốt: Nha khoa uy tín sẽ có chế độ bảo hành rõ ràng, minh bạch, đảm bảo quyền lợi cho khách hàng.
        </span>
      </div>
    </div>

  </div>

</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  data() {
    return {
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  created() {

  },
  methods: {

    toggleFavorite(item) {
      item.favorite = !item.favorite;
    },
  },
};
</script>

<style>

</style>
