<template>
    <div class="container pt-60 pb-60">
      <div class="row">
        <div class="col-md-6 who-trongrang">
          <div>
            <div class="main-title pb-30">Vì Sao Làm Răng Sứ Ngày Càng Được Ưa Chuộng?</div>
            <div class="desc-title">Bọc răng sứ mang lại nhiều ưu điểm vượt trội, giúp nó ngày càng trở nên phổ biến. Tuy nhiên, cũng cần cân nhắc kỹ lưỡng một số hạn chế trước khi quyết định thực hiện.</div>
            <div class="card-who-trongrang pt-10">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Tính thẩm mỹ cao: Răng sứ được chế tạo với màu sắc và hình dáng tự nhiên, rất khó phân biệt với răng thật. Chúng có thể che đi các khuyết điểm như răng ố vàng, sứt mẻ, lệch lạc, giúp mang lại nụ cười đều đẹp và tự tin hơn.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Độ bền và tuổi thọ cao: Răng sứ có độ bền cao, chịu được lực nhai tốt và có tuổi thọ dài (trung bình từ 10-20 năm hoặc hơn nếu được chăm sóc đúng cách).
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Tương thích sinh học tốt: Sứ là vật liệu lành tính, không gây kích ứng mô nướu và ít gây ra phản ứng dị ứng.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Tương thích sinh học cao: Sứ là vật liệu lành tính, không gây kích ứng nướu và mô mềm.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Khôi phục chức năng ăn nhai: Răng sứ giúp khôi phục chức năng ăn nhai hiệu quả, đặc biệt trong trường hợp răng bị hư tổn nặng.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Công nghệ chế tạo răng sứ ngày càng phát triển: Công nghệ CAD/CAM hiện đại cho phép chế tạo răng sứ với độ chính xác cao, rút ngắn thời gian điều trị và mang lại kết quả thẩm mỹ tốt hơn.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Nhu cầu thẩm mỹ ngày càng tăng: Trong xã hội hiện đại, ngoại hình và nụ cười đẹp đóng vai trò quan trọng. Nhiều người tìm đến răng sứ để cải thiện nụ cười và tăng sự tự tin.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Chi phí ngày càng hợp lý: So với trước đây, chi phí làm răng sứ đã trở nên hợp lý hơn, giúp nhiều người có thể tiếp cận được phương pháp này.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Dễ dàng chăm sóc: Việc chăm sóc răng sứ tương đối đơn giản, tương tự như chăm sóc răng thật.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Truyền thông và quảng cáo: Sự phổ biến của các phương tiện truyền thông và quảng cáo cũng góp phần làm tăng nhận thức và sự quan tâm của mọi người đối với răng sứ.
              </div>
            </div>
          </div>


        </div>
        <div class="col-md-6">
            <div class="img-trongrang">
                <img src="@/assets/img/phuongthanh/dichvu/who.png" alt="" style="max-height: 500px;">
            </div>
        </div>
      </div>
    </div>



</template>

<script>
  import { ref } from "vue";
  const currentDate = ref(new Date());
  import AOS from "aos";
  import "aos/dist/aos.css";
  import 'vue3-carousel/dist/carousel.css'
  export default {
    components: {
    },
    data() {
      return {
        startdate: currentDate,
        list: [],
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    props: {
      header: { type : Object}
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
    watch:{
      '$props':{
        handler: function (val) {
          this.list = val.header;
        //  console.log("LOG WATCH LIST ", this.list )
        },
        deep: true
      }
    },
    methods: {
      submitForm() {
        this.$router.push("/search-2");
      },
    },
  };
</script>
<style>
</style>
