<template>
    <div class="container pt-60 pb-60">
      <div class="main-title text-center pb-30">
        Dán sứ Veneer<br>
        An toàn tối đa, Kết quả vượt trội
      </div>
      <div class="desc-title text-center">
        Dán sứ veneer là một giải pháp điều trị chỉnh nha giúp cải thiện hình dáng, cấu trúc răng miệng và nụ cười của bệnh nhân.
      </div>
      <div class="desc-title text-center">
        Dán sứ veneer là một phương pháp hiệu quả để không chỉ cải thiện ngoại hình mà còn nâng cao sức khỏe răng miệng lâu dài. Vì vậy Dán sứ veneer có những lợi ích như
      </div>
      <div class="list-lamrang pt-30 row">
        <div class="card-lamrang mb-3">
          Áp dụng được các trường hợp từ đơn giản đến phức tạp
        </div>

        <div class="card-lamrang mb-3">
          Đa dạng phương pháp Dán sứ veneer
        </div>

        <div class="card-lamrang mb-3">
          Chi phí niềng răng phù hợp nhiều người
        </div>
      </div>
    </div>

</template>

<script>
  import { ref } from "vue";
  const currentDate = ref(new Date());
  import AOS from "aos";
  import "aos/dist/aos.css";
  import 'vue3-carousel/dist/carousel.css'
  export default {
    components: {
    },
    data() {
      return {
        startdate: currentDate,
        list: [],
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    props: {
      header: { type : Object}
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
    watch:{
      '$props':{
        handler: function (val) {
          this.list = val.header;
        //  console.log("LOG WATCH LIST ", this.list )
        },
        deep: true
      }
    },
    methods: {
      submitForm() {
        this.$router.push("/search-2");
      },
    },
  };
</script>
<style>
</style>
