<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb :title="title" :text="text" :text1="text1" />

    <div class="content">
      <div class="container">
        <div class="card custom-card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="card custom-card">
                  <div  class="card-body">
                    <div class="booking-doc-info">
                      <router-link to="ho-so-bac-si" class="booking-doc-img">
                        <img
                            src="../../../assets/img/phuongthanh/doctor/BS_Thanh.jpg"
                            alt="User Image"
                        />
                      </router-link>
                      <div class="booking-info">
                        <h4>
                          <router-link to="ho-so-bac-si">BSCKI. Phạm Kim Thành</router-link>
                        </h4>
                        <div class="rating">
                          <i class="fas fa-star filled"></i>
                          <i class="fas fa-star filled ms-1"></i>
                          <i class="fas fa-star filled ms-1"></i>
                          <i class="fas fa-star filled ms-1"></i>
                          <i class="fas fa-star filled ms-1"></i>
                          <span class="d-inline-block average-rating ms-1">35</span>
                        </div>
                        <p class="text-muted mb-0">
                          <i class="fas fa-map-marker-alt"></i> Răng sứ thẩm mỹ
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="row">
                  <div class="col-12 col-sm-4 col-md-6">
                    <h4 class="mb-1">Ngày 30 tháng 6 Năm 2024</h4>
                    <p class="text-muted">Chủ nhật</p>
                  </div>
                </div>
                <div class="col-md-7 col-lg-8 col-xl-12">
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="tab-content pt-0 timing-content">

                        <!-- General Availability -->
                        <div class="tab-pane fade show active" id="general-availability" role="tabpanel">
                          <div class="card custom-card">
                            <div class="card-body">
                              <div class="card-header">
                                <h3>Vui lòng chọn giờ</h3>
                              </div>

                              <div class="available-tab">
                                <h5 >Vui lòng chọn ngày</h5>
                                <ul class="nav" role="tablist">
                                  <li>
                                    <a href="#" class="active" data-bs-toggle="tab" data-bs-target="#monday" aria-selected="true" role="tab">Thứ 2</a>
                                  </li>
                                  <li>
                                    <a href="#" data-bs-toggle="tab" data-bs-target="#tuesday" aria-selected="false" role="tab" class="" tabindex="-1">Thứ 3</a>
                                  </li>
                                  <li>
                                    <a href="#" data-bs-toggle="tab" data-bs-target="#wednesday" aria-selected="false" tabindex="-1" role="tab">Thứ 4</a>
                                  </li>
                                  <li>
                                    <a href="#" data-bs-toggle="tab" data-bs-target="#thursday" aria-selected="false" tabindex="-1" role="tab">Thứ 5</a>
                                  </li>
                                  <li>
                                    <a href="#" data-bs-toggle="tab" data-bs-target="#friday" aria-selected="false" tabindex="-1" role="tab">Thứ 6</a>
                                  </li>
                                  <li>
                                    <a href="#" data-bs-toggle="tab" data-bs-target="#saturday" aria-selected="false" tabindex="-1" role="tab">Thứ 7</a>
                                  </li>
                                  <li>
                                    <a href="#" data-bs-toggle="tab" data-bs-target="#sunday" aria-selected="false" tabindex="-1" role="tab">Chủ nhật</a>
                                  </li>
                                </ul>
                              </div>

                              <div class="tab-content pt-0">

                                <!-- Slot -->
                                <div class="tab-pane active show" id="monday" role="tabpanel">
                                  <div class="slot-box">
                                    <div class="slot-header">
                                      <h4 class="form-label">Thứ 2</h4>
                                    </div>
                                    <div class="slot-body">
                                      <ul class="time-slots">
                                        <h5>Sáng</h5>
                                        <li><i class="fa-regular fa-clock"></i>09:00 AM</li>
                                        <li><i class="fa-regular fa-clock"></i>09:30 AM</li>
                                        <li><i class="fa-regular fa-clock"></i>10:00 AM</li>
                                        <li><i class="fa-regular fa-clock"></i>10:30 AM</li>
                                        <li><i class="fa-regular fa-clock"></i>11:00 AM</li>
                                        <li><i class="fa-regular fa-clock"></i>09:00 AM</li>
                                        <li><i class="fa-regular fa-clock"></i>09:30 AM</li>
                                        <li><i class="fa-regular fa-clock"></i>09:00 AM</li>
                                        <li><i class="fa-regular fa-clock"></i>09:30 AM</li>
                                        <li><i class="fa-regular fa-clock"></i>10:00 AM</li>
                                        <li><i class="fa-regular fa-clock"></i>10:30 AM</li>
                                        <li><i class="fa-regular fa-clock"></i>11:00 AM</li>
                                        <li><i class="fa-regular fa-clock"></i>09:00 AM</li>
                                        <li><i class="fa-regular fa-clock"></i>09:30 AM</li>
                                        <h5>Chiều</h5>
                                        <li><i class="fa-regular fa-clock"></i>10:00 AM</li>
                                        <li><i class="fa-regular fa-clock"></i>10:30 AM</li>
                                        <li><i class="fa-regular fa-clock"></i>11:00 AM</li>
                                        <li><i class="fa-regular fa-clock"></i>09:00 AM</li>
                                        <li><i class="fa-regular fa-clock"></i>09:30 AM</li>
                                        <li><i class="fa-regular fa-clock"></i>10:00 AM</li>
                                        <li><i class="fa-regular fa-clock"></i>10:30 AM</li>
                                        <li><i class="fa-regular fa-clock"></i>11:00 AM</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <!-- /Slot -->

                                <!-- Slot -->
                                <div class="tab-pane fade" id="tuesday" role="tabpanel">
                                  <div class="slot-box">
                                    <div class="slot-header">
                                      <h5>Thứ 3</h5>
                                      <ul>
                                        <li>
                                          <a href="#" class="add-slot" data-bs-toggle="modal" data-bs-target="#add_slot">Add Slots</a>
                                        </li>
                                        <li>
                                          <a href="#" class="del-slot" data-bs-toggle="modal" data-bs-target="#delete_slot">Delete All</a>
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="slot-body">
                                      <p>No Slots Available</p>
                                    </div>
                                  </div>
                                </div>
                                <!-- /Slot -->

                                <!-- Slot -->
                                <div class="tab-pane fade" id="wednesday" role="tabpanel">
                                  <div class="slot-box">
                                    <div class="slot-header">
                                      <h5>Wednesday</h5>
                                      <ul>
                                        <li>
                                          <a href="#" class="add-slot" data-bs-toggle="modal" data-bs-target="#add_slot">Add Slots</a>
                                        </li>
                                        <li>
                                          <a href="#" class="del-slot" data-bs-toggle="modal" data-bs-target="#delete_slot">Delete All</a>
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="slot-body">
                                      <p>No Slots Available</p>
                                    </div>
                                  </div>
                                </div>
                                <!-- /Slot -->

                                <!-- Slot -->
                                <div class="tab-pane fade" id="thursday" role="tabpanel">
                                  <div class="slot-box">
                                    <div class="slot-header">
                                      <h5>Thursday</h5>
                                      <ul>
                                        <li>
                                          <a href="#" class="add-slot" data-bs-toggle="modal" data-bs-target="#add_slot">Add Slots</a>
                                        </li>
                                        <li>
                                          <a href="#" class="del-slot" data-bs-toggle="modal" data-bs-target="#delete_slot">Delete All</a>
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="slot-body">
                                      <p>No Slots Available</p>
                                    </div>
                                  </div>
                                </div>
                                <!-- /Slot -->

                                <!-- Slot -->
                                <div class="tab-pane fade" id="friday" role="tabpanel">
                                  <div class="slot-box">
                                    <div class="slot-header">
                                      <h5>Friday</h5>
                                      <ul>
                                        <li>
                                          <a href="#" class="add-slot" data-bs-toggle="modal" data-bs-target="#add_slot">Add Slots</a>
                                        </li>
                                        <li>
                                          <a href="#" class="del-slot" data-bs-toggle="modal" data-bs-target="#delete_slot">Delete All</a>
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="slot-body">
                                      <p>No Slots Available</p>
                                    </div>
                                  </div>
                                </div>
                                <!-- /Slot -->

                                <!-- Slot -->
                                <div class="tab-pane fade" id="saturday" role="tabpanel">
                                  <div class="slot-box">
                                    <div class="slot-header">
                                      <h5>Saturday</h5>
                                      <ul>
                                        <li>
                                          <a href="#" class="add-slot" data-bs-toggle="modal" data-bs-target="#add_slot">Add Slots</a>
                                        </li>
                                        <li>
                                          <a href="#" class="del-slot" data-bs-toggle="modal" data-bs-target="#delete_slot">Delete All</a>
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="slot-body">
                                      <p>No Slots Available</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Submit Section -->
                <div class="submit-section proceed-btn text-end">
                  <router-link to="/thu-tuc" class="btn btn-primary submit-btn"
                  >Tiếp theo</router-link
                  >
                </div>
                <!-- /Submit Section -->
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>

    <footerHome></footerHome>


  </div>
</template>
<script>
import Booking from "@/assets/json/booking/booking.json";
import "daterangepicker/daterangepicker.css";
import "daterangepicker/daterangepicker.js";
import "moment/min/moment.min.js";

import { onMounted } from "vue";
import moment from "moment";
import DateRangePicker from "daterangepicker";
export default {
  setup() {
    onMounted(() => {
      if (document.querySelectorAll(".bookingrange").length > 0) {
        var start = moment().subtract(6, "days");
        var end = moment();

        function booking_range(start, end) {
          document.querySelector(".bookingrange span").innerHTML =
              start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY");
        }

        document.querySelector(".bookingrange").addEventListener("change", function () {
          booking_range(this.startDate, this.endDate);
        });

        var ranges = {
          Today: [moment(), moment()],
          Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
          "Last 7 Days": [moment().subtract(6, "days"), moment()],
          "Last 30 Days": [moment().subtract(29, "days"), moment()],
          "This Month": [moment().startOf("month"), moment().endOf("month")],
          "Last Month": [
            moment().subtract(1, "month").startOf("month"),
            moment().subtract(1, "month").endOf("month"),
          ],
        };

        var options = {
          startDate: start,
          endDate: end,
          ranges: ranges,
        };

        new DateRangePicker(document.querySelector(".bookingrange"), options);
        booking_range(start, end);
      }
    });

    return {
      title: "Đặt lịch hẹn",
      text: "Trang Chủ",
      text1: "Đặt lịch hẹn",
      booking: Booking,
    };
  },
};
</script>
<style>

.custom-card{
  border: 1px solid #E2E8F0;
  box-shadow: 0px 7.5px 17.5px 0px rgba(0, 0, 0, 0.0509803922);
  border-radius: 10px;
}
.custom-card .card-header {
  border-color: #E2E8F0;
  margin: 0 0 24px;
  padding: 0;
}
.card .card-header {
  background-color: #ffffff;
  border-bottom: 1px solid #f0f0f0;
}

.slot-box {
  border: 2px solid #E2E8F0;
  margin: 0 0 30px;
  padding: 15px 15px 0;
  border-radius: 8px;
}
.slot-box .slot-header {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  border-bottom: 1px solid #E2E8F0;
  margin-bottom: 15px;
}

.available-tab ul li a.active, .available-tab ul li a:hover {
  background: #F5E7B2;
  border-color: #F5E7B2;
  color: #ffffff;
}
.available-tab ul li a {
  border: 1px solid #E2E8F0;
  background: #F8FAFC;
  color: #00003b;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  text-align: center;
  padding: 10px 22px;
  border-radius: 10px;
}

.available-tab ul li {
  margin: 0 15px 15px 0;
}
.slot-box .slot-body .time-slots li {
  background: #F1F5F9;
  margin: 0 15px 15px 0;
  padding: 12px 13px;
  border-radius: 6px;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: #00003b;
  min-width: 120px;
  text-align: center;
  position: relative;
  transition: 0.5s;
}
ul li {
  list-style-type: none;
}
.slot-box .slot-body .time-slots li i {
  margin-right: 10px;
}
</style>
