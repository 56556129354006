<template>
    <div class="main-Wrapper">
      <pharmacyheader></pharmacyheader>
      <pharmacysidebar></pharmacysidebar>
      <!-- Page Wrapper -->
      <div class="page-wrapper">
        <div class="content container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                    <h3 class="card-title" style="float: left;">Quản lý Bác sĩ</h3>
                    <div class="card-tools" style="float: right;">
                        <a
                            href="#info_modal"
                            data-bs-toggle="modal"
                            size="sm"
                            type="button"
                            class="btn btn-tool card-add"
                        >
                            <i class="fas fa-plus"></i>
                        </a>
                        <button type="button" class="btn btn-tool" @click="getData">
                            <i class="fas fa-retweet"></i>
                        </button>
                    </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <div class="row mb-2">
                        <div class="col-sm-12 col-md-12">
                            <div
                                class="col-sm-12 d-flex justify-content-left align-items-center"
                            >
                            <div>
                            Hiển thị
                            <label class="d-inline-flex align-items-center" style="color: #F5E7B2;">
                                {{ this.list.length }}
                            </label>
                            trên tổng số <span style="color: red; font-weight: bold;">{{ totalRows }}</span> dòng
                            </div>
                            </div>
                        </div>
                      </div>
                      <div class="custom-new-table">
                        <div class="table-responsive">
                        <table class="table table-hover table-center mb-0">
                            <thead>
                            <!-- <th class="col150 cursor td-stt" style="text-align: center;">
                                STT
                            </th> -->
                            <th class="col150 cursor" style="text-align: left;">
                                Họ và tên
                            </th>
                            <th class="col150 cursor" style="text-align: center;">
                                Phòng
                            </th>
                            <th class="col150 cursor" style="text-align: left;">
                                Số điện thoại
                            </th>
                            <th class="col100 cursor td-xuly" style="text-align: center;">
                                Xử lý
                            </th>
                        </thead>
                          <tbody>
                          <tr v-for="(item, index) in list" :key="index">
                            <td>
                                <router-link
                                    :to="{
                                        path: `/quan-tri/doctor/chi-tiet/${item.id}`,
                                    }"
                                    style="display: flex;"
                                >
                                    <div v-if="item.avatar != null">
                                        <img
                                            :src="url + item.avatar.fileId"
                                            class="image-doctor"
                                        ></img>
                                    </div>
                                    <div v-else>
                                        <img
                                            src="@//assets/img/phuongthanh/logo/logo-doctor-null.png"
                                            class="image-doctor-null"
                                        ></img>
                                    </div>
                                    <div style="margin-left: 10px; color: #000; display: flex; align-items: center;">
                                        <div>
                                            <p style="margin-bottom: 5px;">
                                                <i class="fa-regular fa-address-card"></i>
                                                {{ item.cccd }}
                                            </p>
                                            <p style="margin-bottom: 0; font-weight: bold;">
                                                {{ item.name }}
                                            </p>

                                        </div>
                                    </div>
                                </router-link>
                            </td>
                            <td style="text-align: center">
                                {{ item.room.name }}
                            </td>
                            <td style="text-align: left">
                                <p style="margin-bottom: 5px;">
                                    <i class="fa-regular fa-envelope"></i>
                                    {{ item.email }}
                                </p>
                                <p style="margin-bottom: 0;">
                                    <i class="fa-solid fa-phone"></i>
                                    {{ item.phone }}
                                </p>
                            </td>
                            <td style="text-align: center">
                                <a
                                    href="#info_modal"
                                    data-bs-toggle="modal"
                                    size="sm"
                                    type="button"
                                    class="btn btn-outline btn-sm"
                                    v-on:click="handleUpdate(item.id)"
                                >
                                    <i class="fas fa-pencil-alt text-success me-1"></i>
                                </a>
                                <a
                                    href="#delete_modal"
                                    data-bs-toggle="modal"
                                    class="btn btn-outline btn-sm"
                                    v-on:click="handleShowDeleteModal(item.id)"
                                >
                                    <i class="fas fa-trash-alt text-danger me-1"></i>
                                </a>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      </div>

                      <div
                        class="modal fade"
                        id="info_modal"
                        aria-hidden="true"
                        role="dialog"
                        data-bs-backdrop="static"
                        ref="ref_info_modal"
                        >
                            <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
                                <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Thông tin bác sĩ</h5>
                                    <b-button
                                        type="button"
                                        class="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    ></b-button>
                                </div>
                                <div class="modal-body">
                                    <Form
                                        class="login"
                                        @submit="handleSubmit"
                                        :validation-schema="schema"
                                        v-slot="{ errors }"
                                        ref="form"
                                    >
                                        <div class="row">
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                            <label class="text-left">Họ và tên</label>
                                            <span style="color: red">&nbsp;*</span>
                                            <input type="hidden" v-model="model.id"/>
                                            <Field
                                                v-model="model.name"
                                                placeholder="Vui lòng nhập tên bác sĩ"
                                                name="name"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': errors.name }"
                                            />
                                                <div class="invalid-feedback">{{ errors.name }}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="text-left mb-0">Ngày sinh</label>
                                                <span style="color: red" >&nbsp;*</span>
                                                <Field name="birthday" v-slot="{ field }">
                                                <VueDatePicker
                                                    v-bind="field"
                                                    v-model="model.birthday"
                                                    :enable-time-picker="false"
                                                    :format="format"
                                                    :locale="locale"
                                                    auto-apply
                                                    :max-date="new Date()"
                                                    :class="{ 'is-invalid': errors.birthday }"
                                                />
                                                </Field>
                                                <div class="invalid-feedback">{{ errors.birthday }}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                            <label class="text-left">Căn cước công dân</label>
                                            <span style="color: red">&nbsp;*</span>
                                            <input type="hidden" v-model="model.id"/>
                                            <Field
                                                v-model="model.cccd"
                                                placeholder="Vui lòng nhập căn cước công dân"
                                                name="cccd"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': errors.cccd }"
                                            />
                                                <div class="invalid-feedback">{{ errors.cccd }}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="text-left mb-0">Ngày cấp CCCD</label>
                                                <span style="color: red" >&nbsp;*</span>
                                                <Field name="dateCCCD" v-slot="{ field }">
                                                <VueDatePicker
                                                    v-bind="field"
                                                    v-model="model.dateCCCD"
                                                    :enable-time-picker="false"
                                                    :format="format"
                                                    :locale="locale"
                                                    auto-apply
                                                    :max-date="new Date()"
                                                    :class="{ 'is-invalid': errors.dateCCCD }"
                                                />
                                                </Field>
                                                <div class="invalid-feedback">{{ errors.dateCCCD }}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                            <label class="text-left">Số điện thoại</label>
                                            <span style="color: red">&nbsp;*</span>
                                            <input type="hidden" v-model="model.id"/>
                                            <Field
                                                v-model="model.phone"
                                                placeholder="Vui lòng nhập số điện thoại"
                                                name="phone"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': errors.phone }"
                                            />
                                                <div class="invalid-feedback">{{ errors.phone }}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                            <label class="text-left">Email</label>
                                            <span style="color: red">&nbsp;*</span>
                                            <input type="hidden" v-model="model.id"/>
                                            <Field
                                                v-model="model.email"
                                                placeholder="Vui lòng nhập email"
                                                name="email"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': errors.email }"
                                            />
                                                <div class="invalid-feedback">{{ errors.email }}</div>
                                            </div>
                                        </div>

                                        <div class="col-md-6 multi-role">
                                            <div class="mb-3">
                                                <label class="text-left">Khoa</label>
                                                 <span style="color: red">&nbsp;*</span>
                                                <Field name="department" v-slot="{ field }">
                                                    <VueMultiselect
                                                        v-bind="field"
                                                        v-model="model.department"
                                                        :options="listKhoa"
                                                        :multiple="false"
                                                        label="name"
                                                        placeholder="Nhấp vào để chọn"
                                                        selectLabel="Nhấn vào để chọn"
                                                        deselectLabel="Nhấn vào để xóa"
                                                        track-by="id"
                                                        :class="{ 'is-invalid': errors.department }"
                                                    >
                                                    </VueMultiselect>
                                                    <div class="invalid-feedback">{{ errors.department }}</div>
                                                </Field>
                                            </div>
                                        </div>

                                        <div class="col-md-6 multi-role">
                                            <div class="mb-3">
                                                <label class="text-left">Phòng</label>
                                                <!-- <span style="color: red">&nbsp;*</span> -->
                                                <VueMultiselect
                                                    v-model="model.room"
                                                    :options="listPhong"
                                                    :multiple="false"
                                                    label="name"
                                                    placeholder="Nhấp vào để chọn"
                                                    selectLabel="Nhấn vào để chọn"
                                                    deselectLabel="Nhấn vào để xóa"
                                                    track-by="id"
                                                >
                                                </VueMultiselect>
                                            </div>
                                        </div>

                                        <div class="col-md-6 multi-role">
                                            <div class="mb-3">
                                                <label class="text-left">Đơn vị</label>
                                                <!-- <span style="color: red">&nbsp;*</span> -->
                                                <VueMultiselect
                                                    v-model="model.unitGroup"
                                                    :options="listDonVi"
                                                    :multiple="false"
                                                    label="name"
                                                    placeholder="Nhấp vào để chọn"
                                                    selectLabel="Nhấn vào để chọn"
                                                    deselectLabel="Nhấn vào để xóa"
                                                    track-by="id"
                                                >
                                                </VueMultiselect>
                                            </div>
                                        </div>
                                        <div class="col-md-6 multi-role">
                                            <div class="mb-3">
                                                <label class="text-left">Dịch vụ</label>
                                                <!-- <span style="color: red">&nbsp;*</span> -->
                                                <VueMultiselect
                                                    v-model="model.service"
                                                    :options="listDichVu"
                                                    :multiple="true"
                                                    label="name"
                                                    placeholder="Nhấp vào để chọn"
                                                    selectLabel="Nhấn vào để chọn"
                                                    deselectLabel="Nhấn vào để xóa"
                                                    track-by="id"
                                                >
                                                </VueMultiselect>
                                            </div>
                                        </div>
                                        <div class="col-md-6 multi-role">
                                            <div class="mb-3">
                                                <label class="text-left">Chuyên ngành</label>
                                                <!-- <span style="color: red">&nbsp;*</span> -->
                                                <VueMultiselect
                                                    v-model="model.majors"
                                                    :options="listChuyenNganh"
                                                    :multiple="true"
                                                    label="name"
                                                    placeholder="Nhấp vào để chọn"
                                                    selectLabel="Nhấn vào để chọn"
                                                    deselectLabel="Nhấn vào để xóa"
                                                    track-by="id"
                                                >
                                                </VueMultiselect>
                                            </div>
                                        </div>
                                        <div class="col-md-6 multi-role">
                                            <div class="mb-3">
                                                <label class="text-left">Trình độ học vấn</label>
                                                <!-- <span style="color: red">&nbsp;*</span> -->
                                                <VueMultiselect
                                                    v-model="model.level"
                                                    :options="listLevel"
                                                    :multiple="false"
                                                    label="name"
                                                    placeholder="Nhấp vào để chọn"
                                                    selectLabel="Nhấn vào để chọn"
                                                    deselectLabel="Nhấn vào để xóa"
                                                >
                                                </VueMultiselect>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="mb-2 ">
                                            <label for="formFileSm" class="text-left mb-0">Hình ảnh</label>
                                            <span style="color: red">&nbsp;*</span>
                                            <template  v-if="model.avatar" >
                                                <a
                                                    class="ml-25"
                                                    :href="`${urlFile}/${model.avatar.fileId}`"
                                                ><i
                                                    :class="`${getColorWithExtFile(model.avatar.ext)} me-2 ${getIconWithExtFile(model.avatar.ext)}`"
                                                ></i>{{model.avatar.fileName }}</a>

                                            </template>
                                            <Field
                                                id="formFile"
                                                name="fileImage"
                                                type="file"
                                                accept="image/jpeg"
                                                class="form-control"
                                                @change="upload($event)"
                                                :class="{ 'is-invalid': errors.fileImage }"
                                                ref="fileupload"
                                            />
                                            <div class="invalid-feedback">{{ errors.fileImage }}</div>

                                            </div>
                                        </div>
                                    </div>
                                        <div class="text-end pt-2 mt-3">
                                            <b-button
                                                type="button"
                                                class="btn si_accept_cancel btn-submit w-md btn-out"
                                                data-bs-dismiss="modal"
                                            >
                                                Đóng
                                            </b-button>
                                            <b-button  type="submit" variant="success" class="ms-1 cs-btn-primary btn-submit w-md">
                                                Lưu
                                            </b-button>
                                        </div>
                                    </form>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="modal fade"
                            id="delete_modal"
                            tabindex="-1"
                            role="dialog"
                            aria-hidden="true"
                            data-bs-backdrop="static"
                            ref="ref_delete"
                        >
                            <div class="modal-dialog modal-dialog-centered">
                                <div class="modal-content">
                                    <div class="modal-header">
                                    <h5 class="modal-title" id="acc_title">Xóa</h5>
                                    <b-button
                                        type="button"
                                        class="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    ></b-button>
                                    </div>
                                    <div class="modal-body" style="font-weight: 500;">
                                        <p id="acc_msg">Dữ liệu được chọn sẽ được xóa vĩnh viễn. Bạn có chắc muốn xóa dữ liệu này?</p>
                                    </div>
                                    <div class="modal-footer">
                                    <b-button class="btn btn-delete w-md si_accept_cancel" v-on:click="handleDelete" data-bs-dismiss="modal">
                                        Xóa
                                    </b-button>
                                    <b-button
                                        type="button"
                                        class="btn si_accept_cancel btn-submit w-md btn-out"
                                        data-bs-dismiss="modal"
                                    >
                                        Đóng
                                    </b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                      <div class="row" >
                        <div class="col-md-6 col-sm-6 mt-2">
                          <div>
                            Hiển thị
                            <label class="d-inline-flex align-items-center">
                              <b-form-select
                                  class="form-select form-select-sm"
                                  v-model="perPage"
                                  size="sm"
                                  :options="pageOptions"
                                  style="width: 70px; text-align: center;"
                              ></b-form-select
                              >&nbsp;
                            </label>
                            trên tổng số {{ totalRows }} dòng
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-6 mt-2" style="display: flex; justify-content: flex-end;">
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="totalRows"
                                :per-page="perPage"
                                align="right"
                                size="sm"
                                class="my-0"
                            />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <pharmacymodel />
    <pharmacydelete />
  </template>
  <script >

import VueDatePicker from '@vuepic/vue-datepicker';
import { doctorModel } from "@/models/doctorModel";
import Treeselect from 'vue3-treeselect';
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';
import {notifyModel} from "@/models/notifyModel";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { Modal } from 'bootstrap';
import {dichVuModel} from "@/models/dichVuModel";
  export default {
    components: {
      VueDatePicker,
      Treeselect,
      VueMultiselect,
      Form,
    Field,
    },
    data() {
      return {
        title: "DANH SÁCH",
        model: doctorModel.baseJson(),
        list: [],
        currentPage: 1,
        numberOfElement: 1,
        perPage: 5,
        pageOptions: [5, 10, 25, 50, 100],
        totalRows: 1,
        sortBy: 'age',
        sortDesc: false,
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
        theModal: null,
        format : `dd/MM/yyyy`,
        locale: 'vi',
        listGender: [],
        listKhoa: [],
        listPhong: [],
        listDonVi: [],
        listDichVu: [],
        listHonNhan: [],
        listChuyenNganh: [],
        listLevel: [],
      };
    },
    name: "pharmacy/user",

    created() {
      this.getData();
      this.getListGender();
      this.getListChuyenNganh();
      this.getListKhoa();
      this.getListPhong();
      this.getListDonVi();
      this.getListDichVu();
      this.getListHonNhan();
      this.getListLevel();
    },
    mounted() {
        this.theModal = new Modal(document.getElementById('info_modal'));
      this.$refs.ref_info_modal.addEventListener('hidden.bs.modal', event => {
        this.model= doctorModel.baseJson();
        this.$refs.fileupload.value = null;
        document.getElementById("formFile").value = null;
        this.$refs.form.setFieldValue('department', this.model.department);
      });
      this.$refs.ref_delete.addEventListener('hidden.bs.modal', event => {
        this.model = dichVuModel.baseJson();
        this.$refs.form.setFieldValue('department', this.model.department);
      });
    },

    setup() {
        const schema = Yup.object().shape({
            name: Yup.string().required("Tên không được bỏ trống !"),
            birthday: Yup.date().required("Ngày sinh không được bỏ trống !"),
            phone: Yup.string().required("Số điện thoại không được bỏ trống !"),
            cccd: Yup.string().required("Căn cước công dân không được bỏ trống !"),
            dateCCCD: Yup.string().required("Ngày cấp không được bỏ trống !"),
            email: Yup.string().required("Email không được bỏ trống !"),
            department: Yup.mixed().required("Khoa không được bỏ trống!"),
            fileImage: Yup.mixed().required("File không được bỏ trống!")
        });
        return {
            schema,
        };
    },

    watch: {
        perPage: {
            deep: true,
            handler(val){
                this.getData();
            }
        },
        currentPage: {
            deep: true,
            handler(val){
                this.getData();
            }
        },

    },

    methods: {
        async getData() {
            let params = {
                start: this.currentPage,
                limit: this.perPage,
                sortBy: this.sortBy,
            }
            await this.$store.dispatch("doctorStore/getPagingParams", params ).then(res => {
                  if (res != null && res.code ===0) {
                    this.list = res.data.data
                    this.totalRows = res.data.totalRows
                    this.numberOfElement = res.data.data.length
                  }
            });
        },
        async getListGender(){
            await  this.$store.dispatch("commonStore/getAll","DM_GENDER").then((res) =>{
                  if (res != null && res.code ===0) {
                    this.listGender = res.data || [];
                  }
            })
        },
        async getListChuyenNganh(){
            await  this.$store.dispatch("commonStore/getAll","DM_MAJOR").then((res) =>{
                  if (res != null && res.code ===0) {
                    this.listChuyenNganh = res.data || [];
                  }
            })
        },
        async getListKhoa(){
            await  this.$store.dispatch("departmentStore/getAllSelected").then((res) =>{
                  if (res != null && res.code ===0) {
                    this.listKhoa = res.data || [];
                  }
            })
        },
        async getListPhong(){
            await  this.$store.dispatch("roomStore/getAllSelected").then((res) =>{
                  if (res != null && res.code ===0) {
                    this.listPhong = res.data || [];
                  }
            })
        },
        async getListDonVi(){
            await  this.$store.dispatch("unitGroupStore/getAllSelected").then((res) =>{
                  if (res != null && res.code ===0) {
                    this.listDonVi = res.data || [];
                  }
            })
        },
        async getListDichVu(){
            await  this.$store.dispatch("serviceStore/getAllSelected").then((res) =>{
                  if (res != null && res.code ===0) {
                    this.listDichVu = res.data || [];
                  }
            })
        },
        async getListHonNhan(){
            await  this.$store.dispatch("commonStore/getAll","DM_MARRIED").then((res) =>{
                  if (res != null && res.code ===0) {
                    this.listHonNhan = res.data || [];
                  }
            })
        },
        async getListLevel(){
            await  this.$store.dispatch("commonStore/getAll","DM_LEVEL").then((res) =>{
                  if (res != null && res.code ===0) {
                    this.listLevel = res.data || [];
                  }
            })
        },

        async handleUpdate(id) {
            await this.$store.dispatch("doctorStore/getById", {id : id}).then((res) => {
                if (res != null && res.code ===0) {
               //     console.log(res)
                    this.model = doctorModel.getJson(res.data);
                    this.$refs.form.setFieldValue('birthday', res.data.birthday);
                    this.$refs.form.setFieldValue('dateCCCD', res.data.dateCCCD);
                    this.$refs.form.setFieldValue('department', res.data.department);
                    this.$refs.form.setFieldValue('fileImage', res.data.avatar || null);
                }
       //         this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res));
            });
        },
        handleShowDeleteModal(id) {
            this.model.id = id;
            this.showDeleteModal = true;
        },
        async handleDelete() {
            if (this.model.id != 0 && this.model.id != null && this.model.id) {
                await this.$store.dispatch("doctorStore/delete", { 'id': this.model.id }).then((res) => {
                if (res != null && res.code ===0) {
                    this.showDeleteModal = false;
                    this.getData();
                }
                this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res));
                });
            }
        },
        async handleSubmit() {
            this.model.placeOfBirth = this.itemsNoiSinh;
            this.model.address = this.itemsDiaChi;
            if (
                this.model.id != 0 &&
                this.model.id != null &&
                this.model.id
            ) {
                await this.$store.dispatch("doctorStore/update", this.model).then((res) => {
                if (res != null && res.code ===0) {

                    this.getData();
                    this.model= doctorModel.baseJson();
                    this.model = {};
                    this.theModal.hide();
                }
                this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res));
                });
            } else {
                await this.$store.dispatch("doctorStore/create", this.model).then((res) => {
                if (res != null && res.code ===0) {
                    this.getData();
                    this.model= doctorModel.baseJson();
                    this.model = {};
                    this.theModal.hide();
                }
                this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res));
                });

            }
        },

        async upload() {

            if (this.model != null && this.model.avatar != null)
            {
            // console.log("LOG UPDATE NE : ", event.target.files )
            axios.post(`${process.env.VUE_APP_API_URL}Files/delete/${this.model.avatar.fileId}`).then((response) => {
                this.model.files = null;
                //   console.log('log model file remove', this.model.files);
            }).catch((error) => {
                // Handle error here
                //    console.error('Error deleting file:', error);
            });
            }
         //   console.log("LOG UPLOAD : ", event.target)
            if ( event.target &&  event.target.files.length > 0 ) {
            const formData = new FormData()
            formData.append('files', event.target.files[0])
              formData.append('code', "AVATAR")
            axios.post(`${process.env.VUE_APP_API_URL}Files/upload`,formData).then((response) => {
                // console.log("LOG UPDATE : ", response);
                let resultData = response.data
                if (response.data.code == 0){
                this.model.avatar={
                    fileId: resultData.data.fileId,
                    fileName: resultData.data.fileName,
                    ext: resultData.data.ext,
                };
                console.log("LOG UPDATE : ", this.model);
                }
            }).catch((error) => {
                // Handle error here
                console.error('Error deleting file:', error);
            });

            }
        },

        getColorWithExtFile(ext) {
            if (ext == '.png' || ext == '.jpg'|| ext == '.jpeg' )
                return 'text-danger';

            },
        getIconWithExtFile(ext) {
            if (ext == '.png' || ext == '.jpg'|| ext == '.jpeg')
                return 'mdi mdi-file-image-outline';
        },
        addNodeToModel(node ){
            if(node != null && node.id){
            }
        },
        normalizer(node){
            if(node.children == null || node.children == 'null'){
                delete node.children;
            }
        },
    }
};
</script>

<style>
.image-dichvu{
    width: 200px;
}

.image-doctor{
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 10px;
}
.image-doctor-null{
    width: 50px;
    height: 50px;
    border-radius: 10px;
}
</style>

