<template>
  <div class="container pt-60 pb-30">
    <div class="main-title text-center pb-30">Vì sao nên chọn dán sứ veneer tại Nha Khoa Phương Thành?</div>

    <!-- Khung 2 -->
    <div class="box-luu-y box-secondary">
      <h2>Đội ngũ bác sĩ giàu kinh nghiệm và tay nghề cao</h2>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Là nha khoa top đầu, Phương Thành quy tụ đội ngũ bác sĩ chuyên môn cao, được đào tạo bài bản và có kinh nghiệm 
          dày dặn trong lĩnh vực thẩm mỹ răng, đặc biệt là dán sứ Veneer. Họ có thể đưa ra kế hoạch điều trị phù hợp nhất, 
          đảm bảo tính thẩm mỹ và an toàn cho bạn.
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Sử dụng công nghệ tiên tiến và trang thiết bị hiện đại: Nha khoa hàng đầu thường đầu tư vào công nghệ hiện đại 
          nhất, chẳng hạn như máy scan 3D, phần mềm thiết kế nụ cười, máy CAD/CAM chế tạo Veneer,... Những công nghệ này
           giúp quá trình điều trị chính xác, nhanh chóng và hiệu quả hơn.
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Vật liệu sứ chất lượng cao: Phương Thành sử dụng các loại sứ cao cấp, được nhập khẩu từ các thương hiệu uy tín 
          trên thế giới. Vật liệu chất lượng cao đảm bảo độ bền, tính thẩm mỹ và tuổi thọ của Veneer.
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Cơ sở vật chất khang trang, tiện nghi: Một nha khoa hàng đầu thường có cơ sở vật chất hiện đại, khang trang, 
          tạo cảm giác thoải mái và yên tâm cho khách hàng.
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Chế độ chăm sóc khách hàng chuyên nghiệp và tận tâm: Dịch vụ chăm sóc khách hàng chu đáo, tận tình cũng là một điểm cộng.
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Chế độ bảo hành rõ ràng và minh bạch: Nha khoa uy tín sẽ có chế độ bảo hành rõ ràng cho dịch vụ dán sứ Veneer.
        </span>
      </div>
    </div>

  </div>

</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  data() {
    return {
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  created() {

  },
  methods: {

    toggleFavorite(item) {
      item.favorite = !item.favorite;
    },
  },
};
</script>

<style>

</style>
