<template>
    <!-- Need To Know -->
    <section class="need-to-know-section section-list-service pt-60 pb-60">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="section-header-one section-header-slider text-center pb-30">
              <div class="main-title">Các phương pháp nha khoa thẩm mỹ</div>
            </div>
          </div>
        </div>
        <div class="listing-service pt-30">
          <div class="row d-flex justify-content-center">
            <div class="col-xl-4 col-md-6 col-12">
              <router-link
                  :to="{
                    path: `/nha-khoa-tham-my/mat-dan-su-veneer`,
                  }"
              >
                <div class="service-item">
                  <div class="service-thumb">
                    <a href="">
                      <img width="300" height="208" 
                        src="@/assets/img/phuongthanh/dichvu/dan-su.png"
                        class="attachment-medium size-medium wp-post-image img-dichvu" 
                        alt="Ảnh" 
                        decoding="async" 
                        fetchpriority="high" 
                        srcset="" 
                        sizes="100vw">
                    </a>
                  </div>
                  <div class="service-content">
                    <span style="font-size: 14px;">Giải pháp tiên tiến cho người mất răng</span>
                    <div class="service-title">
                      <h3>
                        <a href="" data-wpel-link="internal">
                          Dán sứ veneer
                        </a>
                      </h3>
                    </div>
                    <div class="service-excerpt">
                      Mặt dán sứ Veneer là một lớp sứ mỏng, được chế tạo riêng biệt cho từng răng 
                      và dán lên bề mặt ngoài của răng thật bằng keo đặc biệt.
                    </div>
                    <div class="service-button">
                      <a href="" data-wpel-link="internal">
                        <div>
                          Xem thêm
                        </div>
                        <i class="fa fa-solid fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-xl-4 col-md-6 col-12">
              <router-link
                  :to="{
                    path: `/nha-khoa-tham-my/boc-rang-su`,
                  }"
              >
                <div class="service-item">
                  <div class="service-thumb">
                    <a href="">
                      <img width="300" height="208" 
                        src="@/assets/img/phuongthanh/dichvu/boc-rang-su.png"
                        class="attachment-medium size-medium wp-post-image img-dichvu" 
                        alt="Ảnh" 
                        decoding="async" 
                        fetchpriority="high" 
                        srcset="" 
                        sizes="100vw">
                    </a>
                  </div>
                  <div class="service-content">
                    <span style="font-size: 14px;">Giải pháp tiên tiến cho người mất răng</span>
                    <div class="service-title">
                      <h3>
                        <a href="" data-wpel-link="internal">
                          Bọc răng sứ
                        </a>
                      </h3>
                    </div>
                    <div class="service-excerpt">
                      Bọc răng sứ là một phương pháp phục hình răng cố định, trong đó mão răng sứ được chế tạo theo 
                      hình dạng và màu sắc của răng thật, sau đó được gắn cố định lên răng thật đã được mài nhỏ. 
                    </div>
                    <div class="service-button">
                      <a href="" data-wpel-link="internal">
                        <div>
                          Xem thêm
                        </div>
                        <i class="fa fa-solid fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-xl-4 col-md-6 col-12">
              <router-link
                  :to="{
                    path: `/nha-khoa-tham-my/phuc-hinh-rang-su-cad-cam`,
                  }"
              >
                <div class="service-item">
                  <div class="service-thumb">
                    <a href="">
                      <img width="300" height="208" 
                        src="@/assets/img/phuongthanh/dichvu/phuc-hinh-rang-su.png"
                        class="attachment-medium size-medium wp-post-image img-dichvu" 
                        alt="Ảnh" 
                        decoding="async" 
                        fetchpriority="high" 
                        srcset="" 
                        sizes="100vw">
                    </a>
                  </div>
                  <div class="service-content">
                    <span style="font-size: 14px;">Giải pháp tiên tiến cho người mất răng</span>
                    <div class="service-title">
                      <h3>
                        <a href="" data-wpel-link="internal">
                          Phục hình răng sứ CAD/CAM
                        </a>
                      </h3>
                    </div>
                    <div class="service-excerpt">
                      Phục hình răng sứ CAD/CAM là công nghệ ứng dụng kỹ thuật số để phục hình răng. 
                      Máy CAD sẽ dùng để thiết kế mẫu răng, còn máy CAM dùng để chế tác răng sứ theo mẫu đã thiết kế.
                    </div>
                    <div class="service-button">
                      <a href="" data-wpel-link="internal">
                        <div>
                          Xem thêm
                        </div>
                        <i class="fa fa-solid fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-xl-4 col-md-6 col-12">
              <router-link
                  :to="{
                    path: `/nha-khoa-tham-my/dieu-tri-cuoi-ho-loi`,
                  }"
              >
                <div class="service-item">
                  <div class="service-thumb">
                    <a href="">
                      <img width="300" height="208" 
                        src="@/assets/img/phuongthanh/dichvu/cuoi-ho-loi.png"
                        class="attachment-medium size-medium wp-post-image img-dichvu" 
                        alt="Ảnh" 
                        decoding="async" 
                        fetchpriority="high" 
                        srcset="" 
                        sizes="100vw">
                    </a>
                  </div>
                  <div class="service-content">
                    <span style="font-size: 14px;">Giải pháp tiên tiến cho người mất răng</span>
                    <div class="service-title">
                      <h3>
                        <a href="" data-wpel-link="internal">
                          Điều trị cười hở lợi
                        </a>
                      </h3>
                    </div>
                    <div class="service-excerpt">
                      Cười hở lợi là tình trạng khi cười làm lộ phần nướu ở hàm trên quá nhiều, khiến cho nụ cười trở 
                      nên kém thu hút, mất thẩm mỹ và khiến cho nhiều người cảm thấy tự ti vì sở hữu đặc điểm này
                    </div>
                    <div class="service-button">
                      <a href="" data-wpel-link="internal">
                        <div>
                          Xem thêm
                        </div>
                        <i class="fa fa-solid fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-xl-4 col-md-6 col-12">
              <router-link
                  :to="{
                    path: `/nha-khoa-tham-my/tay-trang-rang`,
                  }"
              >
                <div class="service-item">
                  <div class="service-thumb">
                    <a href="">
                      <img width="300" height="208" 
                        src="@/assets/img/phuongthanh/dichvu/tay-trang-rang.png"
                        class="attachment-medium size-medium wp-post-image img-dichvu" 
                        alt="Ảnh" 
                        decoding="async" 
                        fetchpriority="high" 
                        srcset="" 
                        sizes="100vw">
                    </a>
                  </div>
                  <div class="service-content">
                    <span style="font-size: 14px;">Giải pháp tiên tiến cho người mất răng</span>
                    <div class="service-title">
                      <h3>
                        <a href="" data-wpel-link="internal">
                          Tẩy trắng răng
                        </a>
                      </h3>
                    </div>
                    <div class="service-excerpt">
                      Tẩy trắng răng là phương pháp giúp răng trở nên trắng sáng hơn bằng cách sử dụng oxy hóa kết hợp 
                      với ánh sáng để làm tan các phân tử tạo màu trong ngà răng
                    </div>
                    <div class="service-button">
                      <a href="" data-wpel-link="internal">
                        <div>
                          Xem thêm
                        </div>
                        <i class="fa fa-solid fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- /Need To Know -->
  
  </template>
  <script>
  import AOS from "aos";
  import "aos/dist/aos.css";
  import indexFiveService from "@/assets/json/indexfiveservice.json";
  export default {
    data() {
      return {
        indexFiveService: indexFiveService,
        list: [],
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
    created() {
      this.getData();
    },
    methods: {
      async getData() {
        let params = {
            start: this.currentPage,
            limit: this.perPage,
            sortBy: this.sortBy,
        }
        await this.$store.dispatch("dichVuStore/getPagingParams", params ).then(res => {
            this.list = res.data.data
        });
    },
      toggleFavorite(item) {
        item.favorite = !item.favorite;
      },
    },
  };
  </script>
  
  <style>
  .img-service{
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 12px;
  }
  
  @media (max-width: 576px) {
    .img-service{
      height: 300px;
    }
  }
  </style>
  