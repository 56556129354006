<template>
  <div class="container pt-60 pb-30">
    <div class="main-title text-center pb-30">Lợi ích khi làm nha khoa tổng quát</div>

    <!-- Khung 2 -->
    <div class="box-luu-y box-secondary">
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Phát hiện và điều trị kịp thời bệnh lý răng miệng.
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Duy trì khả năng ăn nhai và tính thẩm mỹ của răng.
        </span>
      </div>
      <div class="item-luu-y mb-3">
        <img src="@/assets/img/phuongthanh/dichvu/icon-plus.png" alt="">
        <span class="desc-title">
          Là bước đầu tiên khi cấy ghép implant hoặc làm thẩm mỹ răng.
        </span>
      </div>
    </div>

  </div>

</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  data() {
    return {
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  created() {

  },
  methods: {

    toggleFavorite(item) {
      item.favorite = !item.favorite;
    },
  },
};
</script>

<style>

</style>
