<template>
    <div class="container pt-60 pb-60">
      <div class="row">
        <div class="col-md-6 who-trongrang">
          <div>
            <div class="main-title pb-30">Điều trị rối loạn khớp thái dương hàm?</div>
            <div class="desc-title">Đối với chỉnh nha cho trẻ em, quá trình điều trị sẽ được chia làm hai giai đoạn: giai đoạn chỉnh xương, giai đoạn chỉnh nha hoàn thiện</div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Đây là giai đoạn điều chỉnh sai hình xương hàm như hô, móm, hẹp hàm.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Mặc dù chưa có loại thuốc nào có tác dụng đặc trị rối loạn khớp thái dương hàm, nhưng tùy vào mức độ và triệu chứng thì bác sĩ sẽ chỉ định một số loại thuốc giảm đau  phù hợp nhất. 
                Ngoài ra có thể thực hiện thêm biện pháp thư giãn tâm lý giúp bệnh tình nhanh chóng thuyên giảm.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Máng nhai là loại khí cụ mà khi sử dụng giúp cố định lại khớp cắn và giảm bớt áp lực lên phần khớp thái dương hàm.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Nếu khớp cắn không được điều chỉnh hoàn toàn nhờ máng nhai thì có thể cân nhắc mài chỉnh lại khớp cắn sao cho bệnh không bị tái lại.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Chỉnh nha là phương pháp hiệu quả nhất để nắn chỉnh lại khớp cắn .Tuy nhiên việc niềng răng sẽ mất một thời gian dài nên phương pháp này thường được áp dụng kết hợp với các cách chữa bệnh khác.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Một số trường hợp đặc biệt nếu đã thực hiện các phương pháp trên những vẫn chưa mang lại hiệu quả thì khả năng cao người bệnh sẽ được chỉ định phẫu thuật khớp thái dương hàm.
              </div>
            </div>
            
          </div>


        </div>
        <div class="col-md-6">
            <div class="img-trongrang">
                <img src="@/assets/img/phuongthanh/dichvu/who.png" alt="" style="max-height: 500px;">
            </div>
        </div>
      </div>
    </div>



</template>

<script>
  import { ref } from "vue";
  const currentDate = ref(new Date());
  import AOS from "aos";
  import "aos/dist/aos.css";
  import 'vue3-carousel/dist/carousel.css'
  export default {
    components: {
    },
    data() {
      return {
        startdate: currentDate,
        list: [],
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    props: {
      header: { type : Object}
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
    watch:{
      '$props':{
        handler: function (val) {
          this.list = val.header;
        //  console.log("LOG WATCH LIST ", this.list )
        },
        deep: true
      }
    },
    methods: {
      submitForm() {
        this.$router.push("/search-2");
      },
    },
  };
</script>
<style>
</style>
