<template>

  <div class="testimonial-section-ten need-to-know-section pt-60 pb-60">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-12 pb-30 text-center aos" data-aos="fade-up">
          <div class="section-header-one text-center">
            <div class="sub-title pb-10">LỜI CHỨNG THỰC</div>
            <div class="main-title"><span>Hành Trình Nụ Cười Cùng Bạn</span></div>
          </div>
        </div>
        <div class="aos" data-aos="fade-up">
          <swiper
            :spaceBetween="0"
            :navigation="true"
            :pagination="true"
            :loop="true"
            :autoplay="{ delay: 5000, disableOnInteraction: false }"
            :modules="modules"
            class="swiper-news"
          >
            <swiper-slide class="slide-news" v-for="(item, index) in this.list" :key="index.id">
              <div class="camnhan-slide">
                <div class="camnhan-card">
                  <img src="@/assets/img/phuongthanh/core/baidang.png" alt="">
                  <p class="camnhan-noidung">Trước khi đến Nha Khoa Phương Thành, tôi luôn cảm thấy tự ti với nụ cười của mình vì răng 
                    bị lệch và không đều màu. Nhưng nhờ sự tư vấn tận tâm và kế hoạch điều trị chuyên nghiệp 
                    từ đội ngũ bác sĩ, tôi đã hoàn toàn thay đổi. Không chỉ được sử dụng các thiết bị hiện đại, 
                    tôi còn được giải thích kỹ lưỡng về từng bước điều trị. Kết quả là giờ đây tôi đã có một nụ cười 
                    rạng rỡ và tự tin hơn rất nhiều. Cảm ơn Nha Khoa Phương Thành đã mang lại sự thay đổi lớn lao này!
                  </p>
                  <p class="camnhan-name">Nguyễn Thị Lan, Khách hàng thân thiết</p>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <!-- <Carousel ref="carousel" v-bind="settings" :wrapAround="true" :transition="500" :autoplay="5000">
            <Slide v-for="(item, index) in this.list" :key="index.id">
              <div class="camnhan-slide">
                <div class="camnhan-card">
                  <img src="@/assets/img/phuongthanh/core/baidang.png" alt="">
                  <p class="camnhan-noidung">Trước khi đến Nha Khoa Phương Thành, tôi luôn cảm thấy tự ti với nụ cười của mình vì răng 
                    bị lệch và không đều màu. Nhưng nhờ sự tư vấn tận tâm và kế hoạch điều trị chuyên nghiệp 
                    từ đội ngũ bác sĩ, tôi đã hoàn toàn thay đổi. Không chỉ được sử dụng các thiết bị hiện đại, 
                    tôi còn được giải thích kỹ lưỡng về từng bước điều trị. Kết quả là giờ đây tôi đã có một nụ cười 
                    rạng rỡ và tự tin hơn rất nhiều. Cảm ơn Nha Khoa Phương Thành đã mang lại sự thay đổi lớn lao này!
                  </p>
                  <p class="camnhan-name">Nguyễn Thị Lan, Khách hàng thân thiết</p>
                </div>
              </div>
            </Slide>
          </Carousel>
          <div class="btn-w-slide">
            <button class="btn-slide btn-prev" @click="prevSlide"><i class="fa fa-solid fa-angle-left" aria-hidden="true"></i></button>
            <button class="btn-slide btn-next"@click="nextSlide"><i class="fa fa-solid fa-angle-right" aria-hidden="true"></i></button>
          </div> -->
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
import indexFiveDoctor from "@/assets/json/indexfivedoctor.json";
import {Carousel, Slide} from "vue3-carousel";
import 'vue3-carousel/dist/carousel.css'
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
export default {
  components: {
    Carousel,
    Slide,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      modules: [Navigation, Pagination, Autoplay],
      indexFiveDoctor: indexFiveDoctor,
      perPage: 4,
      list: [],
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      settings: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      breakpoints: {
        // 700px and up
        768: {
          itemsToShow: 3,
          snapAlign: 'start',
        },
        // 1024 and up
        992: {
          itemsToShow: 3,
          snapAlign: 'start',
        },
        1200: {
          itemsToShow: 4,
          snapAlign: 'start',
        },
      },
    };
  },

  props: {
    customerReviews: { type : Object}
  },
  watch:{
    '$props':{
      handler: function (val) {
        this.list = val.customerReviews;
      },
      deep: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  created() {
  },
  methods: {
    nextSlide() {
      this.$refs.carousel.next();
    },
    prevSlide() {
      this.$refs.carousel.prev();
    },
  },
};
</script>
