<template>
    <div class="container pt-60 pb-60">
      <div class="row">
        <div class="col-md-6 who-trongrang">
          <div>
            <div class="main-title pb-30">Thời gian chỉnh nha cho trẻ em trong bao lâu</div>
            <div class="desc-title">Đối với chỉnh nha cho trẻ em, quá trình điều trị sẽ được chia làm hai giai đoạn: giai đoạn chỉnh xương, giai đoạn chỉnh nha hoàn thiện</div>
            <p class="pt-20" style="margin-bottom: 0; font-size: 14">* Giai đoạn chỉnh xương</p>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Đây là giai đoạn điều chỉnh sai hình xương hàm như hô, móm, hẹp hàm.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Độ tuổi điều trị: Từ 5-11 tuổi. 
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Thời gian từ 12 – 24 tháng.
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Tùy từng tình trạng, bác sĩ sẽ chỉ định các loại khí cụ chuyên biệt gắn cố định hoặc có thể tháo lắp như Facemask, Twinblock, Quad Helix,...
              </div>
            </div>
            <div class="card-who-trongrang pt-20">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Lưu ý: 
                Đây là giai đoạn này trẻ đang thay răng sữa sang răng vĩnh viễn, xương đang trong quá trình phát triển nên dễ dàng nắn chỉnh.
                Can thiệp trong giai đoạn này giúp điều chỉnh tương quan hàm trên- hàm dưới trở nên hài hòa, hạn chế việc phải nhổ răng hoặc phải phẫu thuật sau này.
                Nếu qua giai đoạn này, hiệu quả điều chỉnh sai hình xương hàm đạt được thường rất ít hoặc không hiệu quả.
                Sau giai đoạn chỉnh xương, bác sĩ tiến hành theo dõi quá trình thay răng sữa và mọc răng vĩnh viễn ở trẻ định kỳ 3 – 6 tháng/lần đến khi vào giai đoạn chỉnh nha hoàn thiện.
              </div>
            </div>

            <p class="pt-30" style="margin-bottom: 0; font-size: 14">* Giai đoạn chỉnh nha hoàn thiện</p>
            <div class="card-who-trongrang pt-10">
              <img src="@/assets/img/phuongthanh/dichvu/stick.png" alt="">
              <div class="desc-title">
                Sau khi thay răng gần hoàn thiện, bác sĩ tiến hành sắp xếp lại răng bị lệch về đúng vị trí trên cung hàm bằng chỉnh nha khay trong suốt hoặc chỉnh nha mắc cài.
              </div>
            </div>
            
          </div>


        </div>
        <div class="col-md-6">
            <div class="img-trongrang">
                <img src="@/assets/img/phuongthanh/dichvu/who.png" alt="" style="max-height: 500px;">
            </div>
        </div>
      </div>
    </div>



</template>

<script>
  import { ref } from "vue";
  const currentDate = ref(new Date());
  import AOS from "aos";
  import "aos/dist/aos.css";
  import 'vue3-carousel/dist/carousel.css'
  export default {
    components: {
    },
    data() {
      return {
        startdate: currentDate,
        list: [],
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    props: {
      header: { type : Object}
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
    watch:{
      '$props':{
        handler: function (val) {
          this.list = val.header;
        //  console.log("LOG WATCH LIST ", this.list )
        },
        deep: true
      }
    },
    methods: {
      submitForm() {
        this.$router.push("/search-2");
      },
    },
  };
</script>
<style>
</style>
