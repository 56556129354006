<template>
    <section class="app-section app-sec-twelve pharmacy-app-sec">
      <div class="container">
        <div class="app-twelve border-0">
          <div class="app-bg">
            <div class="row align-items-center">
              <div class="col-lg-6 col-md-12 aos aos-init aos-animate" data-aos="fade-up">
                <div class="mobile-img" style="text-align: end;">
                  <img src="@/assets/img/phuongthanh/mobile-img.png" class="img-fluid" alt="img">
                </div>
              </div>
              <div class="col-lg-6 col-md-12">
                <div class="app-content">
                  <div class="app-header aos aos-init aos-animate" data-aos="fade-up">
                    <h5>Vì sức khỏe của bạn.</h5>
                    <h2>Tải ứng dụng Nha khoa Phương thành ngay hôm nay!</h2>
                  </div>
                  <div class="app-scan aos aos-init aos-animate" data-aos="fade-up">
                    <p>Quét mã QR để tải ứng dụng!</p>
                    <img src="@/assets/img/phuongthanh/scan-img.png" alt="scan-image">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- /FAQ Section -->
  </template>
  <script>
  import AOS from "aos";
  import "aos/dist/aos.css";
  import Flickity from 'vue-flickity';
  import {Carousel, Pagination, Slide} from "vue3-carousel";
  import 'vue3-carousel/dist/carousel.css'
  export default {
    components: {
      Flickity,
      Carousel,
      Slide,
      Pagination,
    },
    data() {
      return {
        settings: {
          itemsToShow: 1,
          snapAlign: 'center',
        },
        // breakpoints are mobile first
        // any settings not specified will fallback to the carousel settings
        breakpoints: {
          // 700px and up
          576: {
            itemsToShow: 2,
            snapAlign: 'start',
          },
          768: {
            itemsToShow: 2,
            snapAlign: 'start',
          },
          // 1024 and up
          992: {
            itemsToShow: 3,
            snapAlign: 'start',
          },
          1200: {
            itemsToShow: 3,
            snapAlign: 'start',
          },
        },
        flickityOptions: {
          initialIndex: 0,
          initialSlides: 4,
          prevNextButtons: false,
          pageDots: false,
          wrapAround: true,
          autoPlay: 4000

          // any options from Flickity can be used
        },
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
    computed: {
    },
    methods: {
      next() {
        this.$refs.flickity.next();
      },

      previous() {
        this.$refs.flickity.previous();
      },
      toggleFavorite(item) {
        item.favorite = !item.favorite;
      },
    },
  };
  </script>

  <style>


  </style>

