<template>
    <div class="main-Wrapper">
      <pharmacyheader></pharmacyheader>
      <pharmacysidebar></pharmacysidebar>
      <!-- Page Wrapper -->
      <div class="page-wrapper">
        <div class="content container-fluid">
        <pharmacybreadcrumb2 :title="title" />
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-search">
                    <div class="row mb-2">
                        <div class="col-md-12 col-sm-12">
                            <div class="mb-3">
                            <label>Tìm kiếm theo danh mục</label>
                            <VueMultiselect
                                v-model="collectionSearch"
                                :options="listDanhMuc"
                                :multiple="false"
                                track-by="code"
                                class="helo"
                                selectLabel="Nhấn vào để chọn"
                                deselectLabel="Nhấn vào để xóa"
                                label="name"
                                placeholder="Chọn loại danh mục"
                               @select="getData"
                            ></VueMultiselect>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                        <div class="row mb-2">
                            <div class="col-sm-8 col-md-8 d-flex justify-content-left align-items-center">
                                <div
                                    class="col-sm-12 d-flex justify-content-left align-items-center"
                                >
                                <div>
                                Hiển thị
                                <label class="d-inline-flex align-items-center" style="color: #F5E7B2;">
                                    {{ this.list.length }}
                                </label>
                                trên tổng số <span style="color: red; font-weight: bold;">{{ totalRows }}</span> dòng
                                </div>
                                </div>
                            </div>
                            <div class="col-md-4" style="display: flex; justify-content: flex-end; align-items: center;">
                                <div class="card-tools">
                                <a
                                    href="#info_modal"
                                    data-bs-toggle="modal"
                                    size="sm"
                                    type="button"
                                    class="btn btn-tool card-add"
                                >
                                    <i class="fas fa-plus"></i>
                                </a>
                                <button type="button" class="btn btn-tool" @click="getData">
                                    <i class="fas fa-retweet"></i>
                                </button>
                                </div>
                            </div>
                            </div>
                            <div class="custom-new-table">
                                <div class="table-responsive">
                        <table class="table table-hover table-center mb-0">
                            <thead>
                            <th class="col150 cursor td-stt" style="text-align: center;">
                                STT
                            </th>
                            <th class="col150 cursor" style="text-align: left;">
                                Tên
                            </th>
                            <th class="col100 cursor" style="text-align: center;">
                                Code
                            </th>
                            <th class="col100 cursor td-xuly" style="text-align: center;">
                                Xử lý
                            </th>
                        </thead>
                          <tbody>
                          <tr v-for="(item, index) in list" :key="index">
                            <td style="text-align: center">
                              {{ index + ((currentPage-1)*perPage) + 1}}
                            </td>
                            <td style="text-align: left">
                                {{ item.name }}
                            </td>
                            <td style="text-align: center">
                                {{ item.code }}
                            </td>
                            <td style="text-align: center">
                                <a
                                    href="#info_modal"
                                    data-bs-toggle="modal"
                                    size="sm"
                                    type="button"
                                    class="btn btn-outline btn-sm"
                                    v-on:click="handleUpdate(item.id)"
                                >
                                    <i class="fas fa-pencil-alt text-success me-1"></i>
                                </a>
                                <a
                                    href="#delete_user"
                                    data-bs-toggle="modal"
                                    class="btn btn-outline btn-sm"
                                    v-on:click="handleShowDeleteModal(item)"
                                >
                                    <i class="fas fa-trash-alt text-danger me-1"></i>
                                </a>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                            </div>

                      <div
                        class="modal fade"
                        id="info_modal"
                        aria-hidden="true"
                        role="dialog"
                        data-bs-backdrop="static"
                        >
                            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                                <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Thông tin</h5>
                                    <b-button
                                        type="button"
                                        class="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    ></b-button>
                                </div>
                                <div class="modal-body">
                                    <Form
                                        class="login"
                                        @submit="handleSubmit"
                                        :validation-schema="schema"
                                        v-slot="{ errors }"
                                    >
                                        <div class="row">
                                        <div class="col-md-12">
                                            <div class="mb-3">
                                            <label class="text-left">Tên</label>
                                            <span style="color: red">&nbsp;*</span>
                                            <input type="hidden" v-model="model.id"/>
                                            <Field
                                                v-model="model.name"
                                                placeholder="Vui lòng nhập tên"
                                                name="name"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': errors.name }"
                                            />
                                                <div class="invalid-feedback">{{ errors.name }}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="mb-3">
                                            <label class="text-left" >Code</label>
                                            <span style="color: red">&nbsp;*</span>
                                            <input type="hidden" v-model="model.id"/>
                                            <Field
                                                v-model="model.code"
                                                placeholder="Vui lòng nhập code"
                                                name="code"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': errors.code }"
                                            />
                                                <div class="invalid-feedback">{{ errors.code }}</div>
                                            </div>
                                        </div>
                                        </div>
                                        <div class="text-end pt-2 mt-3">
                                            <b-button
                                                type="button"
                                                class="btn si_accept_cancel btn-submit w-md btn-out"
                                                data-bs-dismiss="modal"
                                            >
                                                Đóng
                                            </b-button>
                                            <b-button  type="submit" variant="success" class="ms-1 cs-btn-primary btn-submit w-md">
                                                Lưu
                                            </b-button>
                                        </div>
                                    </form>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="modal fade"
                            id="delete_user"
                            tabindex="-1"
                            role="dialog"
                            aria-hidden="true"
                            data-bs-backdrop="static"
                        >
                            <div class="modal-dialog modal-dialog-centered">
                                <div class="modal-content">
                                    <div class="modal-header">
                                    <h5 class="modal-title" id="acc_title">Xóa</h5>
                                    <b-button
                                        type="button"
                                        class="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    ></b-button>
                                    </div>
                                    <div class="modal-body" style="font-weight: 500;">
                                        <p id="acc_msg">Dữ liệu được chọn sẽ được xóa vĩnh viễn. Bạn có chắc muốn xóa dữ liệu này?</p>
                                    </div>
                                    <div class="modal-footer">
                                    <b-button class="btn btn-delete w-md si_accept_cancel" v-on:click="handleDelete" data-bs-dismiss="modal">
                                        Xóa
                                    </b-button>
                                    <b-button
                                        type="button"
                                        class="btn si_accept_cancel btn-submit w-md btn-out"
                                        data-bs-dismiss="modal"
                                    >
                                        Đóng
                                    </b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                      <div class="row" >
                        <div class="col-md-6 col-sm-6 mt-2">
                          <div>
                            Hiển thị
                            <label class="d-inline-flex align-items-center">
                              <b-form-select
                                  class="form-select form-select-sm"
                                  v-model="perPage"
                                  size="sm"
                                  :options="pageOptions"
                                  style="width: 70px; text-align: center;"
                              ></b-form-select
                              >&nbsp;
                            </label>
                            trên tổng số {{ totalRows }} dòng
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-6 mt-2" style="display: flex; justify-content: flex-end;">
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="totalRows"
                                :per-page="perPage"
                                align="right"
                                size="sm"
                                class="my-0"
                            />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <pharmacymodel />
    <pharmacydelete />
  </template>
  <script >

import VueDatePicker from '@vuepic/vue-datepicker';
import { commonModel } from "@/models/commonModel";
import Treeselect from 'vue3-treeselect';
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';
import {notifyModel} from "@/models/notifyModel";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { Modal } from 'bootstrap';
  export default {
    components: {
      VueDatePicker,
      Treeselect,
      VueMultiselect,
      Form,
    Field,
    },
    data() {
      return {
        title: "Quản lý danh mục",
        model: commonModel.baseJson(),
        list: [],
        listDanhMuc: [],
        currentPage: 1,
        numberOfElement: 1,
        perPage: 5,
        pageOptions: [5, 10, 25, 50, 100],
        totalRows: 1,
        sortBy: 'age',
        sortDesc: false,
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
        theModal: null,
        collectionSearch: {
          code: null,
          name: null,
        },
      };
    },
    name: "pharmacy/user",

    created() {
      this.getListDanhMuc();

    },
    mounted() {
        this.theModal = new Modal(document.getElementById('info_modal'));
        this.collectionSearch = this.listDanhMuc[0];
    },

    setup() {
        const schema = Yup.object().shape({
            name: Yup.string().required("Tên không được bỏ trống !"),
            code: Yup.string().required("Code không được bỏ trống !"),
        });
        return {
            schema,
        };
    },
    methods: {
        async getListDanhMuc(){
            await this.$store.dispatch("commonStore/getList").then((res) =>{
                  if (res != null && res.code ===0) {
                    this.listDanhMuc = res.data || [];
                    if (this.listDanhMuc != null && this.listDanhMuc.length > 0)
                    {
                      this.collectionSearch = this.listDanhMuc.at(0);
                      this.getData();
                    }
                  }
            })

        },
        async getData() {
            let params = {
                start: this.currentPage,
                limit: this.perPage,
                sortBy: this.sortBy,
                collectionName: this.collectionSearch?.code == null ? "" : this.collectionSearch?.code
            }
            await this.$store.dispatch("commonStore/getPagingParams", params ).then(res => {
                  if (res != null && res.code ===0) {
                    this.list = res.data.data
                    this.totalRows = res.data.totalRows
                    this.numberOfElement = res.data.data.length
                  }
            });
        },

        async handleUpdate(id) {
            await this.$store.dispatch("commonStore/getById", commonModel.convertJson({id : id}, this.collectionSearch.code)).then((res) => {
                if (res != null && res.code ===0) {
                    console.log(res)
                    this.model = commonModel.getJson(res.data);
                }
                this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res));
            });
        },
        handleShowDeleteModal(value) {
            this.model = value;
            this.showDeleteModal = true;
        },
        async handleDelete() {
            if (this.model.id != 0 && this.model.id != null && this.model.id) {
                await this.$store.dispatch("commonStore/delete", commonModel.convertJson(this.model, this.collectionSearch.code)).then((res) => {
                if (res != null && res.code ===0) {
                    this.showDeleteModal = false;
                    this.getData();
                }
                this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res));
                });
            }
        },
        async handleSubmit() {
            if (
                this.model.id != 0 &&
                this.model.id != null &&
                this.model.id
            ) {
                await this.$store.dispatch("commonStore/update", commonModel.convertJson(this.model, this.collectionSearch.code)).then((res) => {
                if (res != null && res.code ===0) {

                    this.getData();
                    this.model= commonModel.baseJson()
                    this.theModal.hide();
                }
                this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res));
                });
            } else {
                await this.$store.dispatch("commonStore/create", commonModel.convertJson(this.model, this.collectionSearch.code)).then((res) => {
                if (res != null && res.code ===0) {
                    this.getData();
                    this.model= commonModel.baseJson()
                    this.theModal.hide();
                }
                this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res));
                });

            }
        },

        addNodeToModel(node ){
            if(node != null && node.id){
            }
        },
        normalizer(node){
            if(node.children == null || node.children == 'null'){
                delete node.children;
            }
        },
    }
};
</script>

<style>
.image-dichvu{
    width: 200px;
}
.card .card-search{
    padding: 1.5rem;
    background-color: #ffffff;
    border-bottom: 1px solid #eaeaea;
}
</style>

